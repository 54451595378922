/* eslint-disable class-methods-use-this */
import { GetCustomerResponse } from "pages/customer/queries/useGetCustomer";
import { GetCustomersResponse } from "pages/customers/queries/useGetCustomers";
import { ReactQueryAPI } from "models/core/reactQuery.interface";
import { ToAPI } from "models/core";

import { CustomerAPI } from "./useCustomersUseCases";
import { CustomerDTO } from "./models/customer.interface";

export type CustomerAPIDTO = ReactQueryAPI<
  GetCustomerResponse | GetCustomersResponse
>;

interface Mapper {
  toAPI: ToAPI<CustomerAPIDTO, CustomerAPI>;
}

export class CustomerMapper implements Mapper {
  public toAPI: Mapper["toAPI"] = (api) => {
    const extractDTOs = (data: CustomerAPIDTO["data"]): CustomerDTO[] => {
      if ((data as GetCustomersResponse)?.content)
        return (data as GetCustomersResponse)?.content;
      if (data as GetCustomerResponse) return [data as GetCustomerResponse];
      return [];
    };

    return {
      data: {
        customers: extractDTOs(api?.data),
        count: (api?.data as GetCustomersResponse)?.totalElements ?? 0,
      },
      isLoading: (api?.isLoading || api?.isFetching) ?? false,
      error: api?.error ?? undefined,
    };
  };
}
