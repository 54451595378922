import {
  PeriodFilterParams,
  Summary,
} from "hooks/dashboard/models/dashboard.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export type GetSummaryParams = PeriodFilterParams;
export type GetSummaryResponse = Summary;

export function useGetSummary(input?: GetSummaryParams) {
  return useApiQuery<GetSummaryResponse>(
    ["summary", input?.from, input?.to],
    `dashboards/summary?${new URLSearchParams({
      start_date: input?.from.toString() ?? "",
      end_date: input?.to.toString() ?? "",
    })}`
  );
}
