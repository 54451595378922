import { FC } from "react";
import { Table } from "flowbite-react";
import { format } from "date-fns";
import { invoiceBadgeMap } from "helpers/invoice-badge-map";
import { Currency, toCurrency } from "helpers/to-currency";
import { InvoicePayStatus } from "models/paymentDocument/invoice/invoice.interface";
import Badge from "../Badge";
import { SkeletonRow } from "../skeletons/SkeletonRow";
import { Skeleton } from "../skeletons/Skeleton";
import { SkeletonRows } from "../skeletons/SkeletonRows";
import { RoundedIconDropdown } from "../table/RoundedIconDropdown";

export interface LoadingInvoiceRowsProps {
  filteredOnStatus?: InvoicePayStatus;
  isQuote?: boolean;
  isPurchase?: boolean;
}

export const LoadingInvoiceRows: FC<LoadingInvoiceRowsProps> = function ({
  filteredOnStatus,
  isPurchase,
  isQuote,
}) {
  return (
    <SkeletonRows rows={10}>
      <SkeletonRow>
        <Table.Cell />
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        {isPurchase && (
          <Table.Cell>
            <Skeleton />
          </Table.Cell>
        )}
        <Table.Cell>
          <Skeleton isText>{format(new Date(), "dd/MM/yyyy")}</Skeleton>
        </Table.Cell>
        <Table.Cell>
          <Skeleton isText>{toCurrency(100000, Currency.Euro)}</Skeleton>
        </Table.Cell>
        <Table.Cell>
          <Skeleton className="!rounded">
            <Badge value={filteredOnStatus} map={invoiceBadgeMap} />
          </Skeleton>
        </Table.Cell>
        {!isQuote && (
          <Table.Cell>
            <Skeleton className="!rounded">
              <Badge value={filteredOnStatus} map={invoiceBadgeMap} />
            </Skeleton>
          </Table.Cell>
        )}
        <Table.Cell>
          <Skeleton>
            <RoundedIconDropdown />
          </Skeleton>
        </Table.Cell>
      </SkeletonRow>
    </SkeletonRows>
  );
};
