import { DefaultTFuncReturn } from "i18next";
import { ComponentProps, ReactElement } from "react";
import classnames from "classnames";

interface H1Props extends ComponentProps<"h1"> {
  children: ReactElement | DefaultTFuncReturn | string;
}

export function H1({ children, ...rest }: H1Props) {
  return (
    <h1
      {...rest}
      className={classnames(
        "mb-3 text-2xl font-semibold dark:text-white md:text-3xl",
        rest.className
      )}
    >
      {children}
    </h1>
  );
}
