import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Card } from "flowbite-react";
import { useDashboard } from "domains";
import { getPeriodFilter } from "helpers/get-period-filters";
import { LineChart } from "components/charts/lineChart/LineChart";
import { PeriodFilter, PeriodOption } from "../PeriodFilter";
import { Delta } from "../Delta";
import { useGetEbitdaEvolution } from "../../queries/useGetEbitdaEvolution";

export const EBITDA_EVOLUTION_PERIOD_FILTER = "period_ebitda_evolution";

export const EbitdaEvolutionOverview: FC = function () {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { ebitdaEvolution, ebitdaEvolutionLoading: isLoading } = useDashboard({
    ebitdaEvolution: useGetEbitdaEvolution(
      getPeriodFilter(
        (searchParams.get(EBITDA_EVOLUTION_PERIOD_FILTER) as PeriodOption) ??
          undefined
      )
    ),
  });

  return (
    <Card className="grow">
      <div className="flex justify-between items-center mb-4">
        <p className="text-2xl font-bold dark:text-white">
          {t("dashboardPage.ebitdaEvolution.title")}
        </p>
        <div className="flex gap-4">
          {!isLoading && typeof ebitdaEvolution?.delta === "number" && (
            <Delta delta={ebitdaEvolution.delta ?? 0} bold />
          )}
          <PeriodFilter name={EBITDA_EVOLUTION_PERIOD_FILTER} />
        </div>
      </div>

      <div className="min-h-76 items-center flex">
        <LineChart
          height={300}
          data={isLoading ? [] : ebitdaEvolution.data || []}
          xAxisDataKey="period"
          dataKey="quantity"
          loading={isLoading}
        />
      </div>
    </Card>
  );
};
