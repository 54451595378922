import { FC, PropsWithChildren } from "react";

interface TypeAheadOptionWrapperProps {
  onSelectOption: () => void;
  name?: string;
}

export const TypeAheadOptionWrapper: FC<
  PropsWithChildren<TypeAheadOptionWrapperProps>
> = function ({ onSelectOption, children, name }) {
  return (
    <button
      type="button"
      className="w-full text-start py-2 px-4 border-b border-gray-200 dark:border-gray-700 last:border-0 last:rounded-b-md first:rounded-t-md hover:bg-gray-50 dark:hover:bg-gray-900"
      onMouseDown={onSelectOption}
      name={name}
    >
      {children}
    </button>
  );
};
