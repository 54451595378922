import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { HiUserGroup } from "react-icons/hi";

import { ActionBar, PageHeader } from "components/PageHeader";
import { AUTO_REFRESH_INTERVAL_KEY } from "components/AutoRefresh";
import { EditModal } from "components/modals/EditModal";
import { CustomersTable } from "components/customer/table/CustomersTable";
import { DeleteModal } from "components/modals/DeleteModal";
import { UploadCSV } from "components/UploadCSV";
import { useCustomers, useRoutes, useTables } from "domains";
import { useGetCustomer } from "pages/customer/queries/useGetCustomer";
import { useTablesEffects } from "hooks/tables/useTablesUseCases";

import {
  CustomerForm,
  CustomerFormValues,
} from "components/customer/CustomerForm";
import { CreateCustomerWithAddressInput } from "hooks/customers/models/customer.interface";
import { useGetCustomers } from "./queries/useGetCustomers";
import {
  CUSTOMERS_XSLX_KEY,
  useGetCustomersXLSX,
} from "./queries/useGetCustomersXLSX";

function CustomersPage() {
  const [searchParams] = useSearchParams();
  const [idsToDelete, setIdsToDelete] = useState<string[]>([]);
  const [refetchInterval, setRefetchInterval] = useState<number>(
    parseInt(localStorage.getItem(AUTO_REFRESH_INTERVAL_KEY) ?? "", 10)
  );
  const customerTable = useTables();
  const { routes, goToCustomers, goToCustomer } = useRoutes();
  const { t } = useTranslation();
  const { rowSelectedState, handleAllRowsClicked, handleOneRowClicked } =
    customerTable;
  const { action, id } = useParams();
  useTablesEffects(customerTable);

  const getCustomers = useGetCustomers({
    limit: Number(searchParams.get("limit")) || 10,
    offset: Number(searchParams.get("offset")) || 0,
    search: searchParams.get("search") || "",
    sortKey: searchParams.get("sortKey") || "",
    sortOrder: searchParams.get("sortOrder") || "",
    refetchInterval,
  });

  const getXLSX = useGetCustomersXLSX({
    search: searchParams.get("search") || "",
    sortKey: searchParams.get("sortKey") || "",
    sortOrder: searchParams.get("sortOrder") || "",
  });

  const {
    customers,
    count,
    isLoading: isCustomersLoading,
  } = useCustomers(getCustomers);

  const { customer, create, isLoading, error, deleteMany, uploadCSV } =
    useCustomers(useGetCustomer({ id }));

  const onDeleteCustomer = useCallback(
    () => deleteMany.execute({ ids: idsToDelete }),
    [idsToDelete, deleteMany]
  );

  const onEdit = useCallback(
    (id: string) => {
      goToCustomer(id);
    },
    [goToCustomer]
  );

  useEffect(() => {
    if (action === "delete") {
      const ids = searchParams.getAll("idsToDelete");
      setIdsToDelete(ids);
    }
  }, [action, setIdsToDelete, searchParams]);

  return (
    <div className="flex flex-col">
      <PageHeader
        className="bg-white dark:bg-gray-800"
        title={t("pageHeader.title.customers")}
        breadcrumbs={[
          {
            href: routes.suppliers,
            label: t("sidebar.title.customers"),
            icon: HiUserGroup,
          },
        ]}
      >
        <ActionBar
          showBulkDelete
          pageKey="customers"
          onRefetch={getCustomers.refetch}
          onRefetchIntervalChange={setRefetchInterval}
          rowSelectedState={rowSelectedState}
          queryKey={CUSTOMERS_XSLX_KEY}
          {...getXLSX}
        />
      </PageHeader>
      <CustomersTable
        rows={customers}
        isLoading={isCustomersLoading}
        limit={Number(searchParams.get("limit")) || 10}
        total={count}
        handleAllRowsClicked={handleAllRowsClicked}
        handleOneRowClicked={handleOneRowClicked}
        rowSelectedState={rowSelectedState}
        onEdit={onEdit}
      />
      {action === "create" && (
        <EditModal
          action={action as "create"}
          isError={!!error}
          itemName={customer?.name ?? ""}
          page="customers"
          onClose={goToCustomers}
          show
        >
          <CustomerForm
            isInModal
            withAddress
            isLoading={create.isLoading}
            onSubmit={create.execute as (input: CustomerFormValues) => void}
            schema={create.schema}
            defaultValues={
              {
                name: searchParams.get("initialValue") || "",
              } as CreateCustomerWithAddressInput
            }
          />
        </EditModal>
      )}
      {action === "delete" && !!idsToDelete && (
        <DeleteModal
          isLoading={isLoading}
          isDeleting={deleteMany.isLoading}
          isError={!!error}
          isMultiple={idsToDelete.length > 1}
          page="customers"
          show={!!idsToDelete}
          onClose={goToCustomers}
          onDelete={onDeleteCustomer}
        />
      )}
      <UploadCSV
        action={action}
        uploadCSV={uploadCSV}
        onClose={goToCustomers}
        page="customers"
      />
    </div>
  );
}

export default CustomersPage;
