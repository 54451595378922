import { FC, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Label } from "flowbite-react";
import { LoadingButton } from "components/LoadingButton";
import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "helpers/html-validation-schema";
import { HTMLValidationSchemaResolver } from "helpers/html-validation-schema/compat/HTMLValidationSchemaResolver";
import {
  ConvertTimesheetInput,
  Timesheet,
} from "hooks/timesheets/models/timesheets.interface";
import { Select } from "components/input/Select";
import { useGetJournals } from "pages/journals/queries/useGetJournals";
import { JournalTypes } from "hooks/journals/models/journal.interface";

interface GenerateInvoiceFormProps {
  timesheet: Timesheet;
  isLoading: boolean;
  onSubmit: (input: ConvertTimesheetInput) => void;
  schema:
    | HTMLValidationSchema<ConvertTimesheetInput>
    | HTMLValidationSchemaGenerator<ConvertTimesheetInput>;
}

export const GenerateInvoiceForm: FC<GenerateInvoiceFormProps> = function ({
  isLoading,
  onSubmit,
  timesheet,
  schema,
}) {
  const { t } = useTranslation();

  const { data } = useGetJournals();

  const methods = useForm<ConvertTimesheetInput>({
    defaultValues: {
      timesheetIds: [timesheet?.id],
    },
    mode: "onBlur",
    resolver: HTMLValidationSchemaResolver(schema),
  });

  const filteredJournalsByType = useMemo(
    () => (data || []).filter((j) => j.journalType === JournalTypes.SALES),
    [data]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4 p-6">
          <div className="flex gap-4">
            <div className="w-full">
              <Label htmlFor="country">
                {t("generateInvoiceForm.input.journal")}
              </Label>
              <Select
                {...methods.register("journalId")}
                placeholder={
                  t("generateInvoiceForm.placeholder.journal") as string
                }
                id="journal"
                errorMessage={methods.formState.errors.journalId?.message}
              >
                <option value="">
                  {t("generateInvoiceForm.placeholder.journal")}
                </option>
                {filteredJournalsByType.map((j) => (
                  <option key={j.id} value={j.id}>
                    {j.name}
                  </option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="border-1 border-t p-6 dark:border-gray-700 text-lg">
          <LoadingButton
            loading={isLoading}
            className="px-2 py-0.5"
            type="submit"
          >
            {t("generateInvoiceForm.button.save")}
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
};
