import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Table, Tooltip } from "flowbite-react";
import { useRoutes } from "domains";
import classnames from "classnames";
import {
  HiArrowCircleRight,
  HiArrowCircleLeft,
  HiInformationCircle,
} from "react-icons/hi";
import { RowSelectedState } from "hooks/tables/models/tables.interface";
import { EmptyTableRow } from "components/EmptyTableRow";
import { Checkbox } from "components/Checkbox";
import { Pagination, PaginationProps } from "components/Pagination";
import { Transaction } from "hooks/transactions/models/transaction.interface";
import { SkeletonRow } from "components/skeletons/SkeletonRow";
import { SkeletonRows } from "components/skeletons/SkeletonRows";
import { Skeleton } from "components/skeletons/Skeleton";
import { TableRow } from "components/table/TableRow";
import { Currency, toCurrency } from "helpers/to-currency";
import { format } from "date-fns";
import { Link } from "react-router-dom";

interface Props extends Omit<PaginationProps, "rows"> {
  rows: Transaction[];
  isLoading?: boolean;
  handleAllRowsClicked: (ids: string[]) => void;
  handleOneRowClicked: (id: string) => void;
  rowSelectedState: RowSelectedState;
}

interface TransactionTableRowProps extends Transaction {
  selected: boolean;
  onSelect: (id: string) => void;
}

const LoadingTransactionRows: FC = function () {
  return (
    <SkeletonRows rows={10}>
      <SkeletonRow>
        <Table.Cell className="w-4" />
        <Table.Cell className="w-4" />
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <div className="flex flex-col gap-4">
            <Skeleton />
            <Skeleton className="w-6/12" />
          </div>
        </Table.Cell>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
      </SkeletonRow>
    </SkeletonRows>
  );
};

const TransactionTableRow: FC<TransactionTableRowProps> = function ({
  onSelect,
  selected,
  ...transaction
}) {
  const { t } = useTranslation();
  const { openViewModal, routes } = useRoutes();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onOpenEditModal = useCallback(() => {
    openViewModal(transaction.id);
  }, [transaction, openViewModal]);

  const select = useCallback(
    () => onSelect(transaction.id),
    [transaction, onSelect]
  );

  return (
    <TableRow>
      <Table.Cell>
        <Checkbox onChange={select} checked={selected} />
      </Table.Cell>
      <Table.Cell>
        {transaction.amount > 0 ? (
          <HiArrowCircleRight className="text-2xl" color="green" />
        ) : (
          <HiArrowCircleLeft className="text-2xl" color="red" />
        )}
      </Table.Cell>
      <Table.Cell>
        <p
          className="truncate text-gray-900 dark:text-white font-medium"
          role="cell"
        >
          {transaction.counterpartName ?? "-"}
        </p>
      </Table.Cell>
      <Table.Cell>
        <p
          className="truncate text-gray-900 dark:text-white font-medium"
          role="cell"
        >
          {transaction.counterpartReference ?? "-"}
        </p>
        <p className="truncate text-gray-500 dark:text-gray-400" role="cell">
          {transaction.counterpartBic ?? "-"}
        </p>
      </Table.Cell>
      <Table.Cell>
        <div className="flex align-center">
          <Tooltip className="w-150px" content={transaction?.description}>
            <HiInformationCircle className="mr-2" size="18" />
          </Tooltip>
          <p
            className="truncate text-gray-900 dark:text-white font-medium"
            role="cell"
          >
            {transaction.amount
              ? toCurrency(transaction.amount, Currency.Euro)
              : "-"}{" "}
          </p>
        </div>
      </Table.Cell>
      <Table.Cell>
        {format(new Date(transaction.executionDate), "dd-MM-yyyy HH:mm")}
      </Table.Cell>
      <Table.Cell>
        {transaction.linkedInvoice ? (
          <Link
            to={`${
              transaction.linkedInvoice?.financialDocumentType ===
              "SALES_INVOICE"
                ? routes.salesInvoices
                : routes.purchaseInvoices
            }/${transaction.linkedInvoice?.id}`}
            type="button"
            className={classnames(
              "text-blue-500 dark:text-white font-semibold text-start",
              {
                "hover:underline": transaction.linkedInvoice?.id,
                "cursor-default": !transaction.linkedInvoice?.id,
              }
            )}
          >
            {t("transactionTable.title.viewInvoice")}
            {transaction.linkedInvoice?.invoiceNumber
              ? ` (${transaction.linkedInvoice?.invoiceNumber})`
              : ""}
          </Link>
        ) : (
          <span>-</span>
        )}
      </Table.Cell>
    </TableRow>
  );
};

const TransactionsTable: FC<Props> = function ({
  rows,
  isLoading,
  handleAllRowsClicked,
  handleOneRowClicked,
  rowSelectedState,
  ...rest
}) {
  const { t } = useTranslation();
  // const { goToTransactions } = useRoutes();

  const onAllClicked = useCallback(
    () => handleAllRowsClicked(rows.map((prod) => prod.id)),
    [handleAllRowsClicked, rows]
  );

  return (
    <div className="flex flex-col justify-between overflow-hidden">
      <Table
        hoverable
        className="divide-y divide-gray-200 dark:divide-gray-600 table-fixed"
      >
        <Table.Head className="!bg-gray-100 dark:!bg-gray-700">
          <Table.HeadCell className="w-4">
            <Checkbox
              aria-label="check-all"
              checked={rowSelectedState.all}
              onChange={onAllClicked}
            />
          </Table.HeadCell>
          <Table.HeadCell className="w-4" />
          <Table.HeadCell>
            {t("transactionTable.title.transaction")}
          </Table.HeadCell>
          <Table.HeadCell>{t("transactionTable.title.account")}</Table.HeadCell>
          <Table.HeadCell>
            {t("transactionTable.title.totalPrice")}
          </Table.HeadCell>
          <Table.HeadCell className="w-48">
            {t("transactionTable.title.timestamp")}
          </Table.HeadCell>
          <Table.HeadCell className="w-56">
            {t("transactionTable.title.linkedInvoice")}
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
          {isLoading && <LoadingTransactionRows />}
          {!isLoading &&
            rows.map((transaction) => (
              <TransactionTableRow
                key={transaction.id}
                {...transaction}
                selected={rowSelectedState.ids.includes(transaction.id)}
                onSelect={handleOneRowClicked}
              />
            ))}
          {!isLoading && rows.length === 0 && (
            <EmptyTableRow colSpan={7}>
              {t("transactionTable.title.empty")}
            </EmptyTableRow>
          )}
        </Table.Body>
      </Table>
      <Pagination {...rest} />
    </div>
  );
};

export default TransactionsTable;
