import { JournalTypes } from "hooks/journals/models/journal.interface";
import { createContext, useState, useMemo } from "react";

export type JournalType = {
  journal: {
    [JournalTypes.PURCHASE]?: string;
    [JournalTypes.SALES]?: string;
  };
  setJournal: (value: {
    [JournalTypes.PURCHASE]?: string;
    [JournalTypes.SALES]?: string;
  }) => void;
};

const EMPTY_JOURNAL_CONTEXT_VALUE = {
  [JournalTypes.PURCHASE]: undefined,
  [JournalTypes.SALES]: undefined,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const JournalContext = createContext<any>(undefined);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function JournalContextProvider(props: any) {
  const [journal, setJournal] = useState(EMPTY_JOURNAL_CONTEXT_VALUE);

  const value = useMemo(() => ({ journal, setJournal }), [journal, setJournal]);

  return <JournalContext.Provider value={value} {...props} />;
}
