import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useKBOLookup } from "domains";
import { CountryCode } from "models/address/address.interface";
import { KBOLookupOptionDTO } from "hooks/kboLookup/models/KBOLookup.interface";
import { useGetKBOLookup } from "hooks/kboLookup/queries/useGetKBOLookup";
import { TypeAhead } from "./typeAhead/TypeAhead";
import { TextInputProps } from "./TextInput";
import { TypeAheadOptionWrapper } from "./typeAhead/TypeAheadOptionWrapper";

interface LookupProps extends TextInputProps {
  isEdit?: boolean;
  formKey: string;
  isPublic?: boolean;
  placeholder?: string;
}

interface LookupOptionProps {
  name: string;
  vatNumber: string;
  option: KBOLookupOptionDTO;
  selectOption: (option: KBOLookupOptionDTO) => void;
}

const LookupOption: FC<LookupOptionProps> = function ({
  option,
  selectOption,
}: LookupOptionProps) {
  const onSelectOption = useCallback(
    () => selectOption(option),
    [selectOption, option]
  );

  return (
    <TypeAheadOptionWrapper onSelectOption={onSelectOption}>
      <p className="text-gray-900 dark:text-white font-medium text-md">
        {option.name}
      </p>
      <p className="text-gray-500 dark:text-gray-400 text-sm">
        {option.vatNumber}
      </p>
    </TypeAheadOptionWrapper>
  );
};

export const KBOLookupThreshHold = 3;

export const KBOLookup: FC<LookupProps> = function ({
  formKey,
  isEdit,
  placeholder,
  isPublic,
  ...rest
}: LookupProps) {
  const { setValue, formState, clearErrors, watch } = useFormContext();
  const [search, setSearch] = useState("");
  const getOptions = useGetKBOLookup(
    !isEdit && search?.length >= KBOLookupThreshHold,
    formKey,
    search,
    isPublic
  );
  const { refetch } = getOptions;
  const { options } = useKBOLookup(getOptions);

  const onSelectKBOLookup = useCallback(
    (option: KBOLookupOptionDTO) => {
      const code = option.address.country as unknown;

      setValue("name", option.name);
      setValue("vatNumber", option.vatNumber);
      setValue("address.street", option.address.street);
      setValue("address.number", option.address.number);
      setValue("address.bus", option.address.bus);
      setValue("address.zipCode", option.address.zipCode);
      setValue("address.city", option.address.city);
      setValue(
        "address.country",
        CountryCode[code as keyof typeof CountryCode]
      );

      clearErrors("name");
      clearErrors("vatNumber");
    },
    [setValue, clearErrors]
  );

  useEffect(() => {
    if (!isEdit && search?.length >= KBOLookupThreshHold) {
      refetch();
    }
  }, [search, refetch, isEdit]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setValue(formKey, event.target.value),
    [setValue, formKey]
  );

  return (
    <TypeAhead
      {...rest}
      refetch={setSearch}
      placeholder={placeholder}
      selectedValue={watch(formKey)}
      showEmptyOption={
        options?.length === 0 && search?.length >= KBOLookupThreshHold
      }
      showDropdown={search?.length >= KBOLookupThreshHold && !isEdit}
      emptyOption="KBOLookup.noCompanies"
      onChange={onChange}
      errorMessage={formState.errors[formKey]?.message?.toString() ?? ""}
    >
      {options.map((option) => (
        <LookupOption
          key={`${option.name}_${option.vatNumber}`}
          selectOption={onSelectKBOLookup}
          option={option}
          {...option}
        />
      ))}
    </TypeAhead>
  );
};
