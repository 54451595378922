/* eslint-disable class-methods-use-this */
import { ReactQueryAPI } from "models/core/reactQuery.interface";
import { ToAPI } from "models/core";
import { GetProductGroupsResponse } from "pages/productGroups/queries/useGetProductGroups";
import { GetProductGroupResponse } from "pages/productGroups/queries/useGetProductGroup";
import { ProductGroupAPI } from "./useProductGroupsUseCases";
import { ProductGroup } from "./models/productGroup.interface";

export type ProductGroupAPIDTO = ReactQueryAPI<
  GetProductGroupsResponse | GetProductGroupResponse
>;

interface Mapper {
  toAPI: ToAPI<ProductGroupAPIDTO, ProductGroupAPI>;
}

export class ProductGroupMapper implements Mapper {
  public toAPI: Mapper["toAPI"] = (api) => {
    const extractDTOs = (
      data: ProductGroupAPIDTO["data"]
    ): Omit<ProductGroup, "update">[] => {
      if ((data as GetProductGroupsResponse)?.content)
        return (data as GetProductGroupsResponse)?.content;
      if (!Array.isArray(data as GetProductGroupResponse) && data)
        return [data as GetProductGroupResponse];
      return [];
    };

    return {
      data: {
        productGroups: extractDTOs(api?.data),
        count: (api?.data as GetProductGroupsResponse)?.totalElements ?? 0,
      },
      isLoading: (api?.isLoading || api?.isFetching) ?? false,
      error: api?.error ?? undefined,
    };
  };
}
