import { useApiMutation } from "hooks/api/useApiMutation";

export interface LoginBody {
  email: string;
  password: string;
}

export type LoginResponse = string;

export function useLogin() {
  return useApiMutation<LoginResponse, LoginBody>(
    "login",
    "login",
    (input) => ({
      body: JSON.stringify({
        email: input.email,
        password: input.password,
      }),
    }),
    {},
    async (response) => {
      return response.text();
    }
  );
}
