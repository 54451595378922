import { useTranslation } from "react-i18next";
import { useApiMutation } from "hooks/api/useApiMutation";
import { event } from "event";
import { httpErrorCodeWithTranslations } from "helpers/api";
import { useQueryClient } from "react-query";
import { useRoutes } from "domains";

export type UpdateSubscriptionResponse = boolean;
export type UpdateSubscriptionBody = {
  planId: number;
};

export function useUpdateSubscription() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { goToSubscription } = useRoutes();

  return useApiMutation<UpdateSubscriptionResponse, UpdateSubscriptionBody>(
    "updateSubscription",
    () => `subscriptions/change`,
    (input) => ({
      method: "post",
      body: JSON.stringify({
        planId: input.planId,
      }),
    }),
    {
      onSuccess: () => {
        event.emit("mutationSucceeded", t("subscriptionPage.updated"));
        goToSubscription();
        return Promise.all([queryClient.invalidateQueries(["subscription"])]);
      },
      onError: (error) => {
        // For the time being: error messages are shown from the back-end (still in english).
        // They should be able to provide translated error messages since we
        // send the current language over the "Accept-Language" header
        // See: src/helpers/api.ts

        // They'll need to provide they want us
        // to translate messages on the front-end

        if (error?.response?.payload?.detail) {
          event.emit(
            "mutationFailed",
            new Error(error?.response?.payload?.detail)
          );

          goToSubscription();

          return;
        }
        if (httpErrorCodeWithTranslations.includes(error.response.status)) {
          event.emit(
            "mutationFailed",
            new Error(t(`error.${error.response.status}`) as string)
          );

          goToSubscription();

          return;
        }
        event.emit("mutationFailed", new Error(error.message));
        goToSubscription();
      },
    },
    async (response) => {
      return response.json();
    }
  );
}
