import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

import {
  CreateSupplierApiInput,
  Supplier,
} from "hooks/suppliers/models/supplier.interface";
import { event } from "event";
import { httpErrorCodeWithTranslations } from "helpers/api";
import { useApiMutation } from "hooks/api/useApiMutation";

export type CreateSupplierResponse = Supplier;

export function useCreateSupplier() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useApiMutation<CreateSupplierResponse, CreateSupplierApiInput>(
    "createSupplier",
    "suppliers",
    (input) => ({
      body: JSON.stringify({
        name: input.name,
        email: input.email ?? undefined,
        vatNumber: input.vatNumber ?? undefined,
        addresses: input.addresses ?? [],
      }),
    }),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["suppliers"]);
      },
      onError: (error) => {
        // For the time being: error messages are shown from the back-end (still in english).
        // They should be able to provide translated error messages since we
        // send the current language over the "Accept-Language" header
        // See: src/helpers/api.ts

        // They'll need to provide they want us
        // to translate messages on the front-end

        if (error?.response?.payload?.detail) {
          event.emit(
            "mutationFailed",
            new Error(error?.response?.payload?.detail)
          );

          return;
        }
        if (httpErrorCodeWithTranslations.includes(error.response.status)) {
          event.emit(
            "mutationFailed",
            new Error(t(`error.${error.response.status}`) as string)
          );

          return;
        }

        event.emit("mutationFailed", new Error(error.message));
      },
    },
    async (response) => {
      return response.json();
    }
  );
}
