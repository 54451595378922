import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { QUOTES_QUERY } from "pages/quotes/queries/useGetQuotes";
import { SALES_INVOICES_QUERY } from "pages/salesInvoices/queries/useGetSalesInvoices";
import { useApiMutation } from "hooks/api/useApiMutation";
import { event } from "event";
import { httpErrorCodeWithTranslations } from "helpers/api";

export interface SendQuoteParams {
  id: string;
}

export type SendQuoteResponse = string;

export function useSendQuote() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useApiMutation<SendQuoteResponse, SendQuoteParams>(
    "sendQuote",
    (input) => `quotes/${input.id}/send-to-customer`,
    {
      method: "post",
    },
    {
      onSuccess: () =>
        Promise.all([
          queryClient.invalidateQueries([QUOTES_QUERY]),
          queryClient.invalidateQueries([SALES_INVOICES_QUERY]),
        ]),
      onError: (error) => {
        // For the time being: error messages are shown from the back-end (still in english).
        // They should be able to provide translated error messages since we
        // send the current language over the "Accept-Language" header
        // See: src/helpers/api.ts

        // They'll need to provide they want us
        // to translate messages on the front-end

        if (error?.response?.payload?.detail) {
          event.emit(
            "mutationFailed",
            new Error(error?.response?.payload?.detail)
          );

          return;
        }
        if (httpErrorCodeWithTranslations.includes(error.response.status)) {
          event.emit(
            "mutationFailed",
            new Error(t(`error.${error.response.status}`) as string)
          );

          return;
        }

        event.emit("mutationFailed", new Error(error.message));
      },
    },
    async (response) => {
      return String(response.ok);
    }
  );
}
