/* eslint-disable class-methods-use-this */
import { ToAPI } from "models/core";
import { ReactQueryAPI } from "models/core/reactQuery.interface";
import {
  GetTransactionResponse,
  GetTransactionsResponse,
} from "pages/transactions/queries/useGetTransactions";
import { Transaction } from "./models/transaction.interface";
import { TransactionAPI } from "./useTransactionsUseCases";

export type TransactionAPIDTO = ReactQueryAPI<
  GetTransactionsResponse | GetTransactionResponse
>;

interface Mapper {
  toAPI: ToAPI<TransactionAPIDTO, TransactionAPI>;
}

export class TransactionMapper implements Mapper {
  public toAPI: Mapper["toAPI"] = (api) => {
    const extractDTOs = (
      data: TransactionAPIDTO["data"]
    ): Omit<Transaction, "update">[] => {
      if ((data as GetTransactionsResponse)?.content)
        return (data as GetTransactionsResponse)?.content;
      if (data as GetTransactionResponse)
        return [data as GetTransactionResponse];
      return [];
    };

    return {
      data: {
        transactions: extractDTOs(api?.data),
        count: (api?.data as GetTransactionsResponse)?.totalElements ?? 0,
      },
      isLoading: (api?.isLoading || api?.isFetching) ?? false,
      error: api?.error ?? undefined,
    };
  };
}
