import { useApiQuery } from "../api/useApiQuery";
import { GetSubscriptionResponse } from "./models/subscription.interface";

export const SUBSCRIPTION_QUERY = "subscription";

export function useGetSubscription(detail?: boolean) {
  return useApiQuery<GetSubscriptionResponse>(
    [SUBSCRIPTION_QUERY, detail ? "detail" : "nodetail"],
    "subscriptions/active/details",
    {},
    {
      retry: 0,
      onError: () => {
        return false;
      },
    }
  );
}
