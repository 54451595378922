import type { CustomFlowbiteTheme } from "flowbite-react";

const flowbiteTheme: CustomFlowbiteTheme = {
  navbar: {
    root: {
      base: "bg-nav-background dark:bg-nav-dark-background border-nav-border dark:border-nav-dark-border",
    },
  },
  sidebar: {
    root: {
      base: "bg-sidebar-background dark:bg-sidebar-dark-background border-sidebar-border dark:border-sidebar-dark-border",
      inner:
        "h-full overflow-y-auto overflow-x-hidden rounded py-4 px-3 bg-nav-sidebar dark:bg-sidebar-dark-background border-sidebar-border dark:border-sidebar-dark-border",
    },
    item: {
      base: "group flex items-center justify-center rounded-lg p-2 text-base font-normal text-sidebar-text hover:bg-sidebar-highlight hover:text-sidebar-text-highlight dark:text-sidebar-dark-text dark:hover:bg-sidebar-dark-highlight dark:hover:text-sidebar-dark-text-highlight",
      active:
        "bg-sidebar-highlight dark:bg-sidebar-dark-highlight text-sidebar-text-highlight dark:text-sidebar-dark-text-highlight",
      icon: {
        base: "h-6 w-6 flex-shrink-0 text-sidebar-icon group-hover:text-sidebar-icon-highlight dark:group-hover:text-sidebar-dark-icon-highlight transition duration-75 dark:text-sidebar-dark-icon",
        active:
          "text-sidebar-icon-highlight dark:text-sidebar-dark-icon-highlight group-hover:text-sidebar-icon-highlight dark:group-hover:text-sidebar-dark-icon-highlight",
      },
    },
    collapse: {
      button:
        "group flex w-full items-center rounded-lg p-2 text-base font-normal text-sidebar-text hover:text-sidebar-text-highlight transition duration-75 hover:bg-sidebar-highlight dark:text-sidebar-dark-text dark:hover:bg-sidebar-dark-highlight dark:hover:text-sidebar-dark-text-highlight",
      icon: {
        base: "h-6 w-6 text-sidebar-icon transition duration-75 group-hover:text-sidebar-icon-highlight dark:text-sidebar-dark-icon dark:group-hover:text-sidebar-dark-icon-highlight",
      },
      label: {
        base: "ml-3 flex-1 whitespace-nowrap text-left",
        icon: "h-6 w-6",
      },
      list: "space-y-2 py-2",
    },
  },
  button: {
    color: {
      primary:
        "text-white bg-primary hover:bg-primary-highlight focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800",
    },
    outline: {
      on: "transition-all duration-75 ease-in group-hover:bg-opacity-0 group-hover:text-inherit",
    },
    size: {
      md: "text-sm px-3 py-2",
    },
  },
  dropdown: {
    floating: {
      base: "z-10 w-fit rounded-xl divide-y divide-gray-100 shadow",
      content: "rounded-xl text-sm text-gray-700 dark:text-gray-200",
      target: "w-fit dark:text-white",
    },
    content: "",
  },
  modal: {
    content: {
      inner: "relative rounded-lg bg-white shadow dark:bg-gray-800",
    },
    header: {
      base: "flex items-start justify-between rounded-t px-5 pt-5",
    },
  },
  textarea: {
    base: "block w-full text-sm p-4 rounded-lg border disabled:cursor-not-allowed disabled:opacity-50",
  },
  toggleSwitch: {
    toggle: {
      checked: {
        off: "!border-gray-200 !bg-gray-200 dark:!border-gray-600 dark:!bg-gray-700",
      },
    },
  },
  table: {
    cell: {
      base: "group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg px-6 py-4 !whitespace-normal",
    },
  },
};

export default flowbiteTheme;
