import { FC, KeyboardEvent, useCallback, useMemo } from "react";
import classNames from "classnames";

export interface RadioButtonProps {
  label: string;
  value: string;
  disabled?: boolean;
  selectedValue: string;
  onChange: (value: string) => void;
}

const RadioButton: FC<RadioButtonProps> = function ({
  label,
  value,
  selectedValue,
  onChange,
  disabled = false,
}) {
  const isSelected = useMemo(
    () => selectedValue === value,
    [selectedValue, value]
  );

  const handleInputChange = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLLabelElement>) => {
      if (!disabled && (event.key === "Enter" || event.key === " ")) {
        event.preventDefault();
        onChange(value);
        // Don't scroll page when pressing spacebar when a selected radio button is disabled
      } else if (event.key === " ") {
        event.preventDefault();
      }
    },
    [disabled, onChange, value]
  );

  return (
    <label
      className={classNames(
        `flex-1 text-center cursor-pointer py-3 px-4 border focus:ring-4 focus:ring-blue-300 rounded-md text-sm font-medium`,
        {
          "bg-blue-700 border-gray-300 text-white border-transparent":
            isSelected && !disabled,
          "bg-white dark:bg-transparent text-gray-900 hover:bg-gray-100 dark:text-white dark:border-gray-400":
            !isSelected && !disabled,
          "bg-transparent !cursor-not-allowed border-gray-200 text-gray-400 dark:border-gray-800 dark:text-gray-600":
            disabled,
        }
      )}
      onKeyDown={handleKeyPress}
      tabIndex={0}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="radio"
      aria-checked={isSelected}
      htmlFor={value}
    >
      <input
        type="radio"
        className="sr-only"
        id={value}
        value={value}
        checked={isSelected}
        onChange={handleInputChange}
        aria-hidden="true"
        tabIndex={-1}
        disabled={disabled}
      />
      <span>{label}</span>
    </label>
  );
};

export { RadioButton };
