import { FC } from "react";
import { useTranslation } from "react-i18next";
import { HiCalendar } from "react-icons/hi";
import { Timeline } from "flowbite-react";
import { Reminder } from "models/paymentDocument/invoice/invoice.interface";

interface Props {
  issueDate?: string;
  sentDate?: string;
  payDate?: string;
  reminders?: Reminder[];
}

export const InvoiceTimeline: FC<Props> = function ({
  issueDate,
  payDate,
  sentDate,
  reminders,
}) {
  const { t } = useTranslation();

  if (!issueDate) return null;

  return (
    <Timeline className="w-full">
      <Timeline.Item className={sentDate ? "w-full" : "w-full opacity-25"}>
        <Timeline.Point icon={HiCalendar} />
        <Timeline.Content>
          <Timeline.Time>{sentDate || "-"}</Timeline.Time>
          <Timeline.Title>{t("invoiceTimeline.invoiceSent")}</Timeline.Title>
        </Timeline.Content>
      </Timeline.Item>
      {reminders &&
        reminders?.length > 0 &&
        reminders.map((r) => (
          <Timeline.Item className="w-full" key={`reminder_${r.id}`}>
            <Timeline.Point icon={HiCalendar} />
            <Timeline.Content>
              <Timeline.Time>{r.timeSent}</Timeline.Time>
              <Timeline.Title>
                {t("invoiceTimeline.reminderSent")}
              </Timeline.Title>
            </Timeline.Content>
          </Timeline.Item>
        ))}
      <Timeline.Item className={payDate ? "w-full" : "w-full opacity-25"}>
        <Timeline.Point icon={HiCalendar} />
        <Timeline.Content>
          <Timeline.Time>{payDate || "-"}</Timeline.Time>
          <Timeline.Title>{t("invoiceTimeline.payed")}</Timeline.Title>
        </Timeline.Content>
      </Timeline.Item>
    </Timeline>
  );
};
