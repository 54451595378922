import {
  Avatar,
  DarkThemeToggle,
  Dropdown,
  Navbar as BaseNavbar,
} from "flowbite-react";
import { FC, useCallback } from "react";
import { HiX, HiMenuAlt1 } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";

import { useFile, useRoutes } from "domains";
import { useSidebarContext } from "context/SidebarContext";
import isSmallScreen from "helpers/is-small-screen";
import { JournalTypes } from "hooks/journals/models/journal.interface";
import { useGetFile } from "hooks/file/queries/useGetFile";

import Link from "./Link";
import { JournalSelector } from "./journals/JournalSelector";
import { LanguageSelector } from "./sidebar/LanguageSelector";

const UserDropdown: FC = function () {
  const { t } = useTranslation();
  const { instance } = useMsal();

  const account = instance.getActiveAccount();

  const handleLogout = useCallback(() => {
    instance.logoutRedirect();
  }, [instance]);

  return (
    <Dropdown
      arrowIcon={false}
      inline
      label={
        <span>
          <span className="sr-only">{t("page.button.userMenu")}</span>
          <Avatar
            alt="avatar"
            // picture is not in the msal type
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            img={(account?.idTokenClaims as any)?.picture}
            rounded
            size="sm"
          />
        </span>
      }
      className="overflow-hidden"
    >
      <Dropdown.Header className="w-64">
        <span className="block text-sm">{account?.idTokenClaims?.name}</span>
      </Dropdown.Header>
      <Dropdown.Item onClick={handleLogout}>
        {t("navbar.button.signOut")}
      </Dropdown.Item>
    </Dropdown>
  );
};

const Navbar: FC = function () {
  const { routes } = useRoutes();
  const { isOpenOnSmallScreens, setOpenOnSmallScreens } = useSidebarContext();

  const { themeLogo, logoDark, showCompanyName, name } = useFile(useGetFile());

  const onSidebarToggle = () => {
    setOpenOnSmallScreens(!isOpenOnSmallScreens);
  };

  const { pathname } = window.location;

  return (
    <BaseNavbar
      fluid
      className="border-b border-gray-200 fixed w-full z-30 !p-0"
    >
      <div className="w-full p-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <button
              type="button"
              onClick={onSidebarToggle}
              className="mr-3 cursor-pointer rounded p-2 bg-nav-primary text-nav-icon hover:text-nav-icon-highlight hover:bg-nav-highlight dark:text-nav-dark-icon dark:bg-nav-dark-primary dark:hover:bg-nav-dark-highlight dark:hover:text-nav-dark-icon-highlight focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 lg:inline"
            >
              <span className="sr-only">Toggle sidebar</span>
              {isOpenOnSmallScreens && isSmallScreen() ? (
                <HiX className="h-6 w-6" />
              ) : (
                <HiMenuAlt1 className="h-6 w-6" />
              )}
            </button>

            <BaseNavbar.Brand
              className="rounded focus:outline-none focus:ring-4 focus:ring-gray-200 dark:text-gray-400 dark:focus:ring-gray-700"
              as={Link}
              to={routes.index}
            >
              <div
                style={{ backgroundImage: `url(${themeLogo})` }}
                className="dark:hidden mr-3 h-6 w-40 bg-contain bg-no-repeat"
              />
              <img
                alt=""
                src={logoDark || themeLogo}
                className="hidden dark:block mr-3 h-6"
              />
              {showCompanyName && (
                <span className="self-center text-nav-logo dark:text-nav-dark-logo whitespace-nowrap text-2xl font-semibold">
                  {name}
                </span>
              )}
            </BaseNavbar.Brand>
          </div>
          <div className="flex items-center gap-3">
            {(pathname.includes("invoices") || pathname.includes("quotes")) && (
              <div className="flex items-center">
                <JournalSelector
                  type={
                    pathname.includes("sales") || pathname.includes("quotes")
                      ? JournalTypes.SALES
                      : JournalTypes.PURCHASE
                  }
                />
              </div>
            )}
            <div className="flex items-center mr-2">
              <LanguageSelector />
            </div>
            <div className="flex items-center mr-2">
              <DarkThemeToggle className="bg-nav-primary dark:bg-nav-dark-primary text-nav-text hover:text-nav-text hover:bg-nav-highlight dark:bg-nav dark:text-nav-dark-text dark:hover:bg-nav-dark-highlight dark:hover:text-nav-dark-text" />
            </div>
            <div className="block">
              <UserDropdown />
            </div>
          </div>
        </div>
      </div>
    </BaseNavbar>
  );
};

export default Navbar;
