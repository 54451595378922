import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Label } from "flowbite-react";

import {
  AddressType,
  Country,
  CreateAddressInput,
  UpdateAddressInput,
} from "models/address/address.interface";

import { HTMLValidationSchema } from "helpers/html-validation-schema";
import { HTMLValidationSchemaGenerator } from "helpers/html-validation-schema/models/validationSchema";
import { HTMLValidationSchemaResolver } from "helpers/html-validation-schema/compat/HTMLValidationSchemaResolver";
import { LoadingButton } from "components/LoadingButton";
import { Select } from "components/input/Select";
import { TextInput } from "components/input/TextInput";

export type AddressFormValues = CreateAddressInput | UpdateAddressInput;

interface Props {
  defaultValues?: Partial<AddressFormValues>;
  isLoading?: boolean;
  schema:
    | HTMLValidationSchema<AddressFormValues>
    | HTMLValidationSchemaGenerator<AddressFormValues>;
  onSubmit: (input: AddressFormValues) => void;
}

export const AddressForm: FC<Props> = function ({
  defaultValues,
  isLoading,
  schema,
  onSubmit,
}) {
  const { t } = useTranslation();
  const { handleSubmit, register, formState } = useForm<AddressFormValues>({
    mode: "onBlur",
    defaultValues,
    resolver: HTMLValidationSchemaResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between gap-4 px-6 pt-6">
        <div className="mb-4 flex flex-col flex-1 gap-2">
          <Label htmlFor="street">{t("addressForm.input.street")}</Label>
          <TextInput
            {...register("street")}
            placeholder={t("addressForm.placeholder.street") as string}
            id="street"
            errorMessage={formState.errors.street?.message}
          />
        </div>
        <div className="mb-4 flex flex-col flex-1 gap-2">
          <Label htmlFor="number">{t("addressForm.input.number")}</Label>
          <TextInput
            {...register("number")}
            placeholder={t("addressForm.placeholder.number") as string}
            id="number"
            errorMessage={formState.errors.number?.message}
          />
        </div>
        <div className="mb-4 flex flex-col flex-1 gap-2">
          <Label htmlFor="bus">{t("addressForm.input.bus")}</Label>
          <TextInput
            {...register("bus")}
            placeholder={t("addressForm.placeholder.bus") as string}
            id="bus"
          />
        </div>
      </div>

      <div className="flex justify-between gap-4 px-6">
        <div className="mb-4 flex flex-col flex-1 gap-2">
          <Label htmlFor="zipCode">{t("addressForm.input.zipCode")}</Label>
          <TextInput
            {...register("zipCode")}
            placeholder={t("addressForm.placeholder.zipCode") as string}
            id="zipCode"
            errorMessage={formState.errors.zipCode?.message}
          />
        </div>
        <div className="mb-4 flex flex-col flex-1 gap-2">
          <Label htmlFor="city">{t("addressForm.input.city")}</Label>
          <TextInput
            {...register("city")}
            placeholder={t("addressForm.placeholder.city") as string}
            id="city"
            errorMessage={formState.errors.city?.message}
          />
        </div>
        <div className="mb-4 flex flex-col flex-1 gap-2">
          <Label htmlFor="country">{t("addressForm.input.country")}</Label>
          <Select
            {...register("country")}
            placeholder={t("addressForm.placeholder.country") as string}
            id="country"
            errorMessage={formState.errors.country?.message}
          >
            {Object.values(Country).map((value) => (
              <option key={value} value={value}>
                {t(`country.${value}`)}
              </option>
            ))}
          </Select>
        </div>
      </div>

      <div className="flex justify-between gap-4 px-6">
        <div className="mb-4 flex flex-col flex-1 gap-2">
          <Label htmlFor="address-type">{t("addressForm.input.type")}</Label>
          <Select
            {...register("type")}
            placeholder={t("addressForm.placeholder.type") as string}
            id="address-type"
            errorMessage={formState.errors.type?.message}
          >
            {Object.values(AddressType).map((value) => (
              <option key={value} value={value}>
                {t(`addressType.${value.toLowerCase()}`)}
              </option>
            ))}
          </Select>
        </div>
      </div>

      <div className="border-1 border-t p-6 dark:border-gray-700">
        <LoadingButton
          type="submit"
          loading={isLoading}
          className="px-2 py-0.5"
        >
          {t("addressForm.button.save")}
        </LoadingButton>
      </div>
    </form>
  );
};
