import { useMemo } from "react";
import { useWatch } from "react-hook-form";

import { Address } from "models/address/address.interface";
import { Customer } from "hooks/customers/models/customer.interface";
import { InvoiceFormValues } from "components/invoice/InvoiceForm";
import { isValidInvoiceProduct } from "helpers/is-valid-invoice-product";
import { Product } from "hooks/products/models/product.interface";
import { DocumentLine } from "models/paymentDocument/paymentDocument.interface";

export interface Props {
  customers: Customer[];
  products: Product[];
}

export const useInvoiceFormValues = ({
  customers,
  products: allProducts,
}: Props) => {
  const customerId = useWatch({ name: "customerId" });
  const issueDate = useWatch({ name: "issueDate" });
  const quoteDate = useWatch({ name: "quoteDate" });
  const dueDate = useWatch({ name: "dueDate" });
  const billingAddress = useWatch({ name: "billingAddress" });
  const products = useWatch({ name: "documentLines" });
  const templateAccentColor = useWatch({ name: "template.accentColor" });
  const templateLayout = useWatch({ name: "template.layout" });
  const invoicePayStatus = useWatch({ name: "invoicePayStatus" });
  const currency = useWatch({ name: "currency" });
  const reference = useWatch({ name: "reference" });
  const extraInformation = useWatch({ name: "extraInformation" });
  const discount = useWatch({ name: "discount" });
  const invoicePayType = useWatch<InvoiceFormValues>({
    name: "invoicePayType",
  });

  const selectedCustomer = useMemo(() => {
    return customers.find(({ id }) => id === customerId);
  }, [customers, customerId]);

  const selectedAddress = useMemo<Address | undefined>(() => {
    return selectedCustomer?.addresses.find(
      ({ id }) => id === billingAddress ?? ""
    );
  }, [selectedCustomer, billingAddress]);

  const selectedProducts = useMemo<DocumentLine[]>(() => {
    if (!Array.isArray(products)) {
      return [];
    }

    const validatedProducts = products
      .map((product) => {
        const selectedProduct = allProducts.find(({ id }) => id === product.id);

        if (selectedProduct) {
          return { ...selectedProduct, quantity: product.quantity };
        }

        if (isValidInvoiceProduct(product)) {
          return product;
        }

        return undefined;
      })
      .filter((value) => value !== undefined) as DocumentLine[];

    return validatedProducts;
  }, [products, allProducts]);

  const selectedTemplate = useMemo(() => {
    return {
      id: "",
      accentColor: templateAccentColor,
      layout: templateLayout,
    };
  }, [templateAccentColor, templateLayout]);

  const totalPrice = useMemo(() => {
    return selectedProducts.reduce(
      (total, { amountExclusiveVat = 0, quantity }) => {
        const currentProductTotal = amountExclusiveVat * quantity;
        return total + currentProductTotal;
      },
      0
    );
  }, [selectedProducts]);

  const vatPrice = useMemo(() => {
    return selectedProducts.reduce(
      (total, { amountExclusiveVat = 0, quantity, vatPercentage = 0 }) => {
        const currentProductVatTotal =
          amountExclusiveVat * quantity * (vatPercentage / 100);
        return total + currentProductVatTotal;
      },
      0
    );
  }, [selectedProducts]);

  return {
    selectedAddress,
    selectedCustomer,
    selectedProducts,
    selectedTemplate,
    customerId,
    billingAddress,
    issueDate,
    quoteDate,
    dueDate,
    total: totalPrice,
    vat: vatPrice,
    invoicePayStatus,
    currency,
    invoicePayType,
    reference,
    extraInformation,
    discount,
  };
};
