import { ProductGroup } from "hooks/productGroups/models/productGroup.interface";
import { PaginatedResponse } from "models/schema.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export type GetProductGroupOptionsResponse = PaginatedResponse<
  Omit<ProductGroup, "update">
>;

export function useGetProductGroupOptions(search?: string) {
  return useApiQuery<GetProductGroupOptionsResponse>(
    ["productGroupOptions"],
    `article-groups?${new URLSearchParams({
      search: search ?? "",
    })}`
  );
}
