import { FC, MouseEvent, useCallback } from "react";
import {
  Checkbox as BaseCheckbox,
  CheckboxProps as BaseCheckboxProps,
} from "flowbite-react";

export const Checkbox: FC<BaseCheckboxProps> = function ({ onClick, ...rest }) {
  const click = useCallback(
    (event: MouseEvent<HTMLInputElement>) => {
      event.stopPropagation();
      return onClick?.(event);
    },
    [onClick]
  );

  return <BaseCheckbox className="cursor-pointer" {...rest} onClick={click} />;
};
