import { FC, useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";
import { HiDownload } from "react-icons/hi";

import { XLSXResponse } from "../models/xlsx/xlsx.interface";
import { Button } from "./Button";

export interface DownloadXLSXProps {
  refetch?: () => void;
  data?: XLSXResponse;
  isLoading?: boolean;
  disabled?: boolean;
  queryKey?: string;
}

export const DownloadXLSX: FC<DownloadXLSXProps> = function ({
  refetch,
  data,
  isLoading,
  disabled,
  queryKey,
}) {
  const queryClient = useQueryClient();
  const onDownloadXLSX = useCallback(() => refetch?.(), [refetch]);

  useEffect(() => {
    if (data?.url) {
      const link = document.createElement("a");
      link.href = data.url;
      link.download = data.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    return () => {
      if (queryKey) queryClient.removeQueries({ queryKey });
    };
  }, [data, queryClient, queryKey]);

  return (
    <Button
      onClick={onDownloadXLSX}
      disabled={disabled || isLoading}
      color="gray-500 dark:gray-300"
      className="cursor-pointer rounded !p-0 text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 disabled:cursor-not-allowed disabled:hover:none"
      aria-label="pageHeader.buttons.xlsx"
    >
      <HiDownload size="24" />
    </Button>
  );
};
