import { FC } from "react";
import { Table, TableRowProps } from "flowbite-react";

export const TableRow: FC<TableRowProps> = function ({ ...rest }) {
  return (
    <Table.Row
      className="hover:!bg-gray-100 hover:dark:!bg-gray-700 cursor-pointer"
      {...rest}
    />
  );
};
