// This is a strictly typed version of the default NodeJS EventEmitter.
import { AuthenticationEvents } from "models/events.interface";
import { Emitter as EventEmitter } from "strict-event-emitter";

export type BaseEvents = {
  mutationSucceeded: (message: string) => void;
  mutationFailed: (error: Error) => void;
  validationFailed: (error: Error) => void;
  queryFailed: (error: Error) => void;
};

export type Events = BaseEvents & AuthenticationEvents;

/* eslint-disable @typescript-eslint/no-explicit-any */
type MappedEvents<E extends Record<string, (...args: any) => void>> = {
  [K in keyof E]: Parameters<E[K]>;
};

/**
 * Allows to emit events and listen to them. This allows us to chain different Interaction Domains
 * together without explicitly depending on each other.
 */
export const event = new EventEmitter<MappedEvents<Events>>();
