import { FC, ReactNode } from "react";
import { Modal, Spinner, ModalSizes } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { Button } from "components/Button";

interface EditModalProps {
  action: "edit" | "create" | "upload-csv";
  children: ReactNode;
  isError: boolean;
  isLoading?: boolean;
  itemName?: string;
  page: string;
  show: boolean;
  onClose: () => void;
  showSubtitle?: boolean;
  size?: keyof ModalSizes;
}

export const EditModal: FC<EditModalProps> = function ({
  action,
  children,
  isError,
  isLoading,
  itemName,
  page,
  show,
  showSubtitle,
  onClose,
  size = "2xl",
}) {
  const { t } = useTranslation();

  return (
    <Modal show={show} onClose={onClose} size={size}>
      <Modal.Header className="!p-6 border-b flex items-center dark:border-gray-700">
        {t(`modal.${action}.title.${page}`, { name: itemName })}
        {showSubtitle && (
          <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
            {t(`modal.${action}.subTitle.${page}`, { name: itemName })}
          </div>
        )}
      </Modal.Header>
      <Modal.Body className="!p-0">
        <div
          className="space-y-5 flex flex-col"
          color="text-gray-500 dark:text-gray-400"
        >
          {isLoading && (
            <div className="mx-auto p-5">
              <Spinner />
            </div>
          )}
          {isError && (
            <div className="flex flex-col gap-4 p-5">
              <p className="mx-auto text-base leading-relaxed text-gray-400 dark:text-gray-400">
                {t(`modal.error.${page}`)}
              </p>
              <Button color="gray" onClick={onClose} className="mx-auto">
                {t("modal.error.back")}
              </Button>
            </div>
          )}
          {!isLoading && !isError && children}
        </div>
      </Modal.Body>
    </Modal>
  );
};
