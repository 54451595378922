import { FC } from "react";
import { useTranslation } from "react-i18next";
import { HiBookmark } from "react-icons/hi";
import { useRoutes } from "domains";
import { PageHeader } from "components/PageHeader";
import { Card, Spinner } from "flowbite-react";
import { PaymentInfo } from "components/subscription/PaymentInfo";
import { useGetSubscription } from "hooks/subscription/useGetSubscription";
import { SubscriptionInfo } from "components/subscription/SubscriptionInfo";
import { SubscriptionStats } from "components/subscription/SubscriptionStats";
import { SubscriptionCards } from "components/subscription/SubscriptionCards";
import { TrialCountdown } from "components/subscription/TrialCountdown";
// import { SubscriptionCardsExplanation } from "components/subscription/SubscriptionCardsExplanation";
import { useGetPlans } from "hooks/subscription/useGetPlans";
import { useSearchParams } from "react-router-dom";
import { useActivateSubscription } from "./mutations/useActivateSubscription";
import { useLazyEffect } from "./hooks/useLazyEffect";

interface SubscriptionPageProps {
  activation?: boolean;
}

const SubscriptionPage: FC<SubscriptionPageProps> = function ({ activation }) {
  const { t } = useTranslation();
  const { routes } = useRoutes();

  const [params] = useSearchParams();
  const paymentSessionId = params.get("session_id");
  const planId = localStorage.getItem("planId");

  const { isLoading, data } = useGetSubscription(true);
  const { isLoading: isLoadingPlans, data: plans } = useGetPlans();

  const { mutate, isLoading: isActivating } = useActivateSubscription();

  useLazyEffect(() => {
    if (paymentSessionId && !isActivating)
      mutate({ paymentSessionId, planId: Number(planId) });
  }, [mutate, paymentSessionId, planId, isActivating]);

  return (
    <>
      <PageHeader
        className="bg-white dark:bg-gray-800"
        title={t("subscriptionPage.title.subscription")}
        breadcrumbs={[
          {
            href: routes.subscription,
            label: t("sidebar.title.subscription") as string,
            icon: HiBookmark,
          },
        ]}
      />
      {isLoading || isLoadingPlans || isActivating ? (
        <div className="mx-auto p-5">
          <Spinner size="lg" />
        </div>
      ) : (
        !activation && (
          <div className="m-4 grid grid-cols-1" data-testid="root">
            <div className="col-span-1 gap-4 flex flex-col">
              {!data?.cancelled &&
                data?.plan?.name &&
                data?.plan?.name !== "Trial" && (
                  <Card>
                    <div className="flex flex-col md:flex-row">
                      <SubscriptionInfo subscription={data} />
                      <hr className="w-full h-px mt-8 mb-8 md:h-full md:w-px md:ml-8 md:mr-8 md:mt-0 bg-gray-200 border-0 dark:bg-gray-700" />
                      <PaymentInfo subscription={data} />
                    </div>
                  </Card>
                )}
              {!data?.cancelled &&
                data?.plan?.name &&
                data?.plan?.name !== "Trial" && (
                  <SubscriptionStats subscription={data} />
                )}
              {(data?.cancelled ||
                (data?.plan?.name && data?.plan?.name === "Trial")) && (
                <TrialCountdown
                  subscription={data}
                  cancelled={data?.cancelled}
                />
              )}
              <SubscriptionCards
                subscription={data}
                plans={plans?.content || []}
              />
              {/* <SubscriptionCardsExplanation /> */}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default SubscriptionPage;
