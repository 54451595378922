import { DomainMutation } from "hooks/useDomainMutation";

export enum AddressType {
  Billing = "BILLING",
  Delivery = "DELIVERY",
  Normal = "NORMAL",
}

export enum Country {
  Belgium = "belgium",
  Netherlands = "netherlands",
  Luxembourg = "luxembourg",
  Austria = "austria",
  Bulgaria = "bulgaria",
  Croatia = "croatia",
  Cyprus = "cyprus",
  CzechRepublic = "czech_republic",
  Denmark = "denmark",
  Estonia = "estonia",
  Finland = "finland",
  France = "france",
  Germany = "germany",
  Greece = "greece",
  Hungary = "hungary",
  Ireland = "ireland",
  Italy = "italy",
  Latvia = "latvia",
  Lithuania = "lithuania",
  Malta = "malta",
  Poland = "poland",
  Portugal = "portugal",
  Romania = "romania",
  Slovakia = "slovakia",
  Slovenia = "slovenia",
  Spain = "spain",
  Sweden = "sweden",
  UnitedStates = "united_states",
}

export enum CountryCode {
  BE = Country.Belgium,
  NL = Country.Netherlands,
  LU = Country.Luxembourg,
  AT = Country.Austria,
  BG = Country.Bulgaria,
  HR = Country.Croatia,
  CY = Country.Cyprus,
  CZ = Country.CzechRepublic,
  DK = Country.Denmark,
  EE = Country.Estonia,
  FI = Country.Finland,
  FR = Country.France,
  DE = Country.Germany,
  GR = Country.Greece,
  HU = Country.Hungary,
  IE = Country.Ireland,
  IT = Country.Italy,
  LV = Country.Latvia,
  LT = Country.Lithuania,
  MT = Country.Malta,
  PL = Country.Poland,
  PT = Country.Portugal,
  RO = Country.Romania,
  SK = Country.Slovakia,
  SI = Country.Slovenia,
  ES = Country.Spain,
  SE = Country.Sweden,
  US = Country.UnitedStates,
}

export interface AddressDTO {
  // No id because they don't store addresses in a seperate db table
  street: string;
  number: string;
  bus?: string;
  zipCode: string;
  city: string;
  country: Country;
  type: AddressType;
  description?: string;
}

export interface CreateAddressInput extends AddressDTO {
  id?: never;
}

export interface UpdateAddressInput extends Partial<AddressDTO> {
  id: string;
}

export interface DeleteAddressInput {
  id: string;
}

export interface Address extends AddressDTO {
  id: string;
  update: DomainMutation<UpdateAddressInput>;
  remove: DomainMutation<DeleteAddressInput>;
}

export const createAddressSchema = {
  bus: { required: false },
  city: { required: true },
  country: { required: true },
  number: { required: true },
  zipCode: { required: true },
  street: { required: true },
  type: { required: true },
  description: { required: false },
};
