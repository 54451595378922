import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

import { event } from "event";
import { useApiMutation } from "hooks/api/useApiMutation";
import { httpErrorCodeWithTranslations } from "helpers/api";

import { UpdateInvoiceSettingsInput } from "../models/invoiceSettings.interface";

export type UpdateInvoiceSettingsBody = UpdateInvoiceSettingsInput;

export type UpdateInvoiceSettingsApiInput = Partial<UpdateInvoiceSettingsBody>;

export type UpdateInvoiceSettingsResponse = void;

export function useUpdateInvoiceSettings() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useApiMutation<
    UpdateInvoiceSettingsResponse,
    UpdateInvoiceSettingsApiInput
  >(
    "updateInvoiceSettings",
    () => `company/settings/invoice`,
    (input) => {
      return {
        method: "put",
        body: JSON.stringify({
          invoiceConditions: input.invoiceConditions,
          quoteConditions: input.quoteConditions,
        }),
      };
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["invoiceSettings"]);
      },
      onError: (error) => {
        if (error?.response?.payload?.detail) {
          event.emit(
            "mutationFailed",
            new Error(error?.response?.payload?.detail)
          );

          return;
        }
        if (httpErrorCodeWithTranslations.includes(error.response.status)) {
          event.emit(
            "mutationFailed",
            new Error(t(`error.${error.response.status}`) as string)
          );

          return;
        }

        event.emit("mutationFailed", new Error(error.message));
      },
    }
  );
}
