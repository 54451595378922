import { FC } from "react";
import { useTranslation } from "react-i18next";
import { HiChevronLeft } from "react-icons/hi";

import Link from "components/Link";

interface Props {
  href: string;
  /** Refer to the name of your entity instead of 'page' in the copy of the component. */
  entity?: string;
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const NotFoundBlock: FC<Props> = function ({ href, entity = "page" }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center py-16">
      <img alt="" src="/images/illustrations/404.svg" className="lg:max-w-md" />
      <h1 className="mb-6 text-2xl font-bold dark:text-white md:text-5xl">
        {t("notFoundBlock.title", { entity: capitalizeFirstLetter(entity) })}
      </h1>
      <p className="mb-6 w-2/5 text-center text-lg text-gray-500 dark:text-gray-300">
        {t("notFoundBlock.body", { entity: entity.toLowerCase() })}
      </p>

      <Link to={href} type="button">
        <HiChevronLeft className="mr-2 h-5 w-5 text-2xl" />
        {t("notFoundBlock.cta")}
      </Link>
    </div>
  );
};
