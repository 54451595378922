import { useApiQuery } from "hooks/api/useApiQuery";
import { XLSXResponse } from "models/xlsx/xlsx.interface";

export interface GetProductsXLSXParams {
  search: string;
  sortOrder?: string;
  sortKey?: string;
}

export type GetProductsXLSXResponse = XLSXResponse;

export const PRODUCTS_XSLX_KEY = "productsXLSX";

export function useGetProductsXLSX(input?: GetProductsXLSXParams) {
  return useApiQuery<GetProductsXLSXResponse>(
    [PRODUCTS_XSLX_KEY, input?.search, input?.sortKey, input?.sortOrder],
    `articles-xlsx?${new URLSearchParams({
      search: input?.search ?? "",
      sortKey: input?.sortKey ?? "",
      sortOrder: input?.sortOrder ?? "",
    })}`,
    {},
    {
      enabled: false,
    }
  );
}
