import { useApiQuery } from "hooks/api/useApiQuery";
import { Template } from "hooks/templates/models/template.interface";

export interface GetTemplatesResponse {
  items: Omit<Template, "delete">[];
}

export function useGetTemplates() {
  return useApiQuery<GetTemplatesResponse>(["templates"], `templates`);
}
