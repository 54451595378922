import {
  FC,
  ReactElement,
  useCallback,
  useLayoutEffect,
  useState,
} from "react";
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from "recharts";

import { formatLabel } from "components/charts/helpers/formatLabel";
import { useComponentWidth } from "hooks/useComponentWidth";

import { LoadingBarChart } from "./LoadingBarChart";

import "./BarChart.scss";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<any>;
  labelDataKey: string;
  slicedLabel: boolean;
  bars: Array<{
    color: string;
    dataKey: string;
  }>;

  height?: number;
  loading?: boolean;
  Tooltip?: () => ReactElement;
}

export const BarChart: FC<Props> = function ({
  bars = [],
  data = [],
  height = 275,
  labelDataKey,
  slicedLabel,
  loading = false,
  Tooltip,
}) {
  // componentWidth 620 equals window.innerWidth of 703
  const [layout, setLayout] = useState<"horizontal" | "vertical">(
    window.innerWidth > 703 ? "horizontal" : "vertical"
  );
  const [chartHeight, setChartHeight] = useState<number>(height);

  const [componentWidth, ref] = useComponentWidth();

  useLayoutEffect(() => {
    // Prevent the chart from glitching between layouts when component width is
    // not set yet (e.g. on first renders).
    if (!componentWidth) {
      return;
    }

    if (componentWidth > 620) {
      setLayout("horizontal");
    } else {
      setLayout("vertical");
    }
  }, [componentWidth]);

  useLayoutEffect(() => {
    if (layout === "horizontal") {
      setChartHeight(height);
    } else {
      setChartHeight(data.length * (bars.length * 27.5));
    }
  }, [layout, height, bars.length, data.length]);

  const formatBarChartLabel = useCallback((number: number) => {
    return formatLabel(number);
  }, []);

  return (
    <div className="m-bar-chart w-full" ref={ref}>
      {loading ? (
        <LoadingBarChart />
      ) : (
        <ResponsiveContainer width="100%" height={chartHeight}>
          <RechartsBarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 10,
              left: 20,
              bottom: 10,
            }}
            layout={layout}
            barGap={4}
          >
            <CartesianGrid
              {...(layout === "horizontal"
                ? {
                    vertical: false,
                  }
                : {
                    horizontal: false,
                  })}
              strokeDasharray="6 7"
            />

            <XAxis
              {...(layout === "horizontal"
                ? {
                    dataKey: labelDataKey,
                    tickFormatter: (text: string) =>
                      slicedLabel ? text.slice(0, 3) : text,
                  }
                : {
                    type: "number",
                    tickFormatter: formatBarChartLabel,
                  })}
              stroke="transparant"
              tick={{ dy: 15, fontSize: 12 }}
            />

            <YAxis
              stroke="transparant"
              tickLine={false}
              tick={{
                dx: -15,
                fontSize: 12,
              }}
              {...(layout === "horizontal"
                ? {
                    type: "number",
                    tickFormatter: formatBarChartLabel,
                  }
                : {
                    type: "category",
                    dataKey: labelDataKey,
                    tickFormatter: (text: string) =>
                      slicedLabel ? text.slice(0, 3) : text,
                  })}
            />

            <RechartsTooltip
              {...(typeof Tooltip !== "undefined" && { content: <Tooltip /> })}
            />

            {bars.map((bar) => (
              <Bar
                key={bar.dataKey}
                dataKey={bar.dataKey}
                fill={bar.color}
                radius={
                  layout === "horizontal" ? [10, 10, 0, 0] : [0, 10, 10, 0]
                }
              />
            ))}
          </RechartsBarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};
