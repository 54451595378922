import { useTranslation } from "react-i18next";
import { event } from "event";
import { DomainMutation, useDomainMutation } from "hooks/useDomainMutation";
import { UseCases } from "models/core";
import { API, Mutation } from "models/core/API.interface";
import { UploadCSVInput } from "models/csv/csv.interface";
import {
  ConvertTimesheetInput,
  DeleteTimesheetsInput,
  Timesheet,
  UpdateTimesheetInput,
} from "./models/timesheets.interface";

export type TimesheetAPI = API<{
  timesheets: Omit<Timesheet, "generateInvoice" | "update">[];
  count: number;
}>;
export type GenerateMutation = Mutation<ConvertTimesheetInput>;
export type UpdateMutation = Mutation<UpdateTimesheetInput>;
export type DeleteMutation = Mutation<DeleteTimesheetsInput>;
export type UploadCSVMutation = Mutation<UploadCSVInput>;

interface Props {
  api: TimesheetAPI;
  generateInvoiceMutation: GenerateMutation;
  updateMutation: UpdateMutation;
  deleteMutation: DeleteMutation;
  uploadCSVMutation: UploadCSVMutation;
}

interface Result {
  timesheets: Timesheet[];
  timesheet: Timesheet;
  count: number;
  isLoading: boolean;
  deleteMany: DomainMutation<DeleteTimesheetsInput>;
  uploadCSV: DomainMutation<UploadCSVInput>;
  error?: Error;
}

export const useTimesheetsUseCases: UseCases<Props, Result> = ({
  api,
  generateInvoiceMutation,
  updateMutation,
  deleteMutation,
  uploadCSVMutation,
}) => {
  const { t } = useTranslation();

  const update = useDomainMutation<UpdateTimesheetInput>(
    {
      id: { required: true },
      customerId: { required: true },
      date: { required: true },
      startTime: { required: true },
      endTime: { required: true },
      hourlyRate: { required: true },
      timeToBill: { required: true },
      totalPrice: { required: true },
      description: { required: false },
      duration: { required: false },
    },
    async (input) => {
      await updateMutation(input);
      event.emit("timesheetSaved");
      event.emit("mutationSucceeded", t("domain.timesheet.timesheetSaved"));
    }
  );

  const generateInvoice = useDomainMutation<ConvertTimesheetInput>(
    {
      journalId: { required: true },
      timesheetIds: [{ required: true }],
    },
    async (input) => {
      await generateInvoiceMutation(input);
      event.emit("invoiceGenerated");
      event.emit("mutationSucceeded", t("domain.timesheet.invoiceGenerated"));
    }
  );

  const deleteMany: Result["deleteMany"] =
    useDomainMutation<DeleteTimesheetsInput>(
      {
        ids: [{ required: true }],
      },
      async (input) => {
        await deleteMutation(input);
        event.emit("timesheetsDeleted");
        event.emit(
          "mutationSucceeded",
          t("domain.timesheet.timesheetsDeleted")
        );
      }
    );

  const timesheets: Result["timesheets"] = api.data.timesheets.map(
    (timesheet) => ({ ...timesheet, generateInvoice, update })
  );

  const uploadCSV: Result["uploadCSV"] = useDomainMutation<UploadCSVInput>(
    {
      formData: { required: true },
    },
    async (input) => {
      await uploadCSVMutation(input);

      event.emit("timesheetCSVUploaded");
      event.emit("CSVUploaded");
    }
  );

  return {
    ...api,
    timesheets,
    timesheet: timesheets[0],
    count: api.data.count,
    deleteMany,
    uploadCSV,
  };
};
