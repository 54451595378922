import { FC, useCallback } from "react";
import { useParams } from "react-router";
import { Dropdown, Table } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { HiOutlinePencilAlt, HiTrash } from "react-icons/hi";
import { useRoutes } from "domains";
import { ProductGroup } from "hooks/productGroups/models/productGroup.interface";
import { RowSelectedState } from "hooks/tables/models/tables.interface";
import { Pagination, PaginationProps } from "components/Pagination";
import { EditModal } from "components/modals/EditModal";
import { SkeletonRows } from "components/skeletons/SkeletonRows";
import { Skeleton } from "components/skeletons/Skeleton";
import { SkeletonRow } from "components/skeletons/SkeletonRow";
import { EmptyTableRow } from "components/EmptyTableRow";
import { SortableHeadCell } from "components/table/SortableHeadCell";
import { TableRow } from "components/table/TableRow";
import { Checkbox } from "components/Checkbox";
import { RoundedIconDropdown } from "components/table/RoundedIconDropdown";
import ProductGroupForm, { ProductGroupFormValues } from "./ProductGroupForm";

interface Props extends Omit<PaginationProps, "rows"> {
  rows: ProductGroup[];
  isLoading?: boolean;
  handleAllRowsClicked: (ids: string[]) => void;
  handleOneRowClicked: (id: string) => void;
  rowSelectedState: RowSelectedState;
}

interface ProductGroupTableRowProps extends ProductGroup {
  selected: boolean;
  onSelect: (id: string) => void;
}

type Params = "id" | "action";

const ProductGroupTableRow: FC<ProductGroupTableRowProps> = function ({
  onSelect,
  selected,
  ...productGroup
}) {
  const { t } = useTranslation();
  const { openDeleteModal, openEditModal, goToProductGroups } = useRoutes();
  const { id, action } = useParams<Params>();

  const onOpenDeleteModal = useCallback(
    () => openDeleteModal([productGroup.id]),
    [productGroup, openDeleteModal]
  );

  const onOpenEditModal = useCallback(
    () => openEditModal(productGroup.id),
    [productGroup, openEditModal]
  );

  const select = useCallback(
    () => onSelect(productGroup.id),
    [productGroup, onSelect]
  );

  return (
    <>
      <TableRow onClick={onOpenEditModal}>
        <Table.Cell>
          <Checkbox onChange={select} checked={selected} />
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {productGroup.name}
        </Table.Cell>
        <Table.Cell>
          <RoundedIconDropdown className="w-max mr-2 z-999">
            <Dropdown.Item
              className="flex items-center py-3 rounded-t-lg"
              onClick={onOpenEditModal}
              role="button"
            >
              <HiOutlinePencilAlt className="mr-2 text-lg" />
              {t("productGroupTable.button.edit")}
            </Dropdown.Item>
            <Dropdown.Item
              className="flex items-center py-3 rounded-b-lg"
              onClick={onOpenDeleteModal}
              role="button"
            >
              <HiTrash className="mr-2 text-lg text-red-500 shrink-0" />
              <span className="text-red-500 shrink-0">
                {t("productGroupTable.button.delete")}
              </span>
            </Dropdown.Item>
          </RoundedIconDropdown>
        </Table.Cell>
      </TableRow>
      {action === "edit" && id === productGroup.id && (
        <EditModal
          action={action as "edit"}
          isError={!!productGroup.update.error}
          itemName={productGroup?.name ?? ""}
          page="productGroups"
          onClose={goToProductGroups}
          show
        >
          <ProductGroupForm
            productGroup={productGroup}
            isLoading={productGroup.update.isLoading}
            onSubmit={
              productGroup.update.execute as (
                data: ProductGroupFormValues
              ) => void
            }
            schema={productGroup.update.schema}
          />
        </EditModal>
      )}
    </>
  );
};

const LoadingProductGroupRows: FC = function () {
  return (
    <SkeletonRows rows={10}>
      <SkeletonRow>
        <Table.Cell />
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Skeleton>
            <RoundedIconDropdown />
          </Skeleton>
        </Table.Cell>
      </SkeletonRow>
    </SkeletonRows>
  );
};

const ProductGroupTable: FC<Props> = function ({
  rows,
  isLoading,
  handleAllRowsClicked,
  handleOneRowClicked,
  rowSelectedState,
  ...rest
}) {
  const { t } = useTranslation();

  const onAllClicked = useCallback(
    () => handleAllRowsClicked(rows.map((productGroup) => productGroup.id)),
    [handleAllRowsClicked, rows]
  );
  return (
    <div className="flex flex-col justify-between overflow-hidden">
      <Table
        hoverable
        className="divide-y divide-gray-200 dark:divide-gray-600 table-fixed"
      >
        <Table.Head className="!bg-gray-100 dark:!bg-gray-700">
          <Table.HeadCell className="w-4">
            <Checkbox
              aria-label="check-all"
              checked={rowSelectedState.all}
              onChange={onAllClicked}
            />
          </Table.HeadCell>
          <SortableHeadCell sortKey="name">
            {t("productGroupTable.title.name")}
          </SortableHeadCell>
          <Table.HeadCell className="w-24" />
        </Table.Head>
        <Table.Body className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
          {isLoading && <LoadingProductGroupRows />}
          {!isLoading &&
            rows.map((productGroup) => (
              <ProductGroupTableRow
                key={productGroup.id}
                {...productGroup}
                selected={rowSelectedState.ids.includes(productGroup.id)}
                onSelect={handleOneRowClicked}
              />
            ))}
          {!isLoading && rows.length === 0 && (
            <EmptyTableRow colSpan={3}>
              {t("productGroupTable.title.empty")}
            </EmptyTableRow>
          )}
        </Table.Body>
      </Table>
      <Pagination {...rest} />
    </div>
  );
};

export default ProductGroupTable;
