import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router";
import { HiDocumentReport, HiPaperAirplane, HiReply } from "react-icons/hi";

import {
  useCustomers,
  useProducts,
  useQuotes,
  useRoutes,
  useTemplates,
} from "domains";

import { Breadcrumb, PageHeader } from "components/PageHeader";
import { Button } from "components/Button";
import { DocumentPreview } from "components/invoice/DocumentPreview";
import { InvoiceSidebar } from "components/invoice/InvoiceSidebar";
import { NoHeaderModal } from "components/modals/NoHeaderModal";
import { NotFoundBlock } from "components/errors/404/NotFoundBlock";
import {
  QuoteForm,
  QuoteFormProps,
  QuoteFormValues,
} from "components/quote/QuoteForm";
import { QuoteFormProvider } from "components/quote/QuoteFormProvider";

import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "helpers/html-validation-schema";
import { quoteBadgeMap } from "helpers/invoice-badge-map";

import { ActionPath } from "hooks/routes/useRoutesUseCases";
import { Quote } from "hooks/quotes/models/quote.interface";
import { QuoteStatus } from "models/paymentDocument/invoice/invoice.interface";

import { useGetQuote } from "./queries/useGetQuote";
import { ConvertModal } from "./components/ConvertModal";
import { SendModal } from "./components/SendModal";
import { useGetTemplates } from "../salesInvoice/queries/useGetTemplates";
import { useGetCustomers } from "../customers/queries/useGetCustomers";
import { useGetProducts } from "../products/queries/useGetProducts";

function QuotePage() {
  const [preview, setPreview] =
    useState<Partial<Omit<Quote, "id" | "update">>>();
  const { t } = useTranslation();
  const { id, subAction } = useParams<Params>();
  const {
    routes,
    goToQuotes,
    openSendModal,
    openConvertModal,
    goToQuote,
    action,
  } = useRoutes();
  const { quote, create, convert, send, isLoading } = useQuotes(
    useGetQuote({ id })
  );
  const isDetail = useMemo(() => action === ActionPath.Detail, [action]);
  const { customers } = useCustomers(useGetCustomers());
  const { products } = useProducts(useGetProducts());
  const { templates } = useTemplates(useGetTemplates());

  useEffect(() => {
    if (!preview) {
      setPreview(quote);
    }
  }, [quote, preview]);

  const title = useMemo(() => {
    if (isLoading) return t("pageHeader.title.loading");
    if (!isLoading && quote?.id)
      return t("pageHeader.title.quote", {
        invoiceNumber: quote?.quoteNumber,
      });
    return t("pageHeader.title.createQuote");
  }, [isLoading, quote?.id, quote?.quoteNumber, t]);

  const breadcrumbs = useMemo(() => {
    const crumbs: Breadcrumb[] = [
      {
        href: routes.quotes,
        label: t("sidebar.title.quotes") as string,
        icon: HiDocumentReport,
      },
    ];
    return crumbs;
  }, [routes, t]);

  const onConvert = useCallback(
    () => (quote ? convert.execute({ id: quote.id }) : goToQuotes),
    [quote, convert, goToQuotes]
  );

  const onSend = useCallback(
    () => (quote ? send.execute({ id: quote.id }) : goToQuotes),
    [quote, send, goToQuotes]
  );

  const onClose = useCallback(
    () => (quote ? goToQuote(quote.id) : goToQuotes),
    [goToQuote, goToQuotes, quote]
  );

  if (isDetail && !isLoading && !quote) {
    return (
      <NotFoundBlock
        href={routes.customers}
        entity={t("entity.quote") as string}
      />
    );
  }

  return (
    <>
      <PageHeader
        className="bg-white dark:bg-gray-800"
        title={title}
        badge={quote?.quoteStatus}
        badgeMap={quoteBadgeMap}
        breadcrumbs={breadcrumbs}
        action={
          quote && (
            <div className="flex flex-end">
              <Button
                onClick={openSendModal}
                disabled={quote?.quoteStatus === QuoteStatus.ConvertedToInvoice}
              >
                <HiReply className="mr-2 h-5 w-5 -scale-y-100 -rotate-180" />
                {t("pageHeader.action.send")}
              </Button>
              <Button
                className="ml-4"
                onClick={openConvertModal}
                disabled={quote?.quoteStatus === QuoteStatus.ConvertedToInvoice}
              >
                <HiPaperAirplane className="mr-2 h-5 w-5" />
                {t("pageHeader.action.convert")}
              </Button>
            </div>
          )
        }
      />
      <QuoteFormProvider
        defaultValues={quote}
        schema={
          (quote?.update.schema ?? create.schema) as
            | HTMLValidationSchema<QuoteFormValues>
            | HTMLValidationSchemaGenerator<QuoteFormValues>
        }
      >
        <div className="flex flex-col xl:flex-row xl:m-8 gap-8 grow">
          <InvoiceSidebar>
            <QuoteForm
              customers={customers}
              products={products}
              templates={templates}
              isLoading={quote?.update.isLoading ?? create.isLoading}
              onChange={setPreview}
              onSubmit={
                (quote?.update.execute ??
                  create.execute) as QuoteFormProps["onSubmit"]
              }
            />
          </InvoiceSidebar>
          <div className="w-full flex items-start justify-center overflow-auto mx-auto max-w-screen-lg">
            <DocumentPreview
              customers={customers}
              products={products}
              isLoading={isLoading}
              context="quote"
            />
          </div>
        </div>
      </QuoteFormProvider>
      <NoHeaderModal show={subAction === "convert"} onClose={onClose} size="md">
        <ConvertModal
          onClose={onClose}
          onConvert={onConvert}
          isLoading={convert.isLoading}
        />
      </NoHeaderModal>
      <NoHeaderModal show={subAction === "send"} onClose={onClose} size="md">
        <SendModal
          onClose={onClose}
          onSend={onSend}
          isLoading={send.isLoading}
        />
      </NoHeaderModal>
    </>
  );
}

export default QuotePage;
