import {
  SupplierDTO,
  SupplierDTOWithEntityIds,
} from "hooks/suppliers/models/supplier.interface";

export const addSupplierSubEntitiesIds = (
  supplier: SupplierDTO
): SupplierDTOWithEntityIds => {
  return {
    ...supplier,
    addresses: supplier.addresses?.map((a, index) => ({
      ...a,
      id: index.toString(),
    })),
    bankAccounts: supplier.bankAccounts?.map((b, index) => ({
      ...b,
      id: index.toString(),
    })),
    contacts: supplier.contacts?.map((c, index) => ({
      ...c,
      id: index.toString(),
    })),
  };
};
