/* eslint-disable class-methods-use-this */
import { ReactQueryAPI } from "models/core/reactQuery.interface";
import { ToAPI } from "models/core";
import { GetInvoiceSettingsResponse } from "./models/invoiceSettings.interface";
import { InvoiceSettingsAPI } from "./useInvoiceSettingsUseCases";

export type InvoiceSettingsAPIDTO = ReactQueryAPI<GetInvoiceSettingsResponse>;

interface Mapper {
  toAPI: ToAPI<InvoiceSettingsAPIDTO, InvoiceSettingsAPI>;
}

export class InvoiceSettingsMapper implements Mapper {
  public toAPI: Mapper["toAPI"] = (api) => {
    const extractDTOs = (
      data: InvoiceSettingsAPIDTO["data"]
    ): GetInvoiceSettingsResponse => {
      return data as GetInvoiceSettingsResponse;
    };

    return {
      data: extractDTOs(api?.data),
      isLoading: (api?.isLoading || api?.isFetching) ?? false,
      error: api?.error ?? undefined,
    };
  };
}
