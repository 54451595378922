import { Mutation, UseCases } from "models/core";
import { useAuth } from "hooks/useAuth";
import { HTMLValidationSchema, isValid } from "helpers/html-validation-schema";
import { PATTERN } from "helpers/pattern";
import { useTranslation } from "react-i18next";
import { event } from "event";
import { useCallback, useMemo } from "react";
import { useRoutes } from "domains";
import { useRoutesEffects } from "hooks/routes/useRoutesUseCases";
import { HTMLValidationSchemaGenerator } from "helpers/html-validation-schema/models/validationSchema";
import {
  LoginInput,
  RegisterInput,
} from "./models/authenticationInput.interface";
import { Role, User } from "./models/user.interface";

export type LoginMutation = Mutation<LoginInput, string>;
export type RegisterMutation = Mutation<RegisterInput>;

interface Props {
  loginMutation: LoginMutation;
  registerMutation: RegisterMutation;
  isRegistering: boolean;
}

interface Result {
  user: User;
  loginSchema: HTMLValidationSchema<LoginInput>;
  registerSchema: HTMLValidationSchemaGenerator<RegisterInput>;
  login: (input: LoginInput) => Promise<void>;
  register: (input: RegisterInput) => Promise<void>;
  logout: () => Promise<void>;
  isRegistering: boolean;
}

export const useAuthenticationUseCases: UseCases<Props, Result> = ({
  loginMutation,
  registerMutation,
  isRegistering,
}) => {
  const routesResult = useRoutes();
  useRoutesEffects(routesResult);
  const { token, login: persistToken, logout: removeToken } = useAuth();
  const { t } = useTranslation();

  const loginSchema: Result["loginSchema"] = useMemo(
    () => ({
      email: {
        required: true,
        valueMissing: t("domain.authentication.error.missingValue") as string,
        pattern: PATTERN.EMAIL.source,
        patternMismatch: t(
          "domain.authentication.error.emailPatternMismatch"
        ) as string,
      },
      password: {
        required: true,
        valueMissing: t("domain.authentication.error.missingValue") as string,
      },
    }),
    [t]
  );

  const registerSchema: Result["registerSchema"] = useCallback(
    () => ({
      vatNumber: {
        required: true,
        valueMissing: t("signUpPage.error.vatNumber") as string,
      },
      companyEmail: {
        required: true,
        valueMissing: t("signUpPage.error.companyEmail") as string,
        pattern: PATTERN.EMAIL.source,
        patternMismatch: t(
          "domain.authentication.error.emailPatternMismatch"
        ) as string,
      },
      userFirstname: {
        required: true,
        valueMissing: t("signUpPage.error.userFirstname") as string,
      },
      userLastname: {
        required: true,
        valueMissing: t("signUpPage.error.userLastname") as string,
      },
      userEmail: {
        required: true,
        valueMissing: t("signUpPage.error.userEmail") as string,
        pattern: PATTERN.EMAIL.source,
        patternMismatch: t(
          "domain.authentication.error.emailPatternMismatch"
        ) as string,
      },
      userPassword: {
        required: true,
        minLength: 8,
        tooShort: t("signUpPage.error.min8Chars") as string,
        pattern: PATTERN.PASSWORD.source,
        patternMismatch: t("signUpPage.error.passwordsMustMatch") as string,
        valueMissing: t("signUpPage.error.userPassword") as string,
      },
    }),
    [t]
  );

  const user: Result["user"] = {
    role: !token ? Role.Guest : Role.Admin,
  };

  const login: Result["login"] = useCallback(
    async (input) => {
      try {
        if (isValid(input, loginSchema)) {
          const token = await loginMutation(input);

          if (token) persistToken("token");
        }
      } catch {
        event.emit(
          "mutationFailed",
          new Error(
            t("domain.authentication.error.invalidCredentials") as string
          )
        );
      }
    },
    [loginMutation, loginSchema, persistToken, t]
  );

  const register: Result["register"] = useCallback(
    async (input) => {
      try {
        if (isValid(input, registerSchema(input))) {
          await registerMutation(input);

          event.emit("signUpSuccess");
        }
      } catch (error) {
        if (error instanceof Error) {
          event.emit("signUpError");
          event.emit("mutationFailed", error);
        }
      }
    },
    [registerMutation, registerSchema]
  );

  const logout: Result["logout"] = useCallback(async () => {
    removeToken();
  }, [removeToken]);

  return {
    user,
    loginSchema,
    registerSchema,
    login,
    register,
    logout,
    isRegistering,
  };
};
