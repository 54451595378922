import { FC, useCallback } from "react";
import { Card } from "flowbite-react";
import { useRoutes } from "domains";
import { ConnectableBank } from "hooks/banks/models/bank.interface";

interface Props {
  noUserYet?: boolean;
  notAcceptedLatestTerms?: boolean;
  connectableBank: ConnectableBank;
}

export const ConnectableBankCard: FC<Props> = function ({
  notAcceptedLatestTerms,
  noUserYet,
  connectableBank,
}) {
  const { goToBankAccounts } = useRoutes();

  const handleConnectableBankClick = useCallback(() => {
    if (noUserYet || notAcceptedLatestTerms) {
      goToBankAccounts(
        `/bank-user?connectorId=${connectableBank.connectorID}&nextStep=${
          connectableBank.ibanRequired ? "enter-iban" : "initiate"
        }`
      );
    } else {
      goToBankAccounts(
        `${
          connectableBank?.ibanRequired ? "/enter-iban" : "/initiate"
        }?connectorId=${connectableBank.connectorID}`
      );
    }
  }, [connectableBank, noUserYet, notAcceptedLatestTerms, goToBankAccounts]);

  return (
    <Card
      onClick={handleConnectableBankClick}
      key={connectableBank.connectorID}
      className="max-w-sm cursor-pointer"
    >
      <div className="flex flex-grow flex-col items-center">
        <div className="relative max-h-28 flex flex-grow w-full">
          <img
            alt={connectableBank.fullname}
            src={connectableBank.logoURL}
            className="mb-3 rounded-full flex flex-grow"
          />
        </div>
        <h5 className="text-medium text-center font-medium text-gray-900 dark:text-white">
          {connectableBank.fullname}
        </h5>
      </div>
    </Card>
  );
};
