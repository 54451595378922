import { authErrorCount } from "components/msal/config";
import { useApiQuery } from "../../api/useApiQuery";
import { GetFileResponse } from "../models/file.interface";

export const FILE_QUERY = "file";

export function useGetFile(authErrors?: number) {
  return useApiQuery<GetFileResponse>(
    [FILE_QUERY],
    "company",
    {},
    {
      staleTime: 10000000000,
      enabled: !authErrors || authErrors >= 5,
      retry: 0,
      onSuccess: () => {
        localStorage.removeItem(authErrorCount);
      },
      onError: () => {
        const count = Number(localStorage.getItem(authErrorCount));
        localStorage.setItem(authErrorCount, String(count + 1));
      },
    }
  );
}
