import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "flowbite-react";
import { LanguageOption } from "./LanguageOption";

const FLAGS: Record<string, string> = {
  fr: "/images/flags/fr.svg",
  en: "/images/flags/gb.svg",
  nl: "/images/flags/nl.svg",
};

export const LOCAL_STORAGE_LANGUAGE = "language";

export const LanguageSelector: FC = function () {
  const { t, i18n } = useTranslation();
  const languages = useMemo(
    () => Object.keys(i18n.store.data),
    [i18n.store.data]
  );

  const onChange = useCallback(
    (language: string) => {
      i18n.changeLanguage(language);
      localStorage.setItem(LOCAL_STORAGE_LANGUAGE, language);
    },
    [i18n]
  );

  return (
    <Dropdown
      inline
      arrowIcon={false}
      trigger="hover"
      placement="bottom-end"
      label={
        <img
          width="24"
          alt={t(`language.${i18n.language}`) as string}
          src={FLAGS[i18n.language]}
        />
      }
    >
      {languages.map((language) => (
        <LanguageOption
          key={language}
          value={language}
          flag={FLAGS[language]}
          onClick={onChange}
        />
      ))}
    </Dropdown>
  );
};
