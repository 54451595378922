import { SupplierDTO } from "hooks/suppliers/models/supplier.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export interface GetSupplierParams {
  id?: string;
}

export type GetSupplierResponse = SupplierDTO;

export function useGetSupplier({ id }: GetSupplierParams) {
  return useApiQuery<GetSupplierResponse>(
    ["suppliers", id],
    `suppliers/${id}`,
    {},
    { enabled: !!id }
  );
}
