import { FC, ReactNode, useMemo } from "react";
import {
  Cell,
  Pie,
  PieChart as BasePieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import { LoadingPieChart } from "./LoadingPieChart";
import { PieChartTooltip } from "./PieChartTooltip";

import "./PieChart.scss";

interface PieChartData {
  name: string;
  value: number;
}

interface Props {
  data: PieChartData[];
  dataKey?: string;
  colors: string[];
  height?: number;
  loading?: boolean;
  title?: ReactNode;
  isCurrency?: boolean;
  onSelectCell: (entry: string, isSales?: boolean) => void;
  isSales?: boolean;
}

const PieChart: FC<Props> = function ({
  height = 266,
  data = [],
  colors,
  dataKey = "value",
  loading,
  title,
  isCurrency,
  onSelectCell,
  isSales,
}) {
  const cells = useMemo(() => {
    return data.map((entry, index) => {
      return (
        <Cell
          key={entry.name}
          fill={colors[index % colors.length]}
          name={entry.name}
          onClick={() => onSelectCell(entry.name, isSales)}
          className="hover:cursor-pointer"
        />
      );
    });
  }, [data, onSelectCell, isSales, colors]);

  if (loading) {
    return <LoadingPieChart />;
  }

  return (
    <div className="w-full relative">
      <ResponsiveContainer width="99%" height={height}>
        <BasePieChart>
          <Pie
            data={data}
            dataKey={dataKey}
            cx="50%"
            cy="50%"
            innerRadius={80}
            outerRadius={110}
            paddingAngle={5}
            startAngle={90}
            endAngle={450}
            stroke="none"
          >
            {cells}
          </Pie>
          <Tooltip
            content={({ active, payload }) => (
              <PieChartTooltip
                isCurrency={isCurrency}
                active={active}
                payload={payload}
              />
            )}
          />
        </BasePieChart>
      </ResponsiveContainer>
      {title}
    </div>
  );
};

export { PieChart };
