import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Label } from "flowbite-react";

import {
  CreateBankAccountInput,
  UpdateBankAccountInput,
} from "models/bankAccount/bankAccount.interface";
import { HTMLValidationSchema } from "helpers/html-validation-schema";
import { HTMLValidationSchemaResolver } from "helpers/html-validation-schema/compat/HTMLValidationSchemaResolver";
import { HTMLValidationSchemaGenerator } from "helpers/html-validation-schema/models/validationSchema";
import { LoadingButton } from "components/LoadingButton";
import { TextInput } from "components/input/TextInput";

export type BankAccountFormValues =
  | CreateBankAccountInput
  | UpdateBankAccountInput;

interface Props {
  defaultValues?: Partial<BankAccountFormValues>;
  isLoading: boolean;
  schema:
    | HTMLValidationSchema<BankAccountFormValues>
    | HTMLValidationSchemaGenerator<BankAccountFormValues>;
  onSubmit: (input: BankAccountFormValues) => void;
}

export const BankAccountForm: FC<Props> = function ({
  defaultValues,
  isLoading,
  schema,
  onSubmit,
}) {
  const { t } = useTranslation();
  const { handleSubmit, register, formState } = useForm<BankAccountFormValues>({
    mode: "onBlur",
    defaultValues,
    resolver: HTMLValidationSchemaResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between gap-4 p-6">
        <div className="mb-4 flex flex-col flex-1 gap-2">
          <Label htmlFor="description">
            {t("bankAccountForm.input.description")}
          </Label>
          <TextInput
            {...register("description")}
            placeholder={t("bankAccountForm.placeholder.description") as string}
            id="description"
            errorMessage={formState.errors.description?.message}
          />
        </div>
        <div className="mb-4 flex flex-col flex-1 gap-2">
          <Label htmlFor="iban">{t("bankAccountForm.input.iban")}</Label>
          <TextInput
            {...register("iban")}
            placeholder={t("bankAccountForm.placeholder.iban") as string}
            id="iban"
            errorMessage={formState.errors.iban?.message}
          />
        </div>
        <div className="mb-4 flex flex-col flex-1 gap-2">
          <Label htmlFor="bic">{t("bankAccountForm.input.bic")}</Label>
          <TextInput
            {...register("bic")}
            placeholder={t("bankAccountForm.placeholder.bic") as string}
            id="bic"
            errorMessage={formState.errors.bic?.message}
          />
        </div>
      </div>

      <div className="border-1 border-t p-6 dark:border-gray-700">
        <LoadingButton
          type="submit"
          loading={isLoading}
          className="px-2 py-0.5"
        >
          {t("bankAccountForm.button.save")}
        </LoadingButton>
      </div>
    </form>
  );
};
