import { useApiQuery } from "hooks/api/useApiQuery";
import { XLSXResponse } from "models/xlsx/xlsx.interface";

export interface GetProductGroupsXLSXParams {
  search: string;
  sortOrder?: string;
  sortKey?: string;
}

export type GetProductGroupsXLSXResponse = XLSXResponse;

export const PRODUCT_GROUPS_XSLX_KEY = "productGroupsXLSX";

export function useGetProductGroupsXLSX(input?: GetProductGroupsXLSXParams) {
  return useApiQuery<GetProductGroupsXLSXResponse>(
    [PRODUCT_GROUPS_XSLX_KEY, input?.search, input?.sortKey, input?.sortOrder],
    `article-groups-xlsx?${new URLSearchParams({
      search: input?.search ?? "",
      sortOrder: input?.sortOrder ?? "",
      sortKey: input?.sortKey ?? "",
    })}`,
    {},
    { enabled: false }
  );
}
