import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { Label } from "flowbite-react";
import classnames from "classnames";

import { UpdateFileInput } from "hooks/file/models/file.interface";
import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "helpers/html-validation-schema";
import { HTMLValidationSchemaResolver } from "helpers/html-validation-schema/compat/HTMLValidationSchemaResolver";
import { LoadingButton } from "components/LoadingButton";
import { TextInput } from "components/input/TextInput";
import { Country } from "models/address/address.interface";
import { Select } from "components/input/Select";
import { H2 } from "components/headers/H2";

export type CompanyFormValues = UpdateFileInput;

interface Props {
  defaultValues?: CompanyFormValues;
  isLoading?: boolean;
  schema:
    | HTMLValidationSchema<CompanyFormValues>
    | HTMLValidationSchemaGenerator<CompanyFormValues>;
  onSubmit: (input: CompanyFormValues) => void;
}

export const CompanyForm: FC<Props> = function ({
  defaultValues,
  isLoading,
  schema,
  onSubmit,
}) {
  const { t } = useTranslation();
  const methods = useForm<CompanyFormValues>({
    mode: "onBlur",
    defaultValues,
    resolver: HTMLValidationSchemaResolver(schema),
  });

  return (
    <>
      <H2>{t("companyForm.title")}</H2>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4">
            <div className={classnames("flex flex-col gap-4")}>
              <div className="flex gap-4">
                <div className="w-1/2">
                  <div className="flex flex-col flex-1 gap-2">
                    <Label htmlFor="companyForm.name">
                      {t("companyForm.input.name")}
                    </Label>
                    <TextInput
                      {...methods.register("name")}
                      placeholder={t("companyForm.placeholder.name") as string}
                      id="companyForm.name"
                      errorMessage={methods.formState.errors.name?.message}
                      type="text"
                    />
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="flex flex-col flex-1 gap-2">
                    <Label htmlFor="companyForm.vatNumber">
                      {t("companyForm.input.vatNumber")}
                    </Label>
                    <TextInput
                      disabled
                      {...methods.register("vatNumber")}
                      placeholder={t("companyForm.placeholder.email") as string}
                      id="companyForm.email"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="flex flex-col flex-1 gap-2">
                  <Label htmlFor="companyForm.email">
                    {t("companyForm.input.email")}
                  </Label>
                  <TextInput
                    {...methods.register("email")}
                    placeholder={t("companyForm.placeholder.email") as string}
                    id="companyForm.email"
                    errorMessage={methods.formState.errors.email?.message}
                    type="email"
                  />
                </div>
              </div>
            </div>
            <div className="pt-0">
              <hr className="h-px mt-2 mb-6 bg-gray-200 border-0 dark:bg-gray-700" />
              <div className={classnames("flex flex-col", "gap-4  rounded-lg")}>
                <div className="flex gap-4">
                  <div className="w-1/3">
                    <div className="mb-2 block">
                      <Label htmlFor="street">
                        {t("companyForm.input.street")}
                      </Label>
                    </div>
                    <TextInput
                      {...methods.register("billingAddress.street")}
                      placeholder={
                        t("companyForm.placeholder.street") as string
                      }
                      id="companyForm.street"
                      errorMessage={
                        methods.formState.errors.billingAddress?.street?.message
                      }
                      type="text"
                    />
                  </div>
                  <div className="w-1/3">
                    <div className="mb-2 block">
                      <Label htmlFor="number">
                        {t("companyForm.input.number")}
                      </Label>
                    </div>
                    <TextInput
                      {...methods.register("billingAddress.number")}
                      placeholder={
                        t("companyForm.placeholder.number") as string
                      }
                      id="companyForm.number"
                      errorMessage={
                        methods.formState.errors.billingAddress?.number?.message
                      }
                      type="text"
                    />
                  </div>
                  <div className="w-1/3">
                    <div className="mb-2 block">
                      <Label htmlFor="bus">{t("companyForm.input.bus")}</Label>
                    </div>
                    <TextInput
                      {...methods.register("billingAddress.bus")}
                      placeholder={t("companyForm.placeholder.bus") as string}
                      id="bus"
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="w-1/3">
                    <div className="mb-2 block">
                      <Label htmlFor="zipCode">
                        {t("companyForm.input.zipCode")}
                      </Label>
                    </div>
                    <TextInput
                      {...methods.register("billingAddress.zipCode")}
                      placeholder={
                        t("companyForm.placeholder.zipCode") as string
                      }
                      id="zipCode"
                      errorMessage={
                        methods.formState.errors.billingAddress?.zipCode
                          ?.message
                      }
                    />
                  </div>
                  <div className="w-1/3">
                    <div className="mb-2 block">
                      <Label htmlFor="city">
                        {t("companyForm.input.city")}
                      </Label>
                    </div>
                    <TextInput
                      {...methods.register("billingAddress.city")}
                      placeholder={t("companyForm.placeholder.city") as string}
                      id="city"
                      errorMessage={
                        methods.formState.errors.billingAddress?.city?.message
                      }
                    />
                  </div>
                  <div className="w-1/3">
                    <div className="mb-2 block">
                      <Label htmlFor="country">
                        {t("companyForm.input.country")}
                      </Label>
                    </div>
                    <Select
                      {...methods.register("billingAddress.country")}
                      placeholder={
                        t("companyForm.placeholder.country") as string
                      }
                      id="country"
                      errorMessage={
                        methods.formState.errors.billingAddress?.country
                          ?.message
                      }
                    >
                      {Object.values(Country).map((value) => (
                        <option key={value} value={value}>
                          {t(`country.${value}`)}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-0 mb-6">
              <hr className="h-px mt-2 mb-6 bg-gray-200 border-0 dark:bg-gray-700" />
              <div className={classnames("flex flex-col", "gap-4  rounded-lg")}>
                <div className="flex gap-4">
                  <div className="w-1/2">
                    <div className="mb-2 block">
                      <Label htmlFor="companyForm.bankAccount.iban">
                        {t("companyForm.input.iban")}
                      </Label>
                    </div>
                    <TextInput
                      {...methods.register("bankAccount.iban")}
                      placeholder={t("companyForm.placeholder.iban") as string}
                      id="companyForm.bankAccount.iban"
                      errorMessage={
                        methods.formState.errors.bankAccount?.iban?.message
                      }
                      type="text"
                    />
                  </div>
                  <div className="w-1/2">
                    <div className="mb-2 block">
                      <Label htmlFor="companyForm.bankAccount.bic">
                        {t("companyForm.input.bic")}
                      </Label>
                    </div>
                    <TextInput
                      {...methods.register("bankAccount.bic")}
                      placeholder={t("companyForm.placeholder.bic") as string}
                      id="companyForm.bankAccount.bic"
                      errorMessage={
                        methods.formState.errors.bankAccount?.bic?.message
                      }
                      type="text"
                    />
                  </div>
                </div>
                <div className="flex">
                  <div className="w-full">
                    <div className="mb-2 block">
                      <Label htmlFor="description">
                        {t("companyForm.input.description")}
                      </Label>
                    </div>
                    <TextInput
                      {...methods.register("bankAccount.description")}
                      placeholder={
                        t("companyForm.placeholder.description") as string
                      }
                      id="description"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <LoadingButton type="submit" loading={isLoading}>
              {t("companyForm.button.save")}
            </LoadingButton>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
