import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { H2 } from "components/headers/H2";
import { Card, CardProps } from "flowbite-react";

const GeneralInfoCard: FC<PropsWithChildren<CardProps>> = function ({
  children,
  ...rest
}) {
  const { t } = useTranslation();

  return (
    <Card {...rest}>
      <H2>{t("generalInfoCard.title.generalInfo")}</H2>
      {children}
    </Card>
  );
};

export default GeneralInfoCard;
