import { FC, useCallback } from "react";
import { HiOutlineDownload } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";

interface DownloadButtonProps {
  url: string;
  name: string;
}

export const DownloadButton: FC<DownloadButtonProps> = function ({
  url,
  name,
}: DownloadButtonProps) {
  const { t } = useTranslation();

  const onDownload = useCallback(() => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [url, name]);

  return (
    <Button onClick={onDownload}>
      <HiOutlineDownload className="text-xl mr-2" />
      {t("downloadHeader.downloadInvoice")}
    </Button>
  );
};
