import { Timesheet } from "hooks/timesheets/models/timesheets.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export interface GetTimesheetParams {
  id?: Timesheet["id"];
}

export type GetTimesheetResponse = Timesheet;

export function useGetTimesheet({ id }: GetTimesheetParams) {
  return useApiQuery<GetTimesheetResponse>(
    ["timesheet", id],
    `timesheets/${id}`,
    {},
    { enabled: !!id }
  );
}
