import { FC } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineCheckCircle } from "react-icons/hi";

import { Button } from "./Button";

interface UploadSuccessProps {
  onClose: () => void;
  onUploadNew: () => void;
  message: string;
}

export const UploadSuccess: FC<UploadSuccessProps> = function ({
  onClose,
  onUploadNew,
  message,
}) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col space-y-5 justify-center items-center">
      <HiOutlineCheckCircle
        size="35"
        className="text-green-400 dark:text-green-500"
      />
      <p className="text-center text-gray-400 dark:text-gray-400">
        {t(`uploadSuccess.${message}`)}
      </p>
      <div className="flex gap-4">
        <Button onClick={onUploadNew}>{t("uploadSuccess.btn.new")}</Button>
        <Button color="gray" onClick={onClose}>
          {t("uploadSuccess.btn.cancel")}
        </Button>
      </div>
    </div>
  );
};
