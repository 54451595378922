import { useApiQuery } from "../../api/useApiQuery";
import { GetInvoiceSettingsResponse } from "../models/invoiceSettings.interface";

export function useGetInvoiceSettings() {
  return useApiQuery<GetInvoiceSettingsResponse>(
    ["invoiceSettings"],
    "company/settings/invoice",
    {},
    {}
  );
}
