import { FC, ReactNode } from "react";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import MsalToken from "./MsalToken";

interface MsalAuthProps {
  children: ReactNode;
  pca: PublicClientApplication;
}

const MsalAuth: FC<MsalAuthProps> = function ({ children, pca }) {
  return (
    <MsalProvider instance={pca}>
      <MsalToken>{children}</MsalToken>
    </MsalProvider>
  );
};

export default MsalAuth;
