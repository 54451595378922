import { FC, useMemo } from "react";
import { HiArrowNarrowDown, HiArrowNarrowUp } from "react-icons/hi";
import classNames from "classnames";

interface DeltaProps {
  isCost?: boolean;
  showSign?: boolean;
  bold?: boolean;
  delta: number;
}

export const Delta: FC<DeltaProps> = function ({
  isCost,
  delta,
  showSign,
  bold,
}) {
  const deltaColor = useMemo(() => {
    const isPositiveDelta = delta >= 0;

    if (isCost) {
      return isPositiveDelta ? "text-red-500" : "text-green-500";
    }
    return isPositiveDelta ? "text-green-500" : "text-red-500";
  }, [isCost, delta]);

  return (
    <p
      className={classNames(`flex items-center gap-1 ${deltaColor}`, {
        "font-bold": bold,
      })}
    >
      {showSign && (delta >= 0 ? "+" : "")}
      {delta}%{delta >= 0 ? <HiArrowNarrowUp /> : <HiArrowNarrowDown />}
    </p>
  );
};
