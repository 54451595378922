import { FC, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
// import { Spinner, Alert } from "flowbite-react";
import {
  HiChevronLeft,
  HiChevronRight,
  HiOutlineArrowsExpand,
  HiZoomIn,
  HiZoomOut,
  HiMinusSm,
} from "react-icons/hi";
import "../../../styles/PurchaseInvoiceStyle.scss";

import { Button } from "components/Button";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface PurchaseInvoiceProps {
  url: string;
  type?: string;
}

export const PurchaseInvoice: FC<PurchaseInvoiceProps> = function ({
  url,
  type,
}: PurchaseInvoiceProps) {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [scale, setScale] = useState(1);
  const [imgWidth, setImgWidth] = useState<number>();

  const imgRef = useRef<HTMLImageElement>(null);

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => setNumPages(numPages),
    [setNumPages]
  );

  const isPDF = useMemo(() => type?.includes("pdf"), [type]);

  const onPrevious = useCallback(() => setPage(page - 1), [setPage, page]);
  const onNext = useCallback(() => setPage(page + 1), [setPage, page]);
  const onZoomIn = useCallback(() => {
    if (isPDF) {
      return setScale(scale + 0.5);
    }

    const width = imgRef.current?.clientWidth;
    return setImgWidth((width || 0) + 60);
  }, [setScale, scale, setImgWidth, isPDF]);
  const onZoomOut = useCallback(() => {
    if (isPDF) {
      return setScale(scale - 0.5);
    }

    const width = imgRef.current?.clientWidth;
    return setImgWidth((width || 0) - 60);
  }, [setScale, scale, setImgWidth, isPDF]);
  const onResetScale = useCallback(() => {
    return isPDF ? setScale(1) : setImgWidth(undefined);
  }, [setScale, isPDF]);

  return (
    <div className="flex flex-col gap-8 h-full items-center justify-start pt-5">
      {numPages && numPages > 0 && (
        <div className="h-12 bg-white dark:bg-gray-800 rounded-lg shadow-sm flex justify-between border border-gray-200 dark:border-gray-700 min-w-min w-96 max-w-full">
          <div className="flex gap-1 items-center h-full">
            <Button
              type="button"
              onClick={onZoomOut}
              disabled={isPDF ? scale === 0.5 : (imgWidth || 61) <= 60}
              pill
              className="!p-0 !border-0 focus:!ring-0"
              color="light"
            >
              <HiZoomOut size={25} />
            </Button>
            <Button
              type="button"
              onClick={onZoomIn}
              pill
              className="!p-0 !border-0 focus:!ring-0"
              color="light"
            >
              <HiZoomIn size={25} />
            </Button>
            <Button
              type="button"
              onClick={onResetScale}
              disabled={isPDF ? scale === 1 : false}
              pill
              className="!p-0 !border-0 focus:!ring-0"
              color="light"
            >
              <HiOutlineArrowsExpand size={25} />
            </Button>
          </div>
          <div className="flex items-center h-full">
            <Button
              type="button"
              onClick={onPrevious}
              disabled={page === 1}
              pill
              className="!p-0 !border-0 focus:!ring-0"
              color="light"
            >
              <HiChevronLeft size={25} />
            </Button>
            <p className="dark:text-white flex-shrink-0 w-14 text-center">
              {page} <span className="mx-2">/</span> {numPages || 1}
            </p>
            <Button
              type="button"
              onClick={onNext}
              disabled={page === (numPages || 1)}
              pill
              className="!p-0 !border-0 focus:!ring-0"
              color="light"
            >
              <HiChevronRight size={25} />
            </Button>
          </div>
        </div>
      )}
      {!isPDF && (
        <div className="w-full flex-grow overflow-x-auto">
          <img
            ref={imgRef}
            width={imgWidth}
            src={url}
            alt="purchase-invoice"
            className="h-min max-w-full object-contain rounded-lg mx-auto"
          />
        </div>
      )}
      {isPDF && (
        <Document
          file={url}
          className="w-full h-full flex justify-center"
          onLoadSuccess={onDocumentLoadSuccess}
          loading={
            <div
              role="status"
              className="w-full h-full space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center"
            >
              <div className="flex items-center justify-center w-full h-full bg-gray-300 rounded dark:bg-gray-700">
                <svg
                  className="w-full h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
            </div>
          }
          noData={
            <div className="flex flex-col align-center">
              <div className="w-12 h-12 rounded-full bg-blue-100 dark:bg-blue-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                <HiMinusSm size="medium" />
              </div>
              <p className="mb-4 text-lg text-center font-semibold text-gray-900 dark:text-white">
                {t(`purchaseInvoicePage.preview.pdfNotFound`) as string}
              </p>
            </div>
          }
        >
          <Page
            pageNumber={page}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            className="shadow-sm overflow-auto w-full !min-w-full rounded-lg"
            scale={scale}
          />
        </Document>
      )}
    </div>
  );
};
