import { FC } from "react";
import { Navigate } from "react-router-dom";
import { PrivateProps } from "./Private.interface";

export const Private: FC<PrivateProps> = function ({
  isAuthorized,
  redirectTo,
  children,
}) {
  return isAuthorized ? children : <Navigate to={redirectTo} />;
};
