import { FetchError } from "hooks/api/useApiQuery";
import { UseCases } from "models/core";
import { API } from "models/core/API.interface";
import { KBOLookupOptionDTO } from "./models/KBOLookup.interface";

export type KBOLookupAPI = API<
  {
    options: KBOLookupOptionDTO[];
  },
  FetchError
>;

interface Props {
  api: KBOLookupAPI;
}

interface Result {
  options: KBOLookupOptionDTO[];
  isLoading: boolean;
  error?: Error;
}

export const useKBOLookupUseCases: UseCases<Props, Result> = ({ api }) => {
  let { options } = api.data;
  const { error } = api;

  if (error?.cause?.status === 400) {
    options = [];
  }

  return {
    ...api,
    options,
  };
};
