import { useApiQuery } from "hooks/api/useApiQuery";
import { XLSXResponse } from "models/xlsx/xlsx.interface";

export interface GetCustomersXLSXParams {
  search: string;
  sortOrder?: string;
  sortKey?: string;
}

export type GetCustomersXLSXResponse = XLSXResponse;

export const CUSTOMERS_XSLX_KEY = "customersXLSX";

export function useGetCustomersXLSX(input?: GetCustomersXLSXParams) {
  return useApiQuery<GetCustomersXLSXResponse>(
    [CUSTOMERS_XSLX_KEY, input?.search, input?.sortKey, input?.sortOrder],
    `customers-xlsx?${new URLSearchParams({
      search: input?.search ?? "",
      sortKey: input?.sortKey ?? "",
      sortOrder: input?.sortOrder ?? "",
    })}`,
    {},
    {
      enabled: false,
    }
  );
}
