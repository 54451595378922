import { useEffect } from "react";
import { t } from "i18next";
import { event } from "event";
import { API, Mutation } from "models/core/API.interface";
import { UseCases } from "models/core";
import { PATTERN } from "helpers/pattern";
import { useDomainMutation } from "hooks/useDomainMutation";
import {
  GetFileResponse,
  UpdateFileInput,
  File as FileType,
} from "./models/file.interface";
import { UpdateFileApiInput } from "./mutations/useUpdateFile";

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const w11Theme = {
  primary: "#cf4948",
  primaryHighlight: "#d45d5c",
  nav: {
    background: "#cf4948",
    text: "#ffffff",
    icon: "#ffffff",
    "icon-highlight": "#ffffff",
    highlight: "#df5554",
    border: "#c93635",
    logo: "#ffffff",
  },
  navDark: {
    background: "#8d2827",
    text: "#ffffff",
    icon: "#ffffff",
    "icon-highlight": "#ffffff",
    highlight: "#631b1b",
    border: "#270b0b",
    logo: "#ffffff",
  },
  sidebar: {
    text: "#372F38",
    "text-highlight": "#ffffff",
    icon: "#cf4948",
    "icon-highlight": "#ffffff",
    highlight: "#df5554",
  },
  sidebarDark: {
    text: "#ffffff",
    icon: "#ffffff",
    "icon-highlight": "#ffffff",
    highlight: "#aa2c2b",
  },
};

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const defaultTheme = {
  primary: "#3363e9",
  primaryHighlight: "#2b55d3",
  nav: {
    background: "#ffffff",
    text: "#4d5562",
    icon: "#4d5562",
    "icon-highlight": "#4d5562",
    highlight: "#f3f4f6",
    border: "#e6e7eb",
    logo: "#000001",
  },
  navDark: {
    background: "#212936",
    text: "#9ca3ad",
    icon: "#9ca3ad",
    "icon-highlight": "#9ca3ad",
    highlight: "#394151",
    border: "#394150",
    logo: "#ffffff",
  },
  sidebar: {
    text: "#111827",
    "text-highlight": "#111827",
    icon: "#6c727f",
    "icon-highlight": "#111827",
    highlight: "#f3f4f6",
  },
  sidebarDark: {
    text: "#ffffff",
    icon: "#ffffff",
    "icon-highlight": "#ffffff",
    highlight: "#394151",
  },
};

export type FileAPI = API<GetFileResponse>;

export type UpdateFileMutation = Mutation<UpdateFileApiInput>;

interface Props {
  api: FileAPI;
  updateMutation: UpdateFileMutation;
}

interface Result extends FileType {
  isLoading: boolean;
}

export const useFileUseCases: UseCases<Props, Result> = ({
  api,
  updateMutation,
}) => {
  const file = api.data;

  useEffect(() => {
    if (api.error) {
      event.emit(
        "queryFailed",
        new Error(t("domain.file.error.400") as string)
      );
    }
  }, [api?.error]);

  const update = useDomainMutation<UpdateFileInput>(
    {
      name: { required: true },
      vatNumber: {
        required: false,
      },
      email: {
        required: true,
        valueMissing: t("error.valueMissing") as string,
        pattern: PATTERN.EMAIL.source,
        patternMismatch: t(
          "domain.authentication.error.emailPatternMismatch"
        ) as string,
      },
      billingAddress: {
        street: { required: true },
        number: { required: true },
        bus: { required: false },
        zipCode: { required: true },
        city: { required: true },
        country: { required: true },
      },
      bankAccount: {
        iban: {
          required: false,
          pattern: PATTERN.IBAN.source,
          patternMismatch: t("ibanForm.validation") as string,
        },
        bic: { required: false },
        description: { required: false },
      },
    },
    async (input) => {
      await updateMutation({
        ...input,
      });

      event.emit("mutationSucceeded", t("domain.company.companySaved"));
    }
  );

  return {
    ...api,
    ...file,
    theme: file?.theme || defaultTheme,
    companyLogo: file?.companyLogo,
    themeLogo: file?.themeLogo,
    logoDark: file?.logoDark,
    name: file?.name || "Admisol", // TODO: replace
    vatNumber: file?.vatNumber || "BE 0471.029.822", // TODO: replace
    showCompanyName: Boolean(file?.showCompanyName),
    address: file?.address || {
      street: "Ferdinand Lousbergskaai",
      number: "103",
      bus: "",
      zipCode: "9000",
      city: "Gent",
    }, // TODO: replace
    defaultLanguage: file?.defaultLanguage || "en",
    update,
  };
};
