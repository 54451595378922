import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { event } from "event";
import { HTMLValidationSchemaGenerator } from "helpers/html-validation-schema";
import { PATTERN } from "helpers/pattern";
import { DomainMutation, useDomainMutation } from "hooks/useDomainMutation";
import { API } from "models/core/API.interface";
import { Mutation, UseCases } from "models/core";
import {
  DiscountTypes,
  InvoicePayStatus,
} from "models/paymentDocument/invoice/invoice.interface";
import {
  CreateInvoiceInput,
  UpdateInvoiceInput,
} from "models/paymentDocument/invoice/invoiceInput.interface";
import { UploadCSVInput } from "models/csv/csv.interface";
import { DeleteSalesInvoicesInput } from "./models/salesInvoiceInput.interface";
import { SalesInvoice } from "./models/salesInvoice.interface";
import { SendSalesInvoicesToCustomerApiInput } from "./mutations/useSendSalesInvoicesToCustomer";
import { SendSalesInvoicesToAccountantApiInput } from "./mutations/useSendSalesInvoicesToAccountant";

export type SalesInvoiceAPI = API<{
  salesInvoices: Omit<SalesInvoice, "update">[];
  count: number;
}>;
export type CreateMutation = Mutation<
  CreateInvoiceInput,
  Omit<SalesInvoice, "update">
>;
export type UpdateMutation = Mutation<UpdateInvoiceInput>;
export type DeleteMutation = Mutation<DeleteSalesInvoicesInput>;
export type UploadCSVMutation = Mutation<UploadCSVInput>;
export type SendToCustomerMutation =
  Mutation<SendSalesInvoicesToCustomerApiInput>;
export type SendToAccountantMutation =
  Mutation<SendSalesInvoicesToAccountantApiInput>;

interface Props {
  api: SalesInvoiceAPI;
  createMutation: CreateMutation;
  updateMutation: UpdateMutation;
  deleteMutation: DeleteMutation;
  uploadCSVMutation: UploadCSVMutation;
  sendToCustomerMutation: SendToCustomerMutation;
  sendToAccountantMutation: SendToAccountantMutation;
}

interface Result {
  salesInvoices: SalesInvoice[];
  salesInvoice?: SalesInvoice;
  count: number;
  isLoading: boolean;
  error?: Error;
  create: DomainMutation<
    CreateInvoiceInput,
    HTMLValidationSchemaGenerator<CreateInvoiceInput>
  >;
  uploadCSV: DomainMutation<UploadCSVInput>;
  deleteMany: DomainMutation<DeleteSalesInvoicesInput>;
  sendToCustomer: DomainMutation<
    SendSalesInvoicesToCustomerApiInput,
    HTMLValidationSchemaGenerator<SendSalesInvoicesToCustomerApiInput>
  >;
  sendToAccountant: DomainMutation<
    SendSalesInvoicesToAccountantApiInput,
    HTMLValidationSchemaGenerator<SendSalesInvoicesToAccountantApiInput>
  >;
}

export const useSalesInvoicesUseCases: UseCases<Props, Result> = ({
  api,
  createMutation,
  updateMutation,
  deleteMutation,
  uploadCSVMutation,
  sendToCustomerMutation,
  sendToAccountantMutation,
}) => {
  const { t } = useTranslation();

  const create = useDomainMutation<
    CreateInvoiceInput,
    HTMLValidationSchemaGenerator<CreateInvoiceInput>
  >(
    (input) => {
      const schema = {
        issueDate: {
          required: true,
          max: input.dueDate,
          rangeOverflow: t(
            "invoiceForm.input.issueDate.error.rangeOverflow"
          ) as string,
        },
        dueDate: {
          required: true,
          min: input.issueDate,
          rangeUnderflow: t(
            "invoiceForm.input.dueDate.error.rangeUnderflow"
          ) as string,
        },
        discount: {
          type: {
            required: true,
          },
          amount:
            input?.discount?.type !== DiscountTypes.NoDiscount
              ? {
                  required: true,
                  min: "0",
                  rangeUnderflow: t(
                    "invoiceForm.input.discount.error.rangeUnderflow"
                  ) as string,
                  max:
                    input?.discount?.type === DiscountTypes.Percentage
                      ? "100"
                      : String(
                          (input?.documentLines || [])
                            .map((d) => d.amountExclusiveVat || 0)
                            .reduce((a, b) => a + b)
                        ),
                  rangeOverflow: t(
                    "invoiceForm.input.discount.error.rangeOverflow"
                  ) as string,
                }
              : { required: false },
          description: { required: false },
        },
        documentLines: [
          {
            articleNumber: { required: false },
            description: { required: true },
            vatPercentage: { required: true },
            amountExclusiveVat: { required: true },
            quantity: { required: true },
          },
        ],
        reference: { required: false },
        currency: { required: true },
        customerId: { required: true },
        template: {
          accentColor: { required: true },
          layout: { required: true },
        },
        invoicePayStatus: { required: true },
        invoicePayType: {
          required: input.invoicePayStatus
            ? input.invoicePayStatus === InvoicePayStatus.Paid
            : false,
        },
        payDate: {
          required: input.invoicePayStatus
            ? input.invoicePayStatus === InvoicePayStatus.Paid
            : false,
        },
        structuredCommunication: {
          required: false,
          pattern: PATTERN.BANK_TRANSFER_STRUCTURED_COMMUNICATION.source,
          patternMismatch: t(
            "invoiceForm.input.structuredCommunication.error.patternMismatch"
          ) as string,
        },
      };

      return schema;
    },
    async (input) => {
      const salesInvoice = await createMutation(input);

      if (salesInvoice) {
        event.emit(
          "mutationSucceeded",
          t("domain.salesInvoice.salesInvoiceSaved")
        );
        event.emit("salesInvoiceSaved");
      }
    }
  );

  const update = useDomainMutation<
    UpdateInvoiceInput,
    HTMLValidationSchemaGenerator<UpdateInvoiceInput>
  >(
    (input) => ({
      id: { required: true },
      issueDate: {
        required: true,
        max: input.dueDate,
        rangeOverflow: t(
          "invoiceForm.input.issueDate.error.rangeOverflow"
        ) as string,
      },
      dueDate: {
        required: true,
        min: input.issueDate,
        rangeUnderflow: t(
          "invoiceForm.input.dueDate.error.rangeUnderflow"
        ) as string,
      },
      discount: {
        type: {
          required: true,
        },
        amount:
          input?.discount?.type !== DiscountTypes.NoDiscount
            ? {
                required: true,
                min: "0",
                rangeUnderflow: t(
                  "invoiceForm.input.discount.error.rangeUnderflow"
                ) as string,
                max:
                  input?.discount?.type === DiscountTypes.Percentage
                    ? "100"
                    : String(
                        (input?.documentLines || [])
                          .map((d) => Number(d.amountExclusiveVat || 0))
                          .reduce((a, b) => a + b)
                      ),
                rangeOverflow: t(
                  "invoiceForm.input.discount.error.rangeOverflow"
                ) as string,
              }
            : { required: false },
        description: { required: false },
      },
      documentLines: [
        {
          articleNumber: { required: false },
          description: { required: true },
          vatPercentage: { required: true },
          amountExclusiveVat: { required: true },
          quantity: { required: true },
        },
      ],
      reference: { required: false },
      currency: { required: true },
      customerId: { required: true },
      template: {
        accentColor: { required: true },
        layout: { required: true },
      },
      invoicePayStatus: { required: true },
      invoicePayType: {
        required: input.invoicePayStatus
          ? input.invoicePayStatus === InvoicePayStatus.Paid
          : false,
      },
      payDate: {
        required: input.invoicePayStatus
          ? input.invoicePayStatus === InvoicePayStatus.Paid
          : false,
      },
      structuredCommunication: {
        required: false,
        pattern: PATTERN.BANK_TRANSFER_STRUCTURED_COMMUNICATION.source,
        patternMismatch: t(
          "invoiceForm.input.structuredCommunication.error.patternMismatch"
        ) as string,
      },
    }),
    async (input) => {
      await updateMutation(input);

      event.emit("salesInvoiceSaved");
      event.emit(
        "mutationSucceeded",
        t("domain.salesInvoice.salesInvoiceSaved")
      );
    }
  );

  const deleteMany: Result["deleteMany"] =
    useDomainMutation<DeleteSalesInvoicesInput>(
      {
        ids: [{ required: true }],
      },
      async (input) => {
        await deleteMutation(input);
        event.emit("salesInvoicesDeleted");
        event.emit(
          "mutationSucceeded",
          t("domain.salesInvoice.salesInvoicesDeleted")
        );
      }
    );

  const sendToAccountant = useDomainMutation<
    SendSalesInvoicesToAccountantApiInput,
    HTMLValidationSchemaGenerator<SendSalesInvoicesToAccountantApiInput>
  >(
    () => {
      return {
        ids: [{ required: true }],
      };
    },
    async (input) => {
      await sendToAccountantMutation(input);
      event.emit("salesInvoiceSaved");
    }
  );

  const sendToCustomer = useDomainMutation<
    SendSalesInvoicesToCustomerApiInput,
    HTMLValidationSchemaGenerator<SendSalesInvoicesToCustomerApiInput>
  >(
    () => {
      return {
        ids: [{ required: true }],
      };
    },
    async (input) => {
      await sendToCustomerMutation(input);
    }
  );

  const salesInvoices: Result["salesInvoices"] = useMemo(
    () =>
      api.data.salesInvoices.map((salesInvoice) => ({
        ...salesInvoice,
        update,
      })),
    [api.data.salesInvoices, update]
  );

  const salesInvoice: Result["salesInvoice"] = useMemo(
    () => salesInvoices[0],
    [salesInvoices]
  );

  const uploadCSV: Result["uploadCSV"] = useDomainMutation<UploadCSVInput>(
    {
      formData: { required: true },
    },
    async (input) => {
      await uploadCSVMutation(input);

      event.emit("salesInvoiceCSVUploaded");
      event.emit("CSVUploaded");
    }
  );

  return {
    ...api,
    salesInvoices,
    salesInvoice,
    count: api.data.count,
    create,
    deleteMany,
    uploadCSV,
    sendToCustomer,
    sendToAccountant,
  };
};
