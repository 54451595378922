import { Select as BaseSelect, SelectProps } from "flowbite-react";
import {
  ChangeEvent,
  Children,
  FocusEvent,
  UIEvent,
  forwardRef,
  useCallback,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

interface Props extends Omit<SelectProps, "ref"> {
  errorMessage?: string;
  multiple?: boolean;
  isLoadingOptions?: boolean;
  isInfinite?: boolean;
  onEndReached?: () => void;
}

export const Select = forwardRef<HTMLSelectElement, Props>(function (
  {
    errorMessage,
    multiple,
    onEndReached,
    children,
    isLoadingOptions,
    isInfinite,
    ...rest
  },
  ref
) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const onFocus = useCallback(
    (event: FocusEvent<HTMLSelectElement>) => {
      if (isInfinite) setIsOpen(true);
      rest.onFocus?.(event);
    },
    [isInfinite, rest]
  );

  const onBlur = useCallback(
    (event: FocusEvent<HTMLSelectElement>) => {
      if (isInfinite) setIsOpen(false);
      rest.onBlur?.(event);
    },
    [isInfinite, rest]
  );

  const onChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      event.target.blur();
      if (isInfinite) setIsOpen(false);
      rest.onChange?.(event);
    },
    [isInfinite, rest]
  );

  const onScroll = useCallback(
    (event: UIEvent<HTMLSelectElement>) => {
      if (isInfinite) {
        const isAtBottom: boolean =
          event.currentTarget.scrollHeight - event.currentTarget.scrollTop <=
          event.currentTarget.clientHeight + 1;

        if (isAtBottom) {
          onEndReached?.();
        }
        rest.onScroll?.(event);
      }
    },
    [isInfinite, onEndReached, rest]
  );

  return (
    <BaseSelect
      {...rest}
      ref={ref}
      multiple={multiple}
      color={errorMessage ? "failure" : ""}
      helperText={errorMessage || rest.helperText}
      size={isOpen ? 10 : 1}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      onScroll={onScroll}
      theme={{
        field: {
          select: {
            base: "border w-full border-gray-200 bg-gray-50 dark:bg-gray-900 dark:border-gray-700 text-gray-500 dark:text-gray-300",
          },
        },
      }}
    >
      {children}
      {Children.count(children) === 0 && !isLoadingOptions ? (
        <option disabled>{rest.placeholder}</option>
      ) : null}
      {isLoadingOptions ? (
        <option disabled>{t("select.loading")}</option>
      ) : null}
    </BaseSelect>
  );
});
