import { Skeleton } from "components/skeletons/Skeleton";
import { SkeletonButton } from "components/skeletons/SkeletonButton";
import { SkeletonInput } from "components/skeletons/SkeletonInput";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const CustomerFormSkeleton: FC = function () {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-between gap-4">
        <div className="flex flex-col flex-1 gap-2">
          <Skeleton isText className="mt-1 mb-1.5">
            <span>{t("customerForm.input.name")}</span>
          </Skeleton>
          <SkeletonInput />
        </div>
        <div className="flex flex-col flex-1 gap-2">
          <Skeleton isText className="mt-1 mb-1.5">
            <span>{t("customerForm.input.vatNumber")}</span>
          </Skeleton>
          <SkeletonInput />
        </div>
      </div>
      <div className="flex justify-between gap-4">
        {/* <div className="flex flex-col flex-1 gap-2">
          <Skeleton isText className="mt-1 mb-1.5">
            <span>{t("customerForm.input.type")}</span>
          </Skeleton>
          <SkeletonInput />
        </div> */}
        <div className="flex flex-col flex-1 gap-2">
          <Skeleton isText className="mt-1 mb-1.5">
            <span>{t("customerForm.input.discount")}</span>
          </Skeleton>
          <SkeletonInput />
        </div>
      </div>

      <SkeletonButton>{t("customerForm.button.save")}</SkeletonButton>
    </>
  );
};
