import { FC } from "react";

import { Skeleton } from "components/skeletons/Skeleton";

export const LoadingLineChart: FC = function () {
  return (
    <div className="flex gap-4 w-full">
      <div className="w-1/4 sm:w-1/6">
        <Skeleton className="rounded-md h-44 w-full" />
      </div>
      <div className="flex flex-col gap-4 w-full">
        <Skeleton className="!h-20 rounded-md w-full" />
        <Skeleton className="!h-20 rounded-md w-full" />
      </div>
    </div>
  );
};
