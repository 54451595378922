import { UnpaidInvoices } from "hooks/dashboard/models/dashboard.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export enum UnpaidInvoicesType {
  quantity = "quantity",
  amount = "amount",
}

export interface GetUnpaidInvoicesParams {
  type: UnpaidInvoicesType;
}
export type GetUnpaidInvoicesResponse = UnpaidInvoices;

export function useGetUnpaidInvoices(input?: GetUnpaidInvoicesParams) {
  return useApiQuery<GetUnpaidInvoicesResponse>(
    ["unpaidInvoices", input?.type],
    `dashboards/unpaid-invoices?${new URLSearchParams({
      type: input?.type ?? "",
    })}`
  );
}
