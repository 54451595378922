import { FC, useCallback } from "react";
import classNames from "classnames";
import { UnpaidInvoicesType } from "../../queries/useGetUnpaidInvoices";

interface UnpaidInvoicesTypeButtonProps {
  onSelectType: (type: UnpaidInvoicesType) => void;
  selected: boolean;
  text: string;
  type: UnpaidInvoicesType;
}

export const UnpaidInvoicesTypeButton: FC<UnpaidInvoicesTypeButtonProps> =
  function ({ onSelectType, selected, text, type }) {
    const selectType = useCallback(
      () => onSelectType(type),
      [onSelectType, type]
    );

    return (
      <button
        type="button"
        onClick={selectType}
        className={classNames(
          "border-gray-6 dark:border-gray-600 border px-4 py-1 rounded-lg dark:text-white",
          {
            "border-gray-400 dark:border-gray-200": selected,
          }
        )}
      >
        {text}
      </button>
    );
  };
