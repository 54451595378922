/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useCallback } from "react";
import { Params, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useCustomers, useProducts, useRoutes } from "domains";
import { Product } from "hooks/products/models/product.interface";
import {
  CustomerForm,
  CustomerFormValues,
} from "components/customer/CustomerForm";
import { NoHeaderModal } from "components/modals/NoHeaderModal";
import { CreateCustomerWithAddressInput } from "hooks/customers/models/customer.interface";
import {
  CustomProduct,
  CustomProductForm,
  ProductFieldArrayMethods,
} from "./form/CustomProductForm";
import { EditModal } from "../modals/EditModal";
import { NoteForm } from "./form/NoteForm";

export const CREATE_CUSTOMER_PARAM = "create-customer";
export const NOTE_PARAM = "note";
export const CREATE_CUSTOM_PRODUCT_PARAM = "create-custom-product";

export const InvoiceFormCreateModals: FC<ProductFieldArrayMethods> = function ({
  ...rest
}) {
  const [searchParams] = useSearchParams();
  const { subAction, index } = useParams<Params>();
  const { closeCustomProduct, closeDifferentEntityModal } = useRoutes();
  const { createFromInvoice: createCustomer } = useCustomers();
  const { createFromInvoice: createProduct } = useProducts();

  const onCloseCompanyModal = useCallback(
    () => closeDifferentEntityModal(CREATE_CUSTOMER_PARAM),
    [closeDifferentEntityModal]
  );

  const onCloseNoteModal = useCallback(
    () => closeDifferentEntityModal(NOTE_PARAM),
    [closeDifferentEntityModal]
  );

  if (subAction === CREATE_CUSTOM_PRODUCT_PARAM) {
    return (
      <EditModal
        onClose={closeCustomProduct}
        show
        action="create"
        page="custom-product"
        isLoading={createProduct.isLoading}
        isError={false}
      >
        <CustomProductForm
          isLoading={createProduct.isLoading}
          submit={
            createProduct.execute as (input: CustomProduct) => Promise<Product>
          }
          index={index}
          {...rest}
        />
      </EditModal>
    );
  }

  if (subAction === CREATE_CUSTOMER_PARAM) {
    return (
      <EditModal
        show
        onClose={onCloseCompanyModal}
        action="create"
        page="customers"
        isLoading={createCustomer.isLoading}
        isError={false}
      >
        <CustomerForm
          isInModal
          withAddress
          isLoading={createCustomer.isLoading}
          onSubmit={
            createCustomer.execute as (input: CustomerFormValues) => void
          }
          schema={createCustomer.schema}
          defaultValues={
            {
              name: searchParams.get("initialValue") || "",
            } as CreateCustomerWithAddressInput
          }
        />
      </EditModal>
    );
  }

  if (subAction?.includes(NOTE_PARAM)) {
    const noteParam = subAction.split(`${NOTE_PARAM}-`);

    const number = Number(noteParam && noteParam[1]);

    return (
      <NoHeaderModal show size="md" onClose={onCloseNoteModal}>
        <NoteForm index={number} onClose={onCloseNoteModal} />
      </NoHeaderModal>
    );
  }

  return null;
};
