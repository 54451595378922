import { FC, useCallback } from "react";
import { Table } from "flowbite-react";
import { useTranslation } from "react-i18next";

import { Customer } from "hooks/customers/models/customer.interface";
import { EmptyTableRow } from "components/EmptyTableRow";
import { Pagination, PaginationProps } from "components/Pagination";
import { RowSelectedState } from "hooks/tables/models/tables.interface";

import { CustomersTableHead } from "./CustomersTableHead";
import { CustomersTableRow } from "./CustomersTableRow";
import { LoadingCustomersTableRow } from "./LoadingCustomersTableRow";

export interface CustomersTableProps extends Omit<PaginationProps, "rows"> {
  rows: Customer[];
  isLoading?: boolean;
  handleAllRowsClicked: (ids: string[]) => void;
  handleOneRowClicked: (id: string) => void;
  rowSelectedState: RowSelectedState;
  onEdit: (id: string) => void;
}

export const CustomersTable: FC<CustomersTableProps> = function ({
  rows,
  isLoading,
  handleAllRowsClicked,
  handleOneRowClicked,
  rowSelectedState,
  onEdit,
  ...rest
}) {
  const { t } = useTranslation();

  const onAllClicked = useCallback(
    () => handleAllRowsClicked(rows.map((customer) => customer.id)),
    [handleAllRowsClicked, rows]
  );

  return (
    <div className="flex flex-col justify-between overflow-hidden">
      <Table
        hoverable
        className="divide-y divide-gray-200 dark:divide-gray-600 table-fixed"
      >
        <CustomersTableHead
          onSelectAll={onAllClicked}
          allSelected={rowSelectedState.all}
        />
        <Table.Body className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
          {isLoading && <LoadingCustomersTableRow />}
          {!isLoading &&
            rows.map((customer) => (
              <CustomersTableRow
                key={customer.id}
                {...customer}
                address={customer.addresses[0]}
                contacts={customer.contacts}
                selected={rowSelectedState.ids.includes(customer.id)}
                onSelect={handleOneRowClicked}
                onEdit={onEdit}
              />
            ))}
          {!isLoading && rows.length === 0 && (
            <EmptyTableRow colSpan={6}>
              {t("customersTable.title.empty")}
            </EmptyTableRow>
          )}
        </Table.Body>
      </Table>
      <Pagination {...rest} />
    </div>
  );
};
