/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options } from "ky";
import { useQuery, UseQueryOptions } from "react-query";

import { api } from "helpers/api";
import { event } from "event";

export interface FetchError extends Error {
  cause: {
    status: number;
  };
}

export function useApiQuery<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  key: string | any[], // React Query key
  url: string,
  apiOptions?: Options,
  options?: UseQueryOptions<T, FetchError>
) {
  return useQuery<T, FetchError>(
    key,
    async () => {
      const request = await api(`api/v1/${url}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        throwHttpErrors: false,
        ...apiOptions,
      });

      if (!request.ok) {
        throw new Error(request?.statusText, {
          cause: {
            payload: {
              detail: (request as any).payload.detail,
            },
            status: request.status,
          },
        });
      }

      return request.json();
    },
    {
      onError: (error) => {
        const err = error as any;
        if (err?.cause?.payload?.detail) {
          event.emit("mutationFailed", new Error(err?.cause?.payload?.detail));
        }
      },
      ...options,
    }
  );
}
