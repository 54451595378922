import {
  CustomerDTO,
  CustomerDTOWithEntityIds,
} from "../../hooks/customers/models/customer.interface";

export const addCustomerSubEntitiesIds = (
  customer: CustomerDTO
): CustomerDTOWithEntityIds => {
  return {
    ...customer,
    addresses: customer.addresses?.map((a, index) => ({
      ...a,
      id: index.toString(),
    })),
    bankAccounts: customer.bankAccounts?.map((b, index) => ({
      ...b,
      id: index.toString(),
    })),
    contacts: customer.contacts?.map((c, index) => ({
      ...c,
      id: index.toString(),
    })),
  };
};
