/* eslint-disable @typescript-eslint/no-explicit-any */
import type { DeepPartial } from "./models/deepPartial.d";
import type { DeepRecord } from "./models/deepRecord.d";

const isNotEmpty: any = (entry: any): boolean =>
  entry[1] && Object.keys(entry[1]).length !== 0;

const isEmpty: any = (obj: any): boolean => Object.keys(obj).length === 0;

const isArrayObject: any = (obj: any): boolean =>
  Object.keys(obj).every((key) => /^\d+$/.test(key));

const toArray = (obj: any) => {
  const keys = Object.keys(obj).map((key) => parseInt(key, 10));
  const maxIndex = Math.max(0, ...keys);
  const result = Array(maxIndex).fill(undefined);

  keys.forEach((key) => {
    result[key] = obj[key];
  });

  return result;
};

export const sanitize = <Input extends Record<string, unknown>>(
  value: DeepRecord<Input, Error>
): DeepPartial<DeepRecord<Input, Error>> => {
  const entries = Object.entries(value);
  const filtered = entries.filter(isNotEmpty);
  const result = Object.fromEntries(filtered) as DeepPartial<
    DeepRecord<Input, Error>
  >;

  if (!isEmpty(result) && isArrayObject(result)) {
    return toArray(result) as any;
  }

  return result;
};
