import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { HiInbox } from "react-icons/hi";

import { ActionBar, PageHeader } from "components/PageHeader";
import { AUTO_REFRESH_INTERVAL_KEY } from "components/AutoRefresh";
import { DeleteModal } from "components/modals/DeleteModal";
import { SuppliersTable } from "components/supplier/table/SuppliersTable";
import { UploadCSV } from "components/UploadCSV";
import { useRoutes, useSuppliers, useTables } from "domains";
import { useTablesEffects } from "hooks/tables/useTablesUseCases";

import { EditModal } from "components/modals/EditModal";
import {
  SupplierForm,
  SupplierFormValues,
} from "components/supplier/SupplierForm";
import { CreateSupplierWithAddressInput } from "hooks/suppliers/models/supplier.interface";
import { useGetSuppliers } from "./queries/useGetSuppliers";
import { useGetSupplier } from "./queries/useGetSupplier";
import {
  SUPPLIERS_XSLX_KEY,
  useGetSuppliersXLSX,
} from "./queries/useGetSuppliersXLSX";

function SuppliersPage() {
  const { t } = useTranslation();
  const { routes, goToSuppliers, goToSupplier } = useRoutes();
  const [searchParams] = useSearchParams();
  const [idsToDelete, setIdsToDelete] = useState<string[]>([]);
  const [refetchInterval, setRefetchInterval] = useState<number>(
    parseInt(localStorage.getItem(AUTO_REFRESH_INTERVAL_KEY) ?? "", 10)
  );
  const supplierTable = useTables();
  const { rowSelectedState, handleAllRowsClicked, handleOneRowClicked } =
    supplierTable;
  const { action, id } = useParams();
  useTablesEffects(supplierTable);

  const getSuppliers = useGetSuppliers({
    limit: Number(searchParams.get("limit")) || 10,
    offset: Number(searchParams.get("offset")) || 0,
    search: searchParams.get("search") || "",
    sortKey: searchParams.get("sortKey") || "",
    sortOrder: searchParams.get("sortOrder") || "",
    refetchInterval,
  });

  const getXLSX = useGetSuppliersXLSX({
    search: searchParams.get("search") || "",
    sortKey: searchParams.get("sortKey") || "",
    sortOrder: searchParams.get("sortOrder") || "",
  });

  const {
    suppliers,
    count,
    isLoading: isSuppliersLoading,
  } = useSuppliers(getSuppliers);

  const { supplier, create, isLoading, error, deleteMany, uploadCSV } =
    useSuppliers(useGetSupplier({ id }));

  const onDeleteSupplier = useCallback(
    () => deleteMany.execute({ ids: idsToDelete }),
    [idsToDelete, deleteMany]
  );

  const onEdit = useCallback(
    (id: string) => {
      goToSupplier(id);
    },
    [goToSupplier]
  );

  useEffect(() => {
    if (action === "delete") {
      const ids = searchParams.getAll("idsToDelete");
      setIdsToDelete(ids);
    }
  }, [action, setIdsToDelete, searchParams]);

  return (
    <div className="flex flex-col">
      <PageHeader
        className="bg-white dark:bg-gray-800"
        title={t("pageHeader.title.suppliers")}
        breadcrumbs={[
          {
            href: routes.suppliers,
            label: t("sidebar.title.suppliers"),
            icon: HiInbox,
          },
        ]}
      >
        <ActionBar
          showBulkDelete
          pageKey="suppliers"
          onRefetch={getSuppliers.refetch}
          onRefetchIntervalChange={setRefetchInterval}
          rowSelectedState={rowSelectedState}
          queryKey={SUPPLIERS_XSLX_KEY}
          {...getXLSX}
        />
      </PageHeader>
      <SuppliersTable
        rows={suppliers}
        isLoading={isSuppliersLoading}
        limit={Number(searchParams.get("limit")) || 10}
        total={count}
        handleAllRowsClicked={handleAllRowsClicked}
        handleOneRowClicked={handleOneRowClicked}
        rowSelectedState={rowSelectedState}
        onEdit={onEdit}
      />
      {action === "delete" && !!idsToDelete && (
        <DeleteModal
          isLoading={isLoading}
          isDeleting={deleteMany.isLoading}
          isError={!!error}
          isMultiple={idsToDelete.length > 1}
          page="suppliers"
          show={!!idsToDelete}
          onClose={goToSuppliers}
          onDelete={onDeleteSupplier}
        />
      )}
      {action === "create" && (
        <EditModal
          action={action as "create"}
          isError={!!error}
          itemName={supplier?.name ?? ""}
          page="suppliers"
          onClose={goToSuppliers}
          show
        >
          <SupplierForm
            isInModal
            withAddress
            isLoading={create.isLoading}
            onSubmit={create.execute as (input: SupplierFormValues) => void}
            schema={create.schema}
            defaultValues={
              {
                name: searchParams.get("initialValue") || "",
              } as CreateSupplierWithAddressInput
            }
          />
        </EditModal>
      )}
      <UploadCSV
        action={action}
        uploadCSV={uploadCSV}
        onClose={goToSuppliers}
        page="suppliers"
      />
    </div>
  );
}

export default SuppliersPage;
