import { FC } from "react";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import { GetSubscriptionResponse } from "hooks/subscription/models/subscription.interface";

interface TrialCountdownProps {
  subscription?: GetSubscriptionResponse;
  cancelled?: boolean;
}

export const TrialCountdown: FC<TrialCountdownProps> = function ({
  subscription,
  cancelled,
}) {
  const { t } = useTranslation();

  if (!subscription?.endDate) return null;

  if (cancelled) {
    return (
      <div
        id="alert-additional-content-2"
        className="p-4 mb-0 text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
        role="alert"
      >
        <div className="flex items-center">
          <h3 className="text-lg font-medium">
            {t("subscriptionPage.isCancelled", {
              endDate: format(new Date(subscription?.endDate), "dd-MM-yyyy"),
            })}
          </h3>
        </div>
        <div className="mt-2 mb-2 text-sm">
          {t("subscriptionPage.isCancelledInfo")}
        </div>
      </div>
    );
  }

  return (
    <div
      id="alert-additional-content-2"
      className="p-4 mb-0 text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
      role="alert"
    >
      <div className="flex items-center">
        <h3 className="text-lg font-medium">
          {t("subscriptionPage.trialEnd", {
            endDate: format(new Date(subscription?.endDate), "dd-MM-yyyy"),
          })}
        </h3>
      </div>
      <div className="mt-2 mb-2 text-sm">
        {t("subscriptionPage.trialEndInfo")}
      </div>
    </div>
  );
};
