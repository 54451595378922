import { FC } from "react";
import { useTranslation } from "react-i18next";
import { BankAccount } from "hooks/banks/models/bank.interface";
import { ConnectionCard } from "./ConnectionCard";

interface Props {
  connections?: BankAccount[];
}

export const Connections: FC<Props> = function ({ connections }) {
  const { t } = useTranslation();

  if (!connections || !connections?.length) {
    return (
      <div className="flex flex-col">
        <p className="text-1xl font-bold dark:text-white italic">
          {t("bankspage.connections.noConnectionsYet")}
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <p className="text-2xl font-bold dark:text-white">
        {t("bankspage.connections.title")}
      </p>
      <div className="grid pt-4 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {connections?.map((connection) => (
          <ConnectionCard key={connection.iban} connection={connection} />
        ))}
      </div>
    </div>
  );
};
