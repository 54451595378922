import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Label } from "flowbite-react";
import {
  CreateProductGroupInput,
  UpdateProductGroupInput,
} from "hooks/productGroups/models/productGroup.interface";
import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "helpers/html-validation-schema";
import { HTMLValidationSchemaResolver } from "helpers/html-validation-schema/compat/HTMLValidationSchemaResolver";
import { LoadingButton } from "components/LoadingButton";
import { TextInput } from "components/input/TextInput";

export type ProductGroupFormValues =
  | CreateProductGroupInput
  | UpdateProductGroupInput;

interface ProductGroupFormProps {
  productGroup?: Partial<ProductGroupFormValues>;
  isLoading: boolean;
  onSubmit: (data: ProductGroupFormValues) => void;
  schema:
    | HTMLValidationSchema<ProductGroupFormValues>
    | HTMLValidationSchemaGenerator<ProductGroupFormValues>;
}

const ProductGroupForm: FC<ProductGroupFormProps> = function ({
  productGroup,
  isLoading,
  onSubmit,
  schema,
}) {
  const { t } = useTranslation();

  const { register, handleSubmit, formState } = useForm<ProductGroupFormValues>(
    {
      defaultValues: productGroup,
      mode: "onBlur",
      resolver: HTMLValidationSchemaResolver(schema),
    }
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="p-6">
        <div className="mb-2 block">
          <Label
            htmlFor="name"
            value={t("productGroupForm.label.name") ?? ""}
          />
        </div>
        <TextInput
          autoFocus
          id="name"
          type="text"
          {...register("name")}
          errorMessage={formState.errors.name?.message}
        />
      </div>
      <div className="border-1 border-t p-6 dark:border-gray-700">
        <LoadingButton
          loading={isLoading}
          className="px-2 py-0.5"
          type="submit"
        >
          {t("productGroupForm.button.save")}
        </LoadingButton>
      </div>
    </form>
  );
};

export default ProductGroupForm;
