import axios from "axios";
import { event } from "event";
import { useQueryClient, useMutation } from "react-query";
import { useAuth } from "hooks/useAuth";
import { UploadPurchaseInvoiceInput } from "../models/purchaseInvoiceInput.interface";

export type UploadPurchaseInvoiceResponse = void;

export function useUploadPurchaseInvoice() {
  const queryClient = useQueryClient();
  const { token } = useAuth();

  return useMutation<
    UploadPurchaseInvoiceResponse,
    Error,
    UploadPurchaseInvoiceInput
  >(
    "createSalesInvoice",
    async (input: UploadPurchaseInvoiceInput) => {
      const response = await axios.post(
        `${process.env.REACT_APP_API}api/v1/purchase-invoices/upload`,
        input.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response) {
        return response.data;
      }

      throw new Error("error");
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["purchaseInvoices"]);
      },
      onError: (error) => {
        event.emit("mutationFailed", new Error(error.message));
      },
    }
  );
}
