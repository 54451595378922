import { useLogin } from "hooks/authentication/mutations/useLogin";
import { useRegister } from "hooks/authentication/mutations/useRegister";
import { useAuthenticationUseCases } from "hooks/authentication/useAuthenticationUseCases";
import { CustomerAPIDTO, CustomerMapper } from "hooks/customers/CustomerMapper";
import { useUpdateCustomer } from "hooks/customers/mutations/useUpdateCustomer";
import { useCustomersUseCases } from "hooks/customers/useCustomersUseCases";
import { useCreateCustomer } from "hooks/customers/mutations/useCreateCustomer";
import { useDeleteCustomers } from "hooks/customers/mutations/useDeleteCustomers";
import { useNotificationsUseCases } from "hooks/notifications/useNotificationsUseCases";
import { ProductAPIDTO, ProductMapper } from "hooks/products/ProductMapper";
import { useProductsUseCases } from "hooks/products/useProductsUseCases";
import { useUpdateProduct } from "hooks/products/mutations/useUpdateProduct";
import { useCreateProduct } from "hooks/products/mutations/useCreateProduct";
import { useDeleteProducts } from "hooks/products/mutations/useDeleteProducts";
import { useRoutesUseCases } from "hooks/routes/useRoutesUseCases";
import { Domain } from "models/core";
import { useCreateSalesInvoice } from "hooks/salesInvoices/mutations/useCreateSalesInvoice";
import { useUpdateSalesInvoice } from "hooks/salesInvoices/mutations/useUpdateSalesInvoice";
import { useDeleteSalesInvoices } from "hooks/salesInvoices/mutations/useDeleteSalesInvoices";
import { useSalesInvoicesUseCases } from "hooks/salesInvoices/useSalesInvoicesUseCases";
import {
  SalesInvoiceAPIDTO,
  SalesInvoiceMapper,
} from "hooks/salesInvoices/SalesInvoiceMapper";
import {
  PurchaseInvoiceAPIDTO,
  PurchaseInvoiceMapper,
} from "hooks/purchaseInvoices/PurchaseInvoiceMapper";
import { useUpdatePurchaseInvoice } from "hooks/purchaseInvoices/mutations/useUpdatePurchaseInvoice";
import { useDeletePurchaseInvoices } from "hooks/purchaseInvoices/mutations/useDeletePurchaseInvoices";
import { usePurchaseInvoicesUseCases } from "hooks/purchaseInvoices/usePurchaseInvoicesUseCases";
import { useUploadPurchaseInvoice } from "hooks/purchaseInvoices/mutations/useUploadPurchaseInvoice";
import { useTablesUseCases } from "hooks/tables/useTablesUseCases";
import { SupplierAPIDTO, SupplierMapper } from "hooks/suppliers/SupplierMapper";
import { useSuppliersUseCases } from "hooks/suppliers/useSuppliersUseCases";
import { useUpdateSupplier } from "hooks/suppliers/mutations/useUpdateSupplier";
import { useCreateSupplier } from "hooks/suppliers/mutations/useCreateSupplier";
import { useDeleteSuppliers } from "hooks/suppliers/mutations/useDeleteSupplier";
import { useProductGroupUseCases } from "hooks/productGroups/useProductGroupsUseCases";
import {
  ProductGroupAPIDTO,
  ProductGroupMapper,
} from "hooks/productGroups/ProductGroupMapper";
import { useUpdateProductGroup } from "hooks/productGroups/mutations/useUpdateProductGroup";
import { useCreateProductGroup } from "hooks/productGroups/mutations/useCreateProductGroup";
import { useDeleteProductGroups } from "hooks/productGroups/mutations/useDeleteProductGroup";
import { QuoteAPIDTO, QuoteMapper } from "hooks/quotes/QuotesMapper";
import { useQuotesUseCases } from "hooks/quotes/useQuotesUseCases";
import { useDeleteQuotes } from "hooks/quotes/mutations/useDeleteQuote";
import { TemplateAPIDTO, TemplateMapper } from "hooks/templates/TemplateMapper";
import { useTemplatesUseCases } from "hooks/templates/useTemplatesUseCases";
import { useCreateTemplate } from "hooks/templates/mutations/useCreateTemplate";
import { useDeleteTemplate } from "hooks/templates/mutations/useDeleteTemplate";
import { useCreateQuote } from "hooks/quotes/mutations/useCreateQuote";
import { useUpdateQuote } from "hooks/quotes/mutations/useUpdateQuote";
import { useConvertQuote } from "hooks/quotes/mutations/useConvertQuote";
import { useSendQuote } from "hooks/quotes/mutations/useSendQuote";
import {
  KBOLookupAPIDTO,
  KBOLookupMapper,
} from "hooks/kboLookup/KBOLookupMapper";
import { useKBOLookupUseCases } from "hooks/kboLookup/useKBOLookupUseCases";
import {
  DashboardAPIDTO,
  DashboardMapper,
} from "hooks/dashboard/DashboardMapper";
import { useDashboardUseCases } from "hooks/dashboard/useDashboardUseCases";
import {
  TimesheetAPIDTO,
  TimesheetMapper,
} from "hooks/timesheets/TimesheetMapper";
import { useDeleteTimesheets } from "hooks/timesheets/mutations/useDeleteTimesheets";
import { useGenerateInvoice } from "hooks/timesheets/mutations/useGenerateInvoice";
import { useTimesheetsUseCases } from "hooks/timesheets/useTimesheetsUseCases";
import { useUpdateTimesheet } from "hooks/timesheets/mutations/useUpdateTimesheet";
import { useUploadCustomerCSV } from "hooks/customers/mutations/useUploadCustomerCSV";
import { useUploadProductCSV } from "hooks/products/mutations/useUploadProductCSV";
import { useUploadProductGroupCSV } from "hooks/productGroups/mutations/useUploadProductGroupCSV";
import { useUploadPurchaseInvoiceCSV } from "hooks/purchaseInvoices/mutations/useUploadPurchaseInvoiceCSV";
import { useUploadQuoteCSV } from "hooks/quotes/mutations/useUploadQuoteCSV";
import { useUploadSalesInvoiceCSV } from "hooks/salesInvoices/useUploadSalesInvoiceCSV";
import { useUploadSupplierCSV } from "hooks/suppliers/mutations/useUploadSupplierCSV";
import { useUploadTimesheetCSV } from "hooks/timesheets/mutations/useUploadTimesheetCSV";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FileAPIDTO, FileMapper } from "hooks/file/FileMapper";
import { useFileUseCases } from "hooks/file/useFileUseCases";
import { UserInfoAPIDTO, UserInfoMapper } from "hooks/userInfo/UserInfoMapper";
import { useUserInfoUseCases } from "hooks/userInfo/useUserInfoUseCases";
import { useSendSalesInvoicesToCustomer } from "hooks/salesInvoices/mutations/useSendSalesInvoicesToCustomer";
import { useSendSalesInvoicesToAccountant } from "hooks/salesInvoices/mutations/useSendSalesInvoicesToAccountant";
import { useSendPurchaseInvoicesToAccountant } from "hooks/purchaseInvoices/mutations/useSendPurchaseInvoicesToAccountant";
import {
  TransactionAPIDTO,
  TransactionMapper,
} from "hooks/transactions/TransactionsMapper";
import { useTransactionsUseCases } from "hooks/transactions/useTransactionsUseCases";
import { useUpdateFile } from "hooks/file/mutations/useUpdateFile";
import {
  InvoiceSettingsAPIDTO,
  InvoiceSettingsMapper,
} from "hooks/invoiceSettings/InvoiceSettingsMapper";
import { useUpdateInvoiceSettings } from "hooks/invoiceSettings/mutations/useUpdateInvoiceSettings";
import { useInvoiceSettingsUseCases } from "hooks/invoiceSettings/useInvoiceSettingsUseCases";

export const useRoutes: Domain<undefined, typeof useRoutesUseCases> =
  useRoutesUseCases;

export const useTables: Domain<undefined, typeof useTablesUseCases> =
  useTablesUseCases;

export const useNotifications: Domain<
  undefined,
  typeof useNotificationsUseCases
> = useNotificationsUseCases;

export const useAuthentication: Domain<
  undefined,
  typeof useAuthenticationUseCases
> = () => {
  const { mutateAsync: loginMutation } = useLogin();
  const { mutateAsync: registerMutation, isLoading: isRegistering } =
    useRegister();

  return useAuthenticationUseCases({
    loginMutation,
    registerMutation,
    isRegistering,
  });
};

// TODO: get these from i18n
export const supportedProductLanguages = ["NL", "EN", "FR"].sort();

export const useProducts: Domain<ProductAPIDTO, typeof useProductsUseCases> = (
  api
) => {
  const { i18n } = useTranslation();

  // TODO: luister naar dossier domein om supported languages uit te halen

  const mapper = useMemo(
    () => new ProductMapper(supportedProductLanguages, i18n.language),
    [i18n.language]
  );

  const { mutateAsync: createProductMutation } = useCreateProduct();
  const { mutateAsync: updateProductMutation } = useUpdateProduct();
  const { mutateAsync: deleteProductsMutation } = useDeleteProducts();
  const { mutateAsync: uploadCSVMutation } = useUploadProductCSV();

  return useProductsUseCases({
    api: mapper.toAPI(api),
    createProductMutation,
    updateProductMutation,
    deleteProductsMutation,
    uploadCSVMutation,
  });
};

export const useCustomers: Domain<
  CustomerAPIDTO,
  typeof useCustomersUseCases
> = (api) => {
  const mapper = new CustomerMapper();
  const { mutateAsync: createMutation } = useCreateCustomer();
  const { mutateAsync: updateMutation } = useUpdateCustomer();
  const { mutateAsync: deleteMutation } = useDeleteCustomers();
  const { mutateAsync: uploadCSVMutation } = useUploadCustomerCSV();

  return useCustomersUseCases({
    api: mapper.toAPI(api),
    createMutation,
    updateMutation,
    deleteMutation,
    uploadCSVMutation,
  });
};

export const useSuppliers: Domain<
  SupplierAPIDTO,
  typeof useSuppliersUseCases
> = (api) => {
  const mapper = new SupplierMapper();
  const { mutateAsync: updateSupplierMutation } = useUpdateSupplier();
  const { mutateAsync: createSupplierMutation } = useCreateSupplier();
  const { mutateAsync: deleteSuppliersMutation } = useDeleteSuppliers();
  const { mutateAsync: uploadCSVMutation } = useUploadSupplierCSV();

  return useSuppliersUseCases({
    api: mapper.toAPI(api),
    updateMutation: updateSupplierMutation,
    createMutation: createSupplierMutation,
    deleteMutation: deleteSuppliersMutation,
    uploadCSVMutation,
  });
};

export const useProductGroups: Domain<
  ProductGroupAPIDTO,
  typeof useProductGroupUseCases
> = (api) => {
  const mapper = new ProductGroupMapper();

  const { mutateAsync: updateProductGroupMutation } = useUpdateProductGroup();
  const { mutateAsync: createProductGroupMutation } = useCreateProductGroup();
  const { mutateAsync: deleteProductGroupsMutation } = useDeleteProductGroups();
  const { mutateAsync: uploadCSVMutation } = useUploadProductGroupCSV();

  return useProductGroupUseCases({
    api: mapper.toAPI(api),
    updateProductGroupMutation,
    createProductGroupMutation,
    deleteProductGroupsMutation,
    uploadCSVMutation,
  });
};

export const useTemplates: Domain<
  TemplateAPIDTO,
  typeof useTemplatesUseCases
> = (api) => {
  const mapper = new TemplateMapper();

  const { mutateAsync: createMutation } = useCreateTemplate();
  const { mutateAsync: deleteMutation } = useDeleteTemplate();

  return useTemplatesUseCases({
    api: mapper.toAPI(api),
    createMutation,
    deleteMutation,
  });
};

export const useSalesInvoices: Domain<
  SalesInvoiceAPIDTO,
  typeof useSalesInvoicesUseCases
> = (api) => {
  const mapper = new SalesInvoiceMapper();

  const { mutateAsync: createMutation } = useCreateSalesInvoice();
  const { mutateAsync: updateMutation } = useUpdateSalesInvoice();
  const { mutateAsync: deleteMutation } = useDeleteSalesInvoices();
  const { mutateAsync: uploadCSVMutation } = useUploadSalesInvoiceCSV();
  const { mutateAsync: sendToCustomerMutation } =
    useSendSalesInvoicesToCustomer();
  const { mutateAsync: sendToAccountantMutation } =
    useSendSalesInvoicesToAccountant();

  return useSalesInvoicesUseCases({
    api: mapper.toAPI(api),
    createMutation,
    updateMutation,
    deleteMutation,
    uploadCSVMutation,
    sendToCustomerMutation,
    sendToAccountantMutation,
  });
};

export const usePurchaseInvoices: Domain<
  PurchaseInvoiceAPIDTO,
  typeof usePurchaseInvoicesUseCases
> = (api) => {
  const mapper = new PurchaseInvoiceMapper();

  const { mutateAsync: uploadMutation } = useUploadPurchaseInvoice();
  const { mutateAsync: updateMutation } = useUpdatePurchaseInvoice();
  const { mutateAsync: deleteMutation } = useDeletePurchaseInvoices();
  const { mutateAsync: uploadCSVMutation } = useUploadPurchaseInvoiceCSV();
  const { mutateAsync: sendToAccountantMutation } =
    useSendPurchaseInvoicesToAccountant();

  return usePurchaseInvoicesUseCases({
    api: mapper.toAPI(api),
    uploadMutation,
    updateMutation,
    deleteMutation,
    uploadCSVMutation,
    sendToAccountantMutation,
  });
};
export const useQuotes: Domain<QuoteAPIDTO, typeof useQuotesUseCases> = (
  api
) => {
  const mapper = new QuoteMapper();

  const { mutateAsync: createMutation } = useCreateQuote();
  const { mutateAsync: updateMutation } = useUpdateQuote();
  const { mutateAsync: deleteMutation } = useDeleteQuotes();
  const { mutateAsync: convertMutation } = useConvertQuote();
  const { mutateAsync: sendMutation } = useSendQuote();
  const { mutateAsync: uploadCSVMutation } = useUploadQuoteCSV();

  return useQuotesUseCases({
    api: mapper.toAPI(api),
    createMutation,
    updateMutation,
    deleteMutation,
    convertMutation,
    sendMutation,
    uploadCSVMutation,
  });
};

export const useTransactions: Domain<
  TransactionAPIDTO,
  typeof useTransactionsUseCases
> = (api) => {
  const mapper = new TransactionMapper();

  return useTransactionsUseCases({
    api: mapper.toAPI(api),
  });
};

export const useFile: Domain<FileAPIDTO, typeof useFileUseCases> = (api) => {
  const mapper = new FileMapper();

  const { mutateAsync: updateMutation } = useUpdateFile();

  return useFileUseCases({
    api: mapper.toAPI(api),
    updateMutation,
  });
};

export const useInvoiceSettings: Domain<
  InvoiceSettingsAPIDTO,
  typeof useInvoiceSettingsUseCases
> = (api) => {
  const mapper = new InvoiceSettingsMapper();

  const { mutateAsync: updateMutation } = useUpdateInvoiceSettings();

  return useInvoiceSettingsUseCases({
    api: mapper.toAPI(api),
    updateMutation,
  });
};

export const useUserInfo: Domain<UserInfoAPIDTO, typeof useUserInfoUseCases> = (
  api
) => {
  const mapper = new UserInfoMapper();

  return useUserInfoUseCases({
    api: mapper.toAPI(api),
  });
};

export const useKBOLookup: Domain<
  KBOLookupAPIDTO,
  typeof useKBOLookupUseCases
> = (api) => {
  const mapper = new KBOLookupMapper();

  return useKBOLookupUseCases({
    api: mapper.toAPI(api),
  });
};

export const useTimesheets: Domain<
  TimesheetAPIDTO,
  typeof useTimesheetsUseCases
> = (api) => {
  const mapper = new TimesheetMapper();

  const { mutateAsync: generateInvoiceMutation } = useGenerateInvoice();
  const { mutateAsync: updateMutation } = useUpdateTimesheet();
  const { mutateAsync: deleteMutation } = useDeleteTimesheets();
  const { mutateAsync: uploadCSVMutation } = useUploadTimesheetCSV();

  return useTimesheetsUseCases({
    api: mapper.toAPI(api),
    generateInvoiceMutation,
    updateMutation,
    deleteMutation,
    uploadCSVMutation,
  });
};

export const useDashboard: Domain<
  DashboardAPIDTO,
  typeof useDashboardUseCases
> = (dashboardAPI) => {
  const mapper = new DashboardMapper();

  return useDashboardUseCases({
    summaryAPI: mapper.toSummaryAPI(dashboardAPI?.summary),
    unpaidInvoicesAPI: mapper.toUnpaidInvoicesAPI(dashboardAPI?.unpaidInvoices),
    ebitdaEvolutionAPI: mapper.toEbitdaEvolutionAPI(
      dashboardAPI?.ebitdaEvolution
    ),
    financialEvolutionAPI: mapper.toFinancialEvolutionAPI(
      dashboardAPI?.financialEvolution
    ),
  });
};
