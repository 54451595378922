import {
  TextInputProps as BaseTextInputProps,
  TextInput as BaseTextInput,
} from "flowbite-react";
import { forwardRef } from "react";

export interface TextInputProps extends Omit<BaseTextInputProps, "ref"> {
  errorMessage?: string;
  errorColor?: boolean;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(function (
  { errorMessage, errorColor, ...rest },
  ref
) {
  return (
    <BaseTextInput
      {...rest}
      ref={ref}
      color={errorMessage || errorColor ? "failure" : rest.color}
      helperText={errorMessage || rest.helperText}
      theme={{
        field: {
          input: {
            base: "border w-full border-gray-200 bg-gray-50 dark:bg-gray-900 dark:border-gray-700 text-gray-500 dark:text-gray-300",
          },
        },
      }}
    />
  );
});
