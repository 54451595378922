import { XLSXResponse } from "models/xlsx/xlsx.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export interface GetPurchaseInvoicesXLSXParams {
  search: string;
  sortOrder?: string;
  sortKey?: string;
}

export type GetPurchaseInvoicesXLSXResponse = XLSXResponse;

export const PURCHASE_INVOICES_XSLX_KEY = "purchaseInvoicesXLSX";

export function useGetPurchaseInvoicesXLSX(
  input?: GetPurchaseInvoicesXLSXParams
) {
  return useApiQuery<GetPurchaseInvoicesXLSXResponse>(
    [
      PURCHASE_INVOICES_XSLX_KEY,
      input?.search,
      input?.sortKey,
      input?.sortOrder,
    ],
    `purchase-invoices-xlsx?${new URLSearchParams({
      search: input?.search ?? "",
      sortKey: input?.sortKey ?? "",
      sortOrder: input?.sortOrder ?? "",
    })}`,
    {},
    {
      enabled: false,
    }
  );
}
