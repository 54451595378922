import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { LoadingButton } from "components/LoadingButton";
import { useFile, useRoutes } from "domains";
import { useGetFile } from "hooks/file/queries/useGetFile";

interface SendToCustomerModalProps {
  onClose: () => void;
  onSend: () => void;
  loading: boolean;
}

export const SendToCustomerModal: FC<SendToCustomerModalProps> = function ({
  onSend,
  onClose,
  loading,
}: SendToCustomerModalProps) {
  const { t } = useTranslation();

  const { goToCompanySettings } = useRoutes();

  const handleGoToCompanySettings = useCallback(
    () => goToCompanySettings(),
    [goToCompanySettings]
  );

  const { bankAccount } = useFile(useGetFile());

  if (!bankAccount?.iban) {
    return (
      <>
        <p className="text-base text-center leading-relaxed text-gray-400 dark:text-gray-400">
          {t("sendToCustomerModal.body.noIbanKnownYet")}
        </p>
        <div className="flex gap-4 justify-center">
          <LoadingButton loading={loading} onClick={handleGoToCompanySettings}>
            {t("sendToCustomerModal.footer.enterIban")}
          </LoadingButton>
          <Button color="gray" onClick={onClose}>
            {t("sendToCustomerModal.footer.back")}
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <p className="text-base text-center leading-relaxed text-gray-400 dark:text-gray-400">
        {t("sendToCustomerModal.body.info")}
      </p>
      <div className="flex gap-4 justify-center">
        <LoadingButton loading={loading} onClick={onSend}>
          {t("sendToCustomerModal.footer.send")}
        </LoadingButton>
        <Button color="gray" onClick={onClose}>
          {t("sendToCustomerModal.footer.back")}
        </Button>
      </div>
    </>
  );
};
