import { useMemo } from "react";
import { API } from "models/core/API.interface";
import { UseCases } from "models/core";

import { Transaction } from "./models/transaction.interface";

export type TransactionAPI = API<{
  transactions: Omit<Transaction, "update">[];
  count: number;
}>;

interface Props {
  api: TransactionAPI;
}

interface Result {
  transactions: Transaction[];
  transaction?: Transaction;
  count: number;
  isLoading: boolean;
  error?: Error;
}

export const useTransactionsUseCases: UseCases<Props, Result> = ({ api }) => {
  const transactions: Result["transactions"] = api.data.transactions.map(
    (transaction) => ({
      ...transaction,
    })
  );

  const transaction: Result["transaction"] = useMemo(
    () => transactions[0],
    [transactions]
  );

  return {
    ...api,
    transactions,
    transaction,
    count: api.data.count,
  };
};
