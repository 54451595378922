import classNames from "classnames";
import { useSidebarContext } from "context/SidebarContext";
import { Sidebar as BaseSidebar } from "flowbite-react";
import isSmallScreen from "helpers/is-small-screen";
import { FC, HTMLProps, PropsWithChildren, useMemo } from "react";

type SidebarProps = PropsWithChildren<HTMLProps<HTMLDivElement>>;

const Sidebar: FC<SidebarProps> = function ({ children, ...rest }) {
  const { isOpenOnSmallScreens } = useSidebarContext();
  const collapsed = useMemo(
    () => isOpenOnSmallScreens && !isSmallScreen(),
    [isOpenOnSmallScreens]
  );

  return (
    <div
      {...rest}
      className={classNames("lg:!block h-full", {
        hidden: !isOpenOnSmallScreens,
      })}
    >
      <BaseSidebar
        className={classNames(
          "flex fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16 h-full border-r lg:flex",
          {
            "!w-16": collapsed,
          }
        )}
        aria-label="Sidebar with multi-level dropdown"
        collapsed={collapsed}
      >
        <BaseSidebar.Items>{children}</BaseSidebar.Items>
      </BaseSidebar>
    </div>
  );
};

export default Object.assign(Sidebar, {
  ItemGroup: BaseSidebar.ItemGroup,
  Collapse: BaseSidebar.Collapse,
  Item: BaseSidebar.Item,
});
