import { FC, useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card } from "flowbite-react";
// import { format } from "date-fns";

import { useDashboard, useRoutes } from "domains";
import { PieChart } from "components/charts/pieChart/PieChart";
// import { calculateDateRangePieChart } from "helpers/calculate-date-range-pie-chart";
import { UnpaidInvoiceDetail } from "hooks/dashboard/models/dashboard.interface";
import { InvoicePayStatus } from "models/paymentDocument/invoice/invoice.interface";
import { UnpaidInvoicesLabels } from "./UnpaidInvoicesLabels";
import { UnpaidInvoicesPieChartTitle } from "./UnpaidInvoicesPieChartTitle";
import { UnpaidInvoicesTypeButton } from "./UnpaidInvoicesTypeButton";
import {
  UnpaidInvoicesType,
  useGetUnpaidInvoices,
} from "../../queries/useGetUnpaidInvoices";

export const UNPAID_INVOICES_TYPE_FILTER = "type_unpaid_invoices";
export const UNPAID_INVOICES_PIE_CHART_COLORS = [
  "#F1F7FF",
  "#DBEAFE",
  "#93C5FD",
  "#3B82F6",
];

const mapToPieChartData = (
  selectedType: string,
  data?: UnpaidInvoiceDetail
) => {
  return data
    ? Object.keys(data)
        .filter((key) => key !== "total")
        .map((key) => ({
          name: key,
          value:
            selectedType === UnpaidInvoicesType.quantity
              ? data[key as keyof UnpaidInvoiceDetail].count
              : data[key as keyof UnpaidInvoiceDetail].amount,
        }))
    : [];
};

export const UnpaidInvoices: FC = function () {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { setRouteParams, goToInvoicesFromDashboard, routes } = useRoutes();

  const selectedType = useMemo(
    () =>
      (searchParams.get(UNPAID_INVOICES_TYPE_FILTER) as UnpaidInvoicesType) ??
      UnpaidInvoicesType.quantity,
    [searchParams]
  );

  const { unpaidInvoices, unpaidInvoicesLoading: isLoading } = useDashboard({
    unpaidInvoices: useGetUnpaidInvoices({
      type: selectedType,
    }),
  });

  const chartData = useMemo(() => {
    return {
      toPay: mapToPieChartData(selectedType, unpaidInvoices.toPayDetail),
      receivedDetail: mapToPieChartData(
        selectedType,
        unpaidInvoices.receivedDetail
      ),
    };
  }, [unpaidInvoices, selectedType]);

  const onSelectType = useCallback(
    (type: UnpaidInvoicesType) =>
      setRouteParams(UNPAID_INVOICES_TYPE_FILTER, type),
    [setRouteParams]
  );

  const onSelectCell = useCallback(
    (key: string, isSales?: boolean) => {
      // const { from, to } = calculateDateRangePieChart(key);
      goToInvoicesFromDashboard(
        isSales ? routes.salesInvoices : routes.purchaseInvoices,
        InvoicePayStatus.OverDue
        // from ?? format(new Date(), "dd/MM/yyyy"),
        // to
      );
    },
    [goToInvoicesFromDashboard, routes]
  );

  return (
    <Card className="grow">
      <div className="flex justify-between items-center mb-4">
        <p className="text-2xl font-bold dark:text-white">
          {t("dashboardPage.unpaidInvoices.title")}
        </p>
        <div className="flex gap-4">
          <UnpaidInvoicesTypeButton
            selected={selectedType === UnpaidInvoicesType.quantity}
            onSelectType={onSelectType}
            type={UnpaidInvoicesType.quantity}
            text="#"
          />
          <UnpaidInvoicesTypeButton
            selected={selectedType === UnpaidInvoicesType.amount}
            type={UnpaidInvoicesType.amount}
            onSelectType={onSelectType}
            text="€"
          />
        </div>
      </div>
      <div className="min-h-76">
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <PieChart
            data={chartData.receivedDetail}
            loading={isLoading}
            colors={UNPAID_INVOICES_PIE_CHART_COLORS}
            isCurrency={selectedType === UnpaidInvoicesType.amount}
            onSelectCell={onSelectCell}
            isSales
            title={
              <UnpaidInvoicesPieChartTitle
                label={t("dashboardPage.unpaidInvoices.toReceive")}
                total={
                  selectedType === UnpaidInvoicesType.amount
                    ? unpaidInvoices.receivedDetail?.total?.amount ?? 0
                    : unpaidInvoices.receivedDetail?.total?.count ?? 0
                }
                isCurrency={selectedType === UnpaidInvoicesType.amount}
              />
            }
          />
          <PieChart
            data={chartData.toPay}
            loading={isLoading}
            colors={UNPAID_INVOICES_PIE_CHART_COLORS}
            isCurrency={selectedType === UnpaidInvoicesType.amount}
            onSelectCell={onSelectCell}
            title={
              <UnpaidInvoicesPieChartTitle
                label={t("dashboardPage.unpaidInvoices.toPay")}
                total={
                  selectedType === UnpaidInvoicesType.amount
                    ? unpaidInvoices.toPayDetail?.total?.amount ?? 0
                    : unpaidInvoices.toPayDetail?.total?.count ?? 0
                }
                isCurrency={selectedType === UnpaidInvoicesType.amount}
              />
            }
          />
        </div>
        <UnpaidInvoicesLabels />
      </div>
    </Card>
  );
};
