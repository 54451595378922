import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { format } from "date-fns";
import { useApiMutation } from "hooks/api/useApiMutation";
import { httpErrorCodeWithTranslations } from "helpers/api";
import { event } from "event";
import { InvoicePayStatus } from "models/paymentDocument/invoice/invoice.interface";
import { PurchaseInvoice } from "../models/purchaseInvoice.interface";
import { UpdatePurchaseInvoiceInput } from "../models/purchaseInvoiceInput.interface";

export interface UpdatePurchaseInvoiceParams {
  id: PurchaseInvoice["id"];
}

export type UpdatePurchaseInvoiceBody = UpdatePurchaseInvoiceInput;

export type UpdatePurchaseInvoiceResponse = void;

export function useUpdatePurchaseInvoice() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useApiMutation<
    UpdatePurchaseInvoiceResponse,
    UpdatePurchaseInvoiceParams & UpdatePurchaseInvoiceBody
  >(
    "purchaseInvoices",
    (input) => `purchase-invoices/${input.id}`,
    (input) => ({
      method: "put",
      body: JSON.stringify({
        ...(input.supplierId ? { supplierId: input.supplierId } : {}),
        issueDate: input.issueDate
          ? format(new Date(input.issueDate), "dd/MM/yyyy")
          : undefined,
        dueDate: input.dueDate
          ? format(new Date(input.dueDate), "dd/MM/yyyy")
          : undefined,
        ...(input.totalInclusiveVat
          ? {
              totalInclusiveVat: String(input.totalInclusiveVat).replace(
                ",",
                "."
              ),
            }
          : {}),
        ...(input.totalExclusiveVat
          ? {
              totalExclusiveVat: String(input.totalExclusiveVat).replace(
                ",",
                "."
              ),
            }
          : {}),
        ...(input.totalTaxAmount
          ? { totalTaxAmount: String(input.totalTaxAmount).replace(",", ".") }
          : {}),
        currency: input.currency,
        invoiceNumber: input.invoiceNumber ?? "",
        invoicePayStatus: input.invoicePayStatus ?? "",
        paymentProcessing:
          input.invoicePayStatus === InvoicePayStatus.Processing,
        financialDocumentType: input.financialDocumentType,
        payDate: input.payDate
          ? format(new Date(input.payDate), "dd/MM/yyyy")
          : undefined,
      }),
    }),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["purchaseInvoices"]);
      },
      onError: (error) => {
        // For the time being: error messages are shown from the back-end (still in english).
        // They should be able to provide translated error messages since we
        // send the current language over the "Accept-Language" header
        // See: src/helpers/api.ts

        // They'll need to provide they want us
        // to translate messages on the front-end

        if (error?.response?.payload?.detail) {
          event.emit(
            "mutationFailed",
            new Error(error?.response?.payload?.detail)
          );

          return;
        }
        if (httpErrorCodeWithTranslations.includes(error.response.status)) {
          event.emit(
            "mutationFailed",
            new Error(t(`error.${error.response.status}`) as string)
          );

          return;
        }

        event.emit("mutationFailed", new Error(error.message));
      },
    }
  );
}
