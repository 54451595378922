// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeProperties = <T extends Record<string, any>>(
  input: T,
  propertiesToRemove: (keyof T)[]
): Omit<T, keyof typeof propertiesToRemove> => {
  const filteredObject = { ...input };

  propertiesToRemove.forEach((property) => {
    // eslint-disable-next-line no-prototype-builtins
    if (filteredObject.hasOwnProperty(property)) {
      delete filteredObject[property];
    }
  });

  return filteredObject as Omit<T, keyof typeof propertiesToRemove>;
};
