import { FC, useCallback, useMemo, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { HiDocumentText, HiTrash } from "react-icons/hi";
import { Dropdown, Table } from "flowbite-react";
import { differenceInCalendarDays, format, parse } from "date-fns";
import { useRoutes } from "domains";
import { Pagination, PaginationProps } from "components/Pagination";
import { Checkbox } from "components/Checkbox";
import { SortableHeadCell } from "components/table/SortableHeadCell";
import { SkeletonRows } from "components/skeletons/SkeletonRows";
import { SkeletonRow } from "components/skeletons/SkeletonRow";
import { Skeleton } from "components/skeletons/Skeleton";
import { EmptyTableRow } from "components/EmptyTableRow";
import { TableRow } from "components/table/TableRow";
import { RoundedIconDropdown } from "components/table/RoundedIconDropdown";
import { Currency, toCurrency } from "helpers/to-currency";
import { RowSelectedState } from "hooks/tables/models/tables.interface";
import { Timesheet } from "hooks/timesheets/models/timesheets.interface";
import { secondsToHHmmss } from "helpers/to-hhmmss";
import Badge from "components/Badge";

export const badgeMap: Map<boolean, string> = new Map<boolean, string>([
  [true, "success"],
  [false, "warning"],
]);

interface Props extends Omit<PaginationProps, "rows"> {
  rows: Timesheet[];
  isLoading?: boolean;
  handleAllRowsClicked: (ids: string[]) => void;
  handleOneRowClicked: (id: string) => void;
  rowSelectedState: RowSelectedState;
}

interface TimesheetTableRowProps extends Timesheet {
  selected: boolean;
  onSelect: (id: string) => void;
}

const TimesheetTableRow: FC<TimesheetTableRowProps> = function ({
  onSelect,
  selected,
  ...timesheet
}) {
  const { t } = useTranslation();
  const {
    openDeleteModal,
    openEditModal,
    openGenerateInvoiceModal,
    goToSalesInvoice,
  } = useRoutes();

  const onOpenDeleteModal = useCallback(
    () => openDeleteModal([timesheet.id]),
    [timesheet, openDeleteModal]
  );

  const onOpenEditModal = useCallback(
    () => openEditModal(timesheet.id),
    [timesheet, openEditModal]
  );

  const onOpenGenerateInvoiceModal = useCallback(
    () => openGenerateInvoiceModal(timesheet.id),
    [timesheet, openGenerateInvoiceModal]
  );

  const select = useCallback(
    () => onSelect(timesheet.id),
    [timesheet, onSelect]
  );

  const handleGoToInvoice = useCallback(
    (event: MouseEvent<HTMLTableDataCellElement>) => {
      event.stopPropagation();
      if (timesheet.salesInvoiceId)
        goToSalesInvoice(String(timesheet.salesInvoiceId));
    },
    [timesheet, goToSalesInvoice]
  );

  const timeLabel = useMemo(() => {
    const startTime = timesheet?.startTime
      ? parse(timesheet.startTime, "dd/MM/yyyy HH:mm:ss", new Date())
      : undefined;
    const endTime = timesheet?.endTime
      ? parse(timesheet.endTime, "dd/MM/yyyy HH:mm:ss", new Date())
      : undefined;

    if (!startTime || !endTime) {
      return "-";
    }

    const diff = differenceInCalendarDays(
      new Date(startTime),
      new Date(endTime)
    );

    return `${format(startTime, "HH:mm")} - ${format(endTime, "HH:mm")} ${
      diff > 0 ? `(+${diff})` : ""
    }`;
  }, [timesheet]);

  return (
    <TableRow onClick={onOpenEditModal}>
      <Table.Cell>
        <Checkbox onChange={select} checked={selected} />
      </Table.Cell>
      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
        {timesheet.customerName || "-"}
      </Table.Cell>
      <Table.Cell>{timesheet?.date}</Table.Cell>
      <Table.Cell>
        <p className="text-gray-900 dark:text-white">
          {/* {timesheet.startDate
            ? format(new Date(timesheet.startDate), "dd/MM/yyyy")
            : "-"} */}
        </p>
        <p>{timeLabel}</p>
      </Table.Cell>
      <Table.Cell>
        {timesheet?.duration ? secondsToHHmmss(timesheet.duration) : "-"}
      </Table.Cell>
      <Table.Cell>{toCurrency(timesheet.hourlyRate, Currency.Euro)}</Table.Cell>
      <Table.Cell>{toCurrency(timesheet.totalPrice, Currency.Euro)}</Table.Cell>
      <Table.Cell onClick={handleGoToInvoice}>
        <Badge value={Boolean(timesheet.salesInvoiceId)} map={badgeMap} />
      </Table.Cell>
      <Table.Cell>
        <RoundedIconDropdown
          disabled={Boolean(timesheet?.salesInvoiceId)}
          className="w-max mr-2 z-100"
        >
          {!timesheet?.salesInvoiceId && (
            <>
              <Dropdown.Item
                className="flex items-center py-3 rounded-t-lg"
                onClick={onOpenGenerateInvoiceModal}
                role="button"
              >
                <HiDocumentText className="mr-2 text-lg" />
                {t("timesheetTable.button.generateInvoice")}
              </Dropdown.Item>
              <Dropdown.Item
                className="flex items-center py-3 rounded-b-lg"
                onClick={onOpenDeleteModal}
                role="button"
              >
                <HiTrash className="mr-2 text-lg text-red-500 shrink-0" />
                <span className="text-red-500 shrink-0">
                  {t("timesheetTable.button.delete")}
                </span>
              </Dropdown.Item>
            </>
          )}
        </RoundedIconDropdown>
      </Table.Cell>
    </TableRow>
  );
};

const LoadingTimesheetRows: FC = function () {
  return (
    <SkeletonRows rows={10}>
      <SkeletonRow>
        <Table.Cell />
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Skeleton>
            <RoundedIconDropdown />
          </Skeleton>
        </Table.Cell>
      </SkeletonRow>
    </SkeletonRows>
  );
};

const TimesheetTable: FC<Props> = function ({
  rows,
  isLoading,
  handleAllRowsClicked,
  handleOneRowClicked,
  rowSelectedState,
  ...rest
}) {
  const { t } = useTranslation();

  const onAllClicked = useCallback(
    () => handleAllRowsClicked(rows.map((timesheet) => timesheet.id)),
    [handleAllRowsClicked, rows]
  );

  return (
    <div className="flex flex-col justify-between overflow-hidden">
      <Table
        hoverable
        className="divide-y divide-gray-200 dark:divide-gray-600 table-fixed"
      >
        <Table.Head className="!bg-gray-100 dark:!bg-gray-700">
          <Table.HeadCell className="w-4">
            <Checkbox
              aria-label="check-all"
              checked={rowSelectedState.all}
              onChange={onAllClicked}
            />
          </Table.HeadCell>
          <SortableHeadCell sortKey="customer">
            {t("timesheetTable.title.customer")}
          </SortableHeadCell>
          <SortableHeadCell sortKey="date">
            {t("timesheetTable.title.date")}
          </SortableHeadCell>
          <SortableHeadCell sortKey="duration">
            {t("timesheetTable.title.toggledTime")}
          </SortableHeadCell>
          <Table.HeadCell>{t("timesheetTable.title.duration")}</Table.HeadCell>
          <SortableHeadCell sortKey="hourlyRate">
            {t("timesheetTable.title.hourlyRate")}
          </SortableHeadCell>
          <SortableHeadCell sortKey="totalPrice">
            {t("timesheetTable.title.totalPrice")}
          </SortableHeadCell>
          <Table.HeadCell>{t("timesheetTable.title.status")}</Table.HeadCell>
          <Table.HeadCell className="w-24" />
        </Table.Head>
        <Table.Body className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
          {isLoading && <LoadingTimesheetRows />}
          {!isLoading &&
            rows.map((timesheet) => (
              <TimesheetTableRow
                key={timesheet.id}
                {...timesheet}
                selected={rowSelectedState.ids.includes(timesheet.id)}
                onSelect={handleOneRowClicked}
              />
            ))}
          {!isLoading && rows.length === 0 && (
            <EmptyTableRow colSpan={9}>
              {t("timesheetTable.title.empty")}
            </EmptyTableRow>
          )}
        </Table.Body>
      </Table>
      <Pagination {...rest} />
    </div>
  );
};

export default TimesheetTable;
