import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Skeleton } from "components/skeletons/Skeleton";
import { SkeletonButton } from "components/skeletons/SkeletonButton";
import { SkeletonInput } from "components/skeletons/SkeletonInput";

export const SupplierFormSkeleton: FC = function () {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-between gap-4">
        <div className="flex flex-col flex-1 gap-2">
          <Skeleton isText className="mt-1 mb-2">
            <span>{t("supplierForm.input.name")}</span>
          </Skeleton>
          <SkeletonInput />
        </div>
        <div className="flex flex-col flex-1 gap-2">
          <Skeleton isText className="mt-1 mb-2">
            <span>{t("supplierForm.input.vatNumber")}</span>
          </Skeleton>
          <SkeletonInput />
        </div>
      </div>
      <div className="flex justify-between gap-4">
        <div className="flex flex-col flex-1 gap-2">
          <Skeleton isText className="mt-1 mb-2">
            <span>{t("supplierForm.input.email")}</span>
          </Skeleton>
          <SkeletonInput />
        </div>
      </div>

      <SkeletonButton>{t("supplierForm.button.save")}</SkeletonButton>
    </>
  );
};
