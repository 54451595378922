import { useCallback } from "react";
import { TypeAheadOptionWrapper } from "./TypeAheadOptionWrapper";

interface TypeAheadOptionProps<T> {
  selectOption: (option: T) => void;
  option: T;
  name: string;
}

export const TypeAheadOption = function <T>({
  selectOption,
  option,
  name,
}: TypeAheadOptionProps<T>) {
  const onSelectOption = useCallback(
    () => selectOption(option),
    [selectOption, option]
  );

  return (
    <TypeAheadOptionWrapper onSelectOption={onSelectOption}>
      <p className="text-gray-900 dark:text-white">{name}</p>
    </TypeAheadOptionWrapper>
  );
};
