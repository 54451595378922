import i18n from "i18n/i18n";

export enum Currency {
  Euro = "EUR",
  RussianRubel = "RUB",
  UnitedStatesDollar = "USD",
  AustralianDollar = "AUD",
  SwissFranc = "CHF",
  Argentina = "ARS",
  UkraineGrivna = "UAH",
  SouthAfricanRand = "ZAR",
  HongKongDollar = "HKD",
  MacauPataca = "MOP",
  PhilippinePeso = "PHP",
  MalaysianRinggit = "MYR",
  SingaporeDollar = "SGD",
  NamibiaDollar = "NAD",
  BotswanePula = "BWP",
  MoroccoDirham = "MAD",
  MauritiusRupee = "MUR",
  MalawiKwacha = "MWK",
  ZimbabweDollar = "ZWD",
  KenyaShilling = "KES",
  SeychellesRupee = "SCR",
  AngolaKwanza = "AOA",
  TanzaniaShilling = "TZS",
  GhanaCedi = "GHC",
  ZambiaKwacha = "ZMK",
  MadagascarAiry = "MGA",
  HungarianForint = "HUF",
  LativanLats = "LVL",
  EstonianKroon = "EEK",
  LithuanianLitas = "LTL",
  BolivianBoliviano = "BOB",
  BrazilianReal = "BRL",
  ChilePeso = "CLP",
  ColumbianPeso = "COP",
  GuyanaDollar = "GYD",
  ParaguayGuarani = "PYG",
  PeruNuevoSol = "PEN",
  SurinameDollar = "SRD",
  UruguayPeso = "UYU",
  VenezuelaBolivar = "VEB",
  RomanianOldLei = "ROL",
  RomanianNewLei = "RON",
  KazakhstanTenge = "KZT",
  LesothoLoti = "LSL",
  ThaiBaht = "THB",
  UKPounds = "GBP",
  PolishZloty = "PLN",
  DanishKrone = "DKK",
  SwedishKrone = "SEK",
  CzechKoruna = "CZK",
  CroatianKuna = "HRK",
  BulgarianLev = "BGN",
  SerbianDinar = "RSD",
  NewTurkishLira = "TRY",
  DominicanPeso = "DOP",
  ArubanFlorin = "AWG",
  NorwegionKron = "NOK",
  CanadianDollar = "CAD",
  JapaneseYen = "JPY",
  IndianRupee = "INR",
  RwandaFranc = "RWF",
  AlbanianLek = "ALL",
  MexicanPeso = "MXN",
  IcelandKrona = "ISK",
  NewZealandDollar = "NZD",
  SouthKoreanWon = "KRW",
  NewTaiwanDollar = "TWD",
  IsraelNewShekel = "ILS",
  NewZambianKwacha = "ZMW",
  PanamanianBalbao = "PAB",
  CostaRicaColon = "CRC",
  SaintMartensCuracaoFlorin = "ANG",
  MacedonianDenar = "MKD",
  VietnamDong = "VND",
  LankanRupee = "LKR",
  GeorgianLari = "GEL",
  GibraltarPounds = "GIP",
  NigerianNaira = "NGN",
  UnitedArabEmiratesDirham = "AED",
  SaudiRiyal = "SAR",
  BarbadianDollar = "BBD",
  TrinidadAndTobagoDollar = "TTD",
  QatarRiyal = "QAR",
  GuatemalanQuetzal = "GTQ",
  HonduranLempira = "HNL",
  IndonesianRupiah = "IDR",
  SwaziLilangeni = "SZL",
  MozambicanMetical = "MZN",
  EgyptPound = "EGP",
}

export const ALLLOWED_CURRENCIES = [
  Currency.Euro,
  Currency.UKPounds,
  Currency.UnitedStatesDollar,
];

export const toCurrency = (
  number: number,
  currency: Currency,
  minimumFractionDigits = 2
): string => {
  return number?.toLocaleString(i18n.language, {
    style: "currency",
    currency,
    minimumFractionDigits,
    maximumFractionDigits: 2,
  });
};

export const CURRENCY_SIGNS = {
  [Currency.Euro as string]: "€",
  [Currency.UnitedStatesDollar as string]: "$",
  [Currency.UKPounds as string]: "£",
};
