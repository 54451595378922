import { useEffect, useState, useCallback } from "react";
import { useMsal } from "@azure/msal-react";

import {
  BrowserAuthError,
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useAuth } from "hooks/useAuth";
import { AppLoader } from "components/AppLoader";
import { REFRESH_INTERVAL_IN_MS, request } from "./config";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function MsalToken(props: any) {
  const { instance, inProgress } = useMsal();
  const { login } = useAuth.getState();

  const [initialTokenLoaded, setInitialTokenLoaded] = useState(false);

  const silentRenew = useCallback(async () => {
    await instance.handleRedirectPromise();
    const account = instance.getActiveAccount();
    if (!account) {
      await instance.loginRedirect(request);
      return;
    }

    const silentRequest = { ...request, account };
    const silentRequestWithoutPrompt = { ...silentRequest, prompt: "none" };

    try {
      try {
        const response = await instance.acquireTokenSilent(silentRequest);

        if (response.accessToken) {
          login(response.accessToken);
        }
      } catch (err) {
        const response = await instance.loginPopup(silentRequestWithoutPrompt);

        if (response.accessToken) {
          login(response.accessToken);
        }
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          await instance.acquireTokenRedirect(silentRequest);
        } catch (err) {
          const interaction = sessionStorage.getItem("msal.interaction.status");
          if (interaction !== "test") {
            sessionStorage.removeItem("msal.interaction.status");
            await instance.acquireTokenRedirect(silentRequest);
          }
        }
      }
    }
  }, [instance, login]);

  // Acquire token on load
  useEffect(() => {
    const tryLoadInitialToken = async () => {
      if (initialTokenLoaded) {
        return;
      }

      if (inProgress !== InteractionStatus.None) {
        return;
      }

      await instance.handleRedirectPromise();

      const account = instance.getActiveAccount();
      if (!account) {
        try {
          await instance.loginRedirect(request);
        } catch (err) {
          if (err instanceof BrowserAuthError) {
            const interaction = sessionStorage.getItem(
              "msal.interaction.status"
            );
            if (interaction !== "test") {
              sessionStorage.removeItem("msal.interaction.status");
              await instance.loginRedirect(request);
            }
          }
        }
        return;
      }

      const silentRequest = {
        ...request,
        account,
      };

      const silentRequestWithoutPrompt = { ...silentRequest };

      try {
        const response = await instance.acquireTokenSilent({
          ...silentRequestWithoutPrompt,
        });
        login(response.accessToken);
        setInitialTokenLoaded(true);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          try {
            await instance.acquireTokenRedirect(silentRequest);
          } catch (error) {
            const interaction = sessionStorage.getItem(
              "msal.interaction.status"
            );
            if (interaction !== "test") {
              sessionStorage.removeItem("msal.interaction.status");
              await instance.acquireTokenRedirect(silentRequest);
            }
          }
        }
      }
    };

    // eslint-disable-next-line no-console
    tryLoadInitialToken().catch(console.error);
  }, [initialTokenLoaded, setInitialTokenLoaded, login, instance, inProgress]);

  // Try to renew on interval
  useEffect(() => {
    if (!initialTokenLoaded) {
      return () => {
        // do nothing
      };
    }

    const interval = setInterval(silentRenew, REFRESH_INTERVAL_IN_MS);

    return () => {
      clearInterval(interval);
    };
  }, [initialTokenLoaded, silentRenew]);

  // If no initial token is loaded we're still loading the application
  if (!initialTokenLoaded) {
    return <AppLoader />;
  }

  // valid token
  return props.children;
}

export default MsalToken;
