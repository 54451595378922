import { CustomerDTO } from "hooks/customers/models/customer.interface";
import { PaginatedResponse } from "models/schema.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export interface GetCustomersParams {
  offset: number;
  limit: number;
  search?: string;
  sortKey?: string;
  sortOrder?: string;
  refetchInterval?: number;
}

export type GetCustomersResponse = PaginatedResponse<CustomerDTO>;

export const CUSTOMERS_QUERY = "customers";

export function useGetCustomers(input?: GetCustomersParams) {
  const size = input?.limit ? Number(input.limit) : 200;
  const page = input?.offset ? input.offset / size : 0;

  return useApiQuery<GetCustomersResponse>(
    [
      CUSTOMERS_QUERY,
      input?.limit,
      input?.offset,
      input?.search,
      input?.sortKey,
      input?.sortOrder,
    ],
    `customers?${new URLSearchParams({
      page: String(page),
      size: String(size),
      search: input?.search ?? "",
      sort:
        input?.sortKey && input?.sortOrder
          ? `${input.sortKey},${input.sortOrder}`
          : "",
    })}`,
    {},
    {
      refetchInterval: input?.refetchInterval,
    }
  );
}
