import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { Label } from "flowbite-react";

import { LoadingButton } from "components/LoadingButton";
import { TextInput } from "components/input/TextInput";
import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "helpers/html-validation-schema";
import { HTMLValidationSchemaResolver } from "helpers/html-validation-schema/compat/HTMLValidationSchemaResolver";

export type IbanFormValues = { iban: string };

interface IbanFormProps {
  isLoading: boolean;
  onSubmit: (input: IbanFormValues) => void;
  schema:
    | HTMLValidationSchema<IbanFormValues>
    | HTMLValidationSchemaGenerator<IbanFormValues>;
}

const IbanForm: FC<IbanFormProps> = function ({ isLoading, onSubmit, schema }) {
  const { t } = useTranslation();
  const methods = useForm<IbanFormValues>({
    defaultValues: {},
    mode: "onBlur",
    resolver: HTMLValidationSchemaResolver(schema),
  });

  return (
    <FormProvider {...methods}>
      <form className="!m-0" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4 p-6">
          <div className="flex gap-4">
            <div className="w-full">
              <div className="mb-2 block">
                <Label htmlFor="iban" value={t("ibanForm.label.iban") ?? ""} />
              </div>
              <TextInput
                id="iban"
                type="text"
                placeholder={t("ibanForm.placeholder.iban") ?? ""}
                {...methods.register("iban")}
                errorMessage={methods.formState.errors.iban?.message}
              />
            </div>
          </div>
        </div>
        <div className="border-1 border-t p-6 dark:border-gray-700">
          <LoadingButton
            loading={isLoading}
            className="px-2 py-0.5"
            type="submit"
          >
            {t("ibanForm.button.continue")}
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default IbanForm;
