import {
  TextareaProps as BaseTextAreaProps,
  Textarea as BaseTextArea,
} from "flowbite-react";
import { forwardRef } from "react";

export interface TextAreaProps extends Omit<BaseTextAreaProps, "ref"> {
  errorMessage?: string;
  errorColor?: boolean;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function ({ errorMessage, errorColor, ...rest }, ref) {
    return (
      <BaseTextArea
        {...rest}
        ref={ref}
        color={errorMessage || errorColor ? "failure" : rest.color}
        helperText={errorMessage || rest.helperText}
        theme={{
          base: "border w-full border-gray-200 bg-gray-50 dark:bg-gray-900 dark:border-gray-700 text-gray-500 dark:text-gray-300 rounded-lg text-sm",
        }}
      />
    );
  }
);
