import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useProducts } from "domains";
import { Product } from "hooks/products/models/product.interface";
import { useGetProductOptions } from "pages/products/queries/useGetProductOptions";
import { InvoiceFormValues } from "../InvoiceForm";
import { TextInputProps } from "../../input/TextInput";
import { TypeAhead } from "../../input/typeAhead/TypeAhead";
import { TypeAheadOption } from "../../input/typeAhead/TypeAheadOption";

interface ProductTypeAheadProps extends TextInputProps {
  placeholder?: string;
  index: number;
}

export const ProductTypeAhead: FC<ProductTypeAheadProps> = function ({
  placeholder,
  index,
  ...rest
}) {
  const { setValue, watch, clearErrors } = useFormContext<InvoiceFormValues>();
  const selectedProduct = watch(`documentLines.${index}`);

  const [search, setSearch] = useState("");
  const getProducts = useGetProductOptions(index, true, search);
  const { refetch } = getProducts;
  const { products } = useProducts(getProducts);

  // TODO: check for property naming after api call gets visible
  const onSelectOption = useCallback(
    (product: Product) => {
      setValue(`documentLines.${index}.articleNumber`, product.id);
      setValue(`documentLines.${index}.description`, product.name);
      setValue(
        `documentLines.${index}.amountExclusiveVat`,
        product.amountExclusiveVat
      );
      setValue(`documentLines.${index}.vatPercentage`, product.vatPercentage);

      clearErrors(`documentLines.${index}`);

      return () => null;
    },
    [setValue, index, clearErrors]
  );

  const onChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setValue(`documentLines.${index}.description`, target.value);
      setValue(`documentLines.${index}.articleNumber`, undefined);
    },
    [setValue, index]
  );

  useEffect(() => {
    refetch();
  }, [refetch, search]);

  return (
    <TypeAhead
      {...rest}
      showDropdown
      placeholder={placeholder}
      onChange={onChange}
      refetch={setSearch}
      selectedValue={selectedProduct?.description ?? ""}
      aria-label={`products.${index}.name`}
    >
      {products.map((product, index) => {
        return (
          <TypeAheadOption
            key={product.id ?? index}
            option={product}
            name={product.name || product.reference}
            selectOption={onSelectOption}
          />
        );
      })}
    </TypeAhead>
  );
};
