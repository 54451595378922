import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "flowbite-react";

interface Props {
  value: string;
  flag: string;
  onClick: (value: string) => void;
}

export const LanguageOption: FC<Props> = function ({ value, flag, onClick }) {
  const { t } = useTranslation();

  const onClickHandler = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  return (
    <Dropdown.Item onClick={onClickHandler}>
      <div className="flex gap-4 items-center">
        <img alt={t(`language.${value}`) as string} src={flag} width="16" />
        <div className="pr-4">{t(`language.${value}`)}</div>
      </div>
    </Dropdown.Item>
  );
};
