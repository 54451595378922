import { FC } from "react";
import { useTranslation } from "react-i18next";

import { FinancialEvolutionData } from "hooks/dashboard/models/dashboard.interface";
import { toCurrency, Currency } from "helpers/to-currency";

interface FinancialEvolutionTooltipData {
  fill: string;
  radius: number[];
  dataKey: string;
  name: keyof Omit<FinancialEvolutionData, "name">;
  color: string;
  value: number;
  payload: FinancialEvolutionData;
}

export const FinancialEvolutionTooltip: FC<{
  active: boolean;
  payload: Array<FinancialEvolutionTooltipData>;
}> = function ({ active, payload }) {
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    const title = payload[0].payload.name;

    return (
      <div className="bg-white dark:bg-gray-800 text-black rounded-md shadow-md border border-gray-100 dark:border-gray-700 overflow-hidden">
        <div className="px-3 py-2 bg-gray-100 dark:bg-gray-700">
          <p className="text-sm text-[#a1a1aa] capitalize">{title}</p>
        </div>
        <div className="px-3 py-2">
          {payload.map((item: FinancialEvolutionTooltipData) => {
            return (
              <p key={item.name} className="text-sm flex items-center gap-2">
                <span
                  className="block w-3 h-3 rounded-full"
                  style={{ backgroundColor: item?.fill }}
                />
                <span>
                  <span className="text-gray-500 dark:text-[#a1a1aa] capitalize">
                    {item?.name}:{" "}
                  </span>
                  <span className="font-medium dark:text-white">
                    {`${toCurrency(item.payload[item.name], Currency.Euro, 0)}`}
                  </span>
                </span>
              </p>
            );
          })}
          <p className="text-sm flex items-center gap-2">
            <span className="block w-3 h-3 rounded-full bg-black dark:bg-white" />
            <span>
              <span className="text-gray-500 dark:text-[#a1a1aa] capitalize">
                {t("dashboardPage.financialEvolution.ebitda")}:{" "}
              </span>
              <span className="font-medium dark:text-white">
                {`${toCurrency(payload[0]?.payload?.ebitda, Currency.Euro, 0)}`}
              </span>
            </span>
          </p>
        </div>
      </div>
    );
  }

  return null;
};
