/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */

import { FC, useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Label, TextInput } from "flowbite-react";
import { HTMLValidationSchema } from "helpers/html-validation-schema";
import { HTMLValidationSchemaResolver } from "helpers/html-validation-schema/compat/HTMLValidationSchemaResolver";
import { HTMLValidationSchemaGenerator } from "helpers/html-validation-schema/models/validationSchema";
import { RegisterInput } from "hooks/authentication/models/authenticationInput.interface";
import { HiBadgeCheck, HiCheckCircle, HiXCircle } from "react-icons/hi";
import classNames from "classnames";
import { LoadingButton } from "components/LoadingButton";
import { Button } from "components/Button";
import { KBOLookup } from "components/input/KBOLookup";
import { Logo } from "components/logo/Logo";
import { PATTERN } from "helpers/pattern";
import Link from "components/Link";
import { useParams } from "react-router-dom";

const STEPS = {
  COMPANY: 0,
  ACCOUNT: 1,
  SUCCESS: 2,
  ERROR: 3,
};

const STATES = {
  SUCCESS: "success",
  ERROR: "error",
};

interface Props {
  isRegistering: boolean;
  onSubmit: (input: RegisterInput) => void;
  schema:
    | HTMLValidationSchemaGenerator<RegisterInput>
    | HTMLValidationSchema<RegisterInput>;
}

export const RegisterForm: FC<Props> = function ({
  onSubmit,
  schema,
  isRegistering,
}) {
  const { t } = useTranslation();
  const { state } = useParams();

  const [step, setStep] = useState<number>(STEPS.COMPANY);
  const methods = useForm<RegisterInput>({
    mode: "onBlur",
    resolver: HTMLValidationSchemaResolver(schema),
  });

  const { handleSubmit, register, formState, watch } = methods;

  const vatNumber = watch("vatNumber");
  const companyEmail = watch("companyEmail");

  const validVatNumber = vatNumber?.match(PATTERN.KBO_NUMBER);
  const validCompanyEmail = companyEmail?.match(PATTERN.EMAIL);

  useEffect(() => {
    if (state === STATES.SUCCESS) setStep(STEPS.SUCCESS);
    else if (state === STATES.ERROR) setStep(STEPS.ERROR);
  }, [setStep, state]);

  return (
    <>
      <div className="flex mb-12">
        <Logo small url="./images/sumrise.svg" />
      </div>
      {(step === STEPS.ACCOUNT || step === STEPS.COMPANY) && (
        <ol className="flex items-center mb-6 text-sm font-medium text-center text-gray-500 dark:text-gray-400 lg:mb-12 sm:text-base">
          <li
            className={classNames(
              "flex items-center sm:after:content-[''] after:w-12 after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700",
              { "text-blue-600 dark:text-blue-500": step > STEPS.COMPANY }
            )}
          >
            <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-200 dark:after:text-gray-500">
              {step > STEPS.COMPANY ? (
                <HiCheckCircle
                  size="24"
                  color="blue"
                  className="mr-2 sm:mb-2 sm:mx-auto"
                />
              ) : (
                <div className="mr-2 sm:mb-2 sm:mx-auto">1</div>
              )}
              {t("signUpPage.title.yourCompany")}
            </div>
          </li>
          <li
            className={classNames("flex items-center", {
              "text-blue-600 dark:text-blue-500": step > STEPS.ACCOUNT,
            })}
          >
            <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-200 dark:after:text-gray-500">
              {step > STEPS.ACCOUNT ? (
                <HiCheckCircle
                  size="24"
                  color="blue"
                  className="mr-2 sm:mb-2 sm:mx-auto"
                />
              ) : (
                <div className="mr-2 sm:mb-2 sm:mx-auto">2</div>
              )}
              {t("signUpPage.title.yourAccount")}
            </div>
          </li>
        </ol>
      )}
      <h1 className="mb-4 text-2xl font-extrabold tracking-tight text-gray-900 sm:mb-6 leding-tight dark:text-white">
        {step === STEPS.COMPANY &&
          t("signUpPage.title.enterYourCompanyDetails")}
        {step === STEPS.ACCOUNT &&
          t("signUpPage.title.enterYourAccountDetails")}
      </h1>
      <FormProvider {...methods}>
        <form id="register" onSubmit={handleSubmit(onSubmit)}>
          <div
            className={classNames("mb-4 flex flex-col gap-y-3", {
              "flex hidden": step !== STEPS.COMPANY,
            })}
          >
            <Label htmlFor="signupForm.name">
              {t("signUpPage.input.companyName")}
            </Label>
            <KBOLookup
              id="signupForm.name"
              placeholder={t("signUpPage.placeholder.companyName") as string}
              formKey="vatNumber"
              isPublic
            />
          </div>
          <div
            className={classNames("mb-4 flex flex-col gap-y-3", {
              "flex hidden": step !== STEPS.COMPANY,
            })}
          >
            <Label htmlFor="email">{t("signUpPage.input.companyEmail")}</Label>
            <TextInput
              {...register("companyEmail")}
              id="email"
              placeholder={t("signUpPage.placeholder.companyEmail") || ""}
              type="email"
              color={formState.errors.companyEmail ? "failure" : ""}
              helperText={formState.errors.companyEmail?.message}
            />
          </div>
          <div
            className={classNames("mb-4 flex flex-col gap-y-3", {
              "flex hidden": step !== STEPS.ACCOUNT,
            })}
          >
            <Label htmlFor="userFirstname">
              {t("signUpPage.input.userFirstname")}
            </Label>
            <TextInput
              {...register("userFirstname")}
              id="userFirstname"
              placeholder={t("signUpPage.placeholder.userFirstname") || ""}
              type="text"
              color={formState.errors.userFirstname ? "failure" : ""}
              helperText={formState.errors.userFirstname?.message}
            />
          </div>
          <div
            className={classNames("mb-4 flex flex-col gap-y-3", {
              "flex hidden": step !== STEPS.ACCOUNT,
            })}
          >
            <Label htmlFor="userLastname">
              {t("signUpPage.input.userLastname")}
            </Label>
            <TextInput
              {...register("userLastname")}
              id="userLastname"
              placeholder={t("signUpPage.placeholder.userLastname") || ""}
              type="text"
              color={formState.errors.userLastname ? "failure" : ""}
              helperText={formState.errors.userLastname?.message}
            />
          </div>
          <div
            className={classNames("mb-4 flex flex-col gap-y-3", {
              "flex hidden": step !== STEPS.ACCOUNT,
            })}
          >
            <Label htmlFor="userEmail">{t("signUpPage.input.email")}</Label>
            <TextInput
              {...register("userEmail")}
              id="userEmail"
              placeholder={t("signUpPage.placeholder.email") || ""}
              type="text"
              color={formState.errors.userEmail ? "failure" : ""}
              helperText={formState.errors.userEmail?.message}
            />
          </div>
          <div
            className={classNames("mb-4 flex flex-col gap-y-3", {
              "flex hidden": step !== STEPS.ACCOUNT,
            })}
          >
            <Label htmlFor="userPassword">
              {t("signUpPage.input.password")}
            </Label>
            <TextInput
              {...register("userPassword")}
              id="userPassword"
              placeholder={t("signUpPage.placeholder.password") || ""}
              type="password"
              color={formState.errors.userPassword ? "failure" : ""}
              helperText={formState.errors.userPassword?.message}
            />
          </div>
          {step === STEPS.SUCCESS && (
            <>
              <HiBadgeCheck color="green" size="48" className="mb-4" />
              <h1 className="mb-2 text-2xl font-extrabold tracking-tight text-gray-900 leding-tight dark:text-white">
                {t("signUpPage.title.accountCreated")}
              </h1>
              <p className="mb-4 font-light text-gray-500 dark:text-gray-400 md:mb-6">
                {t("signUpPage.body.accountCreatedInfo")}
              </p>
              <Link to="/dashboard">
                <Button
                  size="medium"
                  className="block w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 sm:py-3.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  {t("signUpPage.link.signIn")}
                </Button>
              </Link>
            </>
          )}
          {step === STEPS.ERROR && (
            <>
              <HiXCircle color="red" size="48" className="mb-4" />
              <h1 className="mb-2 text-2xl font-extrabold tracking-tight text-gray-900 leding-tight dark:text-white">
                {t("signUpPage.title.accountCreatedError")}
              </h1>
              <p className="mb-4 font-light text-gray-500 dark:text-gray-400 md:mb-6">
                {t("signUpPage.body.accountCreatedError")}
              </p>
              <p className="mb-4 font-light text-gray-500 dark:text-gray-400 md:mb-6">
                {t("signUpPage.body.contactSupport")}
                <a
                  className="font-medium text-primary-600 dark:text-primary-500 hover:underline"
                  target="_blank"
                  rel="noreferrer"
                  href="mailto:info@sumrise.be"
                >
                  info@sumrise.be
                </a>
              </p>
            </>
          )}
          <div className="mb-7">
            {step === STEPS.COMPANY && (
              <>
                <Button
                  size="lg"
                  disabled={!validVatNumber || !validCompanyEmail}
                  type="button"
                  onClick={() => setStep(STEPS.ACCOUNT)}
                  form="register"
                  className="block w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  {t("signUpPage.button.next")}
                </Button>
                <hr className="h-px mt-6 mb-6 bg-gray-200 border-0 dark:bg-gray-700" />
                <p className="mt-4 text-sm font-light text-gray-500 dark:text-gray-400">
                  {t("signUpPage.link.alreadyHaveAnAccount")}{" "}
                  <Link to="/dashboard">
                    <a className="font-medium text-blue-600 hover:underline dark:text-blue-500">
                      {t("signUpPage.link.signIn")}
                    </a>
                  </Link>
                  .
                </p>
              </>
            )}
            {step === STEPS.ACCOUNT && (
              <>
                <div className="flex gap-2">
                  <Button
                    color="gray"
                    size="lg"
                    type="button"
                    onClick={() => setStep(STEPS.COMPANY)}
                    form="register"
                    className="block w-full"
                  >
                    {t("signUpPage.button.back")}
                  </Button>
                  <LoadingButton
                    size="lg"
                    type="submit"
                    form="register"
                    loading={isRegistering}
                    className="block w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    {t("signUpPage.button.signUp")}
                  </LoadingButton>
                </div>
                <hr className="h-px mt-6 mb-6 bg-gray-200 border-0 dark:bg-gray-700" />
                <p className="mt-4 text-sm font-light text-gray-500 dark:text-gray-400">
                  <p className="font-light text-gray-500 dark:text-gray-300">
                    {t("signUpPage.link.byCreatingAnAccountYouAgree")}{" "}
                    <a
                      className="font-medium text-primary-600 dark:text-primary-500 hover:underline"
                      href="https://sumrise.be/privacy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("signUpPage.link.privacyPolicy")}
                    </a>
                    ,{" "}
                    <a
                      className="font-medium text-primary-600 dark:text-primary-500 hover:underline"
                      href="https://sumrise.be/cookie"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("signUpPage.link.cookiePolicy")}
                    </a>{" "}
                    and{" "}
                    <a
                      className="font-medium text-primary-600 dark:text-primary-500 hover:underline"
                      href="https://sumrise.be/termsofuse"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("signUpPage.link.termsOfUse")}
                    </a>
                    .
                  </p>
                  .
                </p>
              </>
            )}
          </div>
        </form>
      </FormProvider>
    </>
  );
};
