import { FC, useCallback, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useWatch, Controller } from "react-hook-form";
import { Label } from "flowbite-react";
import { format, parse } from "date-fns";
import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "helpers/html-validation-schema";
import { HTMLValidationSchemaResolver } from "helpers/html-validation-schema/compat/HTMLValidationSchemaResolver";
import { LoadingButton } from "components/LoadingButton";
import { PurchaseInvoice } from "hooks/purchaseInvoices/models/purchaseInvoice.interface";
import { Supplier } from "hooks/suppliers/models/supplier.interface";
import { TextInput } from "components/input/TextInput";
import { UpdatePurchaseInvoiceInput } from "hooks/purchaseInvoices/models/purchaseInvoiceInput.interface";
import { useEvent } from "hooks/useEvent";

import { RadioButtonGroup } from "components/input/radioButton/RadioButtonGroup";
import {
  FinancialDocumentType,
  InvoicePayStatus,
} from "models/paymentDocument/invoice/invoice.interface";
import { Select } from "components/input/Select";
import {
  ALLLOWED_CURRENCIES,
  CURRENCY_SIGNS,
  Currency,
} from "helpers/to-currency";
import { SupplierTypeAhead } from "./SupplierTypeAhead";

interface Props {
  suppliers: Supplier[];
  onSubmit: (input: UpdatePurchaseInvoiceInput) => void;
  purchaseInvoice: PurchaseInvoice;
  schema:
    | HTMLValidationSchema<UpdatePurchaseInvoiceInput>
    | HTMLValidationSchemaGenerator<UpdatePurchaseInvoiceInput>;
  isLoading: boolean;
}

export const PurchaseInvoiceForm: FC<Props> = function ({
  suppliers = [],
  onSubmit,
  purchaseInvoice,
  schema,
  isLoading,
}: Props) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState,
    control,
    setValue,
    clearErrors,
    resetField,
  } = useForm<UpdatePurchaseInvoiceInput>({
    mode: "onBlur",
    defaultValues: {
      ...purchaseInvoice,
      currency: purchaseInvoice.currency ?? Currency.Euro,
      supplierId: purchaseInvoice.supplierId ?? undefined,
      invoiceNumber: purchaseInvoice.invoiceNumber ?? undefined,
      financialDocumentType: purchaseInvoice.financialDocumentType ?? undefined,
      issueDate: purchaseInvoice?.issueDate
        ? format(
            parse(purchaseInvoice?.issueDate, "dd/MM/yyyy", new Date()),
            "yyyy-MM-dd"
          )
        : undefined,
      dueDate: purchaseInvoice?.dueDate
        ? format(
            parse(purchaseInvoice?.dueDate, "dd/MM/yyyy", new Date()),
            "yyyy-MM-dd"
          )
        : undefined,
      payDate: purchaseInvoice?.payDate
        ? format(
            parse(purchaseInvoice?.payDate, "dd/MM/yyyy", new Date()),
            "yyyy-MM-dd"
          )
        : undefined,
      invoicePayStatus:
        // eslint-disable-next-line no-nested-ternary
        purchaseInvoice?.invoicePayStatus === InvoicePayStatus.Processing
          ? InvoicePayStatus.Processing
          : purchaseInvoice?.payDate
          ? InvoicePayStatus.Paid
          : InvoicePayStatus.Due,
      totalInclusiveVat: purchaseInvoice?.totalInclusiveVat ?? "",
      totalExclusiveVat: purchaseInvoice?.totalExclusiveVat ?? "",
      totalTaxAmount: purchaseInvoice?.totalTaxAmount ?? "",
    },
    resolver: HTMLValidationSchemaResolver(schema),
  });

  const currency: Currency = useWatch({
    name: "currency",
    control,
  }) as Currency;
  const invoicePayStatus = useWatch({ name: "invoicePayStatus", control });
  const selectedSupplierId = useWatch({ name: "supplierId", control });
  const selectedSupplier = useMemo(
    () => suppliers.find(({ id }) => id === selectedSupplierId),
    [suppliers, selectedSupplierId]
  );

  const setSupplierValue = useCallback(
    (id: string) => setValue("supplierId", id),
    [setValue]
  );

  // Reset form fields to default values when payment status changes
  useEffect(() => {
    if (invoicePayStatus === InvoicePayStatus.Due) {
      resetField("payDate");
    }
  }, [resetField, invoicePayStatus]);

  useEvent("supplierCreatedFromInvoice", setSupplierValue);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="py-5 border-b dark:border-gray-700 mb-5">
        <p className="text-2xl font-semibold dark:text-white">
          {t("purchaseInvoiceSidebar.specifications.title")}
        </p>
      </div>
      <div className="flex flex-col gap-4 p-5">
        <div className="w-full flex flex-col gap-2">
          <Label htmlFor="supplier">
            {t("purchaseInvoiceSidebar.specifications.supplier")}
          </Label>
          <SupplierTypeAhead
            id="supplier"
            placeholder={
              t("purchaseInvoiceSidebar.specifications.supplier") as string
            }
            selectedSupplier={selectedSupplier?.name || ""}
            formState={formState}
            setValue={setValue}
            clearErrors={clearErrors}
          />
        </div>
        <div className="w-full flex flex-col gap-2">
          <Label htmlFor="invoiceNumber">
            {t("purchaseInvoiceSidebar.specifications.invoiceNumber")}
          </Label>
          <TextInput
            {...register("invoiceNumber")}
            type="text"
            id="invoiceNumber"
            errorMessage={formState.errors.invoiceNumber?.message}
          />
        </div>
        <div className="flex gap-4">
          <div className="w-1/2 flex flex-col gap-2">
            <Label htmlFor="issueDate">
              {t("purchaseInvoiceSidebar.specifications.invoiceDate")}
            </Label>
            <TextInput
              {...register("issueDate")}
              type="date"
              id="issueDate"
              errorMessage={formState.errors.issueDate?.message}
            />
          </div>
          <div className="w-1/2 flex flex-col gap-2">
            <Label htmlFor="dueDate">
              {t("purchaseInvoiceSidebar.specifications.dueDate")}
            </Label>
            <TextInput
              {...register("dueDate")}
              id="dueDate"
              type="date"
              errorMessage={formState.errors.dueDate?.message}
            />
          </div>
        </div>
        {/* Payment info */}
        <div className="flex flex-col flex-1 gap-2">
          <Label htmlFor="invoicePayStatus">
            {t("invoiceForm.input.invoicePayStatus.label")}
          </Label>
          <Controller
            name="invoicePayStatus"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioButtonGroup
                name="invoicePayStatus"
                options={[
                  {
                    label: t("invoiceForm.input.invoicePayStatus.paid"),
                    value: InvoicePayStatus.Paid,
                  },
                  {
                    label: t("invoiceForm.input.invoicePayStatus.unpaid"),
                    value: InvoicePayStatus.Due,
                  },
                  {
                    label: t("invoiceForm.input.invoicePayStatus.processing"),
                    value: InvoicePayStatus.Processing,
                  },
                ]}
                selectedValue={value as string}
                onChange={onChange}
              />
            )}
          />
        </div>

        {invoicePayStatus === InvoicePayStatus.Paid && (
          <>
            {/* Payment date: default vandaag */}
            <div className="flex flex-col flex-1 gap-2">
              <Label htmlFor="payDate">{t("invoiceForm.input.payDate")}</Label>
              <TextInput
                {...register("payDate")}
                type="date"
                placeholder={t("invoiceForm.placeholder.payDate") as string}
                id="payDate"
                errorMessage={formState.errors.payDate?.message}
              />
            </div>
          </>
        )}
        <div className="flex flex-col flex-1 gap-1">
          <Label htmlFor="currency">{t("invoiceForm.input.currency")}</Label>
          <Select
            {...register("currency")}
            id="currency"
            errorMessage={formState.errors?.currency?.message}
            placeholder={t("invoiceForm.placeholder.currency") as string}
          >
            {Object.values(Currency)
              .filter((v) => ALLLOWED_CURRENCIES.find((cr) => cr === v))
              .map((value) => (
                <option key={value} value={value}>
                  {t(`currency.${value}`)}
                </option>
              ))}
          </Select>
        </div>
        <div className="flex flex-col gap-1">
          <Label htmlFor="financialDocumentType">
            {t("purchaseInvoiceSidebar.specifications.financialDocumentType")}
          </Label>
          <Select
            {...register("financialDocumentType")}
            id="financialDocumentType"
            errorMessage={formState.errors?.financialDocumentType?.message}
            placeholder={
              t("invoiceForm.placeholder.financialDocumentType") as string
            }
          >
            {Object.values(FinancialDocumentType).map((fdt) => (
              <option key={fdt} value={fdt}>
                {t(`financialDocumentTypes.${fdt}`)}
              </option>
            ))}
          </Select>
        </div>
        <div className="flex flex-col gap-1">
          <Label htmlFor="totalTaxAmount">
            {t("purchaseInvoiceSidebar.specifications.totalTaxAmount")}
          </Label>
          <TextInput
            id="totalTaxAmount"
            addon={CURRENCY_SIGNS[currency]}
            errorMessage={formState.errors.totalTaxAmount?.message}
            {...register("totalTaxAmount")}
          />
        </div>
        <div className="flex flex-col gap-1">
          <Label htmlFor="totalExclusiveVat">
            {t("purchaseInvoiceSidebar.specifications.totalExclusiveVat")}
          </Label>
          <TextInput
            id="totalExclusiveVat"
            addon={CURRENCY_SIGNS[currency]}
            errorMessage={formState.errors.totalExclusiveVat?.message}
            {...register("totalExclusiveVat")}
          />
        </div>
        <div className="flex flex-col gap-1">
          <Label htmlFor="totalInclusiveVat">
            {t("purchaseInvoiceSidebar.specifications.totalInclusiveVat")}
          </Label>
          <TextInput
            id="totalInclusiveVat"
            addon={CURRENCY_SIGNS[currency]}
            errorMessage={formState.errors.totalInclusiveVat?.message}
            {...register("totalInclusiveVat")}
          />
        </div>
      </div>
      <div className="border-1 border-t mt-5 pt-4 dark:border-gray-700 flex justify-end">
        <LoadingButton
          loading={isLoading}
          className="px-2 py-0.5"
          type="submit"
        >
          {t("purchaseInvoiceSidebar.specifications.save")}
        </LoadingButton>
      </div>
    </form>
  );
};
