import { useApiQuery } from "hooks/api/useApiQuery";
import { Terms } from "hooks/banks/models/bank.interface";

export interface GetTermsParams {
  language: string;
}

export type GetTermsResponse = Terms;

export const TERMS = "terms";

export function useGetTerms(input?: GetTermsParams) {
  return useApiQuery<GetTermsResponse>(
    [TERMS],
    `banks/tc?language=${input?.language}`,
    {}
  );
}
