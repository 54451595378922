import { Accordion, Label } from "flowbite-react";
import { FC, useCallback, useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  // AccentColor,
  Template,
  // TemplateLayout,
} from "hooks/templates/models/template.interface";

// import { AccentColorRadio } from "components/invoice/form/AccentColorRadio";
import {
  CreateQuoteInput,
  UpdateQuoteInput,
} from "models/paymentDocument/quote/quoteInput.interface";
import { QuoteStatus } from "models/paymentDocument/invoice/invoice.interface";
import { Customer } from "hooks/customers/models/customer.interface";
import { CustomerTypeAhead } from "components/invoice/form/CustomerTypeAhead";
// import { H3 } from "components/headers/H3";
import { TextInput } from "components/input/TextInput";
import { LoadingButton } from "components/LoadingButton";
// import { PersonalTemplateRadio } from "components/invoice/form/PersonalTemplateRadio";
import { ProductsFieldset } from "components/invoice/form/ProductsFieldset";
// import { TemplateRadio } from "components/invoice/form/TemplateRadio";
import { useInvoiceFormValues } from "components/invoice/hooks/useInvoiceFormValues";
import { Product } from "hooks/products/models/product.interface";
import { Quote } from "hooks/quotes/models/quote.interface";
import { useEvent } from "hooks/useEvent";
import { CustomerCreateEvent } from "hooks/customers/useCustomersUseCases";
import { InvoiceFormValues } from "../invoice/InvoiceForm";
import { InvoiceFormCreateModals } from "../invoice/InvoiceFormCreateModals";

export type QuoteFormValues = UpdateQuoteInput | CreateQuoteInput;

export interface QuoteFormProps {
  customers: Customer[];
  products: Product[];
  templates: Template[];
  isLoading: boolean;
  onSubmit: (input: QuoteFormValues) => void;
  onChange?: (quote: Partial<Omit<Quote, "id" | "update">>) => void;
}

export const QuoteForm: FC<QuoteFormProps> = function ({
  customers,
  products,
  // templates,
  isLoading,
  onSubmit,
}) {
  const { t } = useTranslation();
  const { setValue, formState, register, handleSubmit, watch } =
    useFormContext<QuoteFormValues>();

  const quoteStatus = watch("quoteStatus");

  const {
    // issueDate,
    // dueDate,
    // total,
    // vat,
    // selectedAddress,
    selectedCustomer,
    // selectedProducts,
    // selectedTemplate,
  } = useInvoiceFormValues({ customers, products });

  const productFieldArrayMethods = useFieldArray<InvoiceFormValues>({
    name: "documentLines",
  });

  // const onPersonalTemplatePicked = useCallback(
  //   (selectedId: string): void => {
  //     const template = templates.find(({ id }) => id === selectedId);

  //     if (template) {
  //       setValue("template.id", template.id);
  //       setValue("template.accentColor", template.accentColor);
  //       setValue("template.layout", template.layout);
  //     }
  //   },
  //   [setValue, templates]
  // );

  // const onDefaultTemplatePicked = useCallback((): void => {
  //   setValue("template.id", undefined);
  //   setValue("template.accentColor", AccentColor.Gray);
  //   setValue("template.layout", TemplateLayout.Template1);
  // }, [setValue]);

  // const onAccentColorPicked = useCallback((): void => {
  //   setValue("template.id", undefined);
  // }, [setValue]);

  // const preview = useMemo(() => {
  //   return {
  //     issueDate,
  //     dueDate,
  //     billingAddress: selectedAddress,
  //     products: selectedProducts,
  //     template: selectedTemplate,
  //     to: selectedCustomer,
  //     totalInclusiveVat: total,
  //     vatPrice: vat,
  //   };
  // }, [
  //   issueDate,
  //   dueDate,
  //   selectedAddress,
  //   selectedCustomer,
  //   selectedProducts,
  //   selectedTemplate,
  //   total,
  //   vat,
  // ]);

  const hasSpecificationsErrors = useMemo(
    () => formState.errors.customerId || formState.errors.documentLines,
    [formState]
  );

  const onCustomerCreated = useCallback(
    ({ customerId }: CustomerCreateEvent) => {
      setValue("customerId", customerId);
    },
    [setValue]
  );

  useEvent("customerCreatedFromInvoice", onCustomerCreated);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="pb-8">
        <Accordion flush alwaysOpen>
          <Accordion.Panel>
            <Accordion.Title
              theme={{
                heading: hasSpecificationsErrors
                  ? "text-red-600 text-2xl font-semibold"
                  : "text-2xl font-semibold dark:text-white",
              }}
            >
              {t("invoiceForm.title.specifications")}
            </Accordion.Title>
            <Accordion.Content>
              {/* Customer type ahead */}
              <div className="mb-4 flex flex-col flex-1 gap-2">
                <Label htmlFor="to">{t("invoiceForm.input.to")}</Label>
                <CustomerTypeAhead
                  customerKey="customerId"
                  id="to"
                  disabled={
                    quoteStatus === QuoteStatus.Sent ||
                    quoteStatus === QuoteStatus.ConvertedToInvoice
                  }
                  selectedCompany={selectedCustomer?.name}
                  placeholder={t("invoiceForm.placeholder.to") as string}
                />
              </div>
              <div className="flex gap-4">
                <div className="mb-4 flex flex-col flex-1 gap-2">
                  <Label htmlFor="quoteDate">
                    {t("invoiceForm.input.quoteDate.label")}
                  </Label>
                  <TextInput
                    {...register("issueDate")}
                    type="date"
                    placeholder={
                      t("invoiceForm.placeholder.quoteDate") as string
                    }
                    id="issueDate"
                    disabled={
                      quoteStatus === QuoteStatus.Sent ||
                      quoteStatus === QuoteStatus.ConvertedToInvoice
                    }
                    errorMessage={formState.errors.quoteDate?.message}
                  />
                </div>
                <div className="mb-4 flex flex-col flex-1 gap-2">
                  <Label htmlFor="dueDate">
                    {t("invoiceForm.input.dueDate.label")}
                  </Label>
                  <TextInput
                    {...register("dueDate")}
                    type="date"
                    placeholder={t("invoiceForm.placeholder.dueDate") as string}
                    id="dueDate"
                    disabled={
                      quoteStatus === QuoteStatus.Sent ||
                      quoteStatus === QuoteStatus.ConvertedToInvoice
                    }
                    errorMessage={formState.errors.dueDate?.message}
                  />
                </div>
              </div>
              {/* Products */}
              <ProductsFieldset
                disabled={
                  quoteStatus === QuoteStatus.Sent ||
                  quoteStatus === QuoteStatus.ConvertedToInvoice
                }
                {...productFieldArrayMethods}
              />
            </Accordion.Content>
          </Accordion.Panel>

          {/* <Accordion.Panel>
            <Accordion.Title
              theme={{
                heading: formState.errors.template
                  ? "text-red-600 text-2xl font-semibold"
                  : "text-2xl font-semibold dark:text-white",
              }}
            >
              {t("invoiceForm.title.layout")}
            </Accordion.Title>
            <Accordion.Content>
              <H3>{t("invoiceForm.input.color")}</H3>
              <div className="flex gap-4">
                {Object.values(AccentColor).map((color) => (
                  <AccentColorRadio
                    key={color}
                    color={color}
                    onChange={onAccentColorPicked}
                  />
                ))}
              </div>
              <H3 className="mt-8">{t("invoiceForm.input.template")}</H3>
              <div className="flex gap-4 flex-wrap">
                {templates.map((template) => (
                  <PersonalTemplateRadio
                    key={template.id}
                    preview={preview}
                    changePersonalTemplate={onPersonalTemplatePicked}
                    template={template}
                  />
                ))}
              </div>
              <H3 className="mt-8">{t("invoiceForm.input.layout")}</H3>
              <div className="flex gap-4 flex-wrap">
                {Object.values(TemplateLayout).map((layout) => (
                  <TemplateRadio
                    key={layout}
                    onChange={onDefaultTemplatePicked}
                    layout={layout}
                    preview={preview}
                  />
                ))}
              </div>
            </Accordion.Content>
          </Accordion.Panel> */}
        </Accordion>

        <div className="p-4 flex justify-end">
          <LoadingButton
            loading={isLoading}
            disabled={
              productFieldArrayMethods.fields?.length <= 0 ||
              quoteStatus === QuoteStatus.Sent ||
              quoteStatus === QuoteStatus.ConvertedToInvoice
            }
            type="submit"
          >
            {t("invoiceForm.button.save")}
          </LoadingButton>
        </div>
      </form>
      <InvoiceFormCreateModals {...productFieldArrayMethods} />
    </>
  );
};
