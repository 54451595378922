import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { event } from "event";

import { httpErrorCodeWithTranslations } from "helpers/api";
import { useApiMutation } from "hooks/api/useApiMutation";
import { ConvertTimesheetInput } from "../models/timesheets.interface";

export type GenerateInvoiceResponse = void;

export function useGenerateInvoice() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useApiMutation<GenerateInvoiceResponse, ConvertTimesheetInput>(
    "generateInvoice",
    () => `timesheets/convert`,
    (input) => ({
      method: "post",
      body: JSON.stringify({
        ...(input.timesheetIds ? { timesheetIds: input.timesheetIds } : {}),
        ...(input.journalId ? { journalId: input.journalId } : {}),
      }),
    }),
    {
      onSuccess: () => queryClient.invalidateQueries(["timesheets"]),
      onError: (error) => {
        if (error?.response?.payload?.detail) {
          event.emit(
            "mutationFailed",
            new Error(error?.response?.payload?.detail)
          );

          return;
        }
        if (httpErrorCodeWithTranslations.includes(error.response.status)) {
          event.emit(
            "mutationFailed",
            new Error(t(`error.${error.response.status}`) as string)
          );

          return;
        }
        event.emit("mutationFailed", new Error(error.message));
      },
    }
  );
}
