import { PaginatedResponse } from "models/schema.interface";
import { Quote } from "hooks/quotes/models/quote.interface";
import { useApiQuery } from "hooks/api/useApiQuery";
import { convertDateFormat } from "helpers/convert-date-format";

export interface GetQuotesParams {
  offset: number;
  limit: number;
  search: string;
  sortKey?: string;
  sortOrder?: string;
  journalId: string;
  refetchInterval?: number;
  from?: string;
  to?: string;
  quoteStatus?: string;
}

export type GetQuotesResponse = PaginatedResponse<Omit<Quote, "update">>;

export const QUOTES_QUERY = "quotes";

export function useGetQuotes(input: GetQuotesParams) {
  const size = Number(input.limit);
  const page = input.offset / size;

  return useApiQuery<GetQuotesResponse>(
    [
      QUOTES_QUERY,
      input.offset,
      input.limit,
      input.search,
      input.sortKey,
      input.sortOrder,
      input.journalId,
      input.from,
      input.to,
      input.quoteStatus,
    ],
    `quotes?${new URLSearchParams({
      page: String(page),
      size: String(size),
      search: input.search,
      quoteStatus: input?.quoteStatus ?? "",
      journal: input?.journalId ?? "",
      ...(input?.from
        ? { start: convertDateFormat(input.from, "dd/MM/yyyy") }
        : {}),
      ...(input?.to ? { end: convertDateFormat(input.to, "dd/MM/yyyy") } : {}),
      sort:
        input?.sortKey && input?.sortOrder
          ? `${input.sortKey},${input.sortOrder}`
          : "",
    })}`,
    {},
    {
      enabled: !!input.journalId,
      refetchInterval: input.refetchInterval,
    }
  );
}
