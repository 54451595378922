import { Journal } from "hooks/journals/models/journal.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export type GetJournalsResponse = Journal[];

export const JOURNALS_QUERY = "journals";

export function useGetJournals() {
  return useApiQuery<GetJournalsResponse>([JOURNALS_QUERY], `journals`, {});
}
