import { parse } from "date-fns";

export const hhmmssToSeconds = (timeString: string): number => {
  const parsedTime = parse(timeString, "HH:mm:ss", new Date(0));
  return (
    parsedTime.getHours() * 3600 +
    parsedTime.getMinutes() * 60 +
    parsedTime.getSeconds()
  );
};
