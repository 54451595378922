/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiCheck } from "react-icons/hi";
import {
  GetSubscriptionResponse,
  PlanDTO,
} from "hooks/subscription/models/subscription.interface";
import { Card } from "flowbite-react";
import { useAuth } from "hooks/useAuth";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useUpdateSubscription } from "pages/subscription/mutations/useUpdateSubscription";
import { LoadingButton } from "components/LoadingButton";

interface SubscriptionCardsProps {
  subscription?: GetSubscriptionResponse;
  plans: PlanDTO[];
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

export const SubscriptionCards: FC<SubscriptionCardsProps> = function ({
  subscription,
  plans,
}) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { mutate, isLoading } = useUpdateSubscription();
  const planId = localStorage.getItem("planId");

  const { token } = useAuth();

  const fetchClientSecret = useCallback(async () => {
    // Create a Checkout Session
    const response = await axios.get(
      `${process.env.REACT_APP_API}api/v1/subscriptions/activate`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  }, [token]);

  const handleOnComplete = useCallback(() => setIsOpen(false), [setIsOpen]);

  const options = { fetchClientSecret, onComplete: handleOnComplete };

  const handleChangePlan = useCallback(
    (planId: number) => mutate({ planId }),
    [mutate]
  );

  return (
    <>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
        {plans
          .filter((plan) => plan.name !== "Trial")
          .map((plan) => (
            <Card
              key={`plan_${plan.id}`}
              className="flex flex-col p-6 w-full text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
            >
              <div className="flex flex-col h-full">
                <h3 className="mb-2 text-2xl font-semibold">{plan.name}</h3>
                {/* <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
              Voor kleine ondernemingen
            </p> */}
                <div className="flex justify-center items-baseline my-8">
                  <span className="mr-2 text-5xl font-extrabold">
                    €{plan.pricePerMonth}
                  </span>
                  <span className="text-gray-500 dark:text-gray-400">
                    /maand
                  </span>
                </div>
                <ul className="mb-8 space-y-4 text-left ml-auto mr-auto">
                  {plan.features.map((f) => (
                    <li
                      key={`feature_${f}`}
                      className="flex items-center space-x-3"
                    >
                      <HiCheck size="24" color="green" />
                      <span>{f}</span>
                    </li>
                  ))}
                </ul>
              </div>
              {(subscription?.cancelled ||
                subscription?.plan?.id !== plan.id) && (
                <LoadingButton
                  loading={isLoading}
                  type="button"
                  onClick={() => {
                    if (
                      subscription?.cancelled ||
                      !subscription?.plan ||
                      subscription?.plan?.name === "Trial"
                    ) {
                      localStorage.setItem("planId", String(plan.id));
                      setIsOpen(true);
                    } else {
                      handleChangePlan(plan.id);
                    }
                  }}
                >
                  {t("subscriptionPage.button.choosePlan")}
                </LoadingButton>
              )}
            </Card>
          ))}
      </div>
      <div
        className={`fixed overflow-hidden z-50 top-0 left-0 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out ${
          isOpen
            ? " transition-opacity opacity-100 duration-500 translate-x-0  "
            : " transition-all delay-500 opacity-0 translate-x-full  "
        }`}
      >
        <div
          className={`w-screen max-w-lg right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  ${
            isOpen ? " translate-x-0 " : " translate-x-full "
          }`}
        >
          <div className="relative w-[514px] max-w-lg pb-10 pt-10 flex flex-col space-y-6 overflow-y-scroll h-full">
            {planId && (
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            )}
          </div>
        </div>
        <div
          className="w-screen h-full cursor-pointer"
          onClick={() => setIsOpen(false)}
        />
      </div>
    </>
  );
};
