import { FC } from "react";
// import { useTranslation } from "react-i18next";
import { GetSubscriptionResponse } from "hooks/subscription/models/subscription.interface";
import { Card } from "flowbite-react";
import { useTranslation } from "react-i18next";

interface SubscriptionStatsProps {
  subscription?: GetSubscriptionResponse;
}

export const SubscriptionStats: FC<SubscriptionStatsProps> = function ({
  subscription,
}) {
  const { t } = useTranslation();
  return (
    <div className="grid xl:grid-cols-2 xs:grid-cols-1 md:grid-cols-2 gap-6">
      <Card className="grow">
        <p className="text-s font-medium text-gray-500 dark:text-gray-400">
          {t("subscriptionPage.stats.bankAccounts")}
        </p>
        <div className="flex justify-between dark:text-white items-center">
          <p className="text-3xl font-bold">
            {subscription?.usedBankAccounts || 0}
          </p>
        </div>
        {/* <Progress progress={80} />
        <p className="text-xs font-medium text-gray-500 dark:text-gray-400">
          {t("subscriptionPage.stats.usedBankAccounts", {
            amount: subscription?.usedBankAccounts || 0,
            max: 5,
          })}
        </p> */}
      </Card>
      <Card className="grow">
        <p className="text-s font-medium text-gray-500 dark:text-gray-400">
          {t("subscriptionPage.stats.financialDocuments")}
        </p>
        <div className="flex justify-between dark:text-white items-center">
          <p className="text-3xl font-bold">
            {subscription?.usedFinancialDocuments || 0}
          </p>
        </div>
        {/* <Progress progress={80} />
        <p className="text-xs font-medium text-gray-500 dark:text-gray-400">
          {t("subscriptionPage.stats.usedFinancialDocuments", {
            amount: subscription?.usedFinancialDocuments || 0,
            max: 100,
          })}
        </p> */}
      </Card>
    </div>
  );
};
