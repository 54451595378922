import {
  FC,
  forwardRef,
  ReactNode,
  useCallback,
  useState,
  FocusEvent,
  useEffect,
  ChangeEvent,
} from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { TextInput, TextInputProps } from "../TextInput";
import { TypeAheadCreateOption } from "./TypeAheadCreateOption";

interface TypeAheadProps extends TextInputProps {
  refetch: (search: string) => void;
  selectedValue?: string;
  placeholder?: string;
  showDropdown?: boolean;
  showEmptyOption?: boolean;
  emptyOption?: string;
  createOptionLabel?: string;
  onCreateOption?: (initialValue: string) => void;
  children: ReactNode;
}

export const TypeAhead: FC<TypeAheadProps> = forwardRef<
  HTMLInputElement,
  TypeAheadProps
>(function (
  {
    refetch,
    selectedValue,
    placeholder,
    onChange,
    showDropdown,
    showEmptyOption,
    emptyOption,
    createOptionLabel,
    onCreateOption,
    children,
    onBlur,
    ...rest
  },
  ref
) {
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(selectedValue || "");
  const onFocus = useCallback(() => setFocused(true), [setFocused]);
  const onCustomBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      onBlur?.(event);
      if (selectedValue) {
        setValue(selectedValue);
      }
      setFocused(false);
    },
    [setFocused, onBlur, selectedValue]
  );

  const debounced = useDebouncedCallback((value) => {
    refetch(value);
  }, 500);

  const onCustomChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
      setValue(event.target.value);
      debounced(event.target.value);
    },
    [onChange, debounced]
  );

  useEffect(() => {
    debounced(selectedValue);
    setValue(selectedValue || "");
  }, [selectedValue, debounced]);

  const createOption = useCallback(
    () => onCreateOption?.(value),
    [value, onCreateOption]
  );

  return (
    <div className="relative">
      <TextInput
        ref={ref}
        {...rest}
        autoComplete="off"
        value={value}
        onFocus={onFocus}
        onBlur={onCustomBlur}
        onChange={onCustomChange}
        placeholder={placeholder}
      />
      {focused && showDropdown && (
        <div className="absolute w-full z-10 rounded-md border border-gray-200 dark:border-gray-700 mt-2 bg-white dark:bg-gray-800 dark:text-white max-h-64 overflow-y-auto shadow-md">
          {createOptionLabel && onCreateOption && (
            <TypeAheadCreateOption
              label={createOptionLabel}
              onCreateOption={createOption}
            />
          )}
          {children}
          {showEmptyOption && (
            <div className="py-2 px-4 text-gray-500 dark:text-gray-400 text-sm">
              {t(`${emptyOption}`)}
            </div>
          )}
        </div>
      )}
    </div>
  );
});
