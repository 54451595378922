import { Card } from "flowbite-react";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useDashboard } from "domains";
import { BarChart } from "components/charts/barChart/BarChart";
import { getPeriodFilter } from "helpers/get-period-filters";
import { useGetFinancialEvolution } from "pages/dashboard/queries/useGetFinancialEvolution";

import { FinancialEvolutionTooltip } from "./FinancialEvolutionTooltip";
import { Delta } from "../Delta";
import { PeriodFilter, PeriodOption } from "../PeriodFilter";

export const FINANCIAL_EVOLUTION_PERIOD_FILTER = "period_financial_evolution";

export const FinancialEvolutionOverview: FC = function () {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const selectedPeriodFilter = useMemo(
    () =>
      (searchParams.get(FINANCIAL_EVOLUTION_PERIOD_FILTER) as PeriodOption) ??
      PeriodOption.YEARLY,
    [searchParams]
  );

  const { financialEvolution, financialEvolutionLoading } = useDashboard({
    financialEvolution: useGetFinancialEvolution(
      getPeriodFilter(selectedPeriodFilter)
    ),
  });

  return (
    <Card className="grow">
      <div className="flex justify-between items-center mb-4">
        <p className="text-2xl font-bold dark:text-white">
          {t("dashboardPage.financialEvolution.title")}
        </p>
        <div className="flex gap-4">
          {!financialEvolutionLoading &&
            typeof financialEvolution?.delta === "number" && (
              <Delta delta={financialEvolution.delta ?? 0} bold />
            )}
          <PeriodFilter name={FINANCIAL_EVOLUTION_PERIOD_FILTER} />
        </div>
      </div>

      <BarChart
        labelDataKey="name"
        slicedLabel={false}
        bars={[
          {
            dataKey: "turnover",
            color: "#1C64F2",
          },
          {
            dataKey: "cost",
            color: "#F3BD93",
          },
        ]}
        height={300}
        data={financialEvolution.data || []}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Tooltip={FinancialEvolutionTooltip as any}
        loading={financialEvolutionLoading}
      />
    </Card>
  );
};
