import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";

export const CompanyErrorPage: FC = function () {
  const { t } = useTranslation();

  const handleClear = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center py-16">
      <img alt="" src="/images/illustrations/404.svg" className="lg:max-w-md" />

      <h1 className="mb-6 text-2xl font-bold dark:text-white md:text-5xl">
        {t("companyErrorPage.title")}
      </h1>
      <div className="mb-6 w-2/5 text-center text-lg text-gray-500 dark:text-gray-300">
        <p>{t("companyErrorPage.body")}</p>
        <span className="flex text-center justify-center">
          <p>{t("companyErrorPage.errorMessage")}</p>&nbsp;
        </span>
      </div>
      <Button onClick={handleClear}>{t("companyErrorPage.cta")}</Button>
    </div>
  );
};
