import { Table } from "flowbite-react";
import { FC, ReactNode } from "react";
import { Skeleton } from "./Skeleton";

interface WithoutChildren {
  cells: number;
  children?: never;
}

interface WithChildren {
  cells?: never;
  children: ReactNode;
}

type Props = WithoutChildren | WithChildren;

export const SkeletonRow: FC<Props> = function ({ cells, children }) {
  return (
    <Table.Row>
      {children ||
        new Array(cells).fill(null).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Table.Cell key={index}>
            <Skeleton />
          </Table.Cell>
        ))}
    </Table.Row>
  );
};
