import { parse, format } from "date-fns";

export const convertDateFormat = (
  inputDate?: string,
  exportFormat?: string
): string => {
  if (!inputDate) return "";
  // Parse the input date string
  const parsedDate = parse(inputDate, "yyyy-MM-dd", new Date());

  // Format the parsed date as "dd-MM-yyyy"
  const formattedDate = format(parsedDate, exportFormat || "dd-MM-yyyy");
  return formattedDate;
};
