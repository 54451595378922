import { FC, useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useCustomers, useRoutes } from "domains";
import { Customer } from "hooks/customers/models/customer.interface";
import { useGetCustomerOptions } from "pages/customers/queries/useGetCustomerOptions";
import { CREATE_CUSTOMER_PARAM } from "../InvoiceFormCreateModals";
import { TextInputProps } from "../../input/TextInput";
import { TypeAhead } from "../../input/typeAhead/TypeAhead";
import { TypeAheadOption } from "../../input/typeAhead/TypeAheadOption";

interface CustomerTypeAheadProps extends TextInputProps {
  selectedCompany?: string;
  placeholder?: string;
  customerKey: string;
  updateBillingAddress?: boolean;
}

export const CustomerTypeAhead: FC<CustomerTypeAheadProps> = function ({
  placeholder,
  selectedCompany,
  customerKey,
  ...rest
}) {
  const { setValue, formState, clearErrors } = useFormContext();
  const [search, setSearch] = useState("");
  const getCustomers = useGetCustomerOptions(search);
  const { refetch } = getCustomers;
  const { customers } = useCustomers(getCustomers);
  const { openDifferentEntityModal } = useRoutes();

  const onSelectOption = useCallback(
    (customer: Customer) => {
      setValue(customerKey, customer.id);
      clearErrors(customerKey);

      return () => null;
    },
    [setValue, clearErrors, customerKey]
  );

  const createOption = useCallback(
    (initialValue: string) =>
      openDifferentEntityModal(CREATE_CUSTOMER_PARAM, initialValue),
    [openDifferentEntityModal]
  );

  useEffect(() => {
    refetch();
  }, [refetch, search]);

  return (
    <TypeAhead
      showDropdown
      showEmptyOption={customers?.length === 0}
      emptyOption="customerTypeAhead.noCustomers"
      createOptionLabel="customerTypeAhead.create"
      onCreateOption={createOption}
      placeholder={placeholder}
      selectedValue={selectedCompany ?? ""}
      errorMessage={formState.errors.customerId?.message?.toString() ?? ""}
      refetch={setSearch}
      {...rest}
    >
      {customers.map((customer) => (
        <TypeAheadOption
          key={customer.id}
          option={customer}
          name={customer.name}
          selectOption={onSelectOption}
        />
      ))}
    </TypeAhead>
  );
};
