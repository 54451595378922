import { DomainMutation } from "hooks/useDomainMutation";
import { WithId } from "models/core/utilities.interface";

export interface BankAccountDTO {
  description: string;
  iban: string;
  bic: string;
}

export interface CreateBankAccountInput extends BankAccountDTO {
  id?: never;
}

export interface UpdateBankAccountInput extends Partial<BankAccountDTO> {
  id: string;
}
export interface DeleteBankAccountInput {
  id: string;
}

export type BankAccount = WithId<BankAccountDTO> & {
  remove: DomainMutation<DeleteBankAccountInput>;
  update: DomainMutation<UpdateBankAccountInput>;
};

export const createBankAccountSchema = {
  bic: { required: true },
  description: { required: true },
  iban: { required: true },
};

export interface CreateBankUserInput {
  tc: number;
}
