import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HiDocumentText } from "react-icons/hi";
import { useParams } from "react-router";

import { ActionPath } from "hooks/routes/useRoutesUseCases";
import { Breadcrumb, PageHeader } from "components/PageHeader";
import { DocumentPreview } from "components/invoice/DocumentPreview";
import { invoiceBadgeMap } from "helpers/invoice-badge-map";
import {
  InvoiceForm,
  InvoiceFormProps,
  InvoiceFormValues,
} from "components/invoice/InvoiceForm";
import { InvoiceFormProvider } from "components/invoice/InvoiceFormProvider";
import { InvoiceSidebar } from "components/invoice/InvoiceSidebar";
import { NotFoundBlock } from "components/errors/404/NotFoundBlock";
import {
  useCustomers,
  useProducts,
  useRoutes,
  useSalesInvoices,
  useTemplates,
} from "domains";
import { useGetCustomers } from "pages/customers/queries/useGetCustomers";
import { useGetProducts } from "pages/products/queries/useGetProducts";

import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "helpers/html-validation-schema";
import { useGetSalesInvoice } from "./queries/useGetSalesInvoice";
import { useGetTemplates } from "./queries/useGetTemplates";

type Params = "id";

function SalesInvoicePage() {
  const { t } = useTranslation();
  const { id } = useParams<Params>();
  const { routes, action } = useRoutes();
  const { salesInvoice, create, isLoading } = useSalesInvoices(
    useGetSalesInvoice({ id })
  );
  const isDetail = useMemo(() => action === ActionPath.Detail, [action]);
  const { customers } = useCustomers(useGetCustomers());
  const { products } = useProducts(useGetProducts());
  const { templates } = useTemplates(useGetTemplates());

  const title = useMemo(() => {
    if (isLoading) return t("salesInvoicePage.title.loading");
    if (!isLoading && salesInvoice?.id)
      return t("salesInvoicePage.title.invoice", {
        invoiceNumber: salesInvoice?.invoiceNumber,
      });
    return t("salesInvoicePage.title.createInvoice");
  }, [isLoading, salesInvoice?.id, salesInvoice?.invoiceNumber, t]);

  const breadcrumbs = useMemo(() => {
    const crumbs: Breadcrumb[] = [
      {
        href: routes.invoices,
        label: t("sidebar.title.invoices"),
        icon: HiDocumentText,
      },
      {
        href: routes.salesInvoices,
        label: t("sidebar.link.salesInvoices"),
      },
    ];

    if (salesInvoice?.invoiceNumber) {
      crumbs.push({
        label: `#${salesInvoice.invoiceNumber?.toString()}`,
      });
    }

    return crumbs;
  }, [salesInvoice, t, routes]);

  if (isDetail && !isLoading && !salesInvoice) {
    return (
      <NotFoundBlock
        href={routes.salesInvoices}
        entity={t("entity.salesInvoice") as string}
      />
    );
  }

  return (
    <>
      <PageHeader
        className="bg-white dark:bg-gray-800"
        title={title}
        badge={salesInvoice?.invoicePayStatus}
        badgeMap={invoiceBadgeMap}
        breadcrumbs={breadcrumbs}
      />
      <InvoiceFormProvider
        defaultValues={salesInvoice}
        schema={
          (salesInvoice?.update.schema ?? create.schema) as
            | HTMLValidationSchema<InvoiceFormValues>
            | HTMLValidationSchemaGenerator<InvoiceFormValues>
        }
      >
        <div className="flex flex-col xl:flex-row xl:m-8 gap-8 grow">
          {!isLoading && (
            <InvoiceSidebar>
              <InvoiceForm
                customers={customers}
                products={products}
                templates={templates}
                isLoading={salesInvoice?.update.isLoading ?? create.isLoading}
                onSubmit={
                  (salesInvoice?.update.execute ??
                    create.execute) as InvoiceFormProps["onSubmit"]
                }
                invoice={salesInvoice}
              />
            </InvoiceSidebar>
          )}
          <div className="w-full flex items-start justify-center overflow-auto mx-auto max-w-screen-lg">
            <DocumentPreview
              isLoading={isLoading}
              customers={customers}
              products={products}
              data={salesInvoice}
              context="invoice"
            />
          </div>
        </div>
      </InvoiceFormProvider>
    </>
  );
}

export default SalesInvoicePage;
