import { FC, PropsWithChildren } from "react";

interface Props {
  rows: number;
}

export const SkeletonRows: FC<PropsWithChildren<Props>> = function ({
  rows,
  children,
}) {
  return <>{new Array(rows).fill(null).map(() => children)}</>;
};
