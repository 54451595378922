import { Spinner } from "flowbite-react";
import { FC, useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { event } from "event";
import { useRoutes } from "domains";
import isAfter from "date-fns/isAfter";
import { useFinalizeConnection } from "../mutations/useFinalizeConnection";

interface Props {
  show?: boolean;
}

export const FinalizeConnection: FC<Props> = function ({ show }) {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const { goToBankAccounts } = useRoutes();
  const stringifiedParams = params?.toString();
  const { mutate, isLoading, error } = useFinalizeConnection();
  const storageDataRef = useRef({});

  const storageOngoingBankConnection = localStorage.getItem(
    "ongoingBankConnection"
  );

  useEffect(() => {
    try {
      if (typeof storageOngoingBankConnection === "string") {
        storageDataRef.current = JSON.parse(storageOngoingBankConnection);
      } else {
        storageDataRef.current = {};
      }
    } catch (err) {
      storageDataRef.current = {};
    }
  }, [storageOngoingBankConnection]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { connectorId, flowContext, expire } = storageDataRef.current as any;
    const expired = !isAfter(new Date(expire), new Date());
    if (
      show &&
      connectorId &&
      flowContext &&
      !expired &&
      stringifiedParams &&
      !isLoading
    ) {
      mutate({ flowContext, connectorId, params: stringifiedParams });
    } else if (show && error) {
      localStorage.removeItem("ongoingBankConnection");
      event.emit(
        "mutationFailed",
        new Error(t("connections.connectingFailed") as string)
      );
      goToBankAccounts();
    }
  }, [error, isLoading, show, stringifiedParams, t, mutate, goToBankAccounts]);

  return (
    <div className="flex flex-col justify-center items-center">
      <Spinner className="mr-4" size="lg" />
      <p className="text-gray-900 dark:text-white mt-3.5">
        {" "}
        {t("connections.finalizing")}...
      </p>
    </div>
  );
};
