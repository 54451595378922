import { RedirectRequest } from "@azure/msal-browser";

export const REFRESH_INTERVAL_IN_MS = 60000;

export const scopes = ["sumrise_internal:access", "email", "tenants"];
export const authority: string = process.env.REACT_APP_AUTHORITY || "";

export const clientId: string = process.env.REACT_APP_CLIENT_ID || "";
export const redirectUri = `${window.location.protocol}//${
  window.location.hostname
}${window.location.port ? `:${window.location.port}` : ""}`;

export const request: RedirectRequest = { scopes, authority };
export const loginRequest: RedirectRequest = { scopes };

export const authErrorCount = `${clientId}AuthErrorCount`;
