import { FC, useCallback, useState } from "react";
import { useRoutes } from "domains";
import { DomainMutation } from "hooks/useDomainMutation";
import { UploadCSVInput } from "models/csv/csv.interface";
import { EditModal } from "./modals/EditModal";
import { UploadFile } from "./uploadFile";
import { NoHeaderModal } from "./modals/NoHeaderModal";
import { UploadSuccess } from "./UploadSuccess";
import { useEvent } from "../hooks/useEvent";

const maxCSVFileSize = 10 * 1024 * 1024; // 30MB
const maxCSVFileCount = 1;

interface UploadCSVProps {
  action?: string;
  uploadCSV: DomainMutation<UploadCSVInput>;
  onClose: () => void;
  page: string;
}

export const UploadCSV: FC<UploadCSVProps> = function ({
  action,
  uploadCSV,
  onClose,
  page,
}) {
  const { openUploadModal } = useRoutes();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const uploadNew = useCallback(() => {
    setShowSuccessModal(false);
    openUploadModal();
  }, [setShowSuccessModal, openUploadModal]);

  const hideSuccessModal = useCallback(
    () => setShowSuccessModal(false),
    [setShowSuccessModal]
  );

  const onUploadCSV = useCallback(
    (files: File[]) => {
      files.forEach((file) => {
        const formData = new FormData();
        formData.append("file", file);
        uploadCSV.execute({ formData });
      });
    },
    [uploadCSV]
  );

  useEvent("CSVUploaded", () => setShowSuccessModal(true));

  return (
    <>
      {action === "upload-csv" && (
        <EditModal
          action="upload-csv"
          isError={!!uploadCSV.error}
          page={page}
          onClose={onClose}
          show
        >
          <UploadFile
            onUpload={onUploadCSV}
            isLoading={uploadCSV.isLoading}
            maxFileSize={maxCSVFileSize}
            maxFileCount={maxCSVFileCount}
            accept={[".csv"]}
          />
        </EditModal>
      )}
      <NoHeaderModal
        show={showSuccessModal}
        onClose={hideSuccessModal}
        size="md"
      >
        <UploadSuccess
          onClose={hideSuccessModal}
          onUploadNew={uploadNew}
          message="csv"
        />
      </NoHeaderModal>
    </>
  );
};
