import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { LoadingButton } from "components/LoadingButton";
import { useRemindSalesInvoices } from "hooks/salesInvoices/mutations/useRemindSalesInvoices";

interface RemindModalProps {
  ids?: string[];
  onClose: () => void;
}

export const RemindModal: FC<RemindModalProps> = function ({
  onClose,
  ids,
}: RemindModalProps) {
  const { t } = useTranslation();

  const { mutate, isLoading } = useRemindSalesInvoices();

  const handleRemind = useCallback(() => {
    if (ids && ids.length) {
      mutate({ id: ids[0] });
    }
  }, [mutate, ids]);

  return (
    <>
      <p className="text-base text-center leading-relaxed text-gray-400 dark:text-gray-400">
        {t("remindModal.body.info")}
      </p>
      <div className="flex gap-4 justify-center">
        <LoadingButton loading={isLoading} onClick={handleRemind}>
          {t("remindModal.footer.send")}
        </LoadingButton>
        <Button color="gray" onClick={onClose}>
          {t("remindModal.footer.back")}
        </Button>
      </div>
    </>
  );
};
