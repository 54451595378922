import { initReactI18next } from "react-i18next";
import i18n from "i18next";

import en from "./translations/en.json";
import fr from "./translations/fr.json";
import nl from "./translations/nl.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: localStorage.getItem("language") || "en",
    nonExplicitSupportedLngs: true,
    supportedLngs: ["en", "fr", "nl"],
    compatibilityJSON: "v3",
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
      nl: {
        translation: nl,
      },
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
