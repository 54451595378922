import { useQueryClient } from "react-query";
import { httpErrorCodeWithTranslations } from "helpers/api";
import { useTranslation } from "react-i18next";
import { event } from "event";
import { useApiMutation } from "hooks/api/useApiMutation";
import { SupplierDTO } from "../models/supplier.interface";

export interface UpdateSupplierParams {
  id: string;
}

export type UpdateSupplierBody = SupplierDTO;

export type UpdateSupplierApiInput = UpdateSupplierParams &
  Partial<UpdateSupplierBody>;

export type UpdateSupplierResponse = void;

export function useUpdateSupplier() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useApiMutation<UpdateSupplierResponse, UpdateSupplierApiInput>(
    "updateSupplier",
    (input) => `suppliers/${input.id}`,
    (input) => ({
      method: "put",
      body: JSON.stringify(input),
    }),
    {
      onSuccess: (_, input) => {
        return Promise.all([
          queryClient.invalidateQueries(["suppliers"]),
          queryClient.invalidateQueries(["supplier", input.id]),
        ]);
      },
      onError: (error) => {
        // For the time being: error messages are shown from the back-end (still in english).
        // They should be able to provide translated error messages since we
        // send the current language over the "Accept-Language" header
        // See: src/helpers/api.ts

        // They'll need to provide they want us
        // to translate messages on the front-end

        if (error?.response?.payload?.detail) {
          event.emit(
            "mutationFailed",
            new Error(error?.response?.payload?.detail)
          );

          return;
        }
        if (httpErrorCodeWithTranslations.includes(error.response.status)) {
          event.emit(
            "mutationFailed",
            new Error(t(`error.${error.response.status}`) as string)
          );

          return;
        }

        event.emit("mutationFailed", new Error(error.message));
      },
    }
  );
}
