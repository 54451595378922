import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRoutes } from "domains";

import { FieldArrayWithId, useFormContext } from "react-hook-form";
import { HiPencilAlt, HiTrash } from "react-icons/hi";

import { Button } from "components/Button";
import { TextInput } from "components/input/TextInput";

import { InvoiceFormValues } from "../InvoiceForm";
import { ProductTypeAhead } from "./ProductTypeAhead";

interface Props {
  disabled?: boolean;
  field: FieldArrayWithId<InvoiceFormValues>;
  index: number;
  onRemove: (index: number) => void;
}

export const ProductFieldset: FC<Props> = function ({
  field,
  index,
  onRemove,
  disabled,
}) {
  const { register, watch, formState } = useFormContext<InvoiceFormValues>();
  const { openNoteModal } = useRoutes();
  const { t } = useTranslation();

  const onRemoveClick = useCallback(() => onRemove(index), [onRemove, index]);
  const onNoteClick = useCallback(
    () => openNoteModal(index),
    [openNoteModal, index]
  );

  const quantity = watch(`documentLines.${index}.quantity`);
  const amountExclusiveVat = watch(`documentLines.${index}.amountExclusiveVat`);
  const vatPercentage = watch(`documentLines.${index}.vatPercentage`);

  const errorMessage = useMemo(() => {
    return Object.keys(formState.errors.documentLines?.[index] ?? {}).length
      ? t(`productTypeAhead.error`)
      : "";
  }, [formState, t, index]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-4" key={field.id}>
        <div className="flex flex-col gap-2 grow">
          <ProductTypeAhead
            disabled={disabled}
            index={index}
            errorColor={!!errorMessage}
          />
        </div>
        <div className="flex flex-col gap-2 grow">
          <TextInput
            {...register(`documentLines.${index}.amountExclusiveVat`)}
            type="number"
            min={0}
            step="any"
            value={amountExclusiveVat}
            disabled={disabled}
            id={`products.${index}.amountExclusiveVat`}
            errorColor={!!errorMessage}
          />
        </div>
        <div className="w-16 flex flex-col gap-2 shrink-0">
          <TextInput
            {...register(`documentLines.${index}.vatPercentage`, {
              valueAsNumber: true,
            })}
            type="number"
            min={0}
            value={vatPercentage}
            disabled={disabled}
            id={`products.${index}.vatPercentage`}
            errorColor={!!errorMessage}
          />
        </div>
        <div className="w-16 flex flex-col gap-2 shrink-0">
          <TextInput
            {...register(`documentLines.${index}.quantity`, {
              valueAsNumber: true,
            })}
            type="number"
            value={quantity}
            disabled={disabled}
            id={`products.${index}.quantity`}
            errorColor={!!errorMessage}
          />
        </div>
        <Button
          className="w-12 shrink-0"
          color="light"
          disabled={disabled}
          onClick={onNoteClick}
          title="Edit note"
          aria-label={`products.${index}.editNote`}
        >
          <HiPencilAlt className="text-xl" />
        </Button>
        <Button
          className="w-12 shrink-0"
          color="failure"
          disabled={disabled}
          onClick={onRemoveClick}
          title="Remove product/service"
          aria-label={`products.${index}.removeProduct`}
        >
          <HiTrash className="text-xl" />
        </Button>
      </div>
      {errorMessage && (
        <p className="text-sm text-red-600 dark:text-red-500">{errorMessage}</p>
      )}
    </div>
  );
};
