import { FC } from "react";

const AppLoader: FC = function () {
  return (
    <div className="w-screen h-screen">
      <div className="flex flex-col justify-center items-center h-screen">
        <div className="relative inline-flex">
          <div className="w-8 h-8 bg-primary rounded-full" />
          <div className="w-8 h-8 bg-primary rounded-full absolute top-0 left-0 animate-ping" />
          <div className="w-8 h-8 bg-primary rounded-full absolute top-0 left-0 animate-pulse" />
        </div>
        <p className="mt-6">Loading application...</p>
      </div>
    </div>
  );
};

export { AppLoader };
