import { FC } from "react";
import { LinkProps, Link as RRLink } from "react-router-dom";

const Link: FC<LinkProps> = function ({ type, className, children, ...rest }) {
  return (
    <RRLink
      {...rest}
      type={type}
      className={
        type === "button"
          ? `flex items-center text-white bg-primary hover:bg-primary-highlight focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none dark:focus:ring-blue-800 ${className}`
          : className
      }
    >
      {children}
    </RRLink>
  );
};

export default Link;
