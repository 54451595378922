import { ComponentProps, FC, PropsWithChildren } from "react";
import classnames from "classnames";

interface Props extends ComponentProps<"div"> {
  isText?: boolean;
  isAnimated?: boolean;
}

export const Skeleton: FC<PropsWithChildren<Props>> = function ({
  isText,
  isAnimated = true,
  className,
  children,
  ...rest
}) {
  return (
    <div
      role="status"
      className={classnames({ "animate-pulse": isAnimated })}
      {...rest}
    >
      <div
        className={classnames(
          "rounded-full bg-gray-200 dark:bg-gray-700",
          { "h-3 w-10/12": !children },
          { "w-fit": children },
          { "h-3": isText },
          className
        )}
      >
        <div className="opacity-0">{children}</div>
      </div>
    </div>
  );
};
