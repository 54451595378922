import { PaginatedResponse } from "models/schema.interface";
import { SupplierDTO } from "hooks/suppliers/models/supplier.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export type GetSuppliersResponse = PaginatedResponse<SupplierDTO>;

export function useGetSupplierOptions(search?: string) {
  return useApiQuery<GetSuppliersResponse>(
    ["supplierOptions"],
    `suppliers?${new URLSearchParams({
      search: search ?? "",
    })}`
  );
}
