import { FC, useCallback } from "react";
import { HiPlus } from "react-icons/hi";
import { FieldArrayWithId, UseFieldArrayAppend } from "react-hook-form";
import { Label } from "flowbite-react";
import { t } from "i18next";

import { Button } from "components/Button";
import { H3 } from "components/headers/H3";

import { ProductFieldset } from "./ProductFieldset";
import { InvoiceFormValues } from "../InvoiceForm";

interface ProductsFieldsetProps {
  fields: FieldArrayWithId<InvoiceFormValues, "documentLines">[];
  append: UseFieldArrayAppend<InvoiceFormValues, "documentLines">;
  remove: (index: number) => void;
  initialVatPercentage?: number;
  disabled?: boolean;
}

export const ProductsFieldset: FC<ProductsFieldsetProps> = function ({
  fields,
  append,
  remove,
  initialVatPercentage,
  disabled,
}) {
  const onAppend = useCallback(() => {
    append({
      quantity: 1,
      description: "",
      articleNumber: "",
      note: "",
      vatPercentage: initialVatPercentage || 21,
    });
  }, [append, initialVatPercentage]);

  const onRemove = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  return (
    <>
      <H3 className="mt-4 mb-2">{t("invoiceForm.title.products")}</H3>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex gap-4">
            <Label className="flex grow">
              {t("invoiceForm.input.product")}
            </Label>
            <Label className="flex grow">
              {t("invoiceForm.input.amountExclusiveVat")}
            </Label>
            <Label className="w-16">
              {t("invoiceForm.input.vatPercentage")}
            </Label>
            <Label className="w-16">{t("invoiceForm.input.quantity")}</Label>
            <div className="w-12" />
            <div className="w-12" />
          </div>
          {fields.map((field, index) => (
            <ProductFieldset
              disabled={disabled}
              field={field}
              index={index}
              onRemove={onRemove}
              key={field.id}
            />
          ))}
        </div>
        <Button
          onClick={onAppend}
          disabled={disabled}
          className="w-full"
          color="light"
        >
          <HiPlus className="mr-4" />
          {t("invoiceForm.button.addProduct")}
        </Button>
      </div>
    </>
  );
};
