import { UseCases } from "models/core";
import { API } from "models/core/API.interface";
import {
  EbitdaEvolution,
  FinancialEvolution,
  Summary,
  UnpaidInvoices,
} from "./models/dashboard.interface";

export type SummaryAPI = API<{
  summary: Summary;
}>;

export type UnpaidInvoiceAPI = API<{
  unpaidInvoices: UnpaidInvoices;
}>;

export type EbitdaEvolutionAPI = API<{
  ebitdaEvolution: EbitdaEvolution;
}>;

export type FinancialEvolutionAPI = API<{
  financialEvolution: FinancialEvolution;
}>;

interface Result {
  summary: Summary;
  summaryLoading: boolean;
  unpaidInvoices: UnpaidInvoices;
  unpaidInvoicesLoading: boolean;
  ebitdaEvolution: EbitdaEvolution;
  ebitdaEvolutionLoading: boolean;
  financialEvolution: FinancialEvolution;
  financialEvolutionLoading: boolean;
}

interface Props {
  summaryAPI: SummaryAPI;
  unpaidInvoicesAPI: UnpaidInvoiceAPI;
  ebitdaEvolutionAPI: EbitdaEvolutionAPI;
  financialEvolutionAPI: FinancialEvolutionAPI;
}

export const useDashboardUseCases: UseCases<Props, Result> = ({
  summaryAPI,
  unpaidInvoicesAPI,
  ebitdaEvolutionAPI,
  financialEvolutionAPI,
}) => {
  const { data: summaryData, isLoading: summaryLoading } = summaryAPI;
  const { data: unpaidInvoicesData, isLoading: unpaidInvoicesLoading } =
    unpaidInvoicesAPI;
  const { data: ebitdaEvolutionData, isLoading: ebitdaEvolutionLoading } =
    ebitdaEvolutionAPI;
  const { data: financialEvolutionData, isLoading: financialEvolutionLoading } =
    financialEvolutionAPI;

  return {
    summary: summaryData.summary,
    summaryLoading,
    unpaidInvoices: unpaidInvoicesData.unpaidInvoices,
    unpaidInvoicesLoading,
    ebitdaEvolution: ebitdaEvolutionData.ebitdaEvolution,
    ebitdaEvolutionLoading,
    financialEvolution: financialEvolutionData.financialEvolution,
    financialEvolutionLoading,
  };
};
