import { InvoicePayStatus } from "models/paymentDocument/invoice/invoice.interface";
import { PaginatedResponse } from "models/schema.interface";
import { SalesInvoice } from "hooks/salesInvoices/models/salesInvoice.interface";
import { useApiQuery } from "hooks/api/useApiQuery";
import { convertDateFormat } from "helpers/convert-date-format";

export interface GetSalesInvoicesParams {
  offset: number;
  limit: number;
  search: string;
  invoicePayStatus: InvoicePayStatus;
  sortKey?: string;
  sortOrder?: string;
  refetchInterval?: number;
  journalId: string;
  from?: string;
  to?: string;
}

export type GetSalesInvoicesResponse = PaginatedResponse<
  Omit<SalesInvoice, "update">
>;

export const SALES_INVOICES_QUERY = "salesInvoices";

export function useGetSalesInvoices(input: GetSalesInvoicesParams) {
  const size = Number(input.limit);
  const page = input.offset / size;

  return useApiQuery<GetSalesInvoicesResponse>(
    [
      SALES_INVOICES_QUERY,
      input.offset,
      input.limit,
      input.search,
      input.invoicePayStatus,
      input.sortKey,
      input.sortOrder,
      input.from,
      input.journalId,
      input.to,
    ],
    `sales-invoices?${new URLSearchParams({
      page: String(page),
      size: String(size),
      search: input.search,
      invoicePayStatus: input.invoicePayStatus,
      journal: input.journalId ?? "",
      ...(input?.from
        ? { start: convertDateFormat(input.from, "dd/MM/yyyy") }
        : {}),
      ...(input?.to ? { end: convertDateFormat(input.to, "dd/MM/yyyy") } : {}),
      sort:
        input?.sortKey && input?.sortOrder
          ? `${input.sortKey},${input.sortOrder}`
          : "",
    })}`,
    {},
    {
      enabled: !!input.journalId,
      refetchInterval: input.refetchInterval,
    }
  );
}
