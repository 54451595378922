import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Dropdown } from "flowbite-react";
import classNames from "classnames";
import { useRoutes } from "domains";

export enum PeriodOption {
  YEARLY = "YEARLY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
}

interface PeriodFilterProps {
  name: string;
}

interface PeriodFilterOptionProps {
  value: PeriodOption;
  isActive: boolean;
  onChange: (value: PeriodOption) => void;
}

const PeriodFilterOption: FC<PeriodFilterOptionProps> = function ({
  value,
  isActive,
  onChange,
}) {
  const { t } = useTranslation();
  const onOptionClicked = useCallback(() => onChange(value), [value, onChange]);

  return (
    <Dropdown.Item
      key={value}
      onClick={onOptionClicked}
      className={classNames({
        "bg-gray-100 dark:bg-gray-600": isActive,
      })}
    >
      {t(`dashboardPage.periodFilter.${value}`)}
    </Dropdown.Item>
  );
};

export const PeriodFilter: FC<PeriodFilterProps> = function ({ name }) {
  const [searchParams] = useSearchParams();
  const { setRouteParams } = useRoutes();
  const { t } = useTranslation();
  const currentValue = searchParams.get(name) || PeriodOption.YEARLY;

  const onChange = useCallback(
    (value: PeriodOption) => setRouteParams(name, value),
    [name, setRouteParams]
  );

  return (
    <Dropdown
      label={t(`dashboardPage.periodFilter.${currentValue}`)}
      inline
      trigger="hover"
      className="py-2"
    >
      {Object.values(PeriodOption).map((value) => (
        <PeriodFilterOption
          key={value}
          value={value}
          isActive={currentValue === value}
          onChange={onChange}
        />
      ))}
    </Dropdown>
  );
};
