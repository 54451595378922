import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "flowbite-react";
import { Checkbox } from "../Checkbox";
import { SortableHeadCell } from "../table/SortableHeadCell";

interface InvoiceTableHeadProps {
  allSelected: boolean;
  onSelectAll: () => void;
  isPurchased?: boolean;
  isQuote?: boolean;
}

export const InvoiceTableHead: FC<InvoiceTableHeadProps> = function ({
  allSelected,
  onSelectAll,
  isPurchased,
  isQuote,
}) {
  const { t } = useTranslation();

  return (
    <Table.Head>
      <Table.HeadCell className="w-4">
        <Checkbox
          aria-label="check-all"
          checked={allSelected}
          onChange={onSelectAll}
        />
      </Table.HeadCell>
      <SortableHeadCell sortKey={isPurchased ? "supplierName" : "customerName"}>
        {t(
          isQuote
            ? "quoteTable.title.quoteTo"
            : `invoiceTable.title.${isPurchased ? "invoiceFrom" : "invoiceTo"}`
        )}
      </SortableHeadCell>
      <Table.HeadCell>
        {t(
          isQuote ? "quoteTable.title.quoteNr" : "invoiceTable.title.invoiceNr"
        )}
      </Table.HeadCell>
      <SortableHeadCell sortKey="issueDate">
        {t(
          isQuote
            ? "quoteTable.title.quoteDate"
            : "invoiceTable.title.invoiceDate"
        )}
      </SortableHeadCell>
      <SortableHeadCell sortKey="totalInclusiveVat">
        {t("invoiceTable.title.totalInclusiveVat")}
      </SortableHeadCell>
      {isPurchased && (
        <SortableHeadCell sortKey="uploadDate">
          {t("invoiceTable.title.uploadDate")}
        </SortableHeadCell>
      )}
      <Table.HeadCell>{t("invoiceTable.title.status")}</Table.HeadCell>
      {!isQuote && (
        <Table.HeadCell>
          {t("invoiceTable.title.invoicePayStatus")}
        </Table.HeadCell>
      )}
      <Table.HeadCell className="w-24" />
    </Table.Head>
  );
};
