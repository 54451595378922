import { FC, useCallback } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { Card, CardProps } from "flowbite-react";

import { Address, CreateAddressInput } from "models/address/address.interface";
import { Button } from "components/Button";
import { DomainMutation } from "hooks/useDomainMutation";
import { EditModal } from "components/modals/EditModal";
import { H2 } from "components/headers/H2";
import { SkeletonButton } from "components/skeletons/SkeletonButton";
import { useRoutes } from "domains";

import { AddressForm, AddressFormValues } from "./AddressForm";
import AddressTable from "./AddressTable";

interface Props extends CardProps {
  addresses?: Address[];
  createAddress?: DomainMutation<CreateAddressInput>;
  isLoading?: boolean;
}

type Params = "subtype" | "id" | "action";

export const ADDRESS_ROUTE_KEY = "addresses";

const AddressCard: FC<Props> = function ({
  addresses = [],
  createAddress,
  isLoading,
  ...rest
}) {
  const { closeDetailModal, openDetailCreateModal } = useRoutes();
  const { t } = useTranslation();
  const { subtype, action } = useParams<Params>();

  const onOpenCreateModal = useCallback(
    () => openDetailCreateModal(ADDRESS_ROUTE_KEY),
    [openDetailCreateModal]
  );

  return (
    <Card {...rest}>
      <div className="flex justify-between">
        <H2>{t("addressCard.title.addresses")}</H2>
        {isLoading && !createAddress && (
          <SkeletonButton>
            <HiPlus className="mr-4" />
            {t("addressCard.button.addAddress")}
          </SkeletonButton>
        )}
        {createAddress && (
          <Button type="button" onClick={onOpenCreateModal}>
            <HiPlus className="mr-4" />
            {t("addressCard.button.addAddress")}
          </Button>
        )}
      </div>
      <AddressTable rows={addresses} isLoading={isLoading} />
      {subtype === ADDRESS_ROUTE_KEY &&
        action === "create" &&
        createAddress && (
          <EditModal
            action={action as "create"}
            show
            isError={!!createAddress?.error}
            page="address"
            onClose={closeDetailModal}
          >
            <AddressForm
              defaultValues={{}}
              isLoading={createAddress.isLoading}
              schema={createAddress.schema}
              onSubmit={
                createAddress.execute as (input: AddressFormValues) => void
              }
            />
          </EditModal>
        )}
    </Card>
  );
};

export default AddressCard;
