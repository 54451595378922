import { ReactQueryAPI } from "models/core/reactQuery.interface";
import { ToAPI } from "models/core";
import { GetProductsResponse } from "pages/products/queries/useGetProducts";
import { GetProductResponse } from "pages/products/queries/useGetProduct";
import i18n from "i18n/i18n";

import {
  Product,
  ProductDescription,
  ProductDTO,
  ProductDTOWithName,
} from "./models/product.interface";
import { ProductAPI } from "./useProductsUseCases";
import { addMissingDescriptions } from "./helpers/add-missing-descriptions";

export type ProductAPIDTO = ReactQueryAPI<
  GetProductsResponse | GetProductResponse
>;

interface Mapper {
  toAPI: ToAPI<ProductAPIDTO, ProductAPI>;
}

export const getEmptyProductDescriptions = (
  supportedLanguages: string[]
): ProductDescription[] => {
  const emptyDescriptions: ProductDescription[] = supportedLanguages.map(
    (lang) => ({
      code: lang,
      description: "",
    })
  );

  return emptyDescriptions;
};

export class ProductMapper implements Mapper {
  private lang: string;

  private supportedLanguages: string[];

  constructor(supportedLanguages: string[], lang?: string) {
    this.lang = lang ?? i18n.language;
    this.supportedLanguages = supportedLanguages ?? [];
  }

  private mapToTranslatedProduct = (
    product: ProductDTO
  ): ProductDTOWithName => {
    const productDescriptionByCurrentLanguage = product?.descriptions.find(
      (desc) => {
        return desc.code.toLowerCase() === this.lang.toLowerCase();
      }
    );

    const fallbackProductDescription = product?.descriptions.find((desc) => {
      return desc.code.toLowerCase() === "en";
    });

    return {
      ...product,
      ...addMissingDescriptions(product, this.supportedLanguages),
      name:
        productDescriptionByCurrentLanguage?.description ??
        fallbackProductDescription?.description ??
        "",
    };
  };

  public toAPI: Mapper["toAPI"] = (api) => {
    const extractDTOs = (
      data: ProductAPIDTO["data"]
    ): Omit<Product, "update">[] => {
      if ((data as GetProductsResponse)?.content)
        return (data as GetProductsResponse)?.content.map(
          this.mapToTranslatedProduct
        );

      if (!Array.isArray(data as GetProductResponse) && data)
        return [this.mapToTranslatedProduct(data as GetProductResponse)];

      return [];
    };

    return {
      data: {
        products: extractDTOs(api?.data),
        count: (api?.data as GetProductsResponse)?.totalElements ?? 0,
      },
      isLoading: (api?.isLoading || api?.isFetching) ?? false,
      error: api?.error ?? undefined,
    };
  };
}
