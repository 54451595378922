import { ProductDescription, ProductDTO } from "../models/product.interface";

export const addMissingDescriptions = <T extends ProductDTO>(
  product: T,
  supportedLanguages: string[]
): T => {
  const existingLanguageCodes = (product?.descriptions || []).map((desc) =>
    desc.code.toLowerCase()
  );

  const missingLanguageCodes = supportedLanguages.filter(
    (lang) => !existingLanguageCodes.includes(lang.toLowerCase())
  );

  const newDescriptions: ProductDescription[] = missingLanguageCodes.map(
    (lang) => ({
      code: lang,
      description: "",
    })
  );

  return {
    ...product,
    descriptions: [...(product?.descriptions ?? []), ...newDescriptions],
  };
};
