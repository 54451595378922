import { DefaultTFuncReturn } from "i18next";
import { ReactElement } from "react";

interface H1Props {
  children: ReactElement | DefaultTFuncReturn | string;
}

export function H2({ children }: H1Props) {
  return (
    <h2 className="mb-2 text-xl font-semibold dark:text-white md:text-2xl">
      {children}
    </h2>
  );
}
