import { useEffect } from "react";
import { t } from "i18next";
import { event } from "event";
import { API, Mutation } from "models/core/API.interface";
import { UseCases } from "models/core";
import { useDomainMutation } from "hooks/useDomainMutation";
import {
  GetInvoiceSettingsResponse,
  UpdateInvoiceSettingsInput,
  InvoiceSettings,
} from "./models/invoiceSettings.interface";
import { UpdateInvoiceSettingsApiInput } from "./mutations/useUpdateInvoiceSettings";

export type InvoiceSettingsAPI = API<GetInvoiceSettingsResponse>;

export type UpdateInvoiceSettingsMutation =
  Mutation<UpdateInvoiceSettingsApiInput>;

interface Props {
  api: InvoiceSettingsAPI;
  updateMutation: UpdateInvoiceSettingsMutation;
}

interface Result extends InvoiceSettings {
  isLoading: boolean;
}

export const useInvoiceSettingsUseCases: UseCases<Props, Result> = ({
  api,
  updateMutation,
}) => {
  const invoiceSettings = api.data;

  useEffect(() => {
    if (api.error) {
      event.emit(
        "queryFailed",
        new Error(t("domain.invoiceSettings.error.400") as string)
      );
    }
  }, [api?.error]);

  const update = useDomainMutation<UpdateInvoiceSettingsInput>(
    {
      taxProfile: { required: true },
      invoiceConditions: { required: false },
      quoteConditions: { required: false },
    },
    async (input) => {
      await updateMutation({
        ...input,
      });

      event.emit("mutationSucceeded", t("domain.company.companySaved"));
    }
  );

  return {
    ...api,
    ...invoiceSettings,
    update,
  };
};
