import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { Label } from "flowbite-react";
import classnames from "classnames";

import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "helpers/html-validation-schema";
import { HTMLValidationSchemaResolver } from "helpers/html-validation-schema/compat/HTMLValidationSchemaResolver";

import { Country } from "models/address/address.interface";
import {
  CreateSupplierInput,
  CreateSupplierWithAddressInput,
  UpdateSupplierInput,
} from "hooks/suppliers/models/supplier.interface";
import { LoadingButton } from "../LoadingButton";
import { KBOLookup } from "../input/KBOLookup";
import { Select } from "../input/Select";
import { TextInput } from "../input/TextInput";

export type SupplierFormValues =
  | (CreateSupplierInput | CreateSupplierWithAddressInput)
  | UpdateSupplierInput;

interface Props {
  isInModal?: boolean;
  isEdit?: boolean;
  withAddress?: boolean;
  defaultValues?: SupplierFormValues;
  isLoading?: boolean;
  schema:
    | HTMLValidationSchema<SupplierFormValues>
    | HTMLValidationSchemaGenerator<SupplierFormValues>;
  onSubmit: (input: SupplierFormValues) => void;
}

export const SupplierForm: FC<Props> = function ({
  isInModal,
  isEdit,
  withAddress,
  defaultValues,
  isLoading,
  schema,
  onSubmit,
}) {
  const { t } = useTranslation();
  const methods = useForm<SupplierFormValues>({
    mode: "onBlur",
    defaultValues,
    resolver: HTMLValidationSchemaResolver(schema),
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div
          className={classnames("flex flex-col gap-4", isInModal ? "p-6" : "")}
        >
          <div className={classnames("flex flex-col gap-4")}>
            <div className="flex gap-4">
              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label htmlFor="name">{t("supplierForm.input.name")}</Label>
                </div>
                <KBOLookup
                  id="name"
                  isEdit={isEdit}
                  placeholder={t("supplierForm.placeholder.name") as string}
                  formKey="name"
                />
              </div>
              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label htmlFor="vatNumber">
                    {t("supplierForm.input.vatNumber")}
                  </Label>
                </div>
                <KBOLookup
                  id="vatNumber"
                  isEdit={isEdit}
                  placeholder={
                    t("supplierForm.placeholder.vatNumber") as string
                  }
                  formKey="vatNumber"
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="w-full">
                <div className="mb-2 block">
                  <Label htmlFor="email">{t("supplierForm.input.email")}</Label>
                </div>
                <TextInput
                  {...methods.register("email")}
                  placeholder={t("supplierForm.placeholder.email") as string}
                  id="email"
                  errorMessage={methods.formState.errors.email?.message}
                  type="email"
                />
              </div>
            </div>
          </div>

          {withAddress && (
            <div className={classnames("flex flex-col gap-4")}>
              <hr className="h-px mt-4 mb-2 bg-gray-200 border-0 dark:bg-gray-700" />

              <div className="flex gap-4">
                <div className="w-1/3">
                  <div className="mb-2 block">
                    <Label htmlFor="street">
                      {t("addressForm.input.street")}
                    </Label>
                  </div>
                  <TextInput
                    {...methods.register("address.street", { required: true })}
                    placeholder={t("addressForm.placeholder.street") as string}
                    id="street"
                    errorMessage={
                      (
                        methods.formState
                          .errors as FieldErrors<CreateSupplierWithAddressInput>
                      )?.address?.street?.message
                    }
                  />
                </div>
                <div className="w-1/3">
                  <div className="mb-2 block">
                    <Label htmlFor="number">
                      {t("addressForm.input.number")}
                    </Label>
                  </div>
                  <TextInput
                    {...methods.register("address.number")}
                    placeholder={t("addressForm.placeholder.number") as string}
                    id="number"
                    errorMessage={
                      (
                        methods.formState
                          .errors as FieldErrors<CreateSupplierWithAddressInput>
                      )?.address?.number?.message
                    }
                  />
                </div>
                <div className="w-1/3">
                  <div className="mb-2 block">
                    <Label htmlFor="bus">{t("addressForm.input.bus")}</Label>
                  </div>
                  <TextInput
                    {...methods.register("address.bus")}
                    placeholder={t("addressForm.placeholder.bus") as string}
                    id="bus"
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <div className="w-1/3">
                  <div className="mb-2 block">
                    <Label htmlFor="zipCode">
                      {t("addressForm.input.zipCode")}
                    </Label>
                  </div>
                  <TextInput
                    {...methods.register("address.zipCode")}
                    placeholder={t("addressForm.placeholder.zipCode") as string}
                    id="zipCode"
                    errorMessage={
                      (
                        methods.formState
                          .errors as FieldErrors<CreateSupplierWithAddressInput>
                      )?.address?.zipCode?.message
                    }
                  />
                </div>
                <div className="w-1/3">
                  <div className="mb-2 block">
                    <Label htmlFor="city">{t("addressForm.input.city")}</Label>
                  </div>
                  <TextInput
                    {...methods.register("address.city")}
                    placeholder={t("addressForm.placeholder.city") as string}
                    id="city"
                    errorMessage={
                      (
                        methods.formState
                          .errors as FieldErrors<CreateSupplierWithAddressInput>
                      )?.address?.city?.message
                    }
                  />
                </div>
                <div className="w-1/3">
                  <div className="mb-2 block">
                    <Label htmlFor="country">
                      {t("addressForm.input.country")}
                    </Label>
                  </div>
                  <Select
                    {...methods.register("address.country")}
                    placeholder={t("addressForm.placeholder.country") as string}
                    id="country"
                    errorMessage={
                      (
                        methods.formState
                          .errors as FieldErrors<CreateSupplierWithAddressInput>
                      )?.address?.country?.message
                    }
                  >
                    {Object.values(Country).map((value) => (
                      <option key={value} value={value}>
                        {t(`country.${value}`)}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className={classnames(
            isInModal ? "border-1 border-t p-6 dark:border-gray-700" : "pt-4"
          )}
        >
          <LoadingButton type="submit" loading={isLoading}>
            {t("supplierForm.button.save")}
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
};
