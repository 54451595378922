import { event, Events } from "event";
import { useEffect } from "react";

export const useEvent = <Event extends keyof Events>(
  eventName: Event,
  listener: Events[Event]
): void => {
  useEffect(() => {
    event.addListener(eventName, listener);

    return () => {
      event.removeListener(eventName, listener);
    };
  }, [eventName, listener]);
};
