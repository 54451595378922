import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HiClock } from "react-icons/hi";
import {
  useCustomers,
  useProducts,
  useRoutes,
  useTables,
  useTimesheets,
} from "domains";
import { ActionBar, PageHeader } from "components/PageHeader";
import { AUTO_REFRESH_INTERVAL_KEY } from "components/AutoRefresh";
import { DeleteModal } from "components/modals/DeleteModal";
import { EditModal } from "components/modals/EditModal";
import { UploadCSV } from "components/UploadCSV";
import { useGetProducts } from "pages/products/queries/useGetProducts";
import { useTablesEffects } from "hooks/tables/useTablesUseCases";
import TimesheetTable from "./components/TimesheetTable";
import { TimesheetForm } from "./components/TimesheetForm";
import { useGetTimesheets } from "./queries/useGetTimesheets";
import {
  TIMESHEETS_XSLX_KEY,
  useGetTimesheetsXLSX,
} from "./queries/useGetTimesheetsXLSX";
import { useGetTimesheet } from "./queries/useGetTimesheet";
import { useGetCustomers } from "../customers/queries/useGetCustomers";
import { GenerateInvoiceForm } from "./components/GenerateInvoiceForm";
import {
  ConvertTimesheetInput,
  UpdateTimesheetInput,
} from "../../hooks/timesheets/models/timesheets.interface";

function TimesheetsPage() {
  const [searchParams] = useSearchParams();
  const [idsToDelete, setIdsToDelete] = useState<string[]>([]);
  const [refetchInterval, setRefetchInterval] = useState<number>(
    parseInt(localStorage.getItem(AUTO_REFRESH_INTERVAL_KEY) ?? "", 10)
  );
  const timesheetsTable = useTables();
  const { rowSelectedState, handleAllRowsClicked, handleOneRowClicked } =
    timesheetsTable;
  useTablesEffects(timesheetsTable);
  const { t } = useTranslation();
  const { routes, goToTimesheets } = useRoutes();
  const { customers } = useCustomers(useGetCustomers());
  const { products } = useProducts(useGetProducts());

  const getTimesheets = useGetTimesheets({
    limit: Number(searchParams.get("limit")) || 10,
    offset: Number(searchParams.get("offset")) || 0,
    search: searchParams.get("search") || "",
    sortKey: searchParams.get("sortKey") || "",
    sortOrder: searchParams.get("sortOrder") || "",
    refetchInterval,
  });

  const getXLSX = useGetTimesheetsXLSX({
    search: searchParams.get("search") || "",
    sortKey: searchParams.get("sortKey") || "",
    sortOrder: searchParams.get("sortOrder") || "",
  });

  const {
    timesheets,
    count,
    isLoading: isLoadingTimesheets,
  } = useTimesheets(getTimesheets);

  const { action, id } = useParams();
  const { timesheet, deleteMany, isLoading, error, uploadCSV } = useTimesheets(
    useGetTimesheet({ id })
  );

  const showModal = useMemo(
    () => String(id) === String(timesheet?.id),
    [timesheet, id]
  );

  const onDeleteTimesheet = useCallback(
    () => deleteMany.execute({ ids: idsToDelete }),
    [idsToDelete, deleteMany]
  );

  useEffect(() => {
    if (action === "delete") {
      const ids = searchParams.getAll("idsToDelete");
      setIdsToDelete(ids);
    }
  }, [action, setIdsToDelete, searchParams]);

  return (
    <div className="flex flex-col">
      <PageHeader
        className="bg-white dark:bg-gray-800"
        title={t("pageHeader.title.timesheets")}
        breadcrumbs={[
          {
            href: routes.timesheets,
            label: t("sidebar.title.timesheets"),
            icon: HiClock,
          },
        ]}
      >
        <ActionBar
          pageKey="timesheets"
          onRefetchIntervalChange={setRefetchInterval}
          onRefetch={getTimesheets.refetch}
          rowSelectedState={rowSelectedState}
          showCreateButton={false}
          queryKey={TIMESHEETS_XSLX_KEY}
          {...getXLSX}
        />
      </PageHeader>
      <TimesheetTable
        rows={timesheets}
        isLoading={isLoadingTimesheets}
        limit={Number(searchParams.get("limit")) || 10}
        total={count}
        handleAllRowsClicked={handleAllRowsClicked}
        handleOneRowClicked={handleOneRowClicked}
        rowSelectedState={rowSelectedState}
      />
      {action === "delete" && !!idsToDelete && (
        <DeleteModal
          isLoading={isLoading}
          isDeleting={deleteMany.isLoading}
          isError={!!error}
          isMultiple={idsToDelete.length > 1}
          page="timesheets"
          show={!!idsToDelete}
          onClose={goToTimesheets}
          onDelete={onDeleteTimesheet}
        />
      )}
      {action === "edit" && showModal && (
        <EditModal
          action={action as "edit"}
          isError={!!timesheet.update.error}
          itemName={timesheet?.customerName ?? ""}
          page="timesheets"
          onClose={goToTimesheets}
          show
        >
          <TimesheetForm
            timesheet={timesheet}
            customers={customers}
            products={products}
            isLoading={timesheet.update.isLoading}
            onSubmit={
              timesheet.update.execute as (input: UpdateTimesheetInput) => void
            }
            schema={timesheet.update.schema}
          />
        </EditModal>
      )}
      {action === "generate-invoice" && showModal && (
        <EditModal
          show
          size="md"
          page="timesheetsInvoice"
          action={action as "edit"}
          isError={!!timesheet.generateInvoice.error}
          onClose={goToTimesheets}
        >
          <GenerateInvoiceForm
            onSubmit={
              timesheet.generateInvoice.execute as (
                input: ConvertTimesheetInput
              ) => void
            }
            timesheet={timesheet}
            schema={timesheet.generateInvoice.schema}
            isLoading={timesheet.generateInvoice.isLoading}
          />
        </EditModal>
      )}
      <UploadCSV
        action={action}
        uploadCSV={uploadCSV}
        onClose={goToTimesheets}
        page="timesheets"
      />
    </div>
  );
}

export default TimesheetsPage;
