import { FC, useCallback, useEffect, useState } from "react";
import {
  FormState,
  UseFormClearErrors,
  UseFormSetValue,
} from "react-hook-form";
import { useRoutes, useSuppliers } from "domains";
import { TypeAheadOption } from "components/input/typeAhead/TypeAheadOption";
import { TypeAhead } from "components/input/typeAhead/TypeAhead";
import { Supplier } from "hooks/suppliers/models/supplier.interface";
import { UpdatePurchaseInvoiceInput } from "hooks/purchaseInvoices/models/purchaseInvoiceInput.interface";
import { CREATE_SUPPLIER_PARAM } from "../purchase-invoice";
import { useGetSupplierOptions } from "../../suppliers/queries/useGetSupplierOptions";

interface SupplierTypeAheadProps {
  id?: string;
  selectedSupplier?: string;
  placeholder?: string;
  formState: FormState<UpdatePurchaseInvoiceInput>;
  setValue: UseFormSetValue<UpdatePurchaseInvoiceInput>;
  clearErrors: UseFormClearErrors<UpdatePurchaseInvoiceInput>;
}

export const SupplierTypeAhead: FC<SupplierTypeAheadProps> = function ({
  id,
  selectedSupplier,
  placeholder,
  formState,
  setValue,
  clearErrors,
}) {
  const [search, setSearch] = useState("");
  const getSuppliers = useGetSupplierOptions(search);
  const { refetch } = getSuppliers;
  const { suppliers } = useSuppliers(getSuppliers);
  const { openDifferentEntityModal } = useRoutes();

  const onSelectOption = useCallback(
    (supplier: Supplier) => {
      setValue("supplierId", supplier.id);
      clearErrors("supplierId");
    },
    [setValue, clearErrors]
  );

  useEffect(() => {
    refetch();
  }, [refetch, search]);

  const createOption = useCallback(
    (initialValue: string) =>
      openDifferentEntityModal(CREATE_SUPPLIER_PARAM, initialValue),
    [openDifferentEntityModal]
  );

  return (
    <TypeAhead
      id={id}
      showDropdown
      createOptionLabel="supplierTypeAhead.create"
      onCreateOption={createOption}
      placeholder={placeholder}
      selectedValue={selectedSupplier || ""}
      errorMessage={formState.errors.supplierId?.message?.toString() ?? ""}
      refetch={setSearch}
    >
      {suppliers.map((supplier) => (
        <TypeAheadOption
          key={supplier.id}
          option={supplier}
          name={supplier.name}
          selectOption={onSelectOption}
        />
      ))}
    </TypeAhead>
  );
};
