import { FC } from "react";
import { Table } from "flowbite-react";

import { RoundedIconDropdown } from "components/table/RoundedIconDropdown";
import { Skeleton } from "components/skeletons/Skeleton";
import { SkeletonRow } from "components/skeletons/SkeletonRow";
import { SkeletonRows } from "components/skeletons/SkeletonRows";

export const LoadingSupplierRow: FC = function () {
  return (
    <SkeletonRows rows={10}>
      <SkeletonRow>
        {/* Checkbox row */}
        <Table.Cell />
        {/* Name + VAT number row */}
        <Table.Cell>
          <div className="flex flex-col gap-4">
            <Skeleton />
            <Skeleton className="w-6/12" />
          </div>
        </Table.Cell>
        {/* Address row */}
        <Table.Cell>
          <div className="flex flex-col gap-4">
            <Skeleton />
            <Skeleton className="w-6/12" />
          </div>
        </Table.Cell>
        {/* Contact name row */}
        <Table.Cell>
          <div className="flex flex-col gap-4">
            <Skeleton />
            <Skeleton className="w-6/12" />
          </div>
        </Table.Cell>
        {/* Actions row */}
        <Table.Cell>
          <Skeleton>
            <RoundedIconDropdown />
          </Skeleton>
        </Table.Cell>
      </SkeletonRow>
    </SkeletonRows>
  );
};
