import {
  ChangeEvent,
  DragEvent,
  FC,
  useCallback,
  useRef,
  useState,
} from "react";
import { Spinner } from "flowbite-react";
import { HiOutlineCloudUpload, HiOutlineSearch } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";

interface UploadFileProps {
  isLoading: boolean;
  onUpload: (arrayFiles: File[]) => void;
  maxFileCount: number;
  maxFileSize: number;
  accept: string[];
}

function allFileTypesAccepted(acceptedTypes: string[], fileTypes: string[]) {
  return fileTypes.every((fileType) => {
    return acceptedTypes.some((acceptedType) => fileType.match(acceptedType));
  });
}

export const UploadFile: FC<UploadFileProps> = function ({
  isLoading,
  onUpload,
  maxFileCount,
  maxFileSize,
  accept,
}) {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const uploadFiles = useCallback(
    (files: FileList | null) => {
      setErrorMessage("");

      if (files && files.length) {
        const arrayFiles = Array.from(files);

        if (arrayFiles.length > maxFileCount) {
          setErrorMessage(
            t("uploadFile.maxFileCountError", { max: maxFileCount }) as string
          );
          return;
        }

        if (
          !allFileTypesAccepted(
            accept,
            arrayFiles.map((file) => file.type)
          )
        ) {
          const allowedTypes = accept
            .map((type) => {
              if (type.includes("pdf")) return "PDF";
              if (type.includes("image")) return "images";
              if (type.includes("csv")) return "CSV";

              return type;
            })
            .join(", ");

          setErrorMessage(
            t("uploadFile.fileTypeError", {
              types: allowedTypes,
            }) as string
          );
          return;
        }

        if (arrayFiles.some((file) => file.size > maxFileSize)) {
          setErrorMessage(
            t("uploadFile.maxFileSizeError", {
              max: maxFileSize / 1024 / 1024,
            }) as string // convert to MB
          );
          return;
        }

        onUpload(arrayFiles);
      }
    },
    [setErrorMessage, onUpload, t, accept, maxFileCount, maxFileSize]
  );

  const handleBrowseClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.dispatchEvent(new MouseEvent("click"));
    }
  }, [fileInputRef]);

  const handleDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      uploadFiles(e.dataTransfer.files);
    },
    [uploadFiles]
  );

  const handleFileSelect = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => uploadFiles(target.files),
    [uploadFiles]
  );

  const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);

  return (
    <div className="flex justify-center items-center m-6 border border-r-2 bg-gray-50 dark:bg-gray-900 border-gray-200 dark:border-gray-700 rounded-lg border-dashed h-80 text-gray-500 dark:text-gray-400 relative">
      {isLoading && <Spinner />}
      {!isLoading && (
        <div
          className="flex flex-col justify-center items-center w-100 h-80 gap-4"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <div className="flex flex-col gap-1 items-center">
            <HiOutlineCloudUpload
              size="33"
              className="text-gray-400 dark:text-gray-500"
            />
            <p>
              <span className="font-semibold">{t("uploadFile.upload")}</span>{" "}
              {t("uploadFile.dragAndDrop")}
            </p>
            <p className="text-sm font-semibold">
              {t("uploadFile.maxSize", {
                maxSize: maxFileSize / 1024 / 1024, // convert to MB
                maxCount: maxFileCount,
              })}
            </p>
          </div>
          <Button onClick={handleBrowseClick}>
            <HiOutlineSearch size="13" className="mr-2" />
            {t("uploadFile.browseBtn")}
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            accept={accept.join(",")}
            style={{ display: "none" }}
            multiple
          />
          {errorMessage && (
            <p className="text-red-700 dark:text-red-600 absolute bottom-5 font-semibold">
              {errorMessage}
            </p>
          )}
        </div>
      )}
    </div>
  );
};
