import { useApiQuery } from "hooks/api/useApiQuery";
import { UserBank } from "hooks/banks/models/bank.interface";

export interface GetUserBanksParams {
  refetchInterval?: number;
}

export type GetUserBanksResponse = UserBank;

export const USER_BANKS = "userBanks";

export function useGetUserBanks(input?: GetUserBanksParams) {
  return useApiQuery<GetUserBanksResponse>(
    [USER_BANKS],
    `banks/user`,
    {},
    {
      retry: 0,
      refetchInterval: input?.refetchInterval,
      onError: () => true,
    }
  );
}
