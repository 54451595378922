import { FC, useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { HiAdjustments } from "react-icons/hi";
import { useFile, useInvoiceSettings, useRoutes } from "domains";
import { PageHeader } from "components/PageHeader";
import { useGetFile } from "hooks/file/queries/useGetFile";
import { Card } from "flowbite-react";
import { CompanyForm } from "components/company/CompanyForm";
import { UpdateFileInput } from "hooks/file/models/file.interface";
import { removeProperties } from "helpers/remove-properties";
import { CompanyLogo } from "components/company/CompanyLogo";
import { InvoiceSettingsForm } from "components/company/InvoiceSettingsForm";
import { useGetInvoiceSettings } from "hooks/invoiceSettings/queries/useGetInvoiceSettings";
import { UpdateInvoiceSettingsInput } from "hooks/invoiceSettings/models/invoiceSettings.interface";

const TABS = {
  GENERAL: "general",
  INVOICE: "invoice",
};

const SettingsPage: FC = function () {
  const { t } = useTranslation();
  const [tab, setTab] = useState<string>(TABS.GENERAL);
  const { routes } = useRoutes();
  const { companyLogo, address, update, ...file } = useFile(useGetFile());
  const { update: updateInvoiceSettings, ...invoiceSettings } =
    useInvoiceSettings(useGetInvoiceSettings());

  const fileFormValues = useMemo(() => {
    if (!file) {
      return undefined;
    }

    return removeProperties({ ...file, billingAddress: address }, ["theme"]);
  }, [file, address]);

  const submitUpdateCompanyForm = useCallback(
    (input: UpdateFileInput) => {
      if (!update) {
        return;
      }

      update?.execute(input);
    },
    [update]
  );

  const submitUpdateInvoiceSettingsForm = useCallback(
    (input: UpdateInvoiceSettingsInput) => {
      if (!updateInvoiceSettings) {
        return;
      }

      updateInvoiceSettings?.execute(input);
    },
    [updateInvoiceSettings]
  );

  return (
    <>
      <PageHeader
        className="bg-white dark:bg-gray-800"
        title={t("settingsPage.title.settings")}
        breadcrumbs={[
          {
            href: routes.settings,
            label: t("sidebar.title.settings") as string,
            icon: HiAdjustments,
          },
        ]}
      />
      <div
        className="m-4 grid grid-cols-1 xl:grid-cols-5 gap-4"
        data-testid="root"
      >
        <div className="col-span-1 xl:col-span-3 flex flex-col gap-4">
          <ul className="flex w-full gap-3 justify-start">
            <li>
              <button
                name={TABS.GENERAL}
                type="button"
                onClick={() => setTab(TABS.GENERAL)}
                className={classNames(
                  `relative flex h-min rounded-lg border !py-0.5 px-4`,
                  {
                    "bg-primary text-white border-primary":
                      tab === TABS.GENERAL,
                    "bg-white dark:bg-transparent text-gray-900 hover:bg-gray-100 dark:text-white dark:border-gray-400":
                      tab !== TABS.GENERAL,
                  }
                )}
              >
                <span className="!py-2 text-sm">
                  {t(`settingsPage.tabs.${TABS.GENERAL}`)}
                </span>
              </button>
            </li>
            <li>
              <button
                name={TABS.INVOICE}
                type="button"
                onClick={() => setTab(TABS.INVOICE)}
                className={classNames(
                  `relative flex h-min rounded-lg border !py-0.5 px-4`,
                  {
                    "bg-primary text-white border-primary":
                      tab === TABS.INVOICE,
                    "bg-white dark:bg-transparent text-gray-900 hover:bg-gray-100 dark:text-white dark:border-gray-400":
                      tab !== TABS.INVOICE,
                  }
                )}
              >
                <span className="!py-2 text-sm">
                  {t(`settingsPage.tabs.${TABS.INVOICE}`)}
                </span>
              </button>
            </li>
          </ul>
          <div className="flex w-full">
            <Card className="w-full">
              {tab === TABS.GENERAL && (
                <CompanyForm
                  defaultValues={fileFormValues}
                  isLoading={update.isLoading}
                  schema={update.schema}
                  onSubmit={
                    submitUpdateCompanyForm as (input: UpdateFileInput) => void
                  }
                />
              )}
              {tab === TABS.INVOICE && (
                <InvoiceSettingsForm
                  languages={file?.languages}
                  defaultValues={invoiceSettings}
                  isLoading={updateInvoiceSettings.isLoading}
                  schema={updateInvoiceSettings.schema}
                  onSubmit={
                    submitUpdateInvoiceSettingsForm as (
                      input: UpdateInvoiceSettingsInput
                    ) => void
                  }
                />
              )}
            </Card>
          </div>
        </div>
        <div className="justify-start h-fit col-span-1 xl:col-span-2 mt-[58px]">
          <Card>
            <CompanyLogo logo={companyLogo} />
          </Card>
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
