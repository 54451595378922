import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "flowbite-react";
import { GetSubscriptionResponse } from "hooks/subscription/models/subscription.interface";

interface PaymentInfoProps {
  subscription?: GetSubscriptionResponse;
}

export const PaymentInfo: FC<PaymentInfoProps> = function ({ subscription }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full card-details">
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        {t("subscriptionPage.cardInfo.cardDetails")}
      </h5>
      <Table className="background-transparent p-0 m-0">
        <Table.Body className="divide-y">
          <Table.Row className="bg-transparent dark:border-gray-700 dark:bg-gray-800 border-none">
            <Table.Cell className="pl-0 pb-1 whitespace-nowrap font-medium text-gray-900 dark:text-white">
              {t("subscriptionPage.cardInfo.cardName")}
            </Table.Cell>
            <Table.Cell className="pb-1">
              {subscription?.card?.name || "-"}
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-transparent dark:border-gray-700 dark:bg-gray-800 border-none">
            <Table.Cell className="pl-0 pb-1 whitespace-nowrap font-medium text-gray-900 dark:text-white">
              {t("subscriptionPage.cardInfo.cvc")}
            </Table.Cell>
            <Table.Cell className="pb-1">
              {subscription?.card?.cardNumber ? "XXX" : "-"}
            </Table.Cell>
          </Table.Row>
          <Table.Row className="bg-transparent dark:border-gray-700 dark:bg-gray-800 border-none">
            <Table.Cell className="pl-0 pb-1 whitespace-nowrap font-medium text-gray-900 dark:text-white">
              {t("subscriptionPage.cardInfo.cardNumber")}
            </Table.Cell>
            <Table.Cell className="pb-1">
              {subscription?.card?.cardNumber || "-"}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      {/* <div className="flex mt-4">
          <Button type="button" onClick={() => setIsOpen(true)}>
            <HiCreditCard className="mr-4" />
            {t("subscriptionPage.button.changePayment")}
          </Button>
        </div> */}
    </div>
  );
};
