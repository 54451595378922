import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Table } from "flowbite-react";
import { HiOutlinePencilAlt, HiTrash } from "react-icons/hi";
import { useParams } from "react-router-dom";

import { Contact } from "models/contact/contact.interface";
import { useRoutes } from "domains";
import { EmptyTableRow } from "components/EmptyTableRow";
import { DeleteModal } from "components/modals/DeleteModal";
import { EditModal } from "components/modals/EditModal";
import { SkeletonRows } from "components/skeletons/SkeletonRows";
import { SkeletonRow } from "components/skeletons/SkeletonRow";
import { Skeleton } from "components/skeletons/Skeleton";
import { RoundedIconDropdown } from "components/table/RoundedIconDropdown";
import { CONTACTS_ROUTE_KEY } from "./ContactCard";
import { ContactForm, ContactFormValues } from "./ContactForm";

interface Props {
  rows: Contact[];
  isLoading?: boolean;
}

interface ContactTableRowProps {
  contact: Contact;
}

type Params = "subtype" | "subtypeId" | "id" | "action";

const ContactTableRow: FC<ContactTableRowProps> = function ({ contact }) {
  const { t } = useTranslation();
  const { closeEditDetailModal, openDetailEditModal, openDetailDeleteModal } =
    useRoutes();
  const { subtype, subtypeId, action } = useParams<Params>();

  const isEdit = useCallback(
    (action?: string, id?: string): boolean => action === "edit" && !!id,
    []
  );

  const onDelete = useCallback(
    () =>
      contact.remove.execute({
        id: subtypeId || "",
      }),
    [contact, subtypeId]
  );

  const onOpenDeleteModal = useCallback(
    () => openDetailDeleteModal(CONTACTS_ROUTE_KEY, contact.id),
    [openDetailDeleteModal, contact]
  );

  const onOpenEditModal = useCallback(
    () => openDetailEditModal(CONTACTS_ROUTE_KEY, contact.id),
    [openDetailEditModal, contact]
  );

  return (
    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
      <Table.Cell>
        <div>
          <strong className="block">{contact.name}</strong>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div>
          <strong className="block">
            {t(`language.${contact.languageCode}`)}
          </strong>
        </div>
      </Table.Cell>
      <Table.Cell className="float-right">
        <RoundedIconDropdown>
          <Dropdown.Item
            className="flex items-center rounded-t-lg py-3"
            onClick={onOpenEditModal}
            role="button"
          >
            <HiOutlinePencilAlt className="mr-2" />
            <span>{t("contactTable.button.edit")}</span>
          </Dropdown.Item>
          <Dropdown.Item
            className="flex items-center rounded-b-lg py-3"
            onClick={onOpenDeleteModal}
            role="button"
          >
            <HiTrash className="mr-2 text-red-500" />
            <span className="text-red-500">
              {t("contactTable.button.delete")}
            </span>
          </Dropdown.Item>
        </RoundedIconDropdown>
      </Table.Cell>
      {action === "delete" &&
        subtype === CONTACTS_ROUTE_KEY &&
        subtypeId === contact.id && (
          <DeleteModal
            isLoading={contact.remove.isLoading}
            isError={!!contact.remove.error}
            page="contact"
            show={!!subtypeId}
            onDelete={onDelete}
            onClose={closeEditDetailModal}
          />
        )}
      {subtype === CONTACTS_ROUTE_KEY &&
        isEdit(action, subtypeId) &&
        subtypeId === contact.id && (
          <EditModal
            action={action as "edit"}
            show
            isError={!!contact.update.error}
            itemName=""
            page="contact"
            onClose={closeEditDetailModal}
          >
            <ContactForm
              defaultValues={contact}
              isLoading={contact.update.isLoading}
              schema={contact.update.schema}
              onSubmit={
                contact.update.execute as (input: ContactFormValues) => void
              }
            />
          </EditModal>
        )}
    </Table.Row>
  );
};

const LoadingContactRows: FC = function () {
  return (
    <SkeletonRows rows={1}>
      <SkeletonRow>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell className="flex justify-end">
          <Skeleton>
            <RoundedIconDropdown />
          </Skeleton>
        </Table.Cell>
      </SkeletonRow>
    </SkeletonRows>
  );
};

const ContactTable: FC<Props> = function ({ rows, isLoading }) {
  const { t } = useTranslation();

  return (
    <Table striped theme={{ root: { wrapper: "" } }}>
      <Table.Head>
        <Table.HeadCell>{t("contactTable.title.name")}</Table.HeadCell>
        <Table.HeadCell>{t("contactTable.title.language")}</Table.HeadCell>
        {/* Action row */}
        <Table.HeadCell />
      </Table.Head>
      <Table.Body className="divide-y">
        {isLoading && <LoadingContactRows />}
        {!isLoading &&
          rows.map((contact) => (
            <ContactTableRow key={contact.id} contact={contact} />
          ))}
        {!isLoading && rows.length === 0 && (
          <EmptyTableRow colSpan={4}>
            {t("contactTable.title.empty")}
          </EmptyTableRow>
        )}
      </Table.Body>
    </Table>
  );
};

export default ContactTable;
