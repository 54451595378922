import { FC } from "react";
import { PieChartTitle } from "components/charts/pieChart/PieChartTitle";
import { Currency, toCurrency } from "helpers/to-currency";

interface UnpaidInvoicesPieChartTitle {
  label: string;
  total: number;
  isCurrency?: boolean;
}

export const UnpaidInvoicesPieChartTitle: FC<UnpaidInvoicesPieChartTitle> =
  function ({ label, total, isCurrency }) {
    return (
      <PieChartTitle>
        <p className="text-gray-500 dark:text-gray-400 text-sm font-medium text-center">
          {label.toUpperCase()}
        </p>
        {isCurrency ? (
          <p className="text-center text-xl text-gray-900 dark:text-white">
            {toCurrency(total, Currency.Euro)}
          </p>
        ) : (
          <p className="text-center text-6xl text-gray-900 dark:text-white">
            {total}
          </p>
        )}
      </PieChartTitle>
    );
  };
