import { ChangeEvent, FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Label } from "flowbite-react";
import { useFormContext } from "react-hook-form";
import { Button } from "components/Button";
import { TextInput } from "../../input/TextInput";

interface NoteFormProps {
  index?: number;
  onClose: () => void;
}

export const NoteForm: FC<NoteFormProps> = function ({ index, onClose }) {
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext();
  const note = watch(`documentLines.${index}.note`);

  const [val, setVal] = useState(note); // Declare a state variable...

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setVal(event.target.value);
    },
    [setVal]
  );

  const handleSetNote = useCallback(() => {
    setValue(`documentLines.${index}.note`, val);
    onClose();
  }, [setValue, index, val, onClose]);

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="flex w-full">
          <div className="w-full">
            <div className="mb-2 block">
              <Label htmlFor="note" value={t("noteForm.label.note") ?? "="} />
            </div>
            <TextInput
              id="note"
              type="text"
              placeholder={t("noteForm.placeholder.note") ?? "="}
              value={val}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="w-full border-1 border-t pt-6 dark:border-gray-700">
        <Button type="button" onClick={handleSetNote}>
          {t("noteForm.button.editNote")}
        </Button>
      </div>
    </>
  );
};
