import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

import { useApiMutation } from "hooks/api/useApiMutation";
import { httpErrorCodeWithTranslations } from "helpers/api";
import { event } from "event";

import {
  CreateProductGroupInput,
  ProductGroup,
} from "../models/productGroup.interface";

export type CreateProductGroupResponse = ProductGroup;

export function useCreateProductGroup() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useApiMutation<CreateProductGroupResponse, CreateProductGroupInput>(
    "createProductGroup",
    "article-groups",
    (input) => ({
      method: "post",
      body: JSON.stringify({
        name: input.name,
      }),
    }),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["productGroups"]);
      },
      onError: (error) => {
        // For the time being: error messages are shown from the back-end (still in english).
        // They should be able to provide translated error messages since we
        // send the current language over the "Accept-Language" header
        // See: src/helpers/api.ts

        // They'll need to provide they want us
        // to translate messages on the front-end

        if (error?.response?.payload?.detail) {
          event.emit(
            "mutationFailed",
            new Error(error?.response?.payload?.detail)
          );

          return;
        }
        if (httpErrorCodeWithTranslations.includes(error.response.status)) {
          event.emit(
            "mutationFailed",
            new Error(t(`error.${error.response.status}`) as string)
          );

          return;
        }

        event.emit("mutationFailed", new Error(error.message));
      },
    },
    async (response) => {
      return response.json();
    }
  );
}
