import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

import { event } from "event";
import { useApiMutation } from "hooks/api/useApiMutation";
import { httpErrorCodeWithTranslations } from "helpers/api";

import { UpdateFileInput } from "../models/file.interface";

export type UpdateFileBody = UpdateFileInput;

export type UpdateFileApiInput = Partial<UpdateFileBody>;

export type UpdateFileResponse = void;

export function useUpdateFile() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useApiMutation<UpdateFileResponse, UpdateFileApiInput>(
    "customers",
    () => `company`,
    (input) => {
      return {
        method: "put",
        body: JSON.stringify(input),
      };
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["file"]);
      },
      onError: (error) => {
        if (error?.response?.payload?.detail) {
          event.emit(
            "mutationFailed",
            new Error(error?.response?.payload?.detail)
          );

          return;
        }
        if (httpErrorCodeWithTranslations.includes(error.response.status)) {
          event.emit(
            "mutationFailed",
            new Error(t(`error.${error.response.status}`) as string)
          );

          return;
        }

        event.emit("mutationFailed", new Error(error.message));
      },
    }
  );
}
