/* eslint-disable class-methods-use-this */
import { ToAPI } from "models/core";
import { ReactQueryAPI } from "models/core/reactQuery.interface";
import { GetSummaryResponse } from "pages/dashboard/queries/useGetSummary";
import { GetUnpaidInvoicesResponse } from "pages/dashboard/queries/useGetUnpaidInvoices";
import { GetEbitdaEvolutionResponse } from "pages/dashboard/queries/useGetEbitdaEvolution";
import { GetFinancialEvolutionResponse } from "pages/dashboard/queries/useGetFinancialEvolution";
import {
  EbitdaEvolutionAPI,
  FinancialEvolutionAPI,
  SummaryAPI,
  UnpaidInvoiceAPI,
} from "./useDashboardUseCases";
import {
  EbitdaEvolution,
  FinancialEvolution,
  Summary,
  UnpaidInvoices,
} from "./models/dashboard.interface";

export type SummaryAPIDTO = ReactQueryAPI<GetSummaryResponse>;
export type UnpaidInvoicesAPIDTO = ReactQueryAPI<GetUnpaidInvoicesResponse>;
export type EbitdaEvolutionAPIDTO = ReactQueryAPI<GetEbitdaEvolutionResponse>;
export type FinancialEvolutionAPIDTO =
  ReactQueryAPI<GetFinancialEvolutionResponse>;

export type DashboardAPIDTO = {
  summary?: SummaryAPIDTO;
  unpaidInvoices?: UnpaidInvoicesAPIDTO;
  ebitdaEvolution?: EbitdaEvolutionAPIDTO;
  financialEvolution?: FinancialEvolutionAPIDTO;
};

interface Mapper {
  toSummaryAPI: ToAPI<SummaryAPIDTO, SummaryAPI>;
  toUnpaidInvoicesAPI: ToAPI<UnpaidInvoicesAPIDTO, UnpaidInvoiceAPI>;
  toEbitdaEvolutionAPI: ToAPI<EbitdaEvolutionAPIDTO, EbitdaEvolutionAPI>;
  toFinancialEvolutionAPI: ToAPI<
    FinancialEvolutionAPIDTO,
    FinancialEvolutionAPI
  >;
}

export class DashboardMapper implements Mapper {
  public toSummaryAPI: Mapper["toSummaryAPI"] = (api) => {
    const extractDTOs = (data: SummaryAPIDTO["data"]): Summary => {
      return data || {};
    };

    return {
      data: {
        summary: extractDTOs(api?.data),
      },
      isLoading: (api?.isLoading || api?.isFetching) ?? false,
      error: api?.error ?? undefined,
    };
  };

  public toUnpaidInvoicesAPI: Mapper["toUnpaidInvoicesAPI"] = (api) => {
    const extractDTOs = (
      data: UnpaidInvoicesAPIDTO["data"]
    ): UnpaidInvoices => {
      return data || {};
    };

    return {
      data: {
        unpaidInvoices: extractDTOs(api?.data),
      },
      isLoading: (api?.isLoading || api?.isFetching) ?? false,
      error: api?.error ?? undefined,
    };
  };

  public toEbitdaEvolutionAPI: Mapper["toEbitdaEvolutionAPI"] = (api) => {
    const extractDTOs = (
      data: EbitdaEvolutionAPIDTO["data"]
    ): EbitdaEvolution => {
      return data || {};
    };

    return {
      data: {
        ebitdaEvolution: extractDTOs(api?.data),
      },
      isLoading: (api?.isLoading || api?.isFetching) ?? false,
      error: api?.error ?? undefined,
    };
  };

  public toFinancialEvolutionAPI: Mapper["toFinancialEvolutionAPI"] = (api) => {
    const extractDTOs = (
      data: FinancialEvolutionAPIDTO["data"]
    ): FinancialEvolution => {
      return data || {};
    };

    return {
      data: {
        financialEvolution: extractDTOs(api?.data),
      },
      isLoading: (api?.isLoading || api?.isFetching) ?? false,
      error: api?.error ?? undefined,
    };
  };
}
