import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useApiMutation } from "hooks/api/useApiMutation";
import { httpErrorCodeWithTranslations } from "helpers/api";
import { useRoutes } from "domains";
import { event } from "event";
import { PurchaseInvoice } from "../models/purchaseInvoice.interface";

export interface PatchPurchaseInvoiceParams {
  id: PurchaseInvoice["id"];
}

export type PatchPurchaseInvoiceBody = {
  paymentProcessing?: boolean;
};

export type PatchPurchaseInvoiceResponse = void;

export function usePatchPurchaseInvoice() {
  const queryClient = useQueryClient();
  const { closePayModal } = useRoutes();
  const { t } = useTranslation();

  return useApiMutation<
    PatchPurchaseInvoiceResponse,
    PatchPurchaseInvoiceParams & PatchPurchaseInvoiceBody
  >(
    "purchaseInvoices",
    (input) => `purchase-invoices/${input.id}`,
    (input) => ({
      method: "patch",
      body: JSON.stringify({
        ...(input.paymentProcessing
          ? { paymentProcessing: input.paymentProcessing }
          : {}),
      }),
    }),
    {
      onSuccess: () => {
        closePayModal();
        return queryClient.invalidateQueries([
          "purchaseInvoices",
          "purchaseInvoice",
        ]);
      },
      onError: (error) => {
        // For the time being: error messages are shown from the back-end (still in english).
        // They should be able to provide translated error messages since we
        // send the current language over the "Accept-Language" header
        // See: src/helpers/api.ts

        // They'll need to provide they want us
        // to translate messages on the front-end

        if (error?.response?.payload?.detail) {
          event.emit(
            "mutationFailed",
            new Error(error?.response?.payload?.detail)
          );

          return;
        }
        if (httpErrorCodeWithTranslations.includes(error.response.status)) {
          event.emit(
            "mutationFailed",
            new Error(t(`error.${error.response.status}`) as string)
          );

          return;
        }

        event.emit("mutationFailed", new Error(error.message));
      },
    }
  );
}
