import { useTranslation } from "react-i18next";
import { event } from "event";
import { DomainMutation, useDomainMutation } from "hooks/useDomainMutation";
import { API, Mutation } from "models/core/API.interface";
import { UseCases } from "models/core";
import { UploadCSVInput } from "models/csv/csv.interface";
import { CREATE_PRODUCT_GROUP_PARAM } from "pages/products/products";
import {
  CreateProductGroupInput,
  DeleteProductGroupsInput,
  ProductGroup,
  UpdateProductGroupInput,
} from "./models/productGroup.interface";

export type ProductGroupAPI = API<{
  productGroups: Omit<ProductGroup, "update">[];
  count: number;
}>;
export type UpdateMutation = Mutation<UpdateProductGroupInput>;
export type CreateMutation = Mutation<CreateProductGroupInput, ProductGroup>;
export type DeleteMutation = Mutation<DeleteProductGroupsInput>;
export type UploadCSVMutation = Mutation<UploadCSVInput>;

interface Props {
  api: ProductGroupAPI;
  updateProductGroupMutation: UpdateMutation;
  createProductGroupMutation: CreateMutation;
  deleteProductGroupsMutation: DeleteMutation;
  uploadCSVMutation: UploadCSVMutation;
}

interface Result {
  productGroups: ProductGroup[];
  productGroup: ProductGroup;
  count: number;
  isLoading: boolean;
  create: DomainMutation<CreateProductGroupInput>;
  createFromProduct: DomainMutation<CreateProductGroupInput>;
  deleteMany: DomainMutation<DeleteProductGroupsInput>;
  uploadCSV: DomainMutation<UploadCSVInput>;
  error?: Error;
}

export const useProductGroupUseCases: UseCases<Props, Result> = ({
  api,
  updateProductGroupMutation,
  createProductGroupMutation,
  deleteProductGroupsMutation,
  uploadCSVMutation,
}) => {
  const { t } = useTranslation();

  const create: Result["create"] = useDomainMutation<CreateProductGroupInput>(
    {
      name: { required: true },
    },
    async (input) => {
      await createProductGroupMutation(input);
      event.emit("productGroupSaved");
      event.emit(
        "mutationSucceeded",
        t("domain.productGroup.productGroupSaved")
      );
    }
  );

  const createFromProduct = useDomainMutation<CreateProductGroupInput>(
    create.schema,
    async (input) => {
      const productGroup = await createProductGroupMutation(input);
      if (productGroup) {
        event.emit("productGroupCreatedFromProduct", productGroup.id);
        event.emit("closeDifferentEntity", CREATE_PRODUCT_GROUP_PARAM);
        event.emit(
          "mutationSucceeded",
          t("domain.productGroup.productGroupSaved")
        );
      }
    }
  );

  const update = useDomainMutation<UpdateProductGroupInput>(
    {
      id: { required: true },
      ...create.schema,
    },
    async (input) => {
      await updateProductGroupMutation(input);
      event.emit("productGroupSaved");
      event.emit(
        "mutationSucceeded",
        t("domain.productGroup.productGroupSaved")
      );
    }
  );

  const deleteMany: Result["deleteMany"] =
    useDomainMutation<DeleteProductGroupsInput>(
      {
        ids: [{ required: true }],
      },
      async (input) => {
        await deleteProductGroupsMutation(input);
        event.emit("productGroupsDeleted");
        event.emit(
          "mutationSucceeded",
          t("domain.productGroup.productGroupsDeleted")
        );
      }
    );

  const productGroups: Result["productGroups"] = api.data.productGroups.map(
    (productGroup) => ({
      ...productGroup,
      update,
    })
  );

  const uploadCSV: Result["uploadCSV"] = useDomainMutation<UploadCSVInput>(
    {
      formData: { required: true },
    },
    async (input) => {
      await uploadCSVMutation(input);

      event.emit("productGroupCSVUploaded");
      event.emit("CSVUploaded");
    }
  );

  return {
    ...api,
    productGroups,
    productGroup: productGroups[0],
    count: api.data.count,
    create,
    createFromProduct,
    deleteMany,
    uploadCSV,
  };
};
