import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { event } from "event";

import { API } from "models/core/API.interface";
import { Mutation, UseCases } from "models/core";
import {
  CreateQuoteInput,
  UpdateQuoteInput,
} from "models/paymentDocument/quote/quoteInput.interface";
import { UploadCSVInput } from "models/csv/csv.interface";
import { HTMLValidationSchemaGenerator } from "helpers/html-validation-schema";
// import { addCustomerSubEntitiesIds } from "helpers/customer/addCustomerSubEntitiesIds";
import { Quote } from "./models/quote.interface";
import {
  ConvertQuoteInput,
  DeleteQuotesInput,
  SendQuoteInput,
} from "./models/quoteInput.interface";
import { ConvertQuoteResponse } from "./mutations/useConvertQuote";
import { DomainMutation, useDomainMutation } from "../useDomainMutation";
import { SendQuoteResponse } from "./mutations/useSendQuote";

export type QuoteAPI = API<{
  quotes: Omit<Quote, "update">[];
  count: number;
}>;
export type CreateMutation = Mutation<CreateQuoteInput, Omit<Quote, "update">>;
export type UpdateMutation = Mutation<UpdateQuoteInput>;
export type DeleteMutation = Mutation<DeleteQuotesInput>;
export type ConvertMutation = Mutation<ConvertQuoteInput, ConvertQuoteResponse>;
export type SendMutation = Mutation<SendQuoteInput, SendQuoteResponse>;
export type UploadCSVMutation = Mutation<UploadCSVInput>;

interface Props {
  api: QuoteAPI;
  createMutation: CreateMutation;
  updateMutation: UpdateMutation;
  deleteMutation: DeleteMutation;
  convertMutation: ConvertMutation;
  sendMutation: SendMutation;
  uploadCSVMutation: UploadCSVMutation;
}

interface Result {
  quotes: Quote[];
  quote?: Quote;
  count: number;
  isLoading: boolean;
  error?: Error;
  create: DomainMutation<
    CreateQuoteInput,
    HTMLValidationSchemaGenerator<CreateQuoteInput>
  >;
  deleteMany: DomainMutation<DeleteQuotesInput>;
  convert: DomainMutation<ConvertQuoteInput>;
  send: DomainMutation<ConvertQuoteInput>;
  uploadCSV: DomainMutation<UploadCSVInput>;
}

export const useQuotesUseCases: UseCases<Props, Result> = ({
  api,
  createMutation,
  updateMutation,
  deleteMutation,
  convertMutation,
  sendMutation,
  uploadCSVMutation,
}) => {
  const { t } = useTranslation();

  const create: Result["create"] = useDomainMutation<
    CreateQuoteInput,
    HTMLValidationSchemaGenerator<CreateQuoteInput>
  >(
    (input) => ({
      documentLines: [
        {
          articleNumber: { required: false },
          description: { required: true },
          vatPercentage: { required: true },
          amountExclusiveVat: { required: true },
          quantity: { required: true },
        },
      ],
      issueDate: {
        required: true,
        max: input.dueDate,
        rangeOverflow: t(
          "invoiceForm.input.issueDate.error.rangeOverflow"
        ) as string,
      },
      dueDate: {
        required: true,
        min: input.issueDate,
        rangeUnderflow: t(
          "invoiceForm.input.dueDate.error.rangeUnderflow"
        ) as string,
      },
      customerId: { required: true },
      template: {
        accentColor: { required: false },
        layout: { required: false },
      },
    }),
    async (input) => {
      const quote = await createMutation(input);

      if (quote) {
        event.emit("mutationSucceeded", t("domain.quote.quoteSaved"));
        event.emit("quoteSaved");
      }
    }
  );

  const update = useDomainMutation<
    UpdateQuoteInput,
    HTMLValidationSchemaGenerator<UpdateQuoteInput>
  >(
    (input) => ({
      id: { required: true },
      documentLines: [
        {
          articleNumber: { required: false },
          description: { required: true },
          vatPercentage: { required: true },
          amountExclusiveVat: { required: true },
          quantity: { required: true },
        },
      ],
      issueDate: {
        required: true,
        max: input.dueDate,
        rangeOverflow: t(
          "invoiceForm.input.issueDate.error.rangeOverflow"
        ) as string,
      },
      dueDate: {
        required: true,
        min: input.issueDate,
        rangeUnderflow: t(
          "invoiceForm.input.dueDate.error.rangeUnderflow"
        ) as string,
      },
      customerId: { required: true },
    }),
    async (input) => {
      await updateMutation({
        ...input,
      });

      event.emit("mutationSucceeded", t("domain.quote.quoteSaved"));
      event.emit("quoteSaved");
    }
  );

  const deleteMany: Result["deleteMany"] = useDomainMutation<DeleteQuotesInput>(
    {
      ids: [{ required: true }],
    },
    async (input) => {
      await deleteMutation(input);
      event.emit("quotesDeleted");
      event.emit("mutationSucceeded", t("domain.quote.quotesDeleted"));
    }
  );

  const convert: Result["convert"] = useDomainMutation<ConvertQuoteInput>(
    {
      id: { required: true },
    },
    async (input) => {
      const salesInvoiceId = await convertMutation(input);
      if (salesInvoiceId) {
        event.emit("quoteConverted");
        event.emit("mutationSucceeded", t("domain.quote.quoteConverted"));
      }
    }
  );

  const send: Result["send"] = useDomainMutation<SendQuoteInput>(
    {
      id: { required: true },
    },
    async (input) => {
      const salesInvoiceId = await sendMutation(input);
      if (salesInvoiceId) {
        event.emit("quoteSend");
        event.emit("mutationSucceeded", t("domain.quote.quoteSend"));
      }
    }
  );

  const quotes: Result["quotes"] = api.data.quotes.map((quote) => ({
    ...quote,
    update,
  }));

  const quote: Result["quote"] = useMemo(() => quotes[0], [quotes]);

  const uploadCSV: Result["uploadCSV"] = useDomainMutation<UploadCSVInput>(
    {
      formData: { required: true },
    },
    async (input) => {
      await uploadCSVMutation(input);

      event.emit("quoteCSVUploaded");
      event.emit("CSVUploaded");
    }
  );

  return {
    ...api,
    quotes,
    quote,
    count: api.data.count,
    create,
    deleteMany,
    convert,
    send,
    uploadCSV,
  };
};
