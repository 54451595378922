import {
  endOfDay,
  endOfMonth,
  subYears,
  subMonths,
  subDays,
  startOfDay,
  format,
} from "date-fns";
import { PeriodOption } from "pages/dashboard/components/PeriodFilter";

export const getPeriodFilter = (periodOption?: PeriodOption) => {
  let from: Date;
  let to: Date;

  const currentDate = new Date();

  switch (periodOption) {
    case PeriodOption.WEEKLY:
      from = subDays(startOfDay(currentDate), 7);
      to = endOfDay(currentDate);
      break;
    case PeriodOption.MONTHLY:
      from = subMonths(startOfDay(currentDate), 1);
      to = endOfDay(currentDate);
      break;
    case PeriodOption.YEARLY:
    default:
      from = subYears(startOfDay(currentDate), 1);
      to = endOfDay(endOfMonth(currentDate));
      break;
  }

  return {
    from: format(from, "dd/MM/yyyy"),
    to: format(to, "dd/MM/yyyy"),
  };
};
