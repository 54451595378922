import { useApiQuery } from "hooks/api/useApiQuery";
import { ConnectableBank } from "hooks/banks/models/bank.interface";

export interface GetConnectableBanksParams {
  refetchInterval?: number;
}

export type GetConnectableBanksResponse = ConnectableBank[];

export const CONNECTABLEBANKS_QUERY = "connectableBanks";

export function useGetConnectableBanks(input?: GetConnectableBanksParams) {
  return useApiQuery<GetConnectableBanksResponse>(
    [CONNECTABLEBANKS_QUERY],
    `banks/supported`,
    {},
    {
      refetchInterval: input?.refetchInterval,
    }
  );
}
