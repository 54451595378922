/* eslint-disable @typescript-eslint/ban-types */
import { useCallback, useState } from "react";
import { Mutation } from "models/core";
import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
  isValid,
} from "../helpers/html-validation-schema";

export interface DomainMutation<
  Input extends Object,
  Schema extends
    | HTMLValidationSchema<Input>
    | HTMLValidationSchemaGenerator<Input> = HTMLValidationSchema<Input>,
  Output = void,
  Context = undefined
> {
  isLoading: boolean;
  schema: Schema;
  execute: Mutation<Input, Output, Context>;
  error?: Error;
}

export const useDomainMutation = <
  Input extends Object,
  Schema extends
    | HTMLValidationSchema<Input>
    | HTMLValidationSchemaGenerator<Input> = HTMLValidationSchema<Input>,
  Output = void,
  Context = undefined
>(
  schema: Schema,
  callback: Mutation<Input, Output, Context>
): DomainMutation<Input, Schema, Output, Context> => {
  const [isLoading, setIsLoading] =
    useState<DomainMutation<Input, Schema>["isLoading"]>(false);
  const [error, setError] =
    useState<DomainMutation<Input, Schema>["error"]>(undefined);

  const execute: DomainMutation<Input, Schema, Output, Context>["execute"] =
    useCallback(
      async (input, context) => {
        try {
          setIsLoading(true);
          setError(undefined);
          if (isValid(input, schema)) {
            const result = await callback(input, context);
            return result;
          }
          return undefined;
        } catch (error) {
          if (error instanceof Error) {
            setError(error);
          }
          return undefined;
        } finally {
          setIsLoading(false);
        }
      },
      [callback, schema]
    );

  return {
    isLoading,
    schema,
    execute,
    error,
  };
};
