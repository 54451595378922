import { useCallback } from "react";
import { HiCurrencyDollar, HiDocumentText } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { usePurchaseInvoices, useRoutes, useSuppliers } from "domains";

import { PageHeader } from "components/PageHeader";
import { LoadingButton } from "components/LoadingButton";
import { DownloadButton } from "components/DownloadButton";
import { InvoiceSidebar } from "components/invoice/InvoiceSidebar";
import { NotFoundBlock } from "components/errors/404/NotFoundBlock";
import { EditModal } from "components/modals/EditModal";

import { invoiceBadgeMap } from "helpers/invoice-badge-map";

import { usePatchPurchaseInvoice } from "hooks/purchaseInvoices/mutations/usePatchPurchaseInvoice";
import { useGetPurchaseInvoice } from "pages/purchaseInvoice/queries/useGetPurchaseInvoice";
import { NoHeaderModal } from "components/modals/NoHeaderModal";
import {
  SupplierForm,
  SupplierFormValues,
} from "components/supplier/SupplierForm";
import { CreateSupplierWithAddressInput } from "hooks/suppliers/models/supplier.interface";
import { PurchaseInvoice } from "./components/PurchaseInvoice";
import { PurchaseInvoiceForm } from "./components/PurchaseInvoiceForm";
import { useGetSuppliers } from "../suppliers/queries/useGetSuppliers";
import { PayModal } from "./components/PayModal";

type Params = "id" | "subAction";

export const CREATE_SUPPLIER_PARAM = "create-supplier";
export const PAY = "pay";

function PurchaseInvoicePage() {
  const { t } = useTranslation();
  const { routes, closeDifferentEntityModal, openPayModal } = useRoutes();
  const { id, subAction } = useParams<Params>();
  const [searchParams] = useSearchParams();
  const { purchaseInvoice, isLoading } = usePurchaseInvoices(
    useGetPurchaseInvoice({ id })
  );
  const { suppliers, createFromInvoice } = useSuppliers(useGetSuppliers());
  const { mutate, isLoading: isPatching } = usePatchPurchaseInvoice();

  const onCloseCompanyModal = useCallback(
    () => closeDifferentEntityModal(CREATE_SUPPLIER_PARAM),
    [closeDifferentEntityModal]
  );

  const onClosePayModal = useCallback(
    () => closeDifferentEntityModal(PAY),
    [closeDifferentEntityModal]
  );

  const handlePatch = useCallback(() => {
    if (purchaseInvoice) {
      mutate({ id: purchaseInvoice.id, paymentProcessing: true });
    }
  }, [mutate, purchaseInvoice]);

  if (!isLoading && !purchaseInvoice) {
    return (
      <NotFoundBlock
        href={routes.customers}
        entity={t("entity.purchaseInvoice") as string}
      />
    );
  }

  return (
    <>
      {purchaseInvoice && (
        <div className="flex justify-end p-5 pb-0 bg-white dark:bg-gray-800 gap-3">
          {purchaseInvoice?.paymentQRCode && (
            <LoadingButton color="success" type="button" onClick={openPayModal}>
              <HiCurrencyDollar className="mr-4" />
              {t("Pay")}
            </LoadingButton>
          )}
          <DownloadButton
            url={purchaseInvoice.originalDocumentUrl ?? ""}
            // type={purchaseInvoice.originalDocumentContentType ?? ""}
            name={purchaseInvoice.invoiceNumber?.toString() ?? ""}
          />
        </div>
      )}
      <PageHeader
        className="bg-white dark:bg-gray-800"
        title={
          t("pageHeader.title.purchaseInvoice", {
            invoiceNumber: purchaseInvoice?.invoiceNumber ?? "...",
          }) as string
        }
        badge={purchaseInvoice?.invoicePayStatus}
        badgeMap={invoiceBadgeMap}
        breadcrumbs={[
          {
            href: routes.invoices,
            label: t("sidebar.title.invoices") as string,
            icon: HiDocumentText,
          },
          {
            href: routes.purchaseInvoices,
            label: t("sidebar.link.purchaseInvoices"),
          },
          purchaseInvoice
            ? {
                label: `#${
                  purchaseInvoice?.invoiceNumber?.toString() ?? "..."
                }`,
              }
            : { label: "" },
        ].filter((l) => l.label)}
      />
      {purchaseInvoice && (
        <div className="flex flex-col xl:flex-row m-8 gap-8 grow">
          <InvoiceSidebar>
            <PurchaseInvoiceForm
              purchaseInvoice={purchaseInvoice}
              suppliers={suppliers}
              schema={purchaseInvoice.update.schema}
              onSubmit={purchaseInvoice.update.execute}
              isLoading={isLoading}
            />
          </InvoiceSidebar>
          <div className="w-full justify-center overflow-hidden">
            <PurchaseInvoice
              url={purchaseInvoice.originalDocumentUrl ?? ""}
              type={purchaseInvoice.originalDocumentContentType ?? ""}
            />
          </div>
        </div>
      )}
      {subAction === CREATE_SUPPLIER_PARAM && (
        <EditModal
          show
          onClose={onCloseCompanyModal}
          action="create"
          page="suppliers"
          isLoading={createFromInvoice.isLoading}
          isError={!!createFromInvoice.error}
        >
          <SupplierForm
            isInModal
            withAddress
            isLoading={createFromInvoice.isLoading}
            onSubmit={
              createFromInvoice.execute as (input: SupplierFormValues) => void
            }
            schema={createFromInvoice.schema}
            defaultValues={
              {
                name: searchParams.get("initialValue") || "",
              } as CreateSupplierWithAddressInput
            }
          />
        </EditModal>
      )}
      {subAction === PAY && (
        <NoHeaderModal show onClose={onClosePayModal} size="md">
          <PayModal
            onClose={onClosePayModal}
            onPay={handlePatch}
            isLoading={isPatching}
            purchaseInvoice={purchaseInvoice}
          />
        </NoHeaderModal>
      )}
    </>
  );
}

export default PurchaseInvoicePage;
