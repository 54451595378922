import { Quote } from "hooks/quotes/models/quote.interface";
import { useApiQuery } from "hooks/api/useApiQuery";
// import { onError } from "helpers/on-error";

export interface GetQuoteParams {
  id?: Quote["id"];
}

export type GetQuoteResponse = Omit<Quote, "update">;

export function useGetQuote({ id }: GetQuoteParams) {
  return useApiQuery<GetQuoteResponse>(
    ["quotes", id],
    `quotes/${id}`,
    {},
    {
      enabled: !!id,
    }
  );
}
