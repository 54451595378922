/* eslint-disable @typescript-eslint/no-explicit-any */
import { Rule } from "./models/rule";
import { DeepRecord } from "./models/deepRecord";
import { HTMLValidationSchema } from "./models/validationSchema";
import { DeepPartial } from "./models/deepPartial";
import { sanitize } from "./sanitizer";
import { isRule } from "./typeGuard";

/**
 * Returns a new `validateSchema` function which validates any input based on the given `validateRule` parameter.
 * Useful if you need a custom `Error` object.
 *
 * @example
 * const validateSchema = createSchemaValidator(customValidateRule);
 * const errors = validateSchema(input, schema);
 */
export const createSchemaValidator = <Error = string | undefined>(
  validateRule: <Value extends string | number | undefined>(
    value: Value,
    rule: Rule
  ) => Error
) => {
  const validateSchema = <Input extends Record<string, unknown>>(
    input: DeepPartial<Input> | undefined,
    schema: HTMLValidationSchema<Input>
  ): DeepPartial<DeepRecord<Input, Error>> => {
    const keys = Object.keys(schema);
    const entries = keys.map((key) =>
      isRule(schema[key])
        ? [key, validateRule(input?.[key] as any, schema[key])]
        : [key, validateSchema(input?.[key], schema[key])]
    );

    return sanitize(Object.fromEntries(entries)) as DeepPartial<
      DeepRecord<Input, Error>
    >;
  };

  return validateSchema;
};
