/* eslint-disable class-methods-use-this */
import { ReactQueryAPI } from "models/core/reactQuery.interface";
import { ToAPI } from "models/core";
import { GetSuppliersResponse } from "pages/suppliers/queries/useGetSuppliers";
import { GetSupplierResponse } from "pages/suppliers/queries/useGetSupplier";
import { SupplierDTO } from "./models/supplier.interface";
import { SupplierAPI } from "./useSuppliersUseCases";

export type SupplierAPIDTO = ReactQueryAPI<
  GetSupplierResponse | GetSuppliersResponse
>;

interface Mapper {
  toAPI: ToAPI<SupplierAPIDTO, SupplierAPI>;
}

export class SupplierMapper implements Mapper {
  public toAPI: Mapper["toAPI"] = (api) => {
    const extractDTOs = (data: SupplierAPIDTO["data"]): SupplierDTO[] => {
      if ((data as GetSuppliersResponse)?.content)
        return (data as GetSuppliersResponse)?.content;
      if (data as GetSupplierResponse) return [data as GetSupplierResponse];
      return [];
    };

    return {
      data: {
        suppliers: extractDTOs(api?.data),
        count: (api?.data as GetSuppliersResponse)?.totalElements ?? 0,
      },
      isLoading: (api?.isLoading || api?.isFetching) ?? false,
      error: api?.error ?? undefined,
    };
  };
}
