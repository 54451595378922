import { FC, useEffect, useMemo } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Label } from "flowbite-react";
import { format, parse } from "date-fns";
import { TextInput } from "components/input/TextInput";
import { LoadingButton } from "components/LoadingButton";
import { CustomerTypeAhead } from "components/invoice/form/CustomerTypeAhead";
import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "helpers/html-validation-schema";
import { HTMLValidationSchemaResolver } from "helpers/html-validation-schema/compat/HTMLValidationSchemaResolver";
import { Currency, toCurrency } from "helpers/to-currency";
import {
  Timesheet,
  UpdateTimesheetInput,
} from "hooks/timesheets/models/timesheets.interface";
import { secondsToHHmmss } from "helpers/to-hhmmss";
import { Product } from "hooks/products/models/product.interface";
import { Customer } from "hooks/customers/models/customer.interface";
import { hhmmssToSeconds } from "helpers/to-seconds";
import { ProductTypeAhead } from "./ProductTypeAhead";

interface TimesheetFormProps {
  timesheet: Timesheet;
  customers: Customer[];
  products: Product[];
  isLoading: boolean;
  onSubmit: (input: UpdateTimesheetInput) => void;
  schema:
    | HTMLValidationSchema<UpdateTimesheetInput>
    | HTMLValidationSchemaGenerator<UpdateTimesheetInput>;
}

export const TimesheetForm: FC<TimesheetFormProps> = function ({
  timesheet,
  customers,
  products,
  isLoading,
  onSubmit,
  schema,
}) {
  const { t } = useTranslation();

  const methods = useForm<UpdateTimesheetInput>({
    defaultValues: {
      ...timesheet,
      timeToBill: secondsToHHmmss(timesheet.timeToBill),
      customerId: timesheet?.customerId ?? undefined,
      date: timesheet?.date
        ? format(parse(timesheet?.date, "dd/MM/yyyy", new Date()), "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd"),
      startTime: timesheet?.startTime
        ? format(
            parse(timesheet.startTime, "dd/MM/yyyy HH:mm:ss", new Date()),
            "yyyy-MM-dd HH:mm"
          )
        : format(new Date(), "yyyy-MM-dd HH:mm"),
      endTime: timesheet?.endTime
        ? format(
            parse(timesheet.endTime, "dd/MM/yyyy HH:mm:ss", new Date()),
            "yyyy-MM-dd HH:mm"
          )
        : format(new Date(), "yyyy-MM-dd HH:mm"),
    },
    mode: "onBlur",
    resolver: HTMLValidationSchemaResolver(schema),
  });

  const [timeToBill, hourlyRate, totalPrice] = useWatch<UpdateTimesheetInput>({
    control: methods.control,
    name: ["timeToBill", "hourlyRate", "totalPrice"],
  });

  useEffect(() => {
    const seconds = hhmmssToSeconds(timeToBill as string);
    const value = Number(seconds / 3600) * Number(hourlyRate);
    if (Number.isNaN(value)) {
      methods.setValue("totalPrice", 0);
    } else {
      methods.setValue("totalPrice", value);
    }
  }, [methods, timeToBill, hourlyRate]);

  const selectedCustomerId = useWatch({
    control: methods.control,
    name: "customerId",
  });

  const selectedCustomer = useMemo(() => {
    return customers.find(({ id }) => id === selectedCustomerId);
  }, [customers, selectedCustomerId]);

  const selectedProductId = useWatch({
    control: methods.control,
    name: "articleId",
  });

  const selectedProduct = useMemo(() => {
    return products.find(({ id }) => id === selectedProductId);
  }, [products, selectedProductId]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4 p-6">
          <div className="bg-gray-50 rounded-lg p-4 flex flex-col gap-2 dark:bg-gray-900">
            <div className="flex gap-4">
              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label
                    htmlFor="customer"
                    value={t("timesheetForm.label.customer") ?? ""}
                  />
                </div>
                <CustomerTypeAhead
                  id="customer"
                  selectedCompany={selectedCustomer?.name}
                  updateBillingAddress={false}
                  customerKey="customer"
                />
              </div>
              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label
                    htmlFor="date"
                    value={t("timesheetForm.label.date") ?? ""}
                  />
                </div>
                <TextInput
                  {...methods.register("date")}
                  id="date"
                  type="date"
                  errorMessage={methods.formState.errors.date?.message}
                />
              </div>
            </div>
            <div className="flex gap-4 mt-2">
              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label
                    htmlFor="product"
                    value={t("timesheetForm.label.product") ?? ""}
                  />
                </div>
                <ProductTypeAhead
                  id="product"
                  selectedProduct={selectedProduct?.name}
                />
              </div>
              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label
                    htmlFor="description"
                    value={t("timesheetForm.label.description") ?? ""}
                  />
                </div>
                <TextInput
                  id="description"
                  type="text"
                  {...methods.register("description")}
                />
              </div>
            </div>
          </div>
          <div className="bg-gray-50 rounded-lg p-4 flex flex-col gap-2 dark:bg-gray-900">
            <div className="flex gap-4">
              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label
                    htmlFor="startTime"
                    value={t("timesheetForm.label.startTime") ?? ""}
                  />
                </div>
                <TextInput
                  {...methods.register("startTime")}
                  id="startTime"
                  type="datetime-local"
                  errorMessage={methods.formState.errors.startTime?.message}
                />
              </div>
              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label
                    htmlFor="endTime"
                    value={t("timesheetForm.label.endTime") ?? ""}
                  />
                </div>
                <TextInput
                  {...methods.register("endTime")}
                  id="endTime"
                  type="datetime-local"
                  errorMessage={methods.formState.errors.endTime?.message}
                />
              </div>
            </div>
            <div className="flex gap-4 mt-2">
              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label
                    htmlFor="timeToBill"
                    value={t("timesheetForm.label.timeToBill") ?? ""}
                  />
                </div>
                <TextInput
                  id="timeToBill"
                  {...methods.register("timeToBill", { valueAsNumber: false })}
                  errorMessage={methods.formState.errors.timeToBill?.message}
                />
              </div>
              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label
                    htmlFor="hourlyRate"
                    value={t("timesheetForm.label.hourlyRate") ?? ""}
                  />
                </div>
                <TextInput
                  id="hourlyRate"
                  addon="€"
                  type="number"
                  step="0.01"
                  {...methods.register("hourlyRate", { valueAsNumber: true })}
                  errorMessage={methods.formState.errors.hourlyRate?.message}
                />
              </div>
            </div>
          </div>
          <div className="flex dark:border-gray-700 pt-4 bg-gray-50 rounded-lg p-4 justify-end items-center dark:bg-gray-900 dark:text-white">
            <span className="text-sm text-gray-600 dark:text-gray-400">
              {t("timesheetForm.label.totalPrice")}:
            </span>
            <span className="font-bold pl-2">
              {toCurrency(Number(totalPrice ?? 0), Currency.Euro)}
            </span>
          </div>
        </div>
        <div className="border-1 border-t p-6 dark:border-gray-700 text-lg">
          <LoadingButton
            disabled={Boolean(timesheet?.salesInvoiceId)}
            loading={isLoading}
            className="px-2 py-0.5"
            type="submit"
          >
            {t("timesheetForm.button.save")}
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
};
