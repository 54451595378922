export enum JournalTypes {
  PURCHASE = "PURCHASE",
  SALES = "SALES",
}

export interface Journal {
  id: string;
  name: string;
  journalType: JournalTypes;
}
