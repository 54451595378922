import { PaginatedResponse } from "models/schema.interface";
import { ProductGroup } from "hooks/productGroups/models/productGroup.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export interface GetProductGroupsParams {
  offset: number;
  limit: number;
  search: string;
  sortOrder?: string;
  sortKey?: string;
  refetchInterval?: number;
}

export type GetProductGroupsResponse = PaginatedResponse<
  Omit<ProductGroup, "update">
>;

export const PRODUCT_GROUPS_QUERY = "productGroups";

export function useGetProductGroups(input?: GetProductGroupsParams) {
  const size = input?.limit ? Number(input.limit) : 100;
  const page = input?.offset ? input.offset / size : 0;

  return useApiQuery<GetProductGroupsResponse>(
    [
      PRODUCT_GROUPS_QUERY,
      input?.limit,
      input?.offset,
      input?.search,
      input?.sortKey,
      input?.sortOrder,
    ],
    `article-groups?${new URLSearchParams({
      page: String(page),
      size: String(size),
      search: input?.search ?? "",
      sort:
        input?.sortKey && input?.sortOrder
          ? `${input.sortKey},${input.sortOrder}`
          : "",
    })}`,
    {},
    {
      refetchInterval: input?.refetchInterval,
    }
  );
}
