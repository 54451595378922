import { FC } from "react";

interface Props {
  url: string;
  small?: boolean;
}

export const Logo: FC<Props> = function ({ url, small }) {
  return (
    <div className="my-6 flex items-center gap-x-1 lg:my-0">
      <img alt="logo" src={url} className={small ? "mr-3 h-9" : "mr-3 h-12"} />
    </div>
  );
};
