import { PaginatedResponse } from "models/schema.interface";
import { SupplierDTO } from "hooks/suppliers/models/supplier.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export interface GetSuppliersParams {
  offset: number;
  limit: number;
  search: string;
  sortKey?: string;
  sortOrder?: string;
  refetchInterval?: number;
}

export type GetSuppliersResponse = PaginatedResponse<SupplierDTO>;

export const SUPPLIERS_QUERY = "suppliers";

export function useGetSuppliers(input?: GetSuppliersParams) {
  const size = input?.limit ? Number(input.limit) : 10;
  const page = input?.offset ? input.offset / size : 0;

  return useApiQuery<GetSuppliersResponse>(
    [
      SUPPLIERS_QUERY,
      input?.limit,
      input?.offset,
      input?.search,
      input?.sortKey,
      input?.sortOrder,
    ],
    `suppliers?${new URLSearchParams({
      page: String(page),
      size: String(size),
      search: input?.search ?? "",
      sort:
        input?.sortKey && input?.sortOrder
          ? `${input.sortKey},${input.sortOrder}`
          : "",
    })}`,
    {},
    {
      refetchInterval: input?.refetchInterval,
    }
  );
}
