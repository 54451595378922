import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { LoadingButton } from "components/LoadingButton";
import { PurchaseInvoice } from "hooks/purchaseInvoices/models/purchaseInvoice.interface";

interface PayModalProps {
  onClose: () => void;
  onPay: () => void;
  purchaseInvoice?: PurchaseInvoice;
  isLoading?: boolean;
}

export const PayModal: FC<PayModalProps> = function ({
  onPay,
  onClose,
  isLoading,
  purchaseInvoice,
}: PayModalProps) {
  const { t } = useTranslation();

  return (
    <>
      <p className="text-base text-center leading-relaxed text-gray-400 dark:text-gray-400">
        {t("payModal.body.info")}
      </p>
      {purchaseInvoice?.paymentQRCode && (
        <img
          className="!mt-0 h-96"
          src={`data:image/png;base64,${purchaseInvoice.paymentQRCode}`}
          alt="paymentQR"
        />
      )}
      <div className="flex gap-4 justify-center">
        <LoadingButton onClick={onPay} loading={isLoading}>
          {t("payModal.footer.markAsPayed")}
        </LoadingButton>
        <Button color="gray" onClick={onClose}>
          {t("payModal.footer.cancel")}
        </Button>
      </div>
    </>
  );
};
