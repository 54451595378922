import { FC } from "react";
import { useTranslation } from "react-i18next";
import { UNPAID_INVOICES_PIE_CHART_COLORS } from "./UnpaidInvoices";

const labels = [
  "dashboardPage.unpaidInvoices.max30",
  "dashboardPage.unpaidInvoices.max60",
  "dashboardPage.unpaidInvoices.max90",
  "dashboardPage.unpaidInvoices.min91",
];

export const UnpaidInvoicesLabels: FC = function () {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center gap-2 2xl:gap-6">
      {labels.map((label, index) => {
        return (
          <div
            key={label}
            className="flex text-sm gap-1 2xl:gap-2 items-center dark:text-white shrink-0"
          >
            <div
              className="h-4 w-4"
              style={{
                backgroundColor: UNPAID_INVOICES_PIE_CHART_COLORS[index],
              }}
            />
            {t(label)}
          </div>
        );
      })}
    </div>
  );
};
