import { FC } from "react";
import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { TypeAheadOptionWrapper } from "./TypeAheadOptionWrapper";

interface TypeAheadCreateOptionProps {
  label: string;
  onCreateOption: () => void;
}

export const TypeAheadCreateOption: FC<TypeAheadCreateOptionProps> = function ({
  label,
  onCreateOption,
}) {
  const { t } = useTranslation();

  return (
    <TypeAheadOptionWrapper onSelectOption={onCreateOption} name={label}>
      <span className="flex gap-2 items-center text-gray-500 dark:text-gray-300">
        <HiPlus />
        {t(label)}
      </span>
    </TypeAheadOptionWrapper>
  );
};
