import { FC, useCallback, useMemo } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { Currency, toCurrency } from "helpers/to-currency";
import { formatLabel } from "components/charts/helpers/formatLabel";
import { useAxisLabelMeasurer } from "hooks/charts/useAxisLabelMeasurer";

import { LoadingLineChart } from "./LoadingLineChart";

import "../../../styles/Charts.scss";
import "./LineChart.scss";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<any>;
  dataKey: string;
  xAxisDataKey: string;

  loading?: boolean;
  height?: number;
  color?: string;
}

const colors = {
  line: "#1C64F2",
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomTooltip = function ({ active, payload }: any) {
  if (active && payload && payload.length) {
    return (
      <div className="px-3 py-2 bg-gray-900 text-white rounded-md shadow-md">
        <p className="label text-xs">{`${toCurrency(
          payload[0].value,
          Currency.Euro,
          0
        )}`}</p>
      </div>
    );
  }

  return null;
};

const LineChart: FC<Props> = function ({
  height = 275,
  color = colors.line,
  data = [],
  xAxisDataKey,
  dataKey,
  loading = false,
}: Props) {
  const { measureText } = useAxisLabelMeasurer(12);

  const yAxisWidth = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const measuredLabelWidths = data.map((value: any) =>
      measureText(formatLabel(value?.[dataKey]))
    );

    return Math.max(...measuredLabelWidths);
  }, [data, dataKey, measureText]);

  const formatLineChartLabel = useCallback((number: number) => {
    return formatLabel(number);
  }, []);

  if (loading) {
    return <LoadingLineChart />;
  }

  return (
    <div className="m-line-chart w-full">
      <ResponsiveContainer width="99%" height={height}>
        <AreaChart
          data={data}
          margin={{
            top: 20,
            bottom: 10,
            left: yAxisWidth - 30,
            right: 10,
          }}
        >
          <defs>
            <linearGradient id="colorAvg" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={color} stopOpacity={0.45} />
              <stop offset="95%" stopColor={color} stopOpacity={0.02} />
            </linearGradient>
          </defs>

          <CartesianGrid vertical={false} strokeDasharray="6 7" />

          <XAxis
            dataKey={xAxisDataKey}
            stroke="transparant"
            tick={{ dy: 15, fontSize: 12 }}
          />

          <YAxis
            stroke="transparant"
            tickLine={false}
            tick={{
              dx: -15,
              fontSize: 12,
            }}
            tickFormatter={formatLineChartLabel}
          />

          {data.length > 0 && (
            <Area
              type="monotone"
              dataKey={dataKey}
              fillOpacity={1}
              fill="url(#colorAvg)"
              stroke={color}
              strokeWidth={4}
              strokeLinejoin="round"
              strokeLinecap="round"
            />
          )}

          <Tooltip cursor={false} content={<CustomTooltip />} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export { LineChart };
