/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

import { event } from "event";
import { httpErrorCodeWithTranslations } from "helpers/api";
import { useAuth } from "hooks/useAuth";

export type SendSalesInvoicesToCustomerResponse = void;
export type SendSalesInvoicesToCustomerApiInput = {
  ids: string[];
};

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ndjsonStream = require("can-ndjson-stream");

export function useSendSalesInvoicesToCustomer() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { token } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const mutateAsync = async (input: SendSalesInvoicesToCustomerApiInput) => {
    setIsLoading(true);
    await fetch(
      `${process.env.REACT_APP_API}api/v1/sales-invoices/send-to-customer`,
      {
        method: "POST",
        headers: {
          Accept: "application/x-ndjson",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ids: input.ids,
        }),
      }
    )
      .then((response) => ndjsonStream(response.body)) // Assuming ndjsonStream is available
      .then((stream) => {
        const reader = stream.getReader();
        let read: any;
        reader.read().then(
          (read = (result: any) => {
            if (result.done) {
              event.emit("salesInvoiceSaved");
              return;
            }
            if (result?.value?.succeeded) {
              event.emit("mutationSucceeded", result?.value?.message);
            } else {
              event.emit("mutationFailed", new Error(result?.value?.message));
            }
            reader.read().then(read); // Read the next item in the stream
          })
        );
      })
      .catch((error) => {
        // For the time being: error messages are shown from the back-end (still in english).
        // They should be able to provide translated error messages since we
        // send the current language over the "Accept-Language" header
        // See: src/helpers/api.ts

        // They'll need to provide they want us
        // to translate messages on the front-end

        if (error?.response?.payload?.detail) {
          event.emit(
            "mutationFailed",
            new Error(error?.response?.payload?.detail)
          );

          return;
        }
        if (httpErrorCodeWithTranslations.includes(error.response.status)) {
          event.emit(
            "mutationFailed",
            new Error(t(`error.${error.response.status}`) as string)
          );

          return;
        }

        event.emit("mutationFailed", new Error(error.message));
      })
      .finally(() => {
        return queryClient.invalidateQueries(["salesInvoices"]);
      });
  };

  return { mutateAsync, isLoading };
}

export default useSendSalesInvoicesToCustomer;
