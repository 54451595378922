import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { LoadingButton } from "components/LoadingButton";

interface SendToAccountantModalProps {
  onClose: () => void;
  onSend: () => void;
  loading: boolean;
}

export const SendToAccountantModal: FC<SendToAccountantModalProps> = function ({
  onSend,
  onClose,
  loading,
}: SendToAccountantModalProps) {
  const { t } = useTranslation();

  return (
    <>
      <p className="text-base text-center leading-relaxed text-gray-400 dark:text-gray-400">
        {t("sendToAccountantModal.body.info")}
      </p>
      <div className="flex gap-4 justify-center">
        <LoadingButton loading={loading} onClick={onSend}>
          {t("sendToAccountantModal.footer.send")}
        </LoadingButton>
        <Button color="gray" onClick={onClose}>
          {t("sendToAccountantModal.footer.back")}
        </Button>
      </div>
    </>
  );
};
