import { RadioButton } from "./RadioButton";

export interface RadioButtonOption {
  label: string;
  value: string;
  disabled?: boolean;
}

type RadioButtonGroupProps = {
  options: RadioButtonOption[];
  name: string;
  selectedValue: string;
  onChange: (value: string) => void;
};

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = function ({
  options,
  selectedValue,
  onChange,
}) {
  return (
    <div className="flex gap-2 flex-wrap">
      {options.map((option) => (
        <RadioButton
          {...option}
          key={option.value}
          selectedValue={selectedValue}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

export { RadioButtonGroup };
