import { DomainMutation } from "hooks/useDomainMutation";
import { WithId } from "models/core/utilities.interface";

export interface ContactDTO {
  name: string;
  languageCode: string;
}

export interface CreateContactInput extends ContactDTO {
  id?: never;
}

export interface UpdateContactInput extends Partial<ContactDTO> {
  id: string;
}

export interface DeleteContactInput {
  id: string;
}

export type Contact = WithId<ContactDTO> & {
  update: DomainMutation<UpdateContactInput>;
  remove: DomainMutation<DeleteContactInput>;
};

export const createContactSchema = {
  name: { required: true },
  languageCode: { required: false },
};
