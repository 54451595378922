import { useQueryClient } from "react-query";

import { useApiMutation } from "hooks/api/useApiMutation";
import { httpErrorCodeWithTranslations } from "helpers/api";
import { useTranslation } from "react-i18next";
import { event } from "event";

export interface DeleteSuppliersParams {
  ids: string[];
}

export type DeleteSuppliersResponse = void;

export function useDeleteSuppliers() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useApiMutation<DeleteSuppliersResponse, DeleteSuppliersParams>(
    "deleteSupplier",
    (input: DeleteSuppliersParams) => {
      if (input.ids.length === 1) {
        return `suppliers/${input.ids[0]}`;
      }

      return "suppliers";
    },
    (input: DeleteSuppliersParams) => {
      return {
        method: "delete",
        ...(input.ids.length === 1
          ? {}
          : { body: JSON.stringify({ ids: input.ids }) }),
      };
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["suppliers"]),
      onError: (error) => {
        // For the time being: error messages are shown from the back-end (still in english).
        // They should be able to provide translated error messages since we
        // send the current language over the "Accept-Language" header
        // See: src/helpers/api.ts

        // They'll need to provide they want us
        // to translate messages on the front-end

        if (error?.response?.payload?.detail) {
          event.emit(
            "mutationFailed",
            new Error(error?.response?.payload?.detail)
          );

          return;
        }
        if (httpErrorCodeWithTranslations.includes(error.response.status)) {
          event.emit(
            "mutationFailed",
            new Error(t(`error.${error.response.status}`) as string)
          );

          return;
        }

        event.emit("mutationFailed", new Error(error.message));
      },
    }
  );
}
