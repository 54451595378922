import { useApiMutation } from "hooks/api/useApiMutation";

export interface RegisterBody {
  vatNumber: string;
  companyEmail: string;
  userFirstname: string;
  userLastname: string;
  userPassword: string;
  userEmail: string;
}

export type RegisterResponse = void;

export function useRegister() {
  return useApiMutation<RegisterResponse, RegisterBody>(
    "register",
    "register/company",
    (input) => ({
      body: JSON.stringify({
        vatNumber: input.vatNumber,
        companyEmail: input.companyEmail,
        userFirstname: input.userFirstname,
        userLastname: input.userLastname,
        userPassword: input.userPassword,
        userEmail: input.userEmail,
      }),
    })
  );
}
