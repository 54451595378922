import { Events } from "event";
import { UseCases } from "models/core";
import { useCallback, useEffect, useState } from "react";
import { useEvent } from "hooks/useEvent";
import { Notification } from "./models/notification.interface";

interface Result {
  notifications: Notification[];
  showSuccessAlert: Events["mutationSucceeded"];
  showErrorAlert:
    | Events["mutationFailed"]
    | Events["validationFailed"]
    | Events["queryFailed"];
  close: (index: number) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let timeout: any;

export const useNotificationsUseCases: UseCases<undefined, Result> = () => {
  const [notifications, setNotifications] = useState<Result["notifications"]>(
    []
  );

  const close: Result["close"] = useCallback(
    (index: number) => {
      if (timeout) clearTimeout(timeout);
      setNotifications(notifications.filter((n, i: number) => i !== index));
    },
    [notifications, setNotifications]
  );

  const showSuccessAlert: Result["showSuccessAlert"] = useCallback(
    (message) => {
      if (timeout) clearTimeout(timeout);
      setNotifications((not) => {
        if (not.length >= 3) {
          return [...not.slice(1), { message, type: "success" }];
        }
        return [...not, { message, type: "success" }];
      });
    },
    [setNotifications]
  );

  const showErrorAlert: Result["showErrorAlert"] = useCallback(
    (error) => {
      if (timeout) clearTimeout(timeout);
      setNotifications((not) => {
        if (not.length >= 3) {
          return [...not.slice(1), { message: error.message, type: "error" }];
        }
        return [...not, { message: error.message, type: "error" }];
      });
    },
    [setNotifications]
  );

  useEffect(() => {
    if (notifications?.length) {
      timeout = setTimeout(() => close(0), 5000);
    }
  }, [notifications, close]);

  return {
    notifications,
    showSuccessAlert,
    showErrorAlert,
    close,
  };
};

export const useNotificationsEffects = (
  notifications: ReturnType<typeof useNotificationsUseCases>
) => {
  useEvent("mutationSucceeded", notifications.showSuccessAlert);
  useEvent("mutationFailed", notifications.showErrorAlert);
  useEvent("validationFailed", notifications.showErrorAlert);
  useEvent("queryFailed", notifications.showErrorAlert);
};
