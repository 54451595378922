import { FC, useCallback, useMemo } from "react";
import classNames from "classnames";

import {
  CreateCustomerInput,
  CreateCustomerWithAddressInput,
  Customer,
  UpdateCustomerInput,
} from "hooks/customers/models/customer.interface";
import { DomainMutation } from "hooks/useDomainMutation";
import { removeProperties } from "helpers/remove-properties";
import AddressCard from "components/address/AddressCard";
import BankAccountCard from "components/bankAccount/BankAccountCard";
import ContactCard from "components/contact/ContactCard";
import GeneralInfoCard from "components/GeneralInfoCard";

import { CustomerForm, CustomerFormValues } from "./CustomerForm";
import { CustomerFormSkeleton } from "./CustomerFormSkeleton";

interface CustomerDetailProps {
  customer?: Customer;
  update?: DomainMutation<UpdateCustomerInput>;
  create?: DomainMutation<CreateCustomerInput | CreateCustomerWithAddressInput>;
  isLoading: boolean;
}

export const CustomerDetail: FC<CustomerDetailProps> = function ({
  customer,
  isLoading,
  update,
  create,
}) {
  const customerFormValues = useMemo(() => {
    if (!customer) {
      return undefined;
    }

    return removeProperties(customer, [
      "addAddress",
      "addBankAccount",
      "addContact",
      "update",
    ]);
  }, [customer]);

  const submitCreateForm = useCallback(
    (input: CreateCustomerInput) => {
      if (!create) {
        return;
      }

      create?.execute(input);
    },
    [create]
  );

  const submitUpdateForm = useCallback(
    (input: UpdateCustomerInput) => {
      if (!update) {
        return;
      }

      update?.execute(input);
    },
    [update]
  );

  return (
    <div
      className="m-4 grid grid-cols-1 xl:grid-cols-5 gap-4"
      data-testid="root"
    >
      <div className="col-span-1 xl:col-span-3 flex flex-col gap-4">
        <GeneralInfoCard className="justify-start">
          {isLoading && <CustomerFormSkeleton />}
          {!isLoading && customerFormValues && update && (
            <CustomerForm
              isEdit
              defaultValues={customerFormValues}
              isLoading={update.isLoading}
              schema={update.schema}
              onSubmit={submitUpdateForm as (input: CustomerFormValues) => void}
            />
          )}
          {!isLoading && !customerFormValues && create && (
            <CustomerForm
              isLoading={create.isLoading}
              schema={create.schema}
              onSubmit={submitCreateForm as (input: CustomerFormValues) => void}
              withAddress
            />
          )}
        </GeneralInfoCard>

        <AddressCard
          className={classNames("justify-start", {
            "opacity-40 pointer-events-none": !customer && !isLoading,
          })}
          addresses={customer?.addresses}
          createAddress={customer?.addAddress}
          isLoading={isLoading}
        />

        <ContactCard
          className={classNames({
            "opacity-40 pointer-events-none": !customer && !isLoading,
          })}
          contacts={customer?.contacts}
          createContact={customer?.addContact}
          isLoading={isLoading}
        />
      </div>

      <BankAccountCard
        className={classNames("justify-start h-fit col-span-1 xl:col-span-2", {
          "opacity-40 pointer-events-none": !customer && !isLoading,
        })}
        bankAccounts={customer?.bankAccounts}
        createBankAccount={customer?.addBankAccount}
        isLoading={isLoading}
      />
    </div>
  );
};
