import { FC, useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useProducts } from "domains";
import { useGetProductOptions } from "pages/products/queries/useGetProductOptions";
import { Product } from "hooks/products/models/product.interface";
import { TypeAhead } from "components/input/typeAhead/TypeAhead";
import { TypeAheadOption } from "components/input/typeAhead/TypeAheadOption";
import { TextInputProps } from "components/input/TextInput";

interface ProductTypeAheadProps extends TextInputProps {
  selectedProduct?: string;
  placeholder?: string;
  updateBillingAddress?: boolean;
}

export const ProductTypeAhead: FC<ProductTypeAheadProps> = function ({
  placeholder,
  selectedProduct,
  ...rest
}) {
  const { setValue, formState } = useFormContext();
  const [search, setSearch] = useState("");
  const getProducts = useGetProductOptions(0, true, search);
  const { refetch } = getProducts;
  const { products } = useProducts(getProducts);

  const onSelectOption = useCallback(
    (product: Product) => {
      setValue("description", product.reference);
      setValue("hourlyRate", product.amountExclusiveVat);
      setValue("articleId", product.id);

      return () => null;
    },
    [setValue]
  );

  useEffect(() => {
    refetch();
  }, [refetch, search]);

  return (
    <TypeAhead
      showDropdown
      showEmptyOption={products?.length === 0}
      emptyOption="productTypeAhead.noProducts"
      placeholder={placeholder}
      selectedValue={selectedProduct ?? ""}
      errorMessage={formState.errors.articleId?.message?.toString() ?? ""}
      refetch={setSearch}
      {...rest}
    >
      {products.map((product) => (
        <TypeAheadOption
          key={product.id}
          option={product}
          name={product.name}
          selectOption={onSelectOption}
        />
      ))}
    </TypeAhead>
  );
};
