import { ProductGroupDTO } from "hooks/productGroups/models/productGroup.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export interface GetProductGroupParams {
  id?: string;
}
export type GetProductGroupResponse = ProductGroupDTO;

export function useGetProductGroup({ id }: GetProductGroupParams) {
  return useApiQuery<GetProductGroupResponse>(
    ["productGroup", id],
    `article-groups/${id}`,
    {},
    { enabled: !!id }
  );
}
