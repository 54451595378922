import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  HiAdjustments,
  HiChartPie,
  HiClock,
  HiDocumentReport,
  HiDocumentText,
  HiInbox,
  HiShoppingCart,
  HiUserGroup,
  HiCash,
} from "react-icons/hi";
import { Outlet, useLocation } from "react-router";
import Sidebar from "components/sidebar/sidebar";
import { useRoutes } from "domains";
import { Link } from "react-router-dom";

const SidebarLayout: FC = function () {
  const { routes } = useRoutes();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <div className="flex items-start pt-16 h-full">
      <Sidebar>
        <Sidebar.ItemGroup>
          <Sidebar.Item
            as={Link}
            icon={HiChartPie}
            to={routes.dashboard}
            active={pathname === routes.dashboard}
          >
            {t("sidebar.link.dashboard")}
          </Sidebar.Item>
          <Sidebar.Item
            as={Link}
            icon={HiDocumentReport}
            to={routes.quotes}
            active={pathname === routes.quotes}
          >
            {t("sidebar.link.quotes")}
          </Sidebar.Item>
          <Sidebar.Collapse
            icon={HiDocumentText}
            label={t("sidebar.title.invoices") as string}
            open={pathname.includes(routes.invoices)}
          >
            <Sidebar.Item
              as={Link}
              to={routes.salesInvoices}
              active={pathname === routes.salesInvoices}
            >
              {t("sidebar.link.salesInvoice")}
            </Sidebar.Item>
            <Sidebar.Item
              as={Link}
              to={routes.purchaseInvoices}
              active={pathname === routes.purchaseInvoices}
            >
              {t("sidebar.link.purchaseInvoice")}
            </Sidebar.Item>
          </Sidebar.Collapse>
          <Sidebar.Collapse
            icon={HiShoppingCart}
            label={t("sidebar.title.products") as string}
            open={pathname.includes(routes.billing)}
          >
            <Sidebar.Item
              as={Link}
              to={routes.products}
              active={pathname === routes.products}
            >
              {t("sidebar.link.products")}
            </Sidebar.Item>
            <Sidebar.Item
              as={Link}
              to={routes.productGroups}
              active={pathname === routes.productGroups}
            >
              {t("sidebar.link.productGroups")}
            </Sidebar.Item>
          </Sidebar.Collapse>
          <Sidebar.Item
            as={Link}
            icon={HiUserGroup}
            to={routes.customers}
            active={pathname === routes.customers}
          >
            {t("sidebar.link.customers")}
          </Sidebar.Item>
          <Sidebar.Item
            as={Link}
            icon={HiInbox}
            to={routes.suppliers}
            active={pathname === routes.suppliers}
          >
            {t("sidebar.link.suppliers")}
          </Sidebar.Item>
          <Sidebar.Item
            as={Link}
            icon={HiClock}
            to={routes.timesheets}
            active={pathname === routes.timesheets}
          >
            {t("sidebar.link.timesheets")}
          </Sidebar.Item>
          <Sidebar.Item
            as={Link}
            icon={HiCash}
            to={routes.transactions}
            active={pathname === routes.transactions}
          >
            {t("sidebar.link.transactions")}
          </Sidebar.Item>
        </Sidebar.ItemGroup>
        <Sidebar.ItemGroup>
          <Sidebar.Collapse
            icon={HiAdjustments}
            label={t("sidebar.link.settings") as string}
            open={pathname.includes(routes.settings)}
          >
            <Sidebar.Item
              as={Link}
              to={routes.companySettings}
              active={pathname === routes.companySettings}
            >
              {t("sidebar.link.companySettings")}
            </Sidebar.Item>
            {/* <Sidebar.Item
              as={Link}
              to={routes.invoiceSettings}
              active={pathname === routes.invoiceSettings}
            >
              {t("sidebar.link.invoiceSettings")}
            </Sidebar.Item> */}
            <Sidebar.Item
              as={Link}
              to={routes.bankAccounts}
              active={pathname === routes.bankAccounts}
            >
              {t("sidebar.link.bankAccounts")}
            </Sidebar.Item>
            <Sidebar.Item
              as={Link}
              to={routes.subscription}
              active={pathname === routes.subscription}
            >
              {t("sidebar.link.subscription")}
            </Sidebar.Item>
          </Sidebar.Collapse>
        </Sidebar.ItemGroup>
      </Sidebar>
      <Outlet />
    </div>
  );
};

export default SidebarLayout;
