import { Table, TableCellProps } from "flowbite-react";
import { FC, PropsWithChildren } from "react";
import classNames from "classnames";

export const EmptyTableRow: FC<PropsWithChildren<TableCellProps>> = function ({
  children,
  ...rest
}) {
  return (
    <Table.Row>
      <Table.Cell
        {...rest}
        className={classNames("text-center", rest.className)}
      >
        {children}
      </Table.Cell>
    </Table.Row>
  );
};
