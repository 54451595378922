import { FC } from "react";
import { Outlet } from "react-router";
import classNames from "classnames";
import { useSidebarContext } from "context/SidebarContext";

const MainContentLayout: FC = function () {
  const { isOpenOnSmallScreens } = useSidebarContext();

  return (
    <main
      className={classNames(
        "relative w-full h-full overflow-y-auto bg-gray-50 dark:bg-gray-900 flex flex-col",
        {
          "lg:ml-64": !isOpenOnSmallScreens,
          "lg:ml-16": isOpenOnSmallScreens,
        }
      )}
    >
      <Outlet />
    </main>
  );
};

export default MainContentLayout;
