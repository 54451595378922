import { FC, HTMLProps } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { Dropdown } from "flowbite-react";

import { useRoutes } from "../domains";

import { Button } from "./Button";

const PAGE_SIZES: number[] = [10, 20, 50];

export interface PaginationProps extends HTMLProps<HTMLDivElement> {
  limit: number;
  total: number;
}

export const Pagination: FC<PaginationProps> = function ({
  limit,
  total,
  ...rest
}) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { setPaginationParams, setLimitParams } = useRoutes();
  const offset = Number(searchParams.get("offset")) || 0;

  const onPrevious = (): void =>
    setPaginationParams(Math.max(offset - limit, 0));

  const onNext = (): void => {
    setPaginationParams(Math.min(offset + limit, total));
  };

  const handleSetLimit = (limit: number): void => {
    setLimitParams(limit);
  };

  return (
    <div
      {...rest}
      className={classNames(
        "flex items-center justify-between bg-white border-t border-gray-200 p-4 text-gray-600 dark:text-gray-400 dark:bg-gray-800 dark:border-gray-700 z-20",
        rest.className
      )}
    >
      <span>
        {process.env.NODE_ENV !== "test" && (
          <Trans
            i18nKey="pagination.body.currentPage"
            values={{
              offset: offset + 1,
              limit: Math.min(limit + offset, total),
              total,
            }}
            components={{ bold: <strong /> }}
          />
        )}
        {process.env.NODE_ENV === "test" &&
          `${offset + 1}-${Math.min(limit + offset, total)}`}
      </span>
      <div className="flex gap-4 items-center">
        <div>
          <Dropdown label={t("pagination.body.showLimit", { limit })} inline>
            {PAGE_SIZES.map((ps) => (
              <Dropdown.Item
                key={`ps_${ps}`}
                onClick={() => handleSetLimit(ps)}
              >
                {ps}
              </Dropdown.Item>
            ))}
          </Dropdown>
        </div>
        <Button type="button" onClick={onPrevious} disabled={offset === 0}>
          <HiChevronLeft className="mr-2" />
          {t("pagination.button.previous")}
        </Button>
        <Button
          type="button"
          onClick={onNext}
          disabled={Math.min(offset + limit, total) === total}
        >
          {t("pagination.button.next")}
          <HiChevronRight className="ml-2" />
        </Button>
      </div>
    </div>
  );
};
