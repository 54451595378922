import { FC, MouseEvent, PropsWithChildren, useCallback } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { Dropdown } from "flowbite-react";

interface Props {
  className?: string;
  disabled?: boolean;
}

export const RoundedIconDropdown: FC<PropsWithChildren<Props>> = function ({
  children,
  className,
  disabled,
}) {
  const click = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => event.stopPropagation(),
    []
  );

  return (
    <div className="w-9 h-9 rounded-full overflow-hidden flex justify-center items-center">
      <Dropdown
        trigger="hover"
        placement="left"
        arrowIcon={false}
        floatingArrow
        label={<HiDotsHorizontal />}
        className={className}
        disabled={disabled}
        onClick={click}
        size="lg"
        color="primary"
      >
        <button type="button" onClick={click}>
          {children}
        </button>
      </Dropdown>
    </div>
  );
};
