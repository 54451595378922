import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HiOutlinePencilAlt, HiTrash } from "react-icons/hi";
import { Dropdown, Table } from "flowbite-react";

import { Address } from "models/address/address.interface";
import { DeleteModal } from "components/modals/DeleteModal";
import { EditModal } from "components/modals/EditModal";
import { EmptyTableRow } from "components/EmptyTableRow";
import { RoundedIconDropdown } from "components/table/RoundedIconDropdown";
import { Skeleton } from "components/skeletons/Skeleton";
import { SkeletonRow } from "components/skeletons/SkeletonRow";
import { SkeletonRows } from "components/skeletons/SkeletonRows";
import { useRoutes } from "domains";

import { ADDRESS_ROUTE_KEY } from "./AddressCard";
import { AddressForm, AddressFormValues } from "./AddressForm";

interface Props {
  rows: Address[];
  isLoading?: boolean;
}

interface AddressTableRowProps {
  address: Address;
}

type Params = "subtype" | "subtypeId" | "id" | "action";

const AddressTableRow: FC<AddressTableRowProps> = function ({ address }) {
  const { t } = useTranslation();
  const { closeEditDetailModal, openDetailEditModal, openDetailDeleteModal } =
    useRoutes();
  const { subtype, subtypeId, action } = useParams<Params>();

  const isEdit = useCallback(
    (action?: string, id?: string): boolean => action === "edit" && !!id,
    []
  );

  const onDelete = useCallback(() => {
    address.remove.execute({
      id: subtypeId || "",
    });
  }, [address, subtypeId]);

  const onOpenDeleteModal = useCallback(
    () => openDetailDeleteModal(ADDRESS_ROUTE_KEY, address.id),
    [openDetailDeleteModal, address]
  );

  const onOpenEditModal = useCallback(
    () => openDetailEditModal(ADDRESS_ROUTE_KEY, address.id),
    [openDetailEditModal, address]
  );

  return (
    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
      <Table.Cell>
        <div role="cell">
          <p
            className="truncate text-gray-900 dark:text-white font-medium"
            role="cell"
          >
            {address?.street} {address?.number}{" "}
            {address?.bus ? `/ ${address?.bus}` : ""}
          </p>
          <p className="text-gray-500 dark:text-gray-400" role="cell">
            {address?.zipCode} {address?.city}
            {address?.country ? `, ${t(`country.${address?.country}`)}` : ""}
          </p>
        </div>
      </Table.Cell>
      <Table.Cell>{t(`addressType.${address.type.toLowerCase()}`)}</Table.Cell>
      <Table.Cell className="float-right">
        <RoundedIconDropdown>
          <Dropdown.Item
            className="flex items-center py-3 rounded-t-lg"
            onClick={onOpenEditModal}
            role="button"
          >
            <HiOutlinePencilAlt className="mr-2" />
            <span>{t("addressTable.button.edit")}</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={onOpenDeleteModal}
            className="flex items-center py-3 rounded-b-lg"
            role="button"
          >
            <HiTrash className="mr-2 text-red-500" />
            <span className="text-red-500">
              {t("addressTable.button.delete")}
            </span>
          </Dropdown.Item>
        </RoundedIconDropdown>
      </Table.Cell>
      {action === "delete" &&
        subtype === ADDRESS_ROUTE_KEY &&
        subtypeId === address.id && (
          <DeleteModal
            isLoading={address.remove.isLoading}
            isError={!!address.remove.error}
            page="address"
            show={!!subtypeId}
            onDelete={onDelete}
            onClose={closeEditDetailModal}
          />
        )}
      {subtype === ADDRESS_ROUTE_KEY &&
        isEdit(action, subtypeId) &&
        subtypeId === address.id && (
          <EditModal
            action={action as "edit"}
            show
            isError={!!address.update.error}
            itemName=""
            page="address"
            onClose={closeEditDetailModal}
          >
            <AddressForm
              defaultValues={address}
              isLoading={address.update.isLoading}
              schema={address.update.schema}
              onSubmit={
                address.update.execute as (input: AddressFormValues) => void
              }
            />
          </EditModal>
        )}
    </Table.Row>
  );
};

const LoadingAddressRows: FC = function () {
  return (
    <SkeletonRows rows={1}>
      <SkeletonRow>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell className="flex justify-end">
          <Skeleton>
            <RoundedIconDropdown />
          </Skeleton>
        </Table.Cell>
      </SkeletonRow>
    </SkeletonRows>
  );
};

const AddressTable: FC<Props> = function ({ rows, isLoading }) {
  const { t } = useTranslation();

  return (
    <Table striped theme={{ root: { wrapper: "" } }}>
      <Table.Head>
        <Table.HeadCell>{t("addressTable.title.address")}</Table.HeadCell>
        <Table.HeadCell>{t("addressTable.title.type")}</Table.HeadCell>
        <Table.HeadCell />
      </Table.Head>
      <Table.Body className="divide-y">
        {isLoading && <LoadingAddressRows />}
        {!isLoading &&
          rows.map((address) => (
            <AddressTableRow key={address.id} address={address} />
          ))}
        {!isLoading && rows.length === 0 && (
          <EmptyTableRow colSpan={5}>
            {t("addressTable.title.empty")}
          </EmptyTableRow>
        )}
      </Table.Body>
    </Table>
  );
};

export default AddressTable;
