import { useRoutes } from "domains";
import { FC } from "react";
import { Navigate, Route, Routes } from "react-router";
import { useRoutesEffects } from "hooks/routes/useRoutesUseCases";
import SidebarLayout from "layouts/sidebar-layout";
import ProductsPage from "pages/products/products";
import SuppliersPage from "pages/suppliers/suppliers";
import NavbarLayout from "layouts/navbar-layout";
import MainContentLayout from "layouts/main-content-layout";
import CustomerPage from "pages/customer/customer";
import SalesInvoicePage from "pages/salesInvoice/sales-invoice";
import PurchaseInvoicesPage from "pages/purchaseInvoices/purchase-invoices";
import PurchaseInvoicePage from "pages/purchaseInvoice/purchase-invoice";
import SalesInvoicesPage from "pages/salesInvoices/sales-invoices";
import CustomersPage from "pages/customers/customers";
import ProductGroupsPage from "pages/productGroups/productGroups";
import QuotesPage from "pages/quotes/quotes";
import QuotePage from "pages/quote/quote";
import SupplierPage from "pages/supplier/supplier";
import TimesheetsPage from "pages/timesheets/timesheets";
import DashboardPage from "pages/dashboard/dashboard";
import SettingsPage from "pages/settings/settings";
import TransactionsPage from "pages/transactions/TransactionsPage";
import BankAccountsPage from "pages/bankAccounts/bankAccounts";
import SubscriptionPage from "pages/subscription/subscription";
import SignUpPage from "pages/authentication/SignUpPage";
// import InvoiceSettingsPage from "pages/invoiceSettings/InvoiceSettingsPage";
import { NotFoundBlock } from "./errors/404/NotFoundBlock";
import { Private } from "./route/Private";

interface ContentProps {
  isValidSubscription?: boolean;
}

const Content: FC<ContentProps> = function ({ isValidSubscription }) {
  const routesResult = useRoutes();
  const { routes } = routesResult;
  useRoutesEffects(routesResult);

  return (
    <Routes>
      <Route path={routes.signup} index element={<SignUpPage />} />
      <Route path={routes.index} element={<NavbarLayout />}>
        <Route path={routes.index} element={<SidebarLayout />}>
          <Route path={routes.index} element={<MainContentLayout />}>
            <Route
              path={routes.index}
              index
              element={
                <Private
                  isAuthorized={Boolean(isValidSubscription)}
                  redirectTo={routes.subscription}
                >
                  <Navigate to={routes.products} />
                </Private>
              }
            />
            <Route
              path={routes.dashboard}
              index
              element={
                <Private
                  isAuthorized={Boolean(isValidSubscription)}
                  redirectTo={routes.subscription}
                >
                  <DashboardPage />
                </Private>
              }
            />
            <Route path={routes.quotes}>
              <Route
                path={`${routes.createQuote}/:subAction?/:index?`}
                index
                element={
                  <Private
                    isAuthorized={Boolean(isValidSubscription)}
                    redirectTo={routes.subscription}
                  >
                    <QuotePage />
                  </Private>
                }
              />
              <Route
                path={`${routes.quote}/:subAction?/:index?`}
                index
                element={
                  <Private
                    isAuthorized={Boolean(isValidSubscription)}
                    redirectTo={routes.subscription}
                  >
                    <QuotePage />
                  </Private>
                }
              />
              <Route
                path={`${routes.quotes}/:action?`}
                index
                element={
                  <Private
                    isAuthorized={Boolean(isValidSubscription)}
                    redirectTo={routes.subscription}
                  >
                    <QuotesPage />
                  </Private>
                }
              />
            </Route>
            <Route
              path={routes.billing}
              index
              element={
                <Private
                  isAuthorized={Boolean(isValidSubscription)}
                  redirectTo={routes.subscription}
                >
                  <Navigate to={routes.products} />
                </Private>
              }
            />
            <Route
              path={`${routes.products}/:action?/:id?/:subAction?`}
              index
              element={
                <Private
                  isAuthorized={Boolean(isValidSubscription)}
                  redirectTo={routes.subscription}
                >
                  <ProductsPage />
                </Private>
              }
            />
            <Route
              path={`${routes.productGroups}/:action?/:id?`}
              index
              element={
                <Private
                  isAuthorized={Boolean(isValidSubscription)}
                  redirectTo={routes.subscription}
                >
                  <ProductGroupsPage />
                </Private>
              }
            />
            <Route path={routes.suppliers}>
              <Route
                path={`${routes.supplier}/:subtype?/:action?/:subtypeId?`}
                index
                element={
                  <Private
                    isAuthorized={Boolean(isValidSubscription)}
                    redirectTo={routes.subscription}
                  >
                    <SupplierPage />
                  </Private>
                }
              />
              <Route
                path={`${routes.suppliers}/:action?`}
                index
                element={
                  <Private
                    isAuthorized={Boolean(isValidSubscription)}
                    redirectTo={routes.subscription}
                  >
                    <SuppliersPage />
                  </Private>
                }
              />
            </Route>
            <Route path={routes.customers}>
              <Route
                path={`${routes.customer}/:subtype?/:action?/:subtypeId?`}
                index
                element={
                  <Private
                    isAuthorized={Boolean(isValidSubscription)}
                    redirectTo={routes.subscription}
                  >
                    <CustomerPage />
                  </Private>
                }
              />
              <Route
                path={`${routes.customers}/:action?`}
                index
                element={
                  <Private
                    isAuthorized={Boolean(isValidSubscription)}
                    redirectTo={routes.subscription}
                  >
                    <CustomersPage />
                  </Private>
                }
              />
            </Route>
            <Route
              path={routes.invoices}
              index
              element={
                <Private
                  isAuthorized={Boolean(isValidSubscription)}
                  redirectTo={routes.subscription}
                >
                  <Navigate to={routes.salesInvoices} />
                </Private>
              }
            />
            <Route path={routes.purchaseInvoices}>
              <Route
                path={`${routes.purchaseInvoice}/:subAction?/:subtypeId?`}
                element={
                  <Private
                    isAuthorized={Boolean(isValidSubscription)}
                    redirectTo={routes.subscription}
                  >
                    <PurchaseInvoicePage />
                  </Private>
                }
              />
              <Route
                path={`${routes.purchaseInvoices}/:action?`}
                index
                element={
                  <Private
                    isAuthorized={Boolean(isValidSubscription)}
                    redirectTo={routes.subscription}
                  >
                    <PurchaseInvoicesPage />
                  </Private>
                }
              />
            </Route>
            <Route path={routes.salesInvoices}>
              <Route
                path={`${routes.createSalesInvoice}/:subAction?/:index?`}
                element={
                  <Private
                    isAuthorized={Boolean(isValidSubscription)}
                    redirectTo={routes.subscription}
                  >
                    <SalesInvoicePage />
                  </Private>
                }
              />
              <Route
                path={`${routes.salesInvoice}/:subAction?/:index?`}
                element={
                  <Private
                    isAuthorized={Boolean(isValidSubscription)}
                    redirectTo={routes.subscription}
                  >
                    <SalesInvoicePage />
                  </Private>
                }
              />
              <Route
                path={`${routes.salesInvoices}/:action?`}
                index
                element={
                  <Private
                    isAuthorized={Boolean(isValidSubscription)}
                    redirectTo={routes.subscription}
                  >
                    <SalesInvoicesPage />
                  </Private>
                }
              />
            </Route>
            <Route
              path={`${routes.timesheets}/:action?/:id?`}
              index
              element={
                <Private
                  isAuthorized={Boolean(isValidSubscription)}
                  redirectTo={routes.subscription}
                >
                  <TimesheetsPage />
                </Private>
              }
            />
            <Route
              path={`${routes.transactions}/:action?/:id?/:subAction?`}
              index
              element={
                <Private
                  isAuthorized={Boolean(isValidSubscription)}
                  redirectTo={routes.subscription}
                >
                  <TransactionsPage />
                </Private>
              }
            />
            <Route
              path={`${routes.companySettings}/:action?`}
              index
              element={
                <Private
                  isAuthorized={Boolean(isValidSubscription)}
                  redirectTo={routes.subscription}
                >
                  <SettingsPage />
                </Private>
              }
            />
            <Route
              path={`${routes.bankAccounts}/:action?`}
              index
              element={
                <Private
                  isAuthorized={Boolean(isValidSubscription)}
                  redirectTo={routes.subscription}
                >
                  <BankAccountsPage />
                </Private>
              }
            />
            {/* <Route
              path={`${routes.invoiceSettings}/:action?`}
              index
              element={<InvoiceSettingsPage />}
            /> */}
            <Route
              path={`${routes.subscription}/:action?`}
              index
              element={<SubscriptionPage />}
            />
            <Route
              path={`${routes.stripCallback}?`}
              index
              element={<SubscriptionPage activation />}
            />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFoundBlock href={routes.index} />} />
    </Routes>
  );
};

export default Content;
