import { useMemo, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HiCash } from "react-icons/hi";

import { useRoutes, useTables, useTransactions } from "domains";

import { useGetUserBanks } from "pages/bankAccounts/queries/usetGetUserBanks";
import { ActionBar, Breadcrumb, PageHeader } from "components/PageHeader";
import { AUTO_REFRESH_INTERVAL_KEY } from "components/AutoRefresh";
import { useTablesEffects } from "hooks/tables/useTablesUseCases";
import { useGetTransactions } from "./queries/useGetTransactions";
import TransactionsTable from "./components/TransactionsTable";

function TransactionsPage() {
  const { t } = useTranslation();
  const { routes, setSearchParam } = useRoutes();

  const [searchParams] = useSearchParams();
  const iban = searchParams.get("iban");

  const [refetchInterval, setRefetchInterval] = useState<number>(
    parseInt(localStorage.getItem(AUTO_REFRESH_INTERVAL_KEY) ?? "", 10)
  );

  const transactionsTable = useTables();
  const { rowSelectedState, handleAllRowsClicked, handleOneRowClicked } =
    transactionsTable;
  useTablesEffects(transactionsTable);

  const { data, isLoading: isLoadingUserBanks } = useGetUserBanks();

  const getTransactions = useGetTransactions({
    limit: Number(searchParams.get("limit")) || 10,
    offset: Number(searchParams.get("offset")) || 0,
    search: searchParams.get("search") || "",
    sortKey: searchParams.get("sortKey") || "",
    sortOrder: searchParams.get("sortOrder") || "",
    iban: searchParams.get("iban") || "",
    refetchInterval,
  });

  const { count, isLoading, transactions } = useTransactions(getTransactions);

  const ibanOptions = useMemo(
    () =>
      data?.bankAccounts?.map((bankAccount) => ({
        value: bankAccount.iban,
        key: bankAccount.iban,
      })),
    [data]
  );

  useEffect(() => {
    if (!iban && ibanOptions?.length && !isLoadingUserBanks) {
      setSearchParam("iban", ibanOptions[0].value);
    }
  }, [setSearchParam, iban, ibanOptions, isLoadingUserBanks]);

  const breadcrumbs = useMemo(() => {
    const crumbs: Breadcrumb[] = [
      {
        href: routes.transactions,
        label: t("sidebar.title.transactions") as string,
        icon: HiCash,
      },
    ];
    return crumbs;
  }, [routes, t]);

  return (
    <div className="flex flex-col">
      <PageHeader
        className="bg-white dark:bg-gray-800"
        title={t("pageHeader.title.transactions")}
        breadcrumbs={breadcrumbs}
      >
        <ActionBar
          hideSearch
          showCreateButton={false}
          pageKey="transactions"
          onRefetch={getTransactions.refetch}
          onRefetchIntervalChange={setRefetchInterval}
          rowSelectedState={rowSelectedState}
          dropdownKey="iban"
          dropdownOptions={ibanOptions}
        />
      </PageHeader>
      <TransactionsTable
        rows={transactions}
        limit={Number(searchParams.get("limit")) || 10}
        isLoading={isLoading || isLoadingUserBanks}
        total={count}
        handleAllRowsClicked={handleAllRowsClicked}
        handleOneRowClicked={handleOneRowClicked}
        rowSelectedState={rowSelectedState}
      />
    </div>
  );
}

export default TransactionsPage;
