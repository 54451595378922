import {
  PublicClientApplication,
  Configuration,
  LogLevel,
  BrowserCacheLocation,
  EventType,
  ProtocolMode,
  AuthenticationResult,
} from "@azure/msal-browser";
import { authority, clientId, redirectUri } from "components/msal/config";
import { useAuth } from "hooks/useAuth";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId,
    authority,
    knownAuthorities: [authority],
    protocolMode: ProtocolMode.OIDC,
    redirectUri,
    postLogoutRedirectUri: redirectUri,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false,
  },
  system: {
    allowRedirectInIframe: true, // make sure it's enabled so the monitorSession (logout on all apps) works.
    loggerOptions: {
      piiLoggingEnabled: false,
      loggerCallback: (level: LogLevel, message: string) => {
        switch (level) {
          case LogLevel.Error:
            // eslint-disable-next-line no-console
            console.error(message);
            return;
          case LogLevel.Info:
            // eslint-disable-next-line no-console
            console.debug(message);
            return;
          case LogLevel.Verbose:
            // eslint-disable-next-line no-console
            console.debug(message);
            return;
          case LogLevel.Warning:
            // eslint-disable-next-line no-console
            console.warn(message);
            return;
          default:
            // eslint-disable-next-line no-console
            console.debug(message);
        }
      },
    },
  },
};

export const createMsalInstance = (
  config: Configuration
): PublicClientApplication => {
  const msalInstance = new PublicClientApplication(config);

  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    const { eventType, payload } = event;
    if (
      eventType === EventType.SSO_SILENT_SUCCESS ||
      eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      eventType === EventType.LOGIN_SUCCESS
    ) {
      const { login } = useAuth.getState();

      const authenticationResult = payload as AuthenticationResult;
      if (authenticationResult) {
        login(authenticationResult.accessToken);
        msalInstance.setActiveAccount(authenticationResult.account);
      }
    } else if (eventType === EventType.ACCOUNT_REMOVED) {
      // perhaps we can execute the logoutRedirect if issues come up, for now not necessary
    }
  });

  return msalInstance;
};
