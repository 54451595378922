import { useCallback, useEffect, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { HiDocumentText } from "react-icons/hi";
import { useRoutes, useSalesInvoices, useTables } from "domains";
import { useTablesEffects } from "hooks/tables/useTablesUseCases";
import { JournalContext } from "context/JournalContext";
import { ActionBar, PageHeader } from "components/PageHeader";
import { DeleteModal } from "components/modals/DeleteModal";
import { AUTO_REFRESH_INTERVAL_KEY } from "components/AutoRefresh";
import { UploadCSV } from "components/UploadCSV";
import { JournalTypes } from "hooks/journals/models/journal.interface";
import { InvoicePayStatus } from "models/paymentDocument/invoice/invoice.interface";
import { NoHeaderModal } from "components/modals/NoHeaderModal";
import { Button } from "components/Button";
import { useGetSalesInvoices } from "./queries/useGetSalesInvoices";
import SalesInvoiceTable from "./components/salesInvoiceTable";
import {
  SALES_INVOICES_XSLX_KEY,
  useGetSalesInvoicesXLSX,
} from "./queries/useGetSalesInvoicesXLSX";
import { SendToCustomerModal } from "./components/sendToCustomerModal";
import { SendToAccountantModal } from "./components/sendToAccountantModal";
import { RemindModal } from "./components/RemindModal";

function SalesInvoicesPage() {
  const [searchParams] = useSearchParams();
  const [idsToDelete, setIdsToDelete] = useState<string[]>([]);
  const [refetchInterval, setRefetchInterval] = useState<number>(
    parseInt(localStorage.getItem(AUTO_REFRESH_INTERVAL_KEY) ?? "", 10)
  );
  const salesInvoicesTable = useTables();
  const {
    routes,
    goToSalesInvoices,
    goToSalesInvoicesSendToAccountant,
    goToSalesInvoicesSendToCustomer,
  } = useRoutes();
  const { t } = useTranslation();
  const { rowSelectedState, handleAllRowsClicked, handleOneRowClicked } =
    salesInvoicesTable;
  useTablesEffects(salesInvoicesTable);

  const { journal } = useContext(JournalContext);
  const journalId = journal[JournalTypes.SALES];

  const getSalesInvoices = useGetSalesInvoices({
    limit: Number(searchParams.get("limit")) || 10,
    offset: Number(searchParams.get("offset")) || 0,
    search: searchParams.get("search") || "",
    invoicePayStatus:
      (searchParams.get("invoicePayStatus") as InvoicePayStatus) || "",
    sortKey: searchParams.get("sortKey") || "",
    sortOrder: searchParams.get("sortOrder") || "",
    from: searchParams.get("from") || "",
    to: searchParams.get("to") || "",
    journalId,
    refetchInterval,
  });

  const getXLSX = useGetSalesInvoicesXLSX({
    search: searchParams.get("search") || "",
    sortKey: searchParams.get("sortKey") || "",
    sortOrder: searchParams.get("sortOrder") || "",
  });

  const {
    salesInvoices,
    count,
    deleteMany,
    isLoading,
    error,
    uploadCSV,
    sendToAccountant,
    sendToCustomer,
  } = useSalesInvoices(getSalesInvoices);

  const { action } = useParams();

  const onDeleteSalesInvoice = useCallback(
    () => deleteMany.execute({ ids: idsToDelete }),
    [idsToDelete, deleteMany]
  );

  const onSendToCustomer = useCallback(
    () => sendToCustomer.execute({ ids: rowSelectedState?.ids }),
    [rowSelectedState, sendToCustomer]
  );

  const onSendToAccountant = useCallback(
    () => sendToAccountant.execute({ ids: rowSelectedState?.ids }),
    [rowSelectedState, sendToAccountant]
  );

  useEffect(() => {
    if (action === "delete") {
      const ids = searchParams.getAll("idsToDelete");
      setIdsToDelete(ids);
    }
  }, [action, setIdsToDelete, searchParams]);

  return (
    <div className="flex flex-col">
      <PageHeader
        className="bg-white dark:bg-gray-800"
        title={t("pageHeader.title.salesInvoices")}
        breadcrumbs={[
          {
            href: routes.invoices,
            label: t("sidebar.title.invoices"),
            icon: HiDocumentText,
          },
          {
            href: routes.salesInvoices,
            label: t("sidebar.link.salesInvoices"),
          },
        ]}
      >
        <ActionBar
          showBulkDelete
          pageKey="salesInvoices"
          rowSelectedState={rowSelectedState}
          onRefetch={getSalesInvoices.refetch}
          onRefetchIntervalChange={setRefetchInterval}
          dropdownKey="invoicePayStatus"
          dropdownOptions={Object.values(InvoicePayStatus)
            .filter((f) => f !== InvoicePayStatus.Processing)
            .map((value) => ({
              key: value,
              value: t(`salesInvoicesPage.dropdown.invoicePayStatus.${value}`),
            }))}
          showTimeFilter
          queryKey={SALES_INVOICES_XSLX_KEY}
          actions={
            <div className="flex gap-4">
              <Button
                onClick={goToSalesInvoicesSendToCustomer}
                type="button"
                className="!p-0 border-0"
              >
                Send to customer
              </Button>
              <Button
                onClick={goToSalesInvoicesSendToAccountant}
                type="button"
                className="!p-0 border-0"
              >
                Send to accountant
              </Button>
            </div>
          }
          {...getXLSX}
        />
      </PageHeader>
      <SalesInvoiceTable
        rows={salesInvoices}
        isLoading={isLoading}
        limit={Number(searchParams.get("limit")) || 10}
        total={count}
        handleAllRowsClicked={handleAllRowsClicked}
        handleOneRowClicked={handleOneRowClicked}
        rowSelectedState={rowSelectedState}
        filteredOnStatus={
          searchParams.get("invoicePayStatus") as InvoicePayStatus
        }
      />
      {action === "delete" && !!idsToDelete && (
        <DeleteModal
          isLoading={isLoading}
          isDeleting={deleteMany.isLoading}
          isError={!!error}
          isMultiple={idsToDelete.length > 1}
          page="salesInvoices"
          show={!!idsToDelete}
          onClose={goToSalesInvoices}
          onDelete={onDeleteSalesInvoice}
        />
      )}
      <UploadCSV
        action={action}
        uploadCSV={uploadCSV}
        onClose={goToSalesInvoices}
        page="salesInvoices"
      />
      <NoHeaderModal
        show={action === "send-to-customer"}
        onClose={goToSalesInvoices}
        size="md"
      >
        <SendToCustomerModal
          loading={sendToCustomer.isLoading}
          onClose={goToSalesInvoices}
          onSend={onSendToCustomer}
        />
      </NoHeaderModal>
      <NoHeaderModal
        show={action === "send-to-accountant"}
        onClose={goToSalesInvoices}
        size="md"
      >
        <SendToAccountantModal
          loading={sendToAccountant.isLoading}
          onClose={goToSalesInvoices}
          onSend={onSendToAccountant}
        />
      </NoHeaderModal>
      <NoHeaderModal
        show={action === "remind"}
        onClose={goToSalesInvoices}
        size="md"
      >
        <RemindModal ids={rowSelectedState?.ids} onClose={goToSalesInvoices} />
      </NoHeaderModal>
    </div>
  );
}

export default SalesInvoicesPage;
