import { FC, useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useProductGroups, useRoutes } from "domains";
import { TypeAhead } from "components/input/typeAhead/TypeAhead";
import { TextInputProps } from "components/input/TextInput";
import { TypeAheadOption } from "components/input/typeAhead/TypeAheadOption";
import { ProductGroup } from "hooks/productGroups/models/productGroup.interface";
import { useGetProductGroupOptions } from "pages/productGroups/queries/useGetProductGroupOptions";
import { ProductFormValues } from "./ProductForm";
import { CREATE_PRODUCT_GROUP_PARAM } from "../products";

interface ProductGroupTypeAheadProps extends TextInputProps {
  placeholder?: string;
  selectedProductGroup?: string;
}

export const ProductGroupTypeAhead: FC<ProductGroupTypeAheadProps> = function ({
  placeholder,
  selectedProductGroup,
  ...rest
}) {
  const { setValue, formState, clearErrors } =
    useFormContext<ProductFormValues>();
  const [search, setSearch] = useState("");
  const getProductGroups = useGetProductGroupOptions(search);
  const { refetch } = getProductGroups;
  const { productGroups } = useProductGroups(getProductGroups);
  const { openDifferentEntityModal } = useRoutes();

  const onSelectOption = useCallback(
    (articleGroup: ProductGroup) => {
      setValue("articleGroupId", articleGroup.id);
      clearErrors("articleGroupId");

      return () => null;
    },
    [setValue, clearErrors]
  );

  const createOption = useCallback(
    () => openDifferentEntityModal(CREATE_PRODUCT_GROUP_PARAM, search),
    [openDifferentEntityModal, search]
  );

  useEffect(() => {
    refetch();
  }, [refetch, search]);

  return (
    <TypeAhead
      showDropdown
      onCreateOption={createOption}
      placeholder={placeholder}
      selectedValue={selectedProductGroup ?? ""}
      errorMessage={formState.errors.articleGroupId?.message?.toString() ?? ""}
      refetch={setSearch}
      {...rest}
    >
      {productGroups.map((productGroup) => (
        <TypeAheadOption
          key={productGroup.id}
          option={productGroup}
          name={productGroup.name}
          selectOption={onSelectOption}
        />
      ))}
    </TypeAhead>
  );
};
