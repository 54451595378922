import { useTranslation } from "react-i18next";
import axios from "axios";
import { useQueryClient, useMutation } from "react-query";
import { useAuth } from "hooks/useAuth";
import { event } from "event";
import { useRoutes } from "domains";

export interface UploadCompanyLogoInput {
  formData?: FormData;
}

export type UploadCompanyLogoResponse = void;

export function useUploadCompanyLogo() {
  const queryClient = useQueryClient();
  const { token } = useAuth();
  const { t } = useTranslation();
  const { goToCompanySettings } = useRoutes();

  return useMutation<UploadCompanyLogoResponse, Error, UploadCompanyLogoInput>(
    "uploadCompanyLogo",
    async (input: UploadCompanyLogoInput) => {
      const response = await axios.post(
        `${process.env.REACT_APP_API}api/v1/company/logo/upload`,
        input.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response) {
        return response.data;
      }

      throw new Error("error");
    },
    {
      onSuccess: () => {
        event.emit("mutationSucceeded", t("domain.company.companyLogoSaved"));
        goToCompanySettings();
        return queryClient.invalidateQueries(["file"]);
      },
      onError: (error) => {
        event.emit("mutationFailed", new Error(error.message));
      },
    }
  );
}
