import { FC } from "react";
import { Table } from "flowbite-react";
import { useTranslation } from "react-i18next";

import { Checkbox } from "components/Checkbox";
import { SortableHeadCell } from "components/table/SortableHeadCell";

interface SuppliersTableHeadProps {
  allSelected: boolean;
  onSelectAll: () => void;
}

export const SuppliersTableHead: FC<SuppliersTableHeadProps> = function ({
  allSelected,
  onSelectAll,
}) {
  const { t } = useTranslation();

  return (
    <Table.Head className="!bg-gray-100 dark:!bg-gray-700">
      <Table.HeadCell className="w-4">
        <Checkbox
          aria-label="check-all"
          checked={allSelected}
          onChange={onSelectAll}
        />
      </Table.HeadCell>
      <SortableHeadCell sortKey="name">
        {t("suppliersTable.title.name")}
      </SortableHeadCell>
      <Table.HeadCell>{t("suppliersTable.title.address")}</Table.HeadCell>
      <Table.HeadCell>{t("suppliersTable.title.contacts")}</Table.HeadCell>
      <Table.HeadCell className="w-24" />
    </Table.Head>
  );
};
