import { useTranslation } from "react-i18next";
import { useApiMutation } from "hooks/api/useApiMutation";
import { event } from "event";
import { httpErrorCodeWithTranslations } from "helpers/api";
import { useQueryClient } from "react-query";
import { useRoutes } from "domains";

export type FinalizeConnectionResponse = boolean;
export type FinalizeConnectionBody = {
  connectorId: string;
  params: string;
  flowContext: string;
};

export function useFinalizeConnection() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { goToBankAccounts } = useRoutes();

  return useApiMutation<FinalizeConnectionResponse, FinalizeConnectionBody>(
    "finalizeConnection",
    (input) =>
      `banks/access/redirect?connector_id=${
        input.connectorId
      }&params=${encodeURIComponent(input.params)}&flow_context=${
        input.flowContext
      }`,
    () => ({
      method: "post",
    }),
    {
      onSuccess: () => {
        event.emit("mutationSucceeded", t("connections.feedback.completed"));
        localStorage.removeItem("ongoingBankConnection");
        goToBankAccounts();
        return Promise.all([queryClient.invalidateQueries(["userBanks"])]);
      },
      onError: (error) => {
        // For the time being: error messages are shown from the back-end (still in english).
        // They should be able to provide translated error messages since we
        // send the current language over the "Accept-Language" header
        // See: src/helpers/api.ts

        // They'll need to provide they want us
        // to translate messages on the front-end

        localStorage.removeItem("ongoingBankConnection");

        if (error?.response?.payload?.detail) {
          event.emit(
            "mutationFailed",
            new Error(error?.response?.payload?.detail)
          );
          goToBankAccounts();

          return;
        }
        if (httpErrorCodeWithTranslations.includes(error.response.status)) {
          event.emit(
            "mutationFailed",
            new Error(t(`error.${error.response.status}`) as string)
          );
          goToBankAccounts();

          return;
        }

        event.emit("mutationFailed", new Error(error.message));
        goToBankAccounts();
      },
    },
    async (response) => {
      return response.json();
    }
  );
}
