import { FC, useCallback } from "react";
import { Table } from "flowbite-react";
import { useTranslation } from "react-i18next";

import { EmptyTableRow } from "components/EmptyTableRow";
import { Pagination, PaginationProps } from "components/Pagination";
import { RowSelectedState } from "hooks/tables/models/tables.interface";
import { Supplier } from "hooks/suppliers/models/supplier.interface";

import { LoadingSupplierRow } from "./LoadingSupplierRow";
import { SuppliersTableHead } from "./SuppliersTableHead";
import { SuppliersTableRow } from "./SuppliersTableRow";

export interface SuppliersTableProps extends Omit<PaginationProps, "rows"> {
  rows: Supplier[];
  isLoading?: boolean;
  handleAllRowsClicked: (ids: string[]) => void;
  handleOneRowClicked: (id: string) => void;
  rowSelectedState: RowSelectedState;
  onEdit: (id: string) => void;
}

export const SuppliersTable: FC<SuppliersTableProps> = function ({
  rows,
  isLoading,
  handleAllRowsClicked,
  handleOneRowClicked,
  rowSelectedState,
  onEdit,
  ...rest
}) {
  const { t } = useTranslation();

  const onAllClicked = useCallback(
    () => handleAllRowsClicked(rows.map((supplier) => supplier.id)),
    [handleAllRowsClicked, rows]
  );

  return (
    <div className="flex flex-col justify-between overflow-hidden">
      <Table
        hoverable
        className="divide-y divide-gray-200 dark:divide-gray-600 table-fixed"
      >
        <SuppliersTableHead
          onSelectAll={onAllClicked}
          allSelected={rowSelectedState.all}
        />
        <Table.Body className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
          {isLoading && <LoadingSupplierRow />}
          {!isLoading &&
            rows.map((supplier) => (
              <SuppliersTableRow
                key={supplier.id}
                {...supplier}
                address={supplier.addresses[0]}
                contacts={supplier.contacts}
                selected={rowSelectedState.ids.includes(supplier.id)}
                onSelect={handleOneRowClicked}
                onEdit={onEdit}
              />
            ))}
          {!isLoading && rows.length === 0 && (
            <EmptyTableRow colSpan={5}>
              {t("suppliersTable.title.empty")}
            </EmptyTableRow>
          )}
        </Table.Body>
      </Table>
      <Pagination {...rest} />
    </div>
  );
};
