import { Currency, toCurrency } from "helpers/to-currency";
import { FC } from "react";

interface PieChartTooltipProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
  active?: boolean;
  isCurrency?: boolean;
}

export const PieChartTooltip: FC<PieChartTooltipProps> = function ({
  active,
  payload,
  isCurrency,
}) {
  if (active && payload && payload.length) {
    return (
      <div className="bg-gray-900 py-2 px-2.5 rounded-md flex gap-2 items-center">
        <div
          className="h-2 w-2 rounded-full"
          style={{ backgroundColor: payload[0].payload.fill }}
        />
        <p className="label text-xs text-white">
          {isCurrency
            ? toCurrency(payload[0].value, Currency.Euro)
            : payload[0].value}
        </p>
      </div>
    );
  }

  return null;
};
