import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "components/skeletons/Skeleton";
import { useGetConnectableBanks } from "../queries/useGetConnectableBanks";
import { ConnectableBankCard } from "./ConnectableBankCard";

const LOADING_ITEMS = Array.from(Array(10).keys());

interface Props {
  noUserYet?: boolean;
  notAcceptedLatestTerms?: boolean;
}

export const ConnectableBanks: FC<Props> = function ({
  notAcceptedLatestTerms,
  noUserYet,
}) {
  const { t } = useTranslation();

  const { data, isLoading } = useGetConnectableBanks();

  return (
    <div className="flex flex-col pt-8">
      <p className="text-2xl font-bold dark:text-white">
        {t("bankspage.connectableBanks.title")}
      </p>
      {isLoading && (
        <div className="grid pt-4 gap-6 sm:grid-cols-3 lg:grid-cols-5">
          {LOADING_ITEMS.map((loadingItem) => (
            <Skeleton
              key={String(loadingItem)}
              className="flex rounded-lg flex-col max-w-sm cursor-pointer w-full"
            >
              <div className="flex h-full flex-col justify-center gap-4 p-16">
                <h5 className="text-medium text-center font-medium text-gray-900 dark:text-white">
                  name
                </h5>
              </div>
            </Skeleton>
          ))}
        </div>
      )}
      {!isLoading && (
        <div className="grid pt-4 gap-6 sm:grid-cols-3 lg:grid-cols-5">
          {data?.map((cb) => (
            <ConnectableBankCard
              key={cb.connectorID}
              noUserYet={noUserYet}
              notAcceptedLatestTerms={notAcceptedLatestTerms}
              connectableBank={cb}
            />
          ))}
        </div>
      )}
    </div>
  );
};
