import { useApiQuery } from "hooks/api/useApiQuery";
import { XLSXResponse } from "models/xlsx/xlsx.interface";

export interface GetSalesInvoicesXLSXParams {
  search: string;
  sortOrder?: string;
  sortKey?: string;
}

export type GetSalesInvoicesXLSXResponse = XLSXResponse;

export const SALES_INVOICES_XSLX_KEY = "salesInvoicesXLSX";

export function useGetSalesInvoicesXLSX(input?: GetSalesInvoicesXLSXParams) {
  return useApiQuery<GetSalesInvoicesXLSXResponse>(
    [SALES_INVOICES_XSLX_KEY, input?.search, input?.sortKey, input?.sortOrder],
    `sales-invoices-xlsx?${new URLSearchParams({
      search: input?.search ?? "",
      sortKey: input?.sortKey ?? "",
      sortOrder: input?.sortOrder ?? "",
    })}`,
    {},
    {
      enabled: false,
    }
  );
}
