import { ButtonProps, Spinner } from "flowbite-react";
import { FC, PropsWithChildren } from "react";
import { Button } from "./Button";

interface Props extends ButtonProps {
  loading?: boolean;
}

export const LoadingButton: FC<PropsWithChildren<Props>> = function ({
  loading,
  children,
  disabled,
  ...rest
}) {
  return (
    <Button {...rest} disabled={disabled || loading}>
      {loading && <Spinner className="mr-4" size="sm" />}
      {children}
    </Button>
  );
};
