/* eslint-disable class-methods-use-this */
import { ToAPI } from "models/core";
import { ReactQueryAPI } from "models/core/reactQuery.interface";
import { GetPurchaseInvoiceResponse } from "pages/purchaseInvoice/queries/useGetPurchaseInvoice";
import { GetPurchaseInvoicesResponse } from "pages/purchaseInvoices/queries/useGetPurchaseInvoices";
import { PurchaseInvoiceAPI } from "./usePurchaseInvoicesUseCases";
import { PurchaseInvoice } from "./models/purchaseInvoice.interface";

export type PurchaseInvoiceAPIDTO = ReactQueryAPI<
  GetPurchaseInvoicesResponse | GetPurchaseInvoiceResponse
>;

interface Mapper {
  toAPI: ToAPI<PurchaseInvoiceAPIDTO, PurchaseInvoiceAPI>;
}

export class PurchaseInvoiceMapper implements Mapper {
  public toAPI: Mapper["toAPI"] = (api) => {
    const extractDTOs = (
      data: PurchaseInvoiceAPIDTO["data"]
    ): Omit<PurchaseInvoice, "update">[] => {
      if ((data as GetPurchaseInvoicesResponse)?.content)
        return (data as GetPurchaseInvoicesResponse)?.content;
      if (data as GetPurchaseInvoiceResponse)
        return [data as GetPurchaseInvoiceResponse];
      return [];
    };

    return {
      data: {
        purchaseInvoices: extractDTOs(api?.data),
        count: (api?.data as GetPurchaseInvoicesResponse)?.totalElements ?? 0,
      },
      isLoading: (api?.isLoading || api?.isFetching) ?? false,
      error: api?.error ?? undefined,
    };
  };
}
