import { PaginatedResponse } from "models/schema.interface";
import { useApiQuery } from "hooks/api/useApiQuery";
import { Transaction } from "hooks/transactions/models/transaction.interface";

export interface GetTransactionsParams {
  offset: number;
  limit: number;
  search: string;
  sortKey?: string;
  sortOrder?: string;
  refetchInterval?: number;
  iban?: string;
}

export type GetTransactionsResponse = PaginatedResponse<
  Omit<Transaction, "update">
>;
export type GetTransactionResponse = Omit<Transaction, "update">;

export const TRANSACTIONS_QUERY = "transactions";

export function useGetTransactions(input: GetTransactionsParams) {
  const size = Number(input.limit);
  const page = input.offset / size;
  return useApiQuery<GetTransactionsResponse>(
    [
      TRANSACTIONS_QUERY,
      input.offset,
      input.limit,
      input.search,
      input.sortKey,
      input.sortOrder,
    ],
    `banks/transactions?${new URLSearchParams({
      page: String(page),
      size: String(size),
      iban: input.iban ?? "",
    })}`,
    {},
    {
      refetchInterval: input.refetchInterval,
      enabled: Boolean(input.iban),
    }
  );
}
