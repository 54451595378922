import { FC } from "react";
import { Modal, Spinner } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { LoadingButton } from "components/LoadingButton";
import { Button } from "components/Button";

interface DeleteModalProps {
  isError: boolean;
  isLoading: boolean;
  isDeleting?: boolean;
  isMultiple?: boolean;
  page: string;
  show: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteModal: FC<DeleteModalProps> = function ({
  isError,
  isLoading,
  isDeleting,
  isMultiple,
  page,
  show,
  onClose,
  onDelete,
}) {
  const { t } = useTranslation();

  return (
    <Modal show={show} onClose={onClose} size="md">
      <Modal.Header />
      <Modal.Body>
        <div
          className="space-y-5 flex flex-col items-center"
          color="text-gray-500 dark:text-gray-400"
        >
          {isLoading && <Spinner />}
          {isError && (
            <>
              <p className="text-base leading-relaxed text-gray-400 dark:text-gray-400">
                {t(`deleteModal.error.${page}`)}
              </p>
              <Button color="gray" onClick={onClose}>
                {t("deleteModal.error.back")}
              </Button>
            </>
          )}
          {!isLoading && !isError && (
            <>
              <HiOutlineExclamationCircle size="35" className="text-gray-400" />
              <p className="text-base text-center leading-relaxed text-gray-400 dark:text-gray-400">
                {isMultiple
                  ? t(`deleteModal.body.multiple.${page}`)
                  : t(`deleteModal.body.${page}`)}
              </p>
              <div className="flex gap-4">
                <LoadingButton
                  color="failure"
                  onClick={onDelete}
                  loading={isDeleting}
                >
                  {t("deleteModal.footer.confirm")}
                </LoadingButton>
                <Button color="gray" onClick={onClose}>
                  {t("deleteModal.footer.back")}
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
