import { ProductDTO } from "hooks/products/models/product.interface";
import { PaginatedResponse } from "models/schema.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export interface GetProductsParams {
  offset: number;
  limit: number;
  search: string;
  articleGroupId: string;
  refetchInterval?: number;
  sortKey?: string;
  sortOrder?: string;
}

export type GetProductsResponse = PaginatedResponse<ProductDTO>;

export const PRODUCTS_QUERY = "products";

export function useGetProducts(input?: GetProductsParams) {
  const size = input?.limit ? Number(input.limit) : 200;
  const page = input?.offset ? input.offset / size : 0;

  return useApiQuery<GetProductsResponse>(
    [
      PRODUCTS_QUERY,
      input?.limit,
      input?.offset,
      input?.search,
      input?.articleGroupId,
      input?.sortKey,
      input?.sortOrder,
    ],
    `articles?${new URLSearchParams({
      page: String(page),
      size: String(size),
      search: input?.search ?? "",
      articleGroupId: input?.articleGroupId ?? "",
      sort:
        input?.sortKey && input?.sortOrder
          ? `${input.sortKey},${input.sortOrder}`
          : "",
    })}`,
    {},
    {
      refetchInterval: input?.refetchInterval,
    }
  );
}
