/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/label-has-associated-control */
import type { FC } from "react";
import { useAuthentication } from "domains";
import { LanguageSelector } from "components/sidebar/LanguageSelector";
import { RegisterForm } from "./components/RegisterForm";

const SignUpPage: FC = function () {
  const { registerSchema, register, isRegistering } = useAuthentication();

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="lg:flex">
        <div
          style={{ backgroundImage: `url(/images/authentication/about2.jpg)` }}
          className="hidden w-[90%] p-12 lg:h-screen lg:block bg-primary-600 bg-cover bg-no-repeat bg-center"
        />
        <div className="flex flex-col grid place-items-center py-12 w-full h-[100vh] overflow-scroll">
          <div className="flex items-center mx-auto md:w-[32rem] px-4 md:px-8 xl:px-0">
            <div className="w-full">
              <RegisterForm
                schema={registerSchema}
                onSubmit={register}
                isRegistering={isRegistering}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-0 right-0">
        <div className="flex items-center mr-8 mt-8">
          <LanguageSelector />
        </div>
      </div>
    </section>
  );
};

export default SignUpPage;
