import { useQueryClient } from "react-query";
import { useApiMutation } from "hooks/api/useApiMutation";
import { CreateBankUserInput } from "models/bankAccount/bankAccount.interface";

export type UpdateBankUserResponse = void;
export type UpdateBankUserBody = CreateBankUserInput;

export function useUpdateBankUser() {
  const queryClient = useQueryClient();

  return useApiMutation<UpdateBankUserResponse, CreateBankUserInput>(
    "updateBankUser",
    (input) => `banks/user?terms_value=${input.tc}`,
    () => ({
      method: "put",
    }),
    {
      onSuccess: () => {
        return Promise.all([queryClient.invalidateQueries(["userBanks"])]);
      },
    },
    async (response) => {
      return response.json();
    }
  );
}
