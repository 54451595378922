import { CustomerDTO } from "hooks/customers/models/customer.interface";
import { PaginatedResponse } from "models/schema.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export type GetCustomersResponse = PaginatedResponse<CustomerDTO>;

export function useGetCustomerOptions(search?: string) {
  return useApiQuery<GetCustomersResponse>(
    ["customerOptions"],
    `customers?${new URLSearchParams({
      search: search ?? "",
    })}`
  );
}
