import { PaginatedResponse } from "models/schema.interface";
import { Timesheet } from "hooks/timesheets/models/timesheets.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export interface GetTimesheetsParams {
  offset: number;
  limit: number;
  search: string;
  sortOrder?: string;
  sortKey?: string;
  refetchInterval?: number;
}

export type GetTimesheetsResponse = PaginatedResponse<
  Omit<Timesheet, "generateInvoice" | "update">
>;

export function useGetTimesheets(input?: GetTimesheetsParams) {
  const size = input?.limit ? Number(input.limit) : 10;
  const page = input?.offset ? input.offset / size : 0;

  return useApiQuery<GetTimesheetsResponse>(
    [
      "timesheets",
      input?.limit,
      input?.offset,
      input?.search,
      input?.sortKey,
      input?.sortOrder,
    ],
    `timesheets?${new URLSearchParams({
      page: String(page),
      size: String(size),
      search: input?.search ?? "",
      sort:
        input?.sortKey && input?.sortOrder
          ? `${input.sortKey},${input.sortOrder}`
          : "",
    })}`,
    {},
    {
      refetchInterval: input?.refetchInterval,
    }
  );
}
