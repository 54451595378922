import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePencilAlt, HiTrash } from "react-icons/hi";
import { Dropdown, Table } from "flowbite-react";

import { Address } from "models/address/address.interface";
import { useRoutes } from "domains";
import { Contact } from "models/contact/contact.interface";
import { Checkbox } from "../../Checkbox";
import { TableRow } from "../../table/TableRow";
import { RoundedIconDropdown } from "../../table/RoundedIconDropdown";

interface SuppliersTableRowProps {
  id: string;
  name?: string;
  vatNumber?: string;
  address?: Address;
  contacts?: Contact[];
  selected: boolean;
  onSelect: (id: string) => void;
  onEdit: (id: string) => void;
}

export const SuppliersTableRow: FC<SuppliersTableRowProps> = function ({
  id,
  selected,
  onSelect,
  onEdit,
  name,
  vatNumber,
  address,
  contacts,
}) {
  const { openDeleteModal } = useRoutes();
  const { t } = useTranslation();

  const onOpenDeleteModal = useCallback(
    () => openDeleteModal([id]),
    [id, openDeleteModal]
  );

  const edit = useCallback(() => {
    onEdit(id);
  }, [onEdit, id]);

  const select = useCallback(() => onSelect(id), [id, onSelect]);

  const mappedContacts = useMemo(
    () => contacts?.map((c) => c.name).join(", "),
    [contacts]
  );

  return (
    <TableRow onClick={edit}>
      <Table.Cell>
        <Checkbox onChange={select} checked={selected} />
      </Table.Cell>
      <Table.Cell>
        <p
          className="truncate text-gray-900 dark:text-white font-medium"
          role="cell"
        >
          {name ?? "-"}
        </p>
        <p className="truncate text-gray-500 dark:text-gray-400" role="cell">
          {vatNumber ?? "-"}
        </p>
      </Table.Cell>
      <Table.Cell>
        <p
          className="truncate text-gray-900 dark:text-white font-medium"
          role="cell"
        >
          {address?.street} {address?.number}{" "}
          {address?.bus ? `/ ${address?.bus}` : ""}
        </p>
        <p className="text-gray-500 dark:text-gray-400" role="cell">
          {address?.zipCode} {address?.city}
          {address?.country ? `, ${t(`country.${address?.country}`)}` : ""}
        </p>
      </Table.Cell>
      <Table.Cell>
        <p
          title={mappedContacts}
          className="truncate text-gray-900 dark:text-white font-medium"
        >
          {mappedContacts}
        </p>
      </Table.Cell>
      <Table.Cell>
        <RoundedIconDropdown className="w-max mr-2">
          <Dropdown.Item
            className="flex items-center py-3 rounded-t-lg"
            onClick={edit}
            role="button"
          >
            <HiOutlinePencilAlt className="mr-2 text-lg" />
            {t("suppliersTable.button.edit")}
          </Dropdown.Item>
          <Dropdown.Item
            className="flex items-center py-3 rounded-b-lg"
            onClick={onOpenDeleteModal}
            role="button"
          >
            <HiTrash className="mr-2 text-lg text-red-500 shrink-0" />
            <span className="text-red-500 shrink-0">
              {t("suppliersTable.button.delete")}
            </span>
          </Dropdown.Item>
        </RoundedIconDropdown>
      </Table.Cell>
    </TableRow>
  );
};
