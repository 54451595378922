import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { API, Mutation } from "models/core/API.interface";
import { DomainMutation, useDomainMutation } from "hooks/useDomainMutation";
import { UseCases } from "models/core";
import { UploadCSVInput } from "models/csv/csv.interface";
import { HTMLValidationSchemaGenerator } from "helpers/html-validation-schema";
import { event } from "event";
import { PurchaseInvoice } from "./models/purchaseInvoice.interface";
import {
  DeletePurchaseInvoicesInput,
  UpdatePurchaseInvoiceInput,
  UploadPurchaseInvoiceInput,
} from "./models/purchaseInvoiceInput.interface";
import { SendPurchaseInvoicesToAccountantApiInput } from "./mutations/useSendPurchaseInvoicesToAccountant";

export type PurchaseInvoiceAPI = API<{
  purchaseInvoices: Omit<PurchaseInvoice, "update">[];
  count: number;
}>;
export type UploadMutation = Mutation<UploadPurchaseInvoiceInput>;
export type UpdateMutation = Mutation<UpdatePurchaseInvoiceInput>;
export type DeleteMutation = Mutation<DeletePurchaseInvoicesInput>;
export type SendToAccountantMutation =
  Mutation<SendPurchaseInvoicesToAccountantApiInput>;
export type UploadCSVMutation = Mutation<UploadCSVInput>;

interface Props {
  api: PurchaseInvoiceAPI;
  uploadMutation: UploadMutation;
  updateMutation: UpdateMutation;
  deleteMutation: DeleteMutation;
  uploadCSVMutation: UploadCSVMutation;
  sendToAccountantMutation: SendToAccountantMutation;
}

interface Result {
  purchaseInvoices: PurchaseInvoice[];
  purchaseInvoice?: PurchaseInvoice;
  count: number;
  isLoading: boolean;
  showSuccessModal: boolean;
  hideSuccessModal: () => void;
  error?: Error;
  deleteMany: DomainMutation<DeletePurchaseInvoicesInput>;
  upload: DomainMutation<UploadPurchaseInvoiceInput>;
  uploadCSV: DomainMutation<UploadCSVInput>;
  sendToAccountant: DomainMutation<
    SendPurchaseInvoicesToAccountantApiInput,
    HTMLValidationSchemaGenerator<SendPurchaseInvoicesToAccountantApiInput>
  >;
}

export const usePurchaseInvoicesUseCases: UseCases<Props, Result> = ({
  api,
  uploadMutation,
  updateMutation,
  deleteMutation,
  uploadCSVMutation,
  sendToAccountantMutation,
}) => {
  const { t } = useTranslation();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const queryClient = useQueryClient();

  const upload = useDomainMutation<UploadPurchaseInvoiceInput>(
    {
      formData: { required: true },
    },
    async (input) => {
      await uploadMutation(input);

      if (input.isLast) {
        setShowSuccessModal(true);

        event.emit("purchaseInvoiceUploaded");
        event.emit(
          "mutationSucceeded",
          t("domain.purchaseInvoice.purchaseInvoiceUploaded")
        );
      }
    }
  );

  const update = useDomainMutation<
    UpdatePurchaseInvoiceInput,
    HTMLValidationSchemaGenerator<UpdatePurchaseInvoiceInput>
  >(
    (input) => {
      return {
        id: { required: true },
        issueDate: {
          required: true,
          max: input.dueDate,
          rangeOverflow: t(
            "invoiceForm.input.issueDate.error.rangeOverflow"
          ) as string,
        },
        dueDate: {
          required: true,
          min: input.issueDate,
          rangeUnderflow: t(
            "invoiceForm.input.dueDate.error.rangeUnderflow"
          ) as string,
        },
        supplierId: { required: true },
        totalInclusiveVat: { required: true },
        totalExclusiveVat: { required: true },
        totalTaxAmount: { required: true },
        currency: { required: true },
        financialDocumentType: { required: true },
      };
    },
    async (input) => {
      await updateMutation(input);
      event.emit(
        "mutationSucceeded",
        t("domain.purchaseInvoice.purchaseInvoiceSaved")
      );
      event.emit("purchaseInvoiceSaved");
    }
  );

  const deleteMany: Result["deleteMany"] =
    useDomainMutation<DeletePurchaseInvoicesInput>(
      {
        ids: [{ required: true }],
      },
      async (input) => {
        await deleteMutation(input);
        event.emit("purchaseInvoicesDeleted");
        event.emit(
          "mutationSucceeded",
          t("domain.purchaseInvoice.purchaseInvoicesDeleted")
        );
      }
    );

  const sendToAccountant = useDomainMutation<
    SendPurchaseInvoicesToAccountantApiInput,
    HTMLValidationSchemaGenerator<SendPurchaseInvoicesToAccountantApiInput>
  >(
    () => {
      return {
        ids: [{ required: true }],
      };
    },
    async (input) => {
      await sendToAccountantMutation(input);
      event.emit("purchaseInvoiceSaved");
    }
  );

  const purchaseInvoices: Result["purchaseInvoices"] =
    api.data.purchaseInvoices.map((purchaseInvoice) => ({
      ...purchaseInvoice,
      update,
    }));

  const hideSuccessModal: Result["hideSuccessModal"] = useCallback(() => {
    setShowSuccessModal(false);
    queryClient.invalidateQueries(["purchaseInvoices"]);
  }, [setShowSuccessModal, queryClient]);

  const uploadCSV: Result["uploadCSV"] = useDomainMutation<UploadCSVInput>(
    {
      formData: { required: true },
    },
    async (input) => {
      await uploadCSVMutation(input);

      event.emit("purchaseInvoiceCSVUploaded");
      event.emit("CSVUploaded");
    }
  );

  return {
    ...api,
    purchaseInvoices,
    purchaseInvoice: purchaseInvoices[0],
    count: api.data.count,
    deleteMany,
    upload,
    uploadCSV,
    showSuccessModal,
    hideSuccessModal,
    sendToAccountant,
  };
};
