import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Footer } from "flowbite-react";
import { format } from "date-fns";
import { toCurrency, Currency } from "helpers/to-currency";
import { Skeleton } from "./Skeleton";
import { OrderTable } from "../invoice/templates/OrderTable";

export const SkeletonTemplate: FC = function () {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-between">
        <Skeleton
          isAnimated={false}
          className="whitespace-nowrap mb-4 dark:!bg-gray-200"
        >
          <h1 className="text-2xl font-semibold">
            {t("salesInvoice.title.number", { number: 100 })}
          </h1>
        </Skeleton>
        <div className="flex flex-col items-end gap-2">
          <Skeleton className="rounded-md dark:!bg-gray-200" isAnimated={false}>
            <img
              alt="Admisol logo"
              src="https://www.admisol.be/images/logo_menu.png"
              className="h-12"
            />
          </Skeleton>
          <Skeleton
            isAnimated={false}
            className="whitespace-nowrap mb-1 dark:!bg-gray-200"
          >
            <h2 className="text-xl font-semibold">Vandewalle NV</h2>
          </Skeleton>
          <Skeleton
            isText
            isAnimated={false}
            className="whitespace-nowrap dark:!bg-gray-200"
          >
            <p>Bruglaan 1, 9000 Gent, Belgium</p>
          </Skeleton>

          <Skeleton
            isText
            isAnimated={false}
            className="mt-3 dark:!bg-gray-200"
          >
            <p className="text-gray-400">{format(new Date(), "dd/MM/yyyy")}</p>
          </Skeleton>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <Skeleton isText isAnimated={false} className="dark:!bg-gray-200">
          <p>{t("salesInvoice.billTo").toUpperCase()}</p>
        </Skeleton>
        <Skeleton isAnimated={false} className="w-32 dark:!bg-gray-200" />
        <Skeleton isAnimated={false} className="w-40 dark:!bg-gray-200" />
        <Skeleton isAnimated={false} className="w-64 dark:!bg-gray-200" />
        <Skeleton isAnimated={false} className="w-32 dark:!bg-gray-200" />
      </div>

      <OrderTable
        rows={[]}
        isLoading
        className="w-full mt-8"
        theme={{ root: { wrapper: "static" } }}
      />
      <Footer.Divider className="dark:!border-gray-200" />

      <div className="flex flex-col w-full items-end gap-4">
        <div className="flex justify-between w-48">
          <Skeleton isText isAnimated={false} className="dark:!bg-gray-200">
            <span>{t("salesInvoice.subtotal")}</span>
          </Skeleton>
          <Skeleton isText isAnimated={false} className="dark:!bg-gray-200">
            <span>{toCurrency(100000, Currency.Euro)}</span>
          </Skeleton>
        </div>

        <div className="flex justify-between w-48">
          <Skeleton isText isAnimated={false} className="dark:!bg-gray-200">
            <span>{t("salesInvoice.vat")}</span>
          </Skeleton>
          <Skeleton isText isAnimated={false} className="dark:!bg-gray-200">
            <span>{toCurrency(100000, Currency.Euro)}</span>
          </Skeleton>
        </div>

        <div className="flex justify-between w-48">
          <Skeleton isText isAnimated={false} className="dark:!bg-gray-200">
            <span>{t("salesInvoice.total")}</span>
          </Skeleton>
          <Skeleton isText isAnimated={false} className="dark:!bg-gray-200">
            <span>{toCurrency(100000, Currency.Euro)}</span>
          </Skeleton>
        </div>
      </div>
    </>
  );
};
