import {
  FinancialEvolution,
  PeriodFilterParams,
} from "hooks/dashboard/models/dashboard.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export type GetFinancialEvolutionParams = PeriodFilterParams;
export type GetFinancialEvolutionResponse = FinancialEvolution;

export function useGetFinancialEvolution(input?: GetFinancialEvolutionParams) {
  return useApiQuery<GetFinancialEvolutionResponse>(
    ["financialEvolution", input?.from, input?.to],
    `dashboards/financial-evolution?${new URLSearchParams({
      start_date: input?.from.toString() ?? "",
      end_date: input?.to.toString() ?? "",
    })}`
  );
}
