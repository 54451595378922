import { useQueryClient } from "react-query";

import { useApiMutation } from "hooks/api/useApiMutation";

import { CreateTemplateInput } from "../models/templateInput.interface";
import { Template } from "../models/template.interface";

export type CreateTemplateBody = CreateTemplateInput;

export type CreateTemplateResponse = Omit<Template, "delete">;

export function useCreateTemplate() {
  const queryClient = useQueryClient();

  return useApiMutation<CreateTemplateResponse, CreateTemplateBody>(
    "templates",
    "templates",
    (input) => ({
      body: JSON.stringify({
        accentColor: input.accentColor,
        layout: input.layout,
      }),
    }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("templates");
      },
    },
    async (response) => {
      return response.json();
    }
  );
}
