import { PurchaseInvoice } from "hooks/purchaseInvoices/models/purchaseInvoice.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export interface GetPurchaseInvoiceParams {
  id?: PurchaseInvoice["id"];
}

export type GetPurchaseInvoiceResponse = Omit<PurchaseInvoice, "update">;

export function useGetPurchaseInvoice({ id }: GetPurchaseInvoiceParams) {
  return useApiQuery<GetPurchaseInvoiceResponse>(
    ["purchaseInvoices", id],
    `purchase-invoices/${id}`,
    {},
    { enabled: !!id }
  );
}
