import { ProductDTO } from "hooks/products/models/product.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export interface GetProductParams {
  id?: string;
}

export type GetProductResponse = ProductDTO;

export function useGetProduct({ id }: GetProductParams) {
  return useApiQuery<GetProductResponse>(
    ["product", id],
    `articles/${id}`,
    {},
    { enabled: !!id }
  );
}
