/* eslint-disable class-methods-use-this */
import { ToAPI } from "models/core";
import { ReactQueryAPI } from "models/core/reactQuery.interface";
import { GetQuotesResponse } from "pages/quotes/queries/useGetQuotes";
import { GetQuoteResponse } from "pages/quote/queries/useGetQuote";
import { Quote } from "./models/quote.interface";
import { QuoteAPI } from "./useQuotesUseCases";

export type QuoteAPIDTO = ReactQueryAPI<GetQuotesResponse | GetQuoteResponse>;

interface Mapper {
  toAPI: ToAPI<QuoteAPIDTO, QuoteAPI>;
}

export class QuoteMapper implements Mapper {
  public toAPI: Mapper["toAPI"] = (api) => {
    const extractDTOs = (
      data: QuoteAPIDTO["data"]
    ): Omit<Quote, "update">[] => {
      if ((data as GetQuotesResponse)?.content)
        return (data as GetQuotesResponse)?.content;
      if (data as GetQuoteResponse) return [data as GetQuoteResponse];
      return [];
    };

    return {
      data: {
        quotes: extractDTOs(api?.data),
        count: (api?.data as GetQuotesResponse)?.totalElements ?? 0,
      },
      isLoading: (api?.isLoading || api?.isFetching) ?? false,
      error: api?.error ?? undefined,
    };
  };
}
