import { Button } from "flowbite-react";
import { FC, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { event } from "event";
import { useRoutes } from "domains";
import { LoadingButton } from "components/LoadingButton";
import { useRevokeConnection } from "../mutations/useRevokeConnection";

export const RevokeConnection: FC = function () {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const iban = searchParams.get("iban");

  const { goToBankAccounts } = useRoutes();

  const { mutate, isLoading } = useRevokeConnection();

  const handleCancel = useCallback(
    () => goToBankAccounts(),
    [goToBankAccounts]
  );

  const handleRevoke = useCallback(() => {
    if (iban) mutate({ iban });
    else {
      event.emit(
        "mutationFailed",
        new Error(t("connections.revokeFailedNoIban") as string)
      );
      goToBankAccounts();
    }
  }, [t, iban, mutate, goToBankAccounts]);

  return (
    <>
      <p className="text-base text-center leading-relaxed text-gray-400 dark:text-gray-400">
        {t("connections.areYouSureRevoke")}
      </p>
      <div className="flex gap-4 justify-center">
        <LoadingButton loading={isLoading} onClick={handleRevoke}>
          {t("connections.confirmRevoke")}
        </LoadingButton>
        <Button color="gray" onClick={handleCancel}>
          {t("connections.cancelRevoke")}
        </Button>
      </div>
    </>
  );
};
