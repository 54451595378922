import { FC } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { HiUserGroup } from "react-icons/hi";
import { useCustomers, useRoutes } from "domains";
import { PageHeader } from "components/PageHeader";
import { NotFoundBlock } from "components/errors/404/NotFoundBlock";
import { CustomerDetail } from "components/customer/CustomerDetail";
import { useGetCustomer } from "./queries/useGetCustomer";

const CustomerPage: FC = function () {
  const { t } = useTranslation();
  const { id } = useParams();
  const { routes } = useRoutes();
  const { customer, isLoading, create } = useCustomers(useGetCustomer({ id }));

  if (!isLoading && id && !customer) {
    return (
      <NotFoundBlock
        href={routes.customers}
        entity={t("entity.customer") as string}
      />
    );
  }

  return (
    <>
      <PageHeader
        className="bg-white dark:bg-gray-800"
        title={
          isLoading ? t("customerPage.title.loading") : customer?.name ?? ""
        }
        breadcrumbs={[
          {
            href: routes.customers,
            label: t("sidebar.title.customers") as string,
            icon: HiUserGroup,
          },
          {
            label: customer?.name ?? "",
          },
        ]}
      />
      <CustomerDetail
        customer={customer}
        update={customer?.update}
        create={create}
        isLoading={isLoading}
      />
    </>
  );
};

export default CustomerPage;
