import { FC } from "react";

import { Skeleton } from "components/skeletons/Skeleton";

export const LoadingPieChart: FC = function () {
  return (
    <div className="flex w-full justify-center items-center h-68">
      <Skeleton className="rounded-full h-54 w-54" />
    </div>
  );
};
