import { useTranslation } from "react-i18next";

import { API, Mutation } from "models/core/API.interface";
import { CREATE_CUSTOMER_PARAM } from "components/invoice/InvoiceFormCreateModals";

import {
  CreateAddressInput,
  createAddressSchema,
  DeleteAddressInput,
  UpdateAddressInput,
} from "models/address/address.interface";
import {
  CreateBankAccountInput,
  createBankAccountSchema,
  UpdateBankAccountInput,
  DeleteBankAccountInput,
} from "models/bankAccount/bankAccount.interface";
import {
  CreateContactInput,
  createContactSchema,
  UpdateContactInput,
  DeleteContactInput,
} from "models/contact/contact.interface";
import { DomainMutation, useDomainMutation } from "hooks/useDomainMutation";
import { event } from "event";
import { HTMLValidationSchema } from "helpers/html-validation-schema";
import { UploadCSVInput } from "models/csv/csv.interface";
import { UseCases } from "models/core";

import { removeProperties } from "helpers/remove-properties";
import { addCustomerSubEntitiesIds } from "helpers/customer/addCustomerSubEntitiesIds";
import {
  CreateCustomerApiInput,
  CreateCustomerInput,
  CreateCustomerWithAddressInput,
  Customer,
  CustomerDTO,
  CustomerDTOWithEntityIds,
  DeleteManyCustomersInput,
  UpdateCustomerInput,
} from "./models/customer.interface";

import { UpdateCustomerApiInput } from "./mutations/useUpdateCustomer";

export type CustomerAPI = API<{
  customers: CustomerDTO[];
  count: number;
}>;

export type CreateCustomerMutation = Mutation<CreateCustomerApiInput, Customer>;
export type UpdateCustomerMutation = Mutation<UpdateCustomerApiInput>;
export type DeleteCustomersMutation = Mutation<DeleteManyCustomersInput>;
export type UploadCSVMutation = Mutation<UploadCSVInput>;

export interface CustomerCreateEvent {
  customerId: string;
  addressId: string;
}

interface Props {
  api: CustomerAPI;
  createMutation: CreateCustomerMutation;
  updateMutation: UpdateCustomerMutation;
  deleteMutation: DeleteCustomersMutation;
  uploadCSVMutation: UploadCSVMutation;
}

interface Result {
  customer?: Customer;
  customers: Customer[];
  count: number;
  isLoading: boolean;
  error?: Error;
  create: DomainMutation<CreateCustomerInput>;
  createFromInvoice: DomainMutation<CreateCustomerWithAddressInput>;
  deleteMany: DomainMutation<DeleteManyCustomersInput>;
  uploadCSV: DomainMutation<UploadCSVInput>;
}

export const useCustomersUseCases: UseCases<Props, Result> = ({
  api,
  createMutation,
  updateMutation,
  deleteMutation,
  uploadCSVMutation,
}) => {
  const { t } = useTranslation();

  const create = useDomainMutation<CreateCustomerWithAddressInput>(
    {
      name: { required: true },
      customerType: { required: false },
      vatNumber: { required: false },
      discountPercentage: { required: false },
      email: { required: false },
      address: {
        street: { required: false },
        number: { required: false },
        bus: { required: false },
        zipCode: { required: false },
        city: { required: false },
        country: { required: false },
        type: { required: false },
      },
    },
    async (input) => {
      const { address, ...rest } = input;

      const result = await createMutation({
        ...rest,
        addresses: [
          {
            ...address,
          },
        ],
      } as CreateCustomerApiInput);

      // Redirect to the customer detail page upon create success
      event.emit("customerSaved", result?.id);

      event.emit("mutationSucceeded", t("domain.customer.customerSaved"));
    }
  );

  const createFromInvoice = useDomainMutation<CreateCustomerWithAddressInput>(
    {
      ...create.schema,
    },
    async (input) => {
      const { address, ...rest } = input;

      const customer = await createMutation({
        ...rest,
        addresses: [
          {
            ...address,
          },
        ],
      } as CreateCustomerApiInput);

      if (customer) {
        event.emit("customerCreatedFromInvoice", {
          customerId: customer.id,
          addressId: "0", // Since this is a newly created customer, the addresses array will only contain 1 address.
        });
        event.emit("closeDifferentEntity", CREATE_CUSTOMER_PARAM);
        event.emit("mutationSucceeded", t("domain.customer.customerSaved"));
      }
    }
  );

  const update = useDomainMutation<UpdateCustomerInput>(
    {
      id: { required: true },
      name: { required: true },
      customerType: { required: false },
      email: { required: false },
      vatNumber: { required: false },
    },
    async (input) => {
      const {
        addresses = [],
        contacts = [],
        bankAccounts = [],
        ...customerDTO
      } = input;

      await updateMutation({
        ...customerDTO,
        addresses: addresses?.map((address) =>
          removeProperties(address, ["update", "id", "remove"])
        ),
        contacts: contacts?.map((contact) =>
          removeProperties(contact, ["update", "id", "remove"])
        ),
        bankAccounts: bankAccounts?.map((bankAccount) =>
          removeProperties(bankAccount, ["update", "id", "remove"])
        ),
      });

      // Redirect to customer detail page after save
      event.emit("customerSaved", input.id);

      event.emit(
        "mutationSucceeded",
        t("domain.customer.customerSaved") as string
      );
    }
  );

  const deleteMany = useDomainMutation<DeleteManyCustomersInput>(
    {
      ids: [{ required: true }],
    },
    async (input) => {
      await deleteMutation(input);
      event.emit("customersDeleted");
      event.emit(
        "mutationSucceeded",
        t("domain.customer.customersDeleted") as string
      );
    }
  );

  const createContact = useDomainMutation<
    CreateContactInput,
    HTMLValidationSchema<CreateContactInput>,
    void,
    CustomerDTOWithEntityIds
  >(createContactSchema, async (input, customer) => {
    if (!customer) {
      return;
    }

    const { contacts } = customer;

    const contactDTOs = contacts.map((contact) =>
      removeProperties(contact, ["id"])
    );

    await updateMutation({
      ...customer,
      addresses: customer.addresses?.map((address) =>
        removeProperties(address, ["id"])
      ),
      contacts: [...contactDTOs, input],
      bankAccounts: customer.bankAccounts?.map((bankAccount) =>
        removeProperties(bankAccount, ["id"])
      ),
    } as UpdateCustomerApiInput);

    event.emit("customerContactSaved", customer.id);
    event.emit(
      "mutationSucceeded",
      t("domain.customer.contactSaved") as string
    );
  });

  const updateContact = useDomainMutation<
    UpdateContactInput,
    HTMLValidationSchema<UpdateContactInput>,
    void,
    CustomerDTOWithEntityIds
  >(
    {
      id: { required: true },
      ...createContact.schema,
    },
    async (input, customer) => {
      if (!customer) {
        return;
      }

      const contacts = customer.contacts.slice();

      const updatedContactIndex = contacts.findIndex(
        (contact) => contact.id === input.id
      );

      if (updatedContactIndex > -1) {
        contacts[updatedContactIndex] = {
          ...contacts[updatedContactIndex],
          ...(input.name ? { name: input.name } : {}),
          ...(input.languageCode ? { languageCode: input.languageCode } : {}),
        };
      }

      await updateMutation({
        ...customer,
        addresses: customer.addresses?.map((address) =>
          removeProperties(address, ["id"])
        ),
        contacts: contacts?.map((contact) => removeProperties(contact, ["id"])),
        bankAccounts: customer.bankAccounts?.map((bankAccount) =>
          removeProperties(bankAccount, ["id"])
        ),
      });

      event.emit("customerContactSaved", customer.id);
      event.emit(
        "mutationSucceeded",
        t("domain.customer.contactSaved") as string
      );
    }
  );

  const deleteContact = useDomainMutation<
    DeleteContactInput,
    HTMLValidationSchema<DeleteContactInput>,
    void,
    CustomerDTOWithEntityIds
  >(
    {
      id: { required: true },
    },
    async (input, customer) => {
      if (!customer) {
        return;
      }

      const contacts = customer.contacts.slice();

      const contactToDeleteIndex = contacts.findIndex(
        (contact) => contact.id === input.id
      );

      if (contactToDeleteIndex > -1) {
        contacts.splice(contactToDeleteIndex, 1);
      }

      await updateMutation({
        ...customer,
        addresses: customer.addresses?.map((address) =>
          removeProperties(address, ["id"])
        ),
        contacts: contacts?.map((contact) => removeProperties(contact, ["id"])),
        bankAccounts: customer.bankAccounts?.map((bankAccount) =>
          removeProperties(bankAccount, ["id"])
        ),
      });

      event.emit("customerContactDeleted", customer.id);
      event.emit(
        "mutationSucceeded",
        t("domain.customer.contactDeleted") as string
      );
    }
  );

  const createAddress = useDomainMutation<
    CreateAddressInput,
    HTMLValidationSchema<CreateAddressInput>,
    void,
    CustomerDTO
  >(createAddressSchema, async (input, customer) => {
    if (!customer) {
      return;
    }

    await updateMutation({
      ...customer,
      addresses: [...customer.addresses, input],
    });

    event.emit("customerAddressSaved", customer.id);
    event.emit(
      "mutationSucceeded",
      t("domain.customer.addressSaved") as string
    );
  });

  const updateAddress = useDomainMutation<
    UpdateAddressInput,
    HTMLValidationSchema<UpdateAddressInput>,
    void,
    CustomerDTOWithEntityIds
  >(
    {
      id: { required: true },
      ...createAddress.schema,
    },
    async (input, customer) => {
      if (!customer) {
        return;
      }

      const addresses = customer.addresses.slice();

      const updatedAddressIndex = addresses.findIndex(
        (address) => address.id === input.id
      );

      if (updatedAddressIndex > -1) {
        addresses[updatedAddressIndex] = {
          ...addresses[updatedAddressIndex],
          ...(input.street ? { street: input.street } : {}),
          ...(input.number ? { number: input.number } : {}),
          ...{ bus: input.bus ?? "" },
          ...(input.zipCode ? { zipCode: input.zipCode } : {}),
          ...(input.city ? { city: input.city } : {}),
          ...(input.country ? { country: input.country } : {}),
          ...(input.type ? { type: input.type } : {}),
          ...(input.description ? { description: input.description } : {}),
        };
      }

      await updateMutation({
        ...customer,
        addresses: addresses?.map((address) =>
          removeProperties(address, ["id"])
        ),
        contacts: customer.contacts?.map((contact) =>
          removeProperties(contact, ["id"])
        ),
        bankAccounts: customer.bankAccounts?.map((bankAccount) =>
          removeProperties(bankAccount, ["id"])
        ),
      });

      event.emit("customerAddressSaved", customer.id);
      event.emit(
        "mutationSucceeded",
        t("domain.customer.addressSaved") as string
      );
    }
  );

  const deleteAddress = useDomainMutation<
    DeleteAddressInput,
    HTMLValidationSchema<DeleteAddressInput>,
    void,
    CustomerDTOWithEntityIds
  >(
    {
      id: { required: true },
    },
    async (input, customer) => {
      if (!customer) {
        return;
      }

      const addresses = customer.addresses.slice();

      const addressToDeleteIndex = addresses.findIndex(
        (address) => address.id === input.id
      );

      if (addressToDeleteIndex > -1) {
        addresses.splice(addressToDeleteIndex, 1);
      }

      await updateMutation({
        ...customer,
        addresses: addresses?.map((address) =>
          removeProperties(address, ["id"])
        ),
        contacts: customer.contacts?.map((contact) =>
          removeProperties(contact, ["id"])
        ),
        bankAccounts: customer.bankAccounts?.map((bankAccount) =>
          removeProperties(bankAccount, ["id"])
        ),
      });

      event.emit("customerAddressDeleted", customer.id);
      event.emit(
        "mutationSucceeded",
        t("domain.customer.addressDeleted") as string
      );
    }
  );

  const createBankAccount = useDomainMutation<
    CreateBankAccountInput,
    HTMLValidationSchema<CreateBankAccountInput>,
    void,
    CustomerDTO
  >(createBankAccountSchema, async (input, customer) => {
    if (!customer) {
      return;
    }

    await updateMutation({
      ...customer,
      bankAccounts: [...customer.bankAccounts, input],
    });

    event.emit("customerBankAccountSaved", customer.id);
    event.emit(
      "mutationSucceeded",
      t("domain.customer.bankAccountSaved") as string
    );
  });

  const updateBankAccount = useDomainMutation<
    UpdateBankAccountInput,
    HTMLValidationSchema<UpdateBankAccountInput>,
    void,
    CustomerDTOWithEntityIds
  >(
    {
      id: { required: true },
      ...createBankAccount.schema,
    },
    async (input, customer) => {
      if (!customer) {
        return;
      }

      const bankAccounts = customer.bankAccounts.slice();

      const updatedBankAccountIndex = bankAccounts.findIndex(
        (bankAccount) => bankAccount.id === input.id
      );

      if (updatedBankAccountIndex > -1) {
        bankAccounts[updatedBankAccountIndex] = {
          ...bankAccounts[updatedBankAccountIndex],
          ...(input.iban ? { iban: input.iban } : {}),
          ...(input.bic ? { bic: input.bic } : {}),
          ...(input.description ? { description: input.description } : {}),
        };
      }

      await updateMutation({
        ...customer,
        addresses: customer.addresses?.map((address) =>
          removeProperties(address, ["id"])
        ),
        contacts: customer.contacts?.map((contact) =>
          removeProperties(contact, ["id"])
        ),
        bankAccounts: bankAccounts?.map((bankAccount) =>
          removeProperties(bankAccount, ["id"])
        ),
      });

      event.emit("customerBankAccountSaved", customer.id);
      event.emit(
        "mutationSucceeded",
        t("domain.customer.bankAccountSaved") as string
      );
    }
  );

  const deleteBankAccount = useDomainMutation<
    DeleteBankAccountInput,
    HTMLValidationSchema<DeleteBankAccountInput>,
    void,
    CustomerDTOWithEntityIds
  >(
    {
      id: { required: true },
    },
    async (input, customer) => {
      if (!customer) {
        return;
      }

      const bankAccounts = customer.bankAccounts.slice();

      const bankAccountToDeleteIndex = bankAccounts.findIndex(
        (bankAccount) => bankAccount.id === input.id
      );

      if (bankAccountToDeleteIndex > -1) {
        bankAccounts.splice(bankAccountToDeleteIndex, 1);
      }

      await updateMutation({
        ...customer,
        addresses: customer.addresses?.map((address) =>
          removeProperties(address, ["id"])
        ),
        contacts: customer.contacts?.map((contact) =>
          removeProperties(contact, ["id"])
        ),
        bankAccounts: bankAccounts?.map((bankAccount) =>
          removeProperties(bankAccount, ["id"])
        ),
      });

      event.emit("customerBankAccountDeleted", customer.id);
      event.emit(
        "mutationSucceeded",
        t("domain.customer.bankAccountDeleted") as string
      );
    }
  );

  const customers: Result["customers"] = api.data.customers.map(
    (customer: CustomerDTO) => {
      const c: CustomerDTOWithEntityIds = {
        ...customer,
        ...addCustomerSubEntitiesIds(customer),
      };

      return {
        ...c,
        contacts: c.contacts?.map((contact) => ({
          ...contact,
          update: {
            ...updateContact,
            execute: (data) => updateContact.execute(data, c),
          },
          remove: {
            ...deleteContact,
            execute: (data) => deleteContact.execute(data, c),
          },
        })),
        addresses: c.addresses?.map((address) => ({
          ...address,
          update: {
            ...updateAddress,
            execute: (data) => updateAddress.execute(data, c),
          },
          remove: {
            ...deleteAddress,
            execute: (data) => deleteAddress.execute(data, c),
          },
        })),
        bankAccounts: c.bankAccounts?.map((bankAccount) => ({
          ...bankAccount,
          update: {
            ...updateBankAccount,
            execute: (data) => updateBankAccount.execute(data, c),
          },
          remove: {
            ...deleteBankAccount,
            execute: (data) => deleteBankAccount.execute(data, c),
          },
        })),
        addContact: {
          ...createContact,
          execute: (data) => createContact.execute(data, c),
        },
        addAddress: {
          ...createAddress,
          execute: (data) => createAddress.execute(data, customer),
        },
        addBankAccount: {
          ...createBankAccount,
          execute: (data) => createBankAccount.execute(data, customer),
        },
        update,
      };
    }
  );

  const uploadCSV: Result["uploadCSV"] = useDomainMutation<UploadCSVInput>(
    {
      formData: { required: true },
    },
    async (input) => {
      await uploadCSVMutation(input);

      event.emit("customerCSVUploaded");
      event.emit("CSVUploaded");
    }
  );

  return {
    ...api,
    customers,
    customer: customers[0],
    count: api.data.count,
    create,
    createFromInvoice,
    deleteMany,
    uploadCSV,
  };
};
