import { FC } from "react";
import { useTranslation } from "react-i18next";
// import { HiDocumentText } from "react-icons/hi";
import format from "date-fns/format";

import { GetSubscriptionResponse } from "hooks/subscription/models/subscription.interface";
import { useCancelSubscription } from "pages/subscription/mutations/useCancelSubscription";
import { LoadingButton } from "components/LoadingButton";
import { Badge } from "flowbite-react";

interface SubscriptionInfoProps {
  subscription?: GetSubscriptionResponse;
}

export const SubscriptionInfo: FC<SubscriptionInfoProps> = function ({
  subscription,
}) {
  const { t } = useTranslation();

  const { mutate, isLoading } = useCancelSubscription();

  return (
    <div className="flex flex-col justify-start w-full">
      <div className="flex">
        <img className="w-48" src="/images/sumrise.svg" alt="logo" />
        <div className="flex ml-2">
          <Badge color="info">{subscription?.plan?.name}</Badge>
        </div>
      </div>
      {/* <p className="mt-4 mb-5 text-base text-gray-500 dark:text-gray-400 sm:text-lg">
        {t("subscriptionPage.text.info")}
      </p> */}
      <p className="mt-5 mb-5 text-base font-semibold text-gray-800 dark:text-gray-400">
        {t("subscriptionPage.text.renewal", {
          price: subscription?.plan.pricePerMonth,
          date: subscription?.endDate
            ? format(
                new Date(subscription.nextBillingDate || subscription.endDate),
                "dd-MM-yyyy"
              )
            : "-",
        })}
      </p>
      <div className="flex gap-4">
        {/* <Button type="button">
          <HiDocumentText className="mr-4" />
          {t("subscriptionPage.button.changePlan")}
        </Button> */}
        {subscription && !subscription?.cancelled && (
          <LoadingButton
            color="red"
            type="button"
            loading={isLoading}
            onClick={mutate}
          >
            {t("subscriptionPage.button.cancelSubscription")}
          </LoadingButton>
        )}
      </div>
    </div>
  );
};
