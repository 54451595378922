import type { Rule } from "./models/rule";
import type {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "./models/validationSchema.d";

export const isDate = (date: unknown): date is Date =>
  Object.prototype.toString.call(date) === "[object Date]";

export const isArray = <Type>(arr: Type | Type[]): arr is Type[] =>
  Array.isArray(arr);

export const isObject = (obj: unknown): obj is Record<string, unknown> =>
  typeof obj === "object" && !isArray(obj) && !isDate(obj) && obj !== null;

export const isRule = (obj: unknown): obj is Rule =>
  isObject(obj) &&
  Object.values(obj).some(
    (value) => !isDate(value) && !isArray(value) && !isObject(value)
  );

export const isGenerator = <Input extends object>(
  schema: HTMLValidationSchema<Input> | HTMLValidationSchemaGenerator<Input>
): schema is HTMLValidationSchemaGenerator<Input> =>
  typeof schema === "function";
