import { Card, CardProps } from "flowbite-react";
import { FC, useCallback } from "react";
import { HiPlus } from "react-icons/hi";
import { useParams, Params } from "react-router";
import { useTranslation } from "react-i18next";

import {
  BankAccount,
  CreateBankAccountInput,
} from "models/bankAccount/bankAccount.interface";
import { Button } from "components/Button";
import { DomainMutation } from "hooks/useDomainMutation";
import { EditModal } from "components/modals/EditModal";
import { H2 } from "components/headers/H2";
import { SkeletonButton } from "components/skeletons/SkeletonButton";
import { useRoutes } from "domains";

import { BankAccountForm, BankAccountFormValues } from "./BankAccountForm";
import BankAccountTable from "./BankAccountTable";

interface Props extends CardProps {
  bankAccounts?: BankAccount[];
  createBankAccount?: DomainMutation<CreateBankAccountInput>;
  isLoading?: boolean;
}

export const BANK_ACCOUNT_ROUTE_KEY = "bank-accounts";

const BankAccountCard: FC<Props> = function ({
  bankAccounts = [],
  createBankAccount,
  isLoading,
  ...rest
}) {
  const { closeDetailModal, openDetailCreateModal } = useRoutes();
  const { t } = useTranslation();
  const { subtype, action } = useParams<Params>();

  const onOpenCreateModal = useCallback(
    () => openDetailCreateModal(BANK_ACCOUNT_ROUTE_KEY),
    [openDetailCreateModal]
  );

  return (
    <Card {...rest}>
      <div className="flex justify-between">
        <H2>{t("bankAccountCard.title.bankAccounts")}</H2>
        {isLoading && !createBankAccount && (
          <SkeletonButton>
            <HiPlus className="mr-4" />
            {t("bankAccountCard.button.addBankAccount")}
          </SkeletonButton>
        )}
        {createBankAccount && (
          <Button disabled type="button" onClick={onOpenCreateModal}>
            <HiPlus className="mr-4" />
            {t("bankAccountCard.button.addBankAccount")}
          </Button>
        )}
      </div>
      <BankAccountTable rows={bankAccounts} isLoading={isLoading} />
      {subtype === BANK_ACCOUNT_ROUTE_KEY &&
        action === "create" &&
        createBankAccount && (
          <EditModal
            action={action as "create"}
            show
            isError={!!createBankAccount.error}
            itemName=""
            page="bankAccount"
            onClose={closeDetailModal}
          >
            <BankAccountForm
              defaultValues={{}}
              isLoading={createBankAccount.isLoading}
              schema={createBankAccount.schema}
              onSubmit={
                createBankAccount.execute as (
                  input: BankAccountFormValues
                ) => void
              }
            />
          </EditModal>
        )}
    </Card>
  );
};

export default BankAccountCard;
