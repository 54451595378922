import { useCallback, useMemo } from "react";

export const useAxisLabelMeasurer = (fontSize?: number) => {
  const ctx = useMemo<CanvasRenderingContext2D | null>(() => {
    const element = document.createElement("canvas").getContext("2d");

    if (element) {
      element.font = `${
        fontSize || 14
      }px ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`;
    }

    return element;
  }, [fontSize]);

  const measureText = useCallback(
    (text: string) => {
      return ctx?.measureText(text).width || 0;
    },
    [ctx]
  );

  return { measureText };
};
