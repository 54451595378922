/* eslint-disable class-methods-use-this */
import { ToAPI } from "models/core";
import { ReactQueryAPI } from "models/core/reactQuery.interface";
import { GetSalesInvoiceResponse } from "pages/salesInvoice/queries/useGetSalesInvoice";
import { GetSalesInvoicesResponse } from "pages/salesInvoices/queries/useGetSalesInvoices";
import { SalesInvoice } from "./models/salesInvoice.interface";
import { SalesInvoiceAPI } from "./useSalesInvoicesUseCases";

export type SalesInvoiceAPIDTO = ReactQueryAPI<
  GetSalesInvoicesResponse | GetSalesInvoiceResponse
>;

interface Mapper {
  toAPI: ToAPI<SalesInvoiceAPIDTO, SalesInvoiceAPI>;
}

export class SalesInvoiceMapper implements Mapper {
  public toAPI: Mapper["toAPI"] = (api) => {
    const extractDTOs = (
      data: SalesInvoiceAPIDTO["data"]
    ): Omit<SalesInvoice, "update">[] => {
      if ((data as GetSalesInvoicesResponse)?.content)
        return (data as GetSalesInvoicesResponse)?.content;
      if (data as GetSalesInvoiceResponse)
        return [data as GetSalesInvoiceResponse];
      return [];
    };

    return {
      data: {
        salesInvoices: extractDTOs(api?.data),
        count: (api?.data as GetSalesInvoicesResponse)?.totalElements ?? 0,
      },
      isLoading: (api?.isLoading || api?.isFetching) ?? false,
      error: api?.error ?? undefined,
    };
  };
}
