import { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "flowbite-react";
import classNames from "classnames";

export const AUTO_REFRESH_INTERVAL_KEY = "autoRefreshInterval";
const MINUTE = 60000;

export enum AutoRefreshOption {
  OFF = 0,
  MIN1 = MINUTE,
  MIN5 = 5 * MINUTE,
  MIN10 = 10 * MINUTE,
}

interface AutoRefreshProps {
  value: AutoRefreshOption;
  onChange: (timeout: AutoRefreshOption) => void;
}

interface AutoRefreshOptionProps {
  value: AutoRefreshOption;
  isActive: boolean;
  onChange: (timeout: number) => void;
}

const AutoRefreshOptionComponent: FC<AutoRefreshOptionProps> = function ({
  value,
  isActive,
  onChange,
}) {
  const { t } = useTranslation();

  const onOptionSelected = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  return (
    <Dropdown.Item
      key={value}
      onClick={onOptionSelected}
      className={classNames({
        "bg-gray-100 dark:bg-gray-600": isActive,
      })}
    >
      {t(`pageHeader.autoRefresh.${value}.option`)}
    </Dropdown.Item>
  );
};

export const AutoRefresh: FC<AutoRefreshProps> = function ({
  value: currentValue,
  onChange,
}: AutoRefreshProps) {
  const { t } = useTranslation();

  useEffect(() => {
    if (!localStorage.getItem(AUTO_REFRESH_INTERVAL_KEY)) {
      localStorage.setItem(
        AUTO_REFRESH_INTERVAL_KEY,
        AutoRefreshOption.MIN10.toString()
      );
    }
  }, []);

  return (
    <Dropdown
      label={t(`pageHeader.autoRefresh.${currentValue}.label`)}
      className="py-2 z-30"
      inline
      trigger="hover"
    >
      {Object.values(AutoRefreshOption)
        .filter((value) => typeof value === "number")
        .map((value) => (
          <AutoRefreshOptionComponent
            key={value}
            value={value as AutoRefreshOption}
            isActive={currentValue === value}
            onChange={onChange}
          />
        ))}
    </Dropdown>
  );
};
