/* eslint-disable class-methods-use-this */
import { ReactQueryAPI } from "models/core/reactQuery.interface";
import { ToAPI } from "models/core";
import { GetFileResponse } from "./models/file.interface";
import { FileAPI } from "./useFileUseCases";

export type FileAPIDTO = ReactQueryAPI<GetFileResponse>;

interface Mapper {
  toAPI: ToAPI<FileAPIDTO, FileAPI>;
}

export class FileMapper implements Mapper {
  public toAPI: Mapper["toAPI"] = (api) => {
    const extractDTOs = (data: FileAPIDTO["data"]): GetFileResponse => {
      return data as GetFileResponse;
    };

    return {
      data: extractDTOs(api?.data),
      isLoading: (api?.isLoading || api?.isFetching) ?? false,
      error: api?.error ?? undefined,
    };
  };
}
