import { KyResponse, Options } from "ky";
import { useMutation, UseMutationOptions } from "react-query";

import { api, ApiError } from "helpers/api";

export function useApiMutation<Data, Params>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  key: string | any[], // React Query key
  url: string | ((input: Params) => string),
  apiOptions?: Options | ((input: Params) => Options),
  options?: UseMutationOptions<Data, ApiError, Params>,
  resolveFn?: (response: KyResponse) => Promise<Data>
) {
  return useMutation<Data, ApiError, Params>(
    key,
    async (input: Params) => {
      const defaultApiOptions: Options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      };

      const kyOptions =
        typeof apiOptions === "function"
          ? { ...defaultApiOptions, ...apiOptions(input) }
          : apiOptions;

      const kyUrl = typeof url === "string" ? url : url(input);

      const response = await api(`api/v1/${kyUrl}`, kyOptions);

      if (!response.ok) {
        throw new Error(response?.statusText, {
          cause: {
            status: response.status,
          },
        });
      }

      if (response?.status === 204) {
        return true as Data;
      }

      if (resolveFn) {
        return resolveFn(response);
      }

      return Promise.resolve(undefined as Data);
    },
    {
      onError: (error) => {
        throw new Error(error.message);
      },
      ...options,
    }
  );
}
