import { useCallback, useReducer } from "react";
import { UseCases } from "models/core";
import {
  RowSelectedState,
  RowSelectionAction,
} from "./models/tables.interface";
import { useEvent } from "../useEvent";

interface Result {
  rowSelectedState: RowSelectedState;
  handleAllRowsClicked: (ids: string[]) => void;
  handleOneRowClicked: (id: string) => void;
  handleDeselectAllRows: () => void;
}

const initialSelectedState: RowSelectedState = {
  all: false,
  ids: [],
};

function reducer(
  state: RowSelectedState,
  action: RowSelectionAction
): RowSelectedState {
  switch (action.type) {
    case "all": {
      return {
        all: !state.all,
        ids: !state.all ? action.ids ?? [] : [],
      };
    }
    case "one": {
      if (!action.id) {
        break;
      }

      if (state.ids.includes(action.id)) {
        return {
          all: false,
          ids: state.ids.filter((id: string) => id !== action.id),
        };
      }

      return {
        all: false,
        ids: [...state.ids, action.id],
      };
    }
    case "deselectAll": {
      return initialSelectedState;
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }

  throw Error(`Unknown action: ${action.type}`);
}

export const useTablesUseCases: UseCases<undefined, Result> = () => {
  const [rowSelectedState, dispatch] = useReducer(
    reducer,
    initialSelectedState
  );

  const handleAllRowsClicked: Result["handleAllRowsClicked"] = useCallback(
    (ids) => dispatch({ type: "all", ids }),
    [dispatch]
  );

  const handleOneRowClicked: Result["handleOneRowClicked"] = useCallback(
    (id) => dispatch({ type: "one", id }),
    [dispatch]
  );

  const handleDeselectAllRows: Result["handleDeselectAllRows"] = useCallback(
    () => dispatch({ type: "deselectAll" }),
    [dispatch]
  );

  return {
    rowSelectedState,
    handleAllRowsClicked,
    handleOneRowClicked,
    handleDeselectAllRows,
  };
};

export const useTablesEffects = (
  table: ReturnType<typeof useTablesUseCases>
) => {
  useEvent("productSaved", table.handleDeselectAllRows);
  useEvent("productsDeleted", table.handleDeselectAllRows);
  useEvent("productGroupSaved", table.handleDeselectAllRows);
  useEvent("productGroupsDeleted", table.handleDeselectAllRows);
  useEvent("supplierSaved", table.handleDeselectAllRows);
  useEvent("suppliersDeleted", table.handleDeselectAllRows);
  useEvent("purchaseInvoiceSaved", table.handleDeselectAllRows);
  useEvent("purchaseInvoicesDeleted", table.handleDeselectAllRows);
  useEvent("salesInvoiceSaved", table.handleDeselectAllRows);
  useEvent("salesInvoicesDeleted", table.handleDeselectAllRows);
  useEvent("quoteSaved", table.handleDeselectAllRows);
  useEvent("quotesDeleted", table.handleDeselectAllRows);
  useEvent("customerSaved", table.handleDeselectAllRows);
  useEvent("customersDeleted", table.handleDeselectAllRows);
  useEvent("timesheetsDeleted", table.handleDeselectAllRows);
  useEvent("searchParamsChanged", table.handleDeselectAllRows);
  useEvent("paginationParamsChanged", table.handleDeselectAllRows);
};
