import { FC } from "react";

import { Skeleton } from "components/skeletons/Skeleton";

export const LoadingBarChart: FC = function () {
  return (
    <div className="flex gap-4 w-full">
      <div className="flex flex-col gap-4 w-1/4 md:w-1/6">
        <Skeleton className="!h-8 rounded-md w-full" />
        <Skeleton className="!h-8 rounded-md w-full" />
        <Skeleton className="!h-8 rounded-md w-full" />
        <Skeleton className="!h-8 rounded-md w-full" />
        <Skeleton className="!h-8 rounded-md w-full" />
      </div>
      <div className="flex gap-4 w-full">
        <div className="w-full">
          <Skeleton className="rounded-md h-56 w-full" />
        </div>
        <div className="w-full">
          <Skeleton className="rounded-md h-56 w-full" />
        </div>
        <div className="w-full">
          <Skeleton className="rounded-md h-56 w-full" />
        </div>
        <div className="w-full hidden sm:block">
          <Skeleton className="rounded-md h-56 w-full" />
        </div>
        <div className="w-full hidden md:block">
          <Skeleton className="rounded-md h-56 w-full" />
        </div>
        <div className="w-full hidden md:block">
          <Skeleton className="rounded-md h-56 w-full" />
        </div>
        <div className="w-full hidden lg:block">
          <Skeleton className="rounded-md h-56 w-full" />
        </div>
        <div className="w-full hidden lg:block">
          <Skeleton className="rounded-md h-56 w-full" />
        </div>
        <div className="w-full hidden xl:block">
          <Skeleton className="rounded-md h-56 w-full" />
        </div>
        <div className="w-full hidden xl:block">
          <Skeleton className="rounded-md h-56 w-full" />
        </div>
        <div className="w-full hidden 2xl:block">
          <Skeleton className="rounded-md h-56 w-full" />
        </div>
        <div className="w-full hidden 2xl:block">
          <Skeleton className="rounded-md h-56 w-full" />
        </div>
      </div>
    </div>
  );
};
