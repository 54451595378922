import { FC, useCallback, MouseEvent } from "react";
import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
} from "flowbite-react";

export const Button: FC<BaseButtonProps> = function ({
  onClick,
  color = "primary",
  ...rest
}) {
  const click = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      return onClick?.(event);
    },
    [onClick]
  );

  return <BaseButton {...rest} color={color} onClick={click} />;
};
