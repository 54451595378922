/* eslint-disable class-methods-use-this */
import { ToAPI } from "models/core";
import { ReactQueryAPI } from "models/core/reactQuery.interface";
import { GetTemplatesResponse } from "pages/salesInvoice/queries/useGetTemplates";
import { TemplateAPI } from "./useTemplatesUseCases";
import { Template } from "./models/template.interface";

export type TemplateAPIDTO = ReactQueryAPI<GetTemplatesResponse>;

interface Mapper {
  toAPI: ToAPI<TemplateAPIDTO, TemplateAPI>;
}

export class TemplateMapper implements Mapper {
  public toAPI: Mapper["toAPI"] = (api) => {
    const extractDTOs = (
      data: TemplateAPIDTO["data"]
    ): Omit<Template, "delete">[] => {
      if ((data as GetTemplatesResponse)?.items)
        return (data as GetTemplatesResponse).items;
      return [];
    };

    return {
      data: {
        templates: extractDTOs(api?.data),
        count: 0,
      },
      isLoading: api?.isLoading ?? false,
      error: api?.error ?? undefined,
    };
  };
}
