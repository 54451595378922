import i18next from "i18next";
import ky, { HTTPError } from "ky";

import { useAuth } from "hooks/useAuth";
// import { enableMocks } from "mocks.config";

export interface ApiError extends HTTPError {
  response: HTTPError["response"] & {
    payload?: {
      detail: string;
      status: number;
      message: string;
    };
  };
}

export const httpErrorCodeWithTranslations = [400, 403, 404, 405, 409, 500];

/* TODO: Get api url from env file */
const api = ky.extend({
  prefixUrl: process.env.REACT_APP_API,
  retry: 0,
  hooks: {
    beforeRequest: [
      (request) => {
        const { token } = useAuth.getState();
        const lang = i18next.resolvedLanguage;

        if (token) {
          request.headers.set("Authorization", `Bearer ${token}`);
        }

        if (lang) {
          request.headers.set("Accept-Language", lang);
        }
      },
    ],
    afterResponse: [
      async (_request, _options, response): Promise<Response | void> => {
        // const { logout } = useAuth.getState();
        // if no jwt is found on the server, log the user out
        if (response.status === 401) {
          // logout
        } else if (!response.ok) {
          (response as ApiError["response"]).payload = await response.json();
        }

        return response;
      },
    ],
  },
});

export { api };
