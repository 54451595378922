import { useQueryClient } from "react-query";
import { useApiMutation } from "hooks/api/useApiMutation";
import { event } from "event";
import { httpErrorCodeWithTranslations } from "helpers/api";
import { useTranslation } from "react-i18next";

export interface RemindSalesInvoicesInput {
  id: string;
}

export type RemindSalesInvoicesResponse = void;
export type RemindSalesInvoicesBody = RemindSalesInvoicesInput;

export function useRemindSalesInvoices() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useApiMutation<RemindSalesInvoicesResponse, RemindSalesInvoicesInput>(
    "remindSalesInvoices",
    (input) => `sales-invoices/${input.id}/reminder`,
    () => ({
      method: "post",
    }),
    {
      onSuccess: () => {
        event.emit("reminderSend");
        return Promise.all([queryClient.invalidateQueries(["salesInvoices"])]);
      },
      onError: (error) => {
        // For the time being: error messages are shown from the back-end (still in english).
        // They should be able to provide translated error messages since we
        // send the current language over the "Accept-Language" header
        // See: src/helpers/api.ts

        // They'll need to provide they want us
        // to translate messages on the front-end

        if (error?.response?.payload?.detail) {
          event.emit(
            "mutationFailed",
            new Error(error?.response?.payload?.detail)
          );
          return;
        }
        if (httpErrorCodeWithTranslations.includes(error.response.status)) {
          event.emit(
            "mutationFailed",
            new Error(t(`error.${error.response.status}`) as string)
          );
          return;
        }

        event.emit("mutationFailed", new Error(error.message));
      },
    },
    async (response) => {
      return response.json();
    }
  );
}
