import { FC, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Label } from "flowbite-react";
import {
  CreateContactInput,
  UpdateContactInput,
} from "models/contact/contact.interface";
import { HTMLValidationSchema } from "helpers/html-validation-schema";
import { HTMLValidationSchemaResolver } from "helpers/html-validation-schema/compat/HTMLValidationSchemaResolver";
import { HTMLValidationSchemaGenerator } from "helpers/html-validation-schema/models/validationSchema";
import { LoadingButton } from "components/LoadingButton";
import { TextInput } from "components/input/TextInput";
import { Select } from "../input/Select";

export type ContactFormValues = CreateContactInput | UpdateContactInput;

interface Props {
  defaultValues?: Partial<ContactFormValues>;
  isLoading?: boolean;
  schema:
    | HTMLValidationSchema<ContactFormValues>
    | HTMLValidationSchemaGenerator<ContactFormValues>;
  onSubmit:
    | ((input: ContactFormValues) => void)
    | ((input: ContactFormValues) => Promise<void>);
}

export const ContactForm: FC<Props> = function ({
  defaultValues,
  isLoading,
  schema,
  onSubmit,
}) {
  const { t, i18n } = useTranslation();
  const languages = useMemo(
    () => Object.keys(i18n.store.data),
    [i18n.store.data]
  );

  const { handleSubmit, register, formState } = useForm<ContactFormValues>({
    mode: "onBlur",
    defaultValues,
    resolver: HTMLValidationSchemaResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between gap-4 p-6">
        <div className="mb-4 flex flex-col flex-1 gap-2">
          <Label htmlFor="contactForm.input.name">
            {t("contactForm.input.name")}
          </Label>
          <TextInput
            {...register("name")}
            placeholder={t("contactForm.placeholder.name") as string}
            id="contactForm.input.name"
            errorMessage={formState.errors.name?.message}
          />
        </div>
        <div className="mb-4 flex flex-col flex-1 gap-2">
          <Label htmlFor="contactForm.input.languageCode">
            {t("contactForm.input.languageCode")}
          </Label>
          <Select
            {...register("languageCode")}
            placeholder={t("contactForm.placeholder.languageCode") as string}
            id="contactForm.input.languageCode"
            errorMessage={formState.errors.name?.message}
          >
            {languages.map((language) => (
              <option key={language} value={language}>
                {t(`language.${language}`)}
              </option>
            ))}
          </Select>
        </div>
      </div>

      <div className="border-1 border-t p-6 dark:border-gray-700">
        <LoadingButton
          type="submit"
          loading={isLoading}
          className="px-2 py-0.5"
        >
          {t("contactForm.button.save")}
        </LoadingButton>
      </div>
    </form>
  );
};
