import { DefaultTFuncReturn } from "i18next";
import { ComponentProps, ReactElement } from "react";
import classnames from "classnames";

interface H3Props extends ComponentProps<"h3"> {
  children: ReactElement | DefaultTFuncReturn | string;
}

export function H3({ children, ...rest }: H3Props) {
  return (
    <h3
      {...rest}
      className={classnames(
        "mb-2 font-semibold dark:text-white",
        rest.className
      )}
    >
      {children}
    </h3>
  );
}
