import { Product } from "hooks/products/models/product.interface";
import { PaginatedResponse } from "models/schema.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export type GetProductsResponse = PaginatedResponse<Omit<Product, "update">>;

export function useGetProductOptions(
  index: number,
  enabled: boolean,
  search?: string
) {
  return useApiQuery<GetProductsResponse>(
    ["productOptions", index],
    `articles?${new URLSearchParams({
      search: search ?? "",
    })}`,
    {},
    { enabled }
  );
}
