/* eslint-disable class-methods-use-this */
import { ToAPI } from "models/core";
import { ReactQueryAPI } from "models/core/reactQuery.interface";
import { GetTimesheetsResponse } from "pages/timesheets/queries/useGetTimesheets";
import { GetTimesheetResponse } from "pages/timesheets/queries/useGetTimesheet";
import { Timesheet } from "./models/timesheets.interface";
import { TimesheetAPI } from "./useTimesheetsUseCases";

export type TimesheetAPIDTO = ReactQueryAPI<
  GetTimesheetsResponse | GetTimesheetResponse
>;

interface Mapper {
  toAPI: ToAPI<TimesheetAPIDTO, TimesheetAPI>;
}

export class TimesheetMapper implements Mapper {
  public toAPI: Mapper["toAPI"] = (api) => {
    const extractDTOs = (
      data: TimesheetAPIDTO["data"]
    ): Omit<Timesheet, "generateInvoice" | "update">[] => {
      if ((data as GetTimesheetsResponse)?.content)
        return (data as GetTimesheetsResponse)?.content;
      if (data as GetTimesheetResponse) return [data as GetTimesheetResponse];
      return [];
    };

    return {
      data: {
        timesheets: extractDTOs(api?.data),
        count: (api?.data as GetTimesheetsResponse)?.totalElements ?? 0,
      },
      isLoading: (api?.isLoading || api?.isFetching) ?? false,
      error: api?.error ?? undefined,
    };
  };
}
