import { API, Mutation } from "models/core/API.interface";
import { DomainMutation, useDomainMutation } from "hooks/useDomainMutation";
import { UseCases } from "models/core";
import { useTranslation } from "react-i18next";
import { event } from "event";
import { HTMLValidationSchema } from "helpers/html-validation-schema";
import {
  CreateTemplateInput,
  DeleteTemplateInput,
} from "./models/templateInput.interface";
import { Template } from "./models/template.interface";

export type TemplateAPI = API<{
  templates: Omit<Template, "delete">[];
  count: number;
}>;
export type CreateTemplateMutation = Mutation<
  CreateTemplateInput,
  Omit<Template, "delete">
>;
export type DeleteTemplateMutation = Mutation<DeleteTemplateInput>;

interface Props {
  api: TemplateAPI;
  createMutation: CreateTemplateMutation;
  deleteMutation: DeleteTemplateMutation;
}

interface Result {
  templates: Template[];
  template?: Template;
  count: number;
  isLoading: boolean;
  error?: Error;
  create: DomainMutation<
    CreateTemplateInput,
    HTMLValidationSchema<CreateTemplateInput>,
    Omit<Template, "delete">
  >;
}

export const useTemplatesUseCases: UseCases<Props, Result> = ({
  api,
  createMutation,
  deleteMutation,
}) => {
  const { t } = useTranslation();

  const create: Result["create"] = useDomainMutation(
    {
      accentColor: { required: true },
      layout: { required: true },
    },
    createMutation
  );

  const deleteMethod = useDomainMutation<DeleteTemplateInput>(
    {
      id: { required: true },
    },
    async (input) => {
      await deleteMutation(input);
      event.emit("mutationSucceeded", t("domain.template.templateDeleted"));
    }
  );

  const templates: Result["templates"] = api.data.templates.map((template) => ({
    ...template,
    delete: deleteMethod,
  }));

  return {
    ...api,
    templates,
    template: templates[0],
    count: api.data.count,
    create,
  };
};
