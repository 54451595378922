import { useQueryClient } from "react-query";
import { format } from "date-fns";
import { useApiMutation } from "hooks/api/useApiMutation";
import { hhmmssToSeconds } from "helpers/to-seconds";
import {
  Timesheet,
  UpdateTimesheetInput,
} from "../models/timesheets.interface";

export interface UpdateTimesheetParams {
  id: Timesheet["id"];
}

export type UpdateTimesheetBody = UpdateTimesheetInput;
export type UpdateTimesheetResponse = void;

export function useUpdateTimesheet() {
  const queryClient = useQueryClient();

  return useApiMutation<
    UpdateTimesheetResponse,
    UpdateTimesheetParams & UpdateTimesheetBody
  >(
    "updateTimesheet",
    (input) => `timesheets/${input.id}`,
    (input) => ({
      method: "put",
      body: JSON.stringify({
        startTime: input.startTime
          ? format(new Date(input.startTime), "dd/MM/yyyy HH:mm:ss")
          : "",
        endTime: input.endTime
          ? format(new Date(input.endTime), "dd/MM/yyyy HH:mm:ss")
          : "",
        date: input.date ? format(new Date(input.date), "dd/MM/yyyy") : "",

        ...(input.customerId ? { customerId: input.customerId } : {}),
        ...(input.hourlyRate ? { hourlyRate: input.hourlyRate } : {}),
        ...(input.totalPrice ? { totalPrice: input.totalPrice } : {}),
        ...(input.timeToBill
          ? { timeToBill: hhmmssToSeconds(input.timeToBill) }
          : {}),
        ...(input.description ? { description: input.description } : {}),
        ...(input.articleId ? { articleId: input.articleId } : {}),
      }),
    }),
    {
      onSuccess: (_, input) => {
        return Promise.all([
          queryClient.invalidateQueries(["timesheets"]),
          queryClient.invalidateQueries(["timesheet", input.id]),
        ]);
      },
    }
  );
}
