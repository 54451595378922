import { FC, ReactNode } from "react";
import { Modal } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { Button } from "components/Button";

interface NoHeaderModalProps {
  show: boolean;
  size: string;
  isError?: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const NoHeaderModal: FC<NoHeaderModalProps> = function ({
  show,
  size,
  onClose,
  isError,
  children,
}: NoHeaderModalProps) {
  const { t } = useTranslation();

  return (
    <Modal show={show} onClose={onClose} size={size}>
      <Modal.Header />
      <Modal.Body>
        <div className="space-y-5 flex flex-col items-center">
          {isError ? (
            <div className="flex flex-col gap-4 p-5">
              <p className="mx-auto text-base leading-relaxed text-gray-400 dark:text-gray-400">
                error
              </p>
              <Button color="gray" onClick={onClose} className="mx-auto">
                {t("modal.error.back")}
              </Button>
            </div>
          ) : (
            children
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
