import {
  Customer,
  CustomerDTO,
} from "hooks/customers/models/customer.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export interface GetCustomerParams {
  id?: Customer["id"];
}

export type GetCustomerResponse = CustomerDTO;

export function useGetCustomer(input: GetCustomerParams) {
  return useApiQuery<GetCustomerResponse>(
    ["customers", input.id],
    `customers/${input.id}`,
    {},
    { enabled: !!input.id }
  );
}
