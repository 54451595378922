/* eslint-disable react-hooks/exhaustive-deps */
import lodash from "lodash";
import {
  DependencyList,
  EffectCallback,
  useCallback,
  useEffect,
  useRef,
} from "react";

export function useLazyEffect(
  effect: EffectCallback,
  deps: DependencyList = [],
  wait = 300
) {
  const cleanUp = useRef<void | (() => void)>();
  const effectRef = useRef<EffectCallback>();
  const updatedEffect = useCallback(effect, deps);
  effectRef.current = updatedEffect;
  const lazyEffect = useCallback(
    lodash.debounce(() => {
      cleanUp.current = effectRef.current?.();
    }, wait),
    []
  );
  useEffect(lazyEffect, deps);
  useEffect(() => {
    return () => {
      // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
      cleanUp.current instanceof Function ? cleanUp.current() : undefined;
    };
  }, []);
}
