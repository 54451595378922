import {
  InvoicePayStatus,
  InvoiceStatus,
  QuoteStatus,
} from "models/paymentDocument/invoice/invoice.interface";

export const invoiceBadgeMap: Map<InvoicePayStatus, string> = new Map<
  InvoicePayStatus,
  string
>([
  [InvoicePayStatus.Paid, "success"],
  [InvoicePayStatus.OverDue, "failure"],
  [InvoicePayStatus.Due, "blue"],
]);

export const invoiceStatusBadgeMap: Map<InvoiceStatus, string> = new Map<
  InvoiceStatus,
  string
>([
  [InvoiceStatus.Saved, "purple"],
  [InvoiceStatus.SendToAccountant, "blue"],
  [InvoiceStatus.SendToCustomer, "green"],
]);

export const quoteBadgeMap: Map<QuoteStatus, string> = new Map<
  QuoteStatus,
  string
>([
  [QuoteStatus.Saved, "info"],
  [QuoteStatus.Sent, "warning"],
  [QuoteStatus.ConvertedToInvoice, "success"],
]);
