import { Badge as BaseBadge } from "flowbite-react";
import { useTranslation } from "react-i18next";

interface BadgeProps<T> {
  value: T;
  map: Map<T, string>;
}

const Badge = function <T>({ value, map }: BadgeProps<T>) {
  const { t } = useTranslation();

  return (
    <BaseBadge
      className="px-2.5 w-fit whitespace-nowrap"
      color={map.get(value)}
    >
      {t(`badge.${value}`)}
    </BaseBadge>
  );
};

export default Badge;
