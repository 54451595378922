import { FC, useCallback, useMemo } from "react";
import classNames from "classnames";

import {
  CreateSupplierInput,
  CreateSupplierWithAddressInput,
  Supplier,
  UpdateSupplierInput,
} from "hooks/suppliers/models/supplier.interface";
import { DomainMutation } from "hooks/useDomainMutation";
import { removeProperties } from "helpers/remove-properties";
import AddressCard from "components/address/AddressCard";
import BankAccountCard from "components/bankAccount/BankAccountCard";
import ContactCard from "components/contact/ContactCard";
import GeneralInfoCard from "components/GeneralInfoCard";

import { SupplierForm, SupplierFormValues } from "./SupplierForm";
import { SupplierFormSkeleton } from "./SupplierFormSkeleton";

interface SupplierDetailProps {
  supplier?: Supplier;
  update?: DomainMutation<UpdateSupplierInput>;
  create?: DomainMutation<CreateSupplierInput | CreateSupplierWithAddressInput>;
  isLoading: boolean;
}

export const SupplierDetail: FC<SupplierDetailProps> = function ({
  supplier,
  isLoading,
  update,
  create,
}) {
  const supplierFormValues = useMemo(() => {
    if (!supplier) {
      return undefined;
    }

    return removeProperties(supplier, [
      "addAddress",
      "addBankAccount",
      "addContact",
      "update",
    ]);
  }, [supplier]);

  const submitCreateForm = useCallback(
    (input: CreateSupplierInput) => {
      if (!create) {
        return;
      }

      create?.execute(input);
    },
    [create]
  );

  const submitUpdateForm = useCallback(
    (input: UpdateSupplierInput) => {
      if (!update) {
        return;
      }

      update?.execute(input);
    },
    [update]
  );

  return (
    <div
      className="m-4 grid grid-cols-1 xl:grid-cols-5 gap-4"
      data-testid="root"
    >
      <div className="col-span-1 xl:col-span-3 flex flex-col gap-4">
        <GeneralInfoCard className="justify-start">
          {isLoading && <SupplierFormSkeleton />}
          {!isLoading && supplier && update && (
            <SupplierForm
              isEdit
              defaultValues={supplierFormValues}
              isLoading={update.isLoading}
              schema={update.schema}
              onSubmit={submitUpdateForm as (input: SupplierFormValues) => void}
            />
          )}
          {!isLoading && !supplier && create && (
            <SupplierForm
              isLoading={create.isLoading}
              schema={create.schema}
              onSubmit={submitCreateForm as (input: SupplierFormValues) => void}
            />
          )}
        </GeneralInfoCard>

        <AddressCard
          className={classNames("justify-start", {
            "opacity-40 pointer-events-none": !supplier && !isLoading,
          })}
          addresses={supplier?.addresses}
          createAddress={supplier?.addAddress}
          isLoading={isLoading}
        />

        <ContactCard
          className={classNames({
            "opacity-40 pointer-events-none": !supplier && !isLoading,
          })}
          contacts={supplier?.contacts}
          createContact={supplier?.addContact}
          isLoading={isLoading}
        />
      </div>

      <BankAccountCard
        className={classNames("justify-start h-fit col-span-1 xl:col-span-2", {
          "opacity-40 pointer-events-none": !supplier && !isLoading,
        })}
        bankAccounts={supplier?.bankAccounts}
        createBankAccount={supplier?.addBankAccount}
        isLoading={isLoading}
      />
    </div>
  );
};
