import { DomainMutation } from "hooks/useDomainMutation";
import { DeleteTemplateInput } from "./templateInput.interface";

export enum AccentColor {
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Green = "green",
  Blue = "blue",
  Purple = "purple",
  Gray = "gray",
}

export enum TemplateLayout {
  Template1 = "template-1",
}

export interface Template {
  id: string;
  accentColor: AccentColor;
  layout: TemplateLayout;
  delete: DomainMutation<DeleteTemplateInput>;
}
