import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { Table } from "flowbite-react";
import { useRoutes } from "domains";
import { useSearchParams } from "react-router-dom";

interface Props {
  sortKey: string;
  className?: string;
}

export const SortableHeadCell: FC<PropsWithChildren<Props>> = function ({
  sortKey,
  className,
  children,
}) {
  const { setSortParams } = useRoutes();
  const [searchParams] = useSearchParams();

  const { asc, desc, inactive } = useMemo(() => {
    const result = {
      asc: false,
      desc: false,
      inactive: true,
    };

    if (searchParams.get("sortKey") === sortKey) {
      const order = searchParams.get("sortOrder");
      result.asc = order !== "DESC";
      result.desc = !result.asc;
      result.inactive = false;
    }

    return result;
  }, [searchParams, sortKey]);

  const sortColumn = useCallback(
    () => setSortParams(sortKey),
    [setSortParams, sortKey]
  );

  return (
    <Table.HeadCell onClick={sortColumn} className={className}>
      <div className="flex gap-2 items-center cursor-pointer">
        {children}
        {inactive && <FaSort size="16" />}
        {asc && <FaSortUp size="16" />}
        {desc && <FaSortDown size="16" />}
      </div>
    </Table.HeadCell>
  );
};
