import { FC, PropsWithChildren } from "react";
import { ButtonProps } from "flowbite-react";
import { Button } from "components/Button";
import { Skeleton } from "./Skeleton";

export const SkeletonButton: FC<
  PropsWithChildren<Omit<ButtonProps, "onClick" | "type" | "disabled">>
> = function ({ children, ...rest }) {
  return (
    <Skeleton className="rounded-md whitespace-nowrap">
      <Button {...rest} disabled role="spinbutton">
        {children}
      </Button>
    </Skeleton>
  );
};
