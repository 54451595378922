import { InvoicePayStatus } from "models/paymentDocument/invoice/invoice.interface";
import { PurchaseInvoice } from "hooks/purchaseInvoices/models/purchaseInvoice.interface";
import { PaginatedResponse } from "models/schema.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export interface GetPurchaseInvoicesParams {
  offset: number;
  limit: number;
  search: string;
  invoicePayStatus: InvoicePayStatus | "";
  sortKey?: string;
  sortOrder?: string;
  refetchInterval?: number;
  from?: string;
  journalId: string;
  to?: string;
}
export type GetPurchaseInvoicesResponse = PaginatedResponse<
  Omit<PurchaseInvoice, "update">
>;

export const PURCHASE_INVOICES_QUERY = "purchaseInvoices";

export function useGetPurchaseInvoices(input: GetPurchaseInvoicesParams) {
  const size = Number(input.limit);
  const page = input.offset / size;

  return useApiQuery<GetPurchaseInvoicesResponse>(
    [
      PURCHASE_INVOICES_QUERY,
      input.offset,
      input.limit,
      input.search,
      input.invoicePayStatus,
      input.sortKey,
      input.sortOrder,
      input.from,
      input.to,
      input.journalId,
    ],
    `purchase-invoices?${new URLSearchParams({
      page: String(page),
      size: String(size),
      journal: input.journalId,
      search: input.search,
      invoicePayStatus: input.invoicePayStatus,
      sort:
        input?.sortKey && input?.sortOrder
          ? `${input.sortKey},${input.sortOrder}`
          : "",
      ...(!!input.to && {
        start: input?.from,
        end: input?.to,
      }),
    })}`,
    {},
    {
      enabled: !!input.journalId,
      refetchInterval: input.refetchInterval,
    }
  );
}
