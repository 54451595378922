import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { H2 } from "components/headers/H2";
import { Logo } from "components/logo/Logo";
import { HiOutlineUpload } from "react-icons/hi";
import { EditModal } from "components/modals/EditModal";
import { useRoutes } from "domains";
import { UploadFile } from "components/uploadFile";
import { useUploadCompanyLogo } from "hooks/file/mutations/useUpdateCompanyLogo";
import { LoadingButton } from "components/LoadingButton";

interface Props {
  logo?: string;
}
const COMPANY_LOGO_ACTION = "company-logo";
const maxFileSize = 10 * 1024 * 1024; // 10MB
const maxFileCount = 1;

export const CompanyLogo: FC<Props> = function ({ logo }) {
  const { t } = useTranslation();
  const { action } = useParams();
  const { goToCompanySettings } = useRoutes();

  const { mutate, isLoading } = useUploadCompanyLogo();

  const handleUpload = useCallback(
    (files: File[]) => {
      const formData = new FormData();
      formData.append("file", files[0]);
      mutate({ formData });
    },
    [mutate]
  );

  return (
    <>
      <H2>{t("companyForm.logo")}</H2>
      {logo ? (
        <Logo url={logo} />
      ) : (
        <i className="dark:text-white">{t("companyForm.noLogoDefined")}</i>
      )}
      <LoadingButton
        loading={isLoading}
        disabled={isLoading}
        onClick={() => goToCompanySettings(`/${COMPANY_LOGO_ACTION}`)}
      >
        <HiOutlineUpload className="text-xl mr-2" />
        {t("companyForm.uploadLogo")}
      </LoadingButton>
      {action === COMPANY_LOGO_ACTION && (
        <EditModal
          show
          action={action as "create"}
          isError={false}
          page="companyLogo"
          onClose={goToCompanySettings}
        >
          <UploadFile
            onUpload={handleUpload}
            isLoading={false}
            maxFileSize={maxFileSize}
            maxFileCount={maxFileCount}
            accept={["image/*"]}
          />
        </EditModal>
      )}
    </>
  );
};
