import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { LoadingButton } from "components/LoadingButton";

interface SendModalProps {
  onClose: () => void;
  onSend: () => void;
  isLoading?: boolean;
}

export const SendModal: FC<SendModalProps> = function ({
  onSend,
  onClose,
  isLoading,
}: SendModalProps) {
  const { t } = useTranslation();

  return (
    <>
      <p className="text-base text-center leading-relaxed text-gray-400 dark:text-gray-400">
        {t("sendModal.body.info")}
      </p>
      <div className="flex gap-4 justify-center">
        <LoadingButton onClick={onSend} loading={isLoading}>
          {t("sendModal.footer.send")}
        </LoadingButton>
        <Button color="gray" onClick={onClose}>
          {t("sendModal.footer.back")}
        </Button>
      </div>
    </>
  );
};
