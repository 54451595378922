/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "flowbite-react";
import format from "date-fns/format";
import { BankAccount } from "hooks/banks/models/bank.interface";
import { formatLabel } from "components/charts/helpers/formatLabel";
import { Currency } from "helpers/to-currency";
import { useRoutes } from "domains";

interface Props {
  connection: BankAccount;
}

export const ConnectionCard: FC<Props> = function ({ connection }) {
  const { t } = useTranslation();

  const { goToBankAccounts } = useRoutes();

  const handleGoToRevoke = useCallback(
    () => goToBankAccounts(`/revoke?iban=${connection.iban}`),
    [connection.iban, goToBankAccounts]
  );

  return (
    <Card key={connection.iban} className="max-w-sm">
      <div className="flex flex-grow p-4 flex-col items-center">
        <div className="relative max-h-28 flex flex-grow w-full">
          <img
            alt="bank logo"
            src={connection.logoURL}
            className="mb-3 rounded-full flex flex-grow"
          />
        </div>
        <h5 className="w-full text-center text-3xl font-medium text-gray-900 dark:text-white">
          {connection.balance
            ? formatLabel(connection.balance, connection.currency as Currency)
            : "-,-"}
        </h5>
        <p className="w-full text-center text-medium font-medium text-gray-400 dark:text-white mt-2">
          {connection.iban}
        </p>
      </div>
      <div className="flex pt-4 justify-between w-full border-1 border-t dark:border-gray-700 text-lg">
        <span className="text-gray-400 text-sm">
          {t("connections.card.consentUntil")}:&nbsp;
          {format(new Date(connection.consentUntil), "dd/MM/yyyy")}
        </span>
        <span
          className="text-red-400 cursor-pointer underline text-sm"
          onClick={handleGoToRevoke}
        >
          {t("connections.card.revoke")}
        </span>
      </div>
    </Card>
  );
};
