import { AppTheme } from "models/core/theme.interface";

export const setTheme = (theme: AppTheme) => {
  /* Primary colors (for buttons) theme */
  if (theme?.primary) {
    document.documentElement.style.setProperty("--primary", theme.primary);
  }

  if (theme?.primaryHighlight) {
    document.documentElement.style.setProperty(
      "--primary-highlight",
      theme.primaryHighlight
    );
  }

  /* Nav theme */
  if (theme?.nav) {
    Object.entries(theme.nav).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--nav-${key}`, value);
    });
  }

  if (theme?.navDark) {
    Object.entries(theme.navDark).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--nav-dark-${key}`, value);
    });
  }

  /* Sidebar theme */
  if (theme?.sidebar) {
    Object.entries(theme.sidebar).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--sidebar-${key}`, value);
    });
  }

  if (theme?.sidebarDark) {
    Object.entries(theme.sidebarDark).forEach(([key, value]) => {
      document.documentElement.style.setProperty(
        `--sidebar-dark-${key}`,
        value
      );
    });
  }
};
