import { SalesInvoice } from "hooks/salesInvoices/models/salesInvoice.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export interface GetSalesInvoiceParams {
  id?: SalesInvoice["id"];
}

export type GetSalesInvoiceResponse = Omit<SalesInvoice, "update">;

export function useGetSalesInvoice({ id }: GetSalesInvoiceParams) {
  return useApiQuery<GetSalesInvoiceResponse>(
    ["salesInvoices", id],
    `sales-invoices/${id}`,
    {},
    { enabled: !!id }
  );
}
