import {
  EbitdaEvolution,
  PeriodFilterParams,
} from "hooks/dashboard/models/dashboard.interface";
import { useApiQuery } from "hooks/api/useApiQuery";

export type GetEbitdaEvolutionParams = PeriodFilterParams;
export type GetEbitdaEvolutionResponse = EbitdaEvolution;

export function useGetEbitdaEvolution(input?: GetEbitdaEvolutionParams) {
  return useApiQuery<GetEbitdaEvolutionResponse>(
    ["ebitdaEvolution", input?.from, input?.to],
    `dashboards/ebitda-evolution?${new URLSearchParams({
      start_date: input?.from.toString() ?? "",
      end_date: input?.to.toString() ?? "",
    })}`
  );
}
