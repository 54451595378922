import { FC, useState, useEffect, useRef, useCallback } from "react";
import { Modal } from "flowbite-react";
import { useTranslation } from "react-i18next";
// import { HiArrowSmDown } from "react-icons/hi";
import { Terms } from "hooks/banks/models/bank.interface";
// import { Button } from "components/Button";
import { LoadingButton } from "components/LoadingButton";

interface TermsModalProps {
  show: boolean;
  size: string;
  onClose: () => void;
  onApprove: () => void;
  termsData?: Terms;
  isApprving: boolean;
}

export const TermsModal: FC<TermsModalProps> = function ({
  show,
  size,
  onClose,
  onApprove,
  termsData,
  isApprving,
}: TermsModalProps) {
  const { t } = useTranslation();

  const divRef = useRef<HTMLDivElement>(null);
  const [scrolledDown, setScrolledDown] = useState<boolean>(true);

  const handleScrollDown = useCallback(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
      setScrolledDown(true);
    }
  }, [setScrolledDown]);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has reached the bottom of the div
      if (divRef.current) {
        const isAtBottom =
          divRef.current.scrollTop + divRef.current.clientHeight ===
          divRef.current.scrollHeight;

        // If at the bottom, call your function
        if (isAtBottom) {
          handleScrollDown();
        }
      }
    };

    const divElement = divRef.current;

    if (divElement) {
      divElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (divElement) {
        divElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScrollDown]);

  return (
    <Modal show={show} onClose={onClose} size={size}>
      <Modal.Header>Accept the terms & conditions</Modal.Header>
      <div className="relative">
        <Modal.Body className="overflow-scroll max-h-[80vh]">
          <div className="relative max-h-[70vh]">
            <div className="pb-6 space-y-5 flex flex-col items-center text-sm leading-5 text-gray-500 whitespace-pre-line">
              <div
                ref={divRef}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: termsData?.tc || "" }}
              />
            </div>
          </div>
        </Modal.Body>
        <div className="blurry-bottom" />
        {/* <div className="flex justify-end bottom-4 right-4 z-10 absolute">
          <Button
            onClick={handleScrollDown}
            className="!rounded-full !w-10 !h-10 !max-h-10 !min-h-10 !max-w-10 !min-w-10"
          >
            <HiArrowSmDown />
          </Button>
        </div> */}
      </div>
      <div className="border-1 border-t p-6 dark:border-gray-700">
        <LoadingButton
          onClick={onApprove}
          disabled={!scrolledDown}
          type="submit"
          loading={isApprving}
          className="px-2 py-0.5"
        >
          {t("termsModal.button.approve")}
        </LoadingButton>
      </div>
    </Modal>
  );
};
