import { FC, useEffect, useMemo, useCallback, useContext } from "react";
import { Dropdown, Tooltip } from "flowbite-react";
import { HiQuestionMarkCircle } from "react-icons/hi";
import classNames from "classnames";
import { useGetJournals } from "pages/journals/queries/useGetJournals";
import { JournalContext } from "context/JournalContext";
import { JournalTypes } from "hooks/journals/models/journal.interface";
import { useTranslation } from "react-i18next";

interface JournalSelectorProps {
  type: JournalTypes;
}

interface JournalOptionProps {
  value: string;
  isActive: boolean;
  label: string;
  onChange: (value: string) => void;
}

const JournalOption: FC<JournalOptionProps> = function ({
  value,
  label,
  isActive,
  onChange,
}) {
  const onOptionClicked = useCallback(() => onChange(value), [value, onChange]);

  return (
    <Dropdown.Item
      key={value}
      onClick={onOptionClicked}
      className={classNames({
        "bg-gray-100 dark:bg-gray-600": isActive,
      })}
    >
      {label}
    </Dropdown.Item>
  );
};

export const JournalSelector: FC<JournalSelectorProps> = function ({ type }) {
  const { data } = useGetJournals();
  const { t } = useTranslation();

  const { journal, setJournal } = useContext(JournalContext);
  const onChange = useCallback(
    (value: string) => {
      const v = {
        ...journal,
        [type]: value,
      };
      setJournal(v);
    },
    [setJournal, type, journal]
  );

  const filteredJournalsByType = useMemo(
    () => (data || []).filter((j) => j.journalType === type),
    [data, type]
  );

  useEffect(() => {
    if (filteredJournalsByType?.length && !journal?.[type]) {
      setJournal({ ...journal, [type]: filteredJournalsByType[0].id });
    }
  }, [filteredJournalsByType, type, setJournal, journal]);

  const activeJournal = useMemo(
    () => filteredJournalsByType.find((j) => j.id === journal?.[type]),
    [journal, filteredJournalsByType, type]
  );

  return (
    <div className="flex items-center gap-2">
      <Tooltip placement="left" content={t("switchJournal")}>
        <HiQuestionMarkCircle />
      </Tooltip>
      <Dropdown
        inline
        label={activeJournal?.name}
        trigger="hover"
        className="py-2"
      >
        {filteredJournalsByType.map((j) => (
          <JournalOption
            key={j.id}
            value={j.id}
            label={j.name}
            isActive={journal?.[type] === j.id}
            onChange={onChange}
          />
        ))}
      </Dropdown>
    </div>
  );
};
