import { useTranslation } from "react-i18next";
import { event } from "event";
import { DomainMutation, useDomainMutation } from "hooks/useDomainMutation";
import { API } from "models/core/API.interface";
import { UseCases, Mutation } from "models/core";
import { UploadCSVInput } from "models/csv/csv.interface";
import {
  CreateAddressInput,
  createAddressSchema,
  UpdateAddressInput,
  DeleteAddressInput,
} from "models/address/address.interface";
import {
  CreateBankAccountInput,
  createBankAccountSchema,
  UpdateBankAccountInput,
  DeleteBankAccountInput,
} from "models/bankAccount/bankAccount.interface";
import {
  CreateContactInput,
  createContactSchema,
  UpdateContactInput,
  DeleteContactInput,
} from "models/contact/contact.interface";
import { removeProperties } from "helpers/remove-properties";
import { HTMLValidationSchema } from "helpers/html-validation-schema";
import { addSupplierSubEntitiesIds } from "helpers/supplier/addSupplierSubEntitiesIds";
import {
  CreateSupplierApiInput,
  CreateSupplierInput,
  CreateSupplierWithAddressInput,
  DeleteSuppliersInput,
  Supplier,
  SupplierDTO,
  SupplierDTOWithEntityIds,
  UpdateSupplierInput,
} from "./models/supplier.interface";
import { CREATE_SUPPLIER_PARAM } from "../../pages/purchaseInvoice/purchase-invoice";
import { UpdateSupplierApiInput } from "./mutations/useUpdateSupplier";

export type SupplierAPI = API<{
  suppliers: SupplierDTO[];
  count: number;
}>;
export type CreateSupplierMutation = Mutation<CreateSupplierInput, Supplier>;
export type UpdateSupplierMutation = Mutation<UpdateSupplierApiInput>;
export type DeleteSupplierMutation = Mutation<DeleteSuppliersInput>;
export type UploadCSVMutation = Mutation<UploadCSVInput>;

interface Props {
  api: SupplierAPI;
  createMutation: CreateSupplierMutation;
  updateMutation: UpdateSupplierMutation;
  deleteMutation: DeleteSupplierMutation;
  uploadCSVMutation: UploadCSVMutation;
}

interface Result {
  supplier?: Supplier;
  suppliers: Supplier[];
  count: number;
  isLoading: boolean;
  error?: Error;
  create: DomainMutation<CreateSupplierInput>;
  createFromInvoice: DomainMutation<CreateSupplierWithAddressInput>;
  deleteMany: DomainMutation<DeleteSuppliersInput>;
  uploadCSV: DomainMutation<UploadCSVInput>;
}

export const useSuppliersUseCases: UseCases<Props, Result> = ({
  api,
  updateMutation,
  createMutation,
  deleteMutation,
  uploadCSVMutation,
}) => {
  const { t } = useTranslation();

  const create: Result["create"] =
    useDomainMutation<CreateSupplierWithAddressInput>(
      {
        name: { required: true },
        vatNumber: { required: false },
        email: { required: false },
      },
      async (input) => {
        const { address, ...rest } = input;

        const supplier = await createMutation({
          ...rest,
          addresses: [
            {
              ...address,
            },
          ],
        } as CreateSupplierApiInput);

        event.emit("supplierSaved", supplier?.id);
        event.emit("mutationSucceeded", t("domain.supplier.supplierSaved"));
      }
    );

  const createFromInvoice: Result["createFromInvoice"] = useDomainMutation(
    {
      ...create.schema,
      address: {
        street: { required: false },
        number: { required: false },
        bus: { required: false },
        zipCode: { required: false },
        city: { required: false },
        country: { required: false },
        type: { required: false },
      },
    },
    async (input) => {
      const { address, ...rest } = input;

      const supplier = await createMutation({
        ...rest,
        addresses: [
          {
            ...address,
          },
        ],
      } as CreateSupplierApiInput);

      if (supplier) {
        event.emit("supplierCreatedFromInvoice", supplier.id);
        event.emit("closeDifferentEntity", CREATE_SUPPLIER_PARAM);
        event.emit("mutationSucceeded", t("domain.supplier.customerSaved"));
      }
    }
  );

  const update = useDomainMutation<UpdateSupplierInput>(
    {
      id: { required: true },
      name: { required: true },
      email: { required: false },
      vatNumber: { required: false },
    },
    async (input) => {
      const {
        addresses = [],
        contacts = [],
        bankAccounts = [],
        ...supplierDTO
      } = input;

      await updateMutation({
        ...supplierDTO,
        addresses: addresses?.map((address) =>
          removeProperties(address, ["update", "id", "remove"])
        ),
        contacts: contacts?.map((contact) =>
          removeProperties(contact, ["update", "id", "remove"])
        ),
        bankAccounts: bankAccounts?.map((bankAccount) =>
          removeProperties(bankAccount, ["update", "id", "remove"])
        ),
      });

      event.emit("supplierSaved", input.id);
      event.emit("mutationSucceeded", t("domain.supplier.supplierSaved"));
    }
  );

  const deleteMany: Result["deleteMany"] = useDomainMutation(
    {
      ids: [{ required: true }],
    },
    async (input) => {
      await deleteMutation(input);
      event.emit("suppliersDeleted");
      event.emit("mutationSucceeded", t("domain.supplier.supplierDeleted"));
    }
  );

  const createContact = useDomainMutation<
    CreateContactInput,
    HTMLValidationSchema<CreateContactInput>,
    void,
    SupplierDTOWithEntityIds
  >(createContactSchema, async (input, supplier) => {
    if (!supplier) {
      return;
    }

    const { contacts } = supplier;

    const contactDTOs = contacts?.map((contact) =>
      removeProperties(contact, ["id"])
    );

    await updateMutation({
      ...supplier,
      addresses: supplier.addresses?.map((address) =>
        removeProperties(address, ["id"])
      ),
      contacts: [...contactDTOs, input],
      bankAccounts: supplier.bankAccounts?.map((bankAccount) =>
        removeProperties(bankAccount, ["id"])
      ),
    } as UpdateSupplierApiInput);

    event.emit("supplierContactSaved", supplier.id);
    event.emit(
      "mutationSucceeded",
      t("domain.supplier.contactSaved") as string
    );
  });

  const updateContact = useDomainMutation<
    UpdateContactInput,
    HTMLValidationSchema<UpdateContactInput>,
    void,
    SupplierDTOWithEntityIds
  >(
    {
      id: { required: true },
      ...createContact.schema,
    },
    async (input, supplier) => {
      if (!supplier) {
        return;
      }

      const contacts = supplier.contacts.slice();

      const updatedContactIndex = contacts.findIndex(
        (contact) => contact.id === input.id
      );

      if (updatedContactIndex > -1) {
        contacts[updatedContactIndex] = {
          ...contacts[updatedContactIndex],
          ...(input.name ? { name: input.name } : {}),
          ...(input.languageCode ? { languageCode: input.languageCode } : {}),
        };
      }

      await updateMutation({
        ...supplier,
        addresses: supplier.addresses?.map((address) =>
          removeProperties(address, ["id"])
        ),
        contacts: contacts?.map((contact) => removeProperties(contact, ["id"])),
        bankAccounts: supplier.bankAccounts?.map((bankAccount) =>
          removeProperties(bankAccount, ["id"])
        ),
      });

      event.emit("supplierContactSaved", supplier.id);
      event.emit(
        "mutationSucceeded",
        t("domain.supplier.contactSaved") as string
      );
    }
  );

  const deleteContact = useDomainMutation<
    DeleteContactInput,
    HTMLValidationSchema<DeleteContactInput>,
    void,
    SupplierDTOWithEntityIds
  >(
    {
      id: { required: true },
    },
    async (input, supplier) => {
      if (!supplier) {
        return;
      }

      const contacts = supplier.contacts.slice();

      const contactToDeleteIndex = contacts.findIndex(
        (contact) => contact.id === input.id
      );

      if (contactToDeleteIndex > -1) {
        contacts.splice(contactToDeleteIndex, 1);
      }

      await updateMutation({
        ...supplier,
        addresses: supplier.addresses?.map((address) =>
          removeProperties(address, ["id"])
        ),
        contacts: contacts?.map((contact) => removeProperties(contact, ["id"])),
        bankAccounts: supplier.bankAccounts?.map((bankAccount) =>
          removeProperties(bankAccount, ["id"])
        ),
      });

      event.emit("supplierContactDeleted", supplier.id);
      event.emit(
        "mutationSucceeded",
        t("domain.supplier.contactDeleted") as string
      );
    }
  );

  const createAddress = useDomainMutation<
    CreateAddressInput,
    HTMLValidationSchema<CreateAddressInput>,
    void,
    SupplierDTO
  >(createAddressSchema, async (input, supplier) => {
    if (!supplier) {
      return;
    }

    await updateMutation({
      ...supplier,
      addresses: [...supplier.addresses, input],
    });

    event.emit("supplierAddressSaved", supplier.id);
    event.emit(
      "mutationSucceeded",
      t("domain.supplier.addressSaved") as string
    );
  });

  const updateAddress = useDomainMutation<
    UpdateAddressInput,
    HTMLValidationSchema<UpdateAddressInput>,
    void,
    SupplierDTOWithEntityIds
  >(
    {
      id: { required: true },
      ...createAddress.schema,
    },
    async (input, supplier) => {
      if (!supplier) {
        return;
      }

      const addresses = supplier.addresses.slice();

      const updatedAddressIndex = addresses.findIndex(
        (address) => address.id === input.id
      );

      if (updatedAddressIndex > -1) {
        addresses[updatedAddressIndex] = {
          ...addresses[updatedAddressIndex],
          ...(input.street ? { street: input.street } : {}),
          ...(input.number ? { number: input.number } : {}),
          ...{ bus: input.bus ?? "" },
          ...(input.zipCode ? { zipCode: input.zipCode } : {}),
          ...(input.city ? { city: input.city } : {}),
          ...(input.country ? { country: input.country } : {}),
          ...(input.type ? { type: input.type } : {}),
          ...(input.description ? { description: input.description } : {}),
        };
      }

      await updateMutation({
        ...supplier,
        addresses: addresses?.map((address) =>
          removeProperties(address, ["id"])
        ),
        contacts: supplier.contacts?.map((contact) =>
          removeProperties(contact, ["id"])
        ),
        bankAccounts: supplier.bankAccounts?.map((bankAccount) =>
          removeProperties(bankAccount, ["id"])
        ),
      });

      event.emit("supplierAddressSaved", supplier.id);
      event.emit(
        "mutationSucceeded",
        t("domain.supplier.addressSaved") as string
      );
    }
  );

  const deleteAddress = useDomainMutation<
    DeleteAddressInput,
    HTMLValidationSchema<DeleteAddressInput>,
    void,
    SupplierDTOWithEntityIds
  >(
    {
      id: { required: true },
    },
    async (input, supplier) => {
      if (!supplier) {
        return;
      }

      const addresses = supplier.addresses.slice();

      const addressToDeleteIndex = addresses.findIndex(
        (address) => address.id === input.id
      );

      if (addressToDeleteIndex > -1) {
        addresses.splice(addressToDeleteIndex, 1);
      }

      await updateMutation({
        ...supplier,
        addresses: addresses?.map((address) =>
          removeProperties(address, ["id"])
        ),
        contacts: supplier.contacts?.map((contact) =>
          removeProperties(contact, ["id"])
        ),
        bankAccounts: supplier.bankAccounts?.map((bankAccount) =>
          removeProperties(bankAccount, ["id"])
        ),
      });

      event.emit("supplierAddressDeleted", supplier.id);
      event.emit(
        "mutationSucceeded",
        t("domain.supplier.addressDeleted") as string
      );
    }
  );

  const createBankAccount = useDomainMutation<
    CreateBankAccountInput,
    HTMLValidationSchema<CreateBankAccountInput>,
    void,
    SupplierDTO
  >(createBankAccountSchema, async (input, supplier) => {
    if (!supplier) {
      return;
    }

    await updateMutation({
      ...supplier,
      bankAccounts: [...supplier.bankAccounts, input],
    });

    event.emit("supplierBankAccountSaved", supplier.id);
    event.emit(
      "mutationSucceeded",
      t("domain.supplier.bankAccountSaved") as string
    );
  });

  const updateBankAccount = useDomainMutation<
    UpdateBankAccountInput,
    HTMLValidationSchema<UpdateBankAccountInput>,
    void,
    SupplierDTOWithEntityIds
  >(
    {
      id: { required: true },
      ...createBankAccount.schema,
    },
    async (input, supplier) => {
      if (!supplier) {
        return;
      }

      const bankAccounts = supplier.bankAccounts.slice();

      const updatedBankAccountIndex = bankAccounts.findIndex(
        (bankAccount) => bankAccount.id === input.id
      );

      if (updatedBankAccountIndex > -1) {
        bankAccounts[updatedBankAccountIndex] = {
          ...bankAccounts[updatedBankAccountIndex],
          ...(input.iban ? { iban: input.iban } : {}),
          ...(input.bic ? { bic: input.bic } : {}),
          ...(input.description ? { description: input.description } : {}),
        };
      }

      await updateMutation({
        ...supplier,
        addresses: supplier.addresses?.map((address) =>
          removeProperties(address, ["id"])
        ),
        contacts: supplier.contacts?.map((contact) =>
          removeProperties(contact, ["id"])
        ),
        bankAccounts: bankAccounts?.map((bankAccount) =>
          removeProperties(bankAccount, ["id"])
        ),
      });

      event.emit("supplierBankAccountSaved", supplier.id);
      event.emit(
        "mutationSucceeded",
        t("domain.supplier.bankAccountSaved") as string
      );
    }
  );

  const deleteBankAccount = useDomainMutation<
    DeleteBankAccountInput,
    HTMLValidationSchema<DeleteBankAccountInput>,
    void,
    SupplierDTOWithEntityIds
  >(
    {
      id: { required: true },
    },
    async (input, supplier) => {
      if (!supplier) {
        return;
      }

      const bankAccounts = supplier.bankAccounts.slice();

      const bankAccountToDeleteIndex = bankAccounts.findIndex(
        (bankAccount) => bankAccount.id === input.id
      );

      if (bankAccountToDeleteIndex > -1) {
        bankAccounts.splice(bankAccountToDeleteIndex, 1);
      }

      await updateMutation({
        ...supplier,
        addresses: supplier.addresses?.map((address) =>
          removeProperties(address, ["id"])
        ),
        contacts: supplier.contacts?.map((contact) =>
          removeProperties(contact, ["id"])
        ),
        bankAccounts: bankAccounts?.map((bankAccount) =>
          removeProperties(bankAccount, ["id"])
        ),
      });

      event.emit("supplierBankAccountDeleted", supplier.id);
      event.emit(
        "mutationSucceeded",
        t("domain.supplier.bankAccountDeleted") as string
      );
    }
  );

  const suppliers: Result["suppliers"] = api.data.suppliers?.map((supplier) => {
    const s = {
      ...supplier,
      ...addSupplierSubEntitiesIds(supplier),
    };

    return {
      ...s,
      contacts: s.contacts?.map((contact) => ({
        ...contact,
        update: {
          ...updateContact,
          execute: (data) => updateContact.execute(data, s),
        },
        remove: {
          ...deleteContact,
          execute: (data) => deleteContact.execute(data, s),
        },
      })),
      addresses: s.addresses?.map((address) => ({
        ...address,
        update: {
          ...updateAddress,
          execute: (data) => updateAddress.execute(data, s),
        },
        remove: {
          ...deleteAddress,
          execute: (data) => deleteAddress.execute(data, s),
        },
      })),
      bankAccounts: s.bankAccounts?.map((bankAccount) => ({
        ...bankAccount,
        update: {
          ...updateBankAccount,
          execute: (data) => updateBankAccount.execute(data, s),
        },
        remove: {
          ...deleteBankAccount,
          execute: (data) => deleteBankAccount.execute(data, s),
        },
      })),
      addContact: {
        ...createContact,
        execute: (data) => createContact.execute(data, s),
      },
      addAddress: {
        ...createAddress,
        execute: (data) => createAddress.execute(data, supplier),
      },
      addBankAccount: {
        ...createBankAccount,
        execute: (data) => createBankAccount.execute(data, supplier),
      },
      update,
    };
  });

  const uploadCSV: Result["uploadCSV"] = useDomainMutation<UploadCSVInput>(
    {
      formData: { required: true },
    },
    async (input) => {
      await uploadCSVMutation(input);

      event.emit("supplierCSVUploaded");
      event.emit("CSVUploaded");
    }
  );

  return {
    ...api,
    suppliers,
    supplier: suppliers[0],
    count: api.data.count,
    create,
    createFromInvoice,
    deleteMany,
    uploadCSV,
  };
};
