import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePencilAlt, HiTrash } from "react-icons/hi";
import { Dropdown, Table } from "flowbite-react";

import { useRoutes } from "domains";

import { Pagination, PaginationProps } from "components/Pagination";
import { EmptyTableRow } from "components/EmptyTableRow";
import { SkeletonRows } from "components/skeletons/SkeletonRows";
import { SkeletonRow } from "components/skeletons/SkeletonRow";
import { Skeleton } from "components/skeletons/Skeleton";
import { SortableHeadCell } from "components/table/SortableHeadCell";
import { TableRow } from "components/table/TableRow";
import { Checkbox } from "components/Checkbox";
import { RoundedIconDropdown } from "components/table/RoundedIconDropdown";

import { Currency, toCurrency } from "helpers/to-currency";

import { Product } from "hooks/products/models/product.interface";
import { RowSelectedState } from "hooks/tables/models/tables.interface";

interface Props extends Omit<PaginationProps, "rows"> {
  rows: Product[];
  isLoading?: boolean;
  handleAllRowsClicked: (ids: string[]) => void;
  handleOneRowClicked: (id: string) => void;
  rowSelectedState: RowSelectedState;
}

interface ProductTableRowProps extends Product {
  selected: boolean;
  onSelect: (id: string) => void;
}

const ProductTableRow: FC<ProductTableRowProps> = function ({
  onSelect,
  selected,
  ...product
}) {
  const { t } = useTranslation();
  const { openDeleteModal, openEditModal } = useRoutes();

  const onOpenDeleteModal = useCallback(
    () => openDeleteModal([product.id]),
    [product, openDeleteModal]
  );

  const onOpenEditModal = useCallback(() => {
    openEditModal(product.id);
  }, [product, openEditModal]);

  const select = useCallback(() => onSelect(product.id), [product, onSelect]);

  return (
    <TableRow onClick={onOpenEditModal}>
      <Table.Cell>
        <Checkbox onChange={select} checked={selected} />
      </Table.Cell>
      <Table.Cell>
        <p
          className="truncate text-gray-900 dark:text-white font-medium"
          role="cell"
        >
          {product.name}
        </p>
        <p className="text-gray-500 dark:text-gray-400" role="cell">
          {product.reference}
        </p>
      </Table.Cell>
      <Table.Cell>{product.articleGroupName ?? "-"}</Table.Cell>
      <Table.Cell>
        {toCurrency(
          product.amountExclusiveVat,
          product.currency || Currency.Euro
        )}
      </Table.Cell>
      <Table.Cell>{`${product.vatPercentage}%`}</Table.Cell>
      <Table.Cell>
        <RoundedIconDropdown className="w-max mr-2">
          <Dropdown.Item
            className="flex items-center py-3 rounded-t-lg"
            onClick={onOpenEditModal}
            role="button"
          >
            <HiOutlinePencilAlt className="mr-2 text-lg" />
            {t("productTable.button.edit")}
          </Dropdown.Item>
          <Dropdown.Item
            className="flex items-center py-3 rounded-b-lg"
            onClick={onOpenDeleteModal}
            role="button"
          >
            <HiTrash className="mr-2 text-lg text-red-500 shrink-0" />
            <span className="text-red-500 shrink-0">
              {t("productTable.button.delete")}
            </span>
          </Dropdown.Item>
        </RoundedIconDropdown>
      </Table.Cell>
    </TableRow>
  );
};

const LoadingProductRows: FC = function () {
  return (
    <SkeletonRows rows={10}>
      <SkeletonRow>
        <Table.Cell />
        <Table.Cell>
          <div className="flex flex-col gap-4">
            <Skeleton />
            <Skeleton className="w-6/12" />
          </div>
        </Table.Cell>
        <Table.Cell>
          <Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Skeleton isText>{toCurrency(100000, Currency.Euro)}</Skeleton>
        </Table.Cell>
        <Table.Cell>
          <Skeleton isText>10%</Skeleton>
        </Table.Cell>
        <Table.Cell>
          <Skeleton>
            <RoundedIconDropdown />
          </Skeleton>
        </Table.Cell>
      </SkeletonRow>
    </SkeletonRows>
  );
};

const ProductTable: FC<Props> = function ({
  rows,
  isLoading,
  handleAllRowsClicked,
  handleOneRowClicked,
  rowSelectedState,
  ...rest
}) {
  const { t } = useTranslation();

  const onAllClicked = useCallback(
    () => handleAllRowsClicked(rows.map((prod) => prod.id)),
    [handleAllRowsClicked, rows]
  );

  return (
    <div className="flex flex-col justify-between overflow-hidden">
      <Table
        hoverable
        className="divide-y divide-gray-200 dark:divide-gray-600 table-fixed"
      >
        <Table.Head className="!bg-gray-100 dark:!bg-gray-700">
          <Table.HeadCell className="w-4">
            <Checkbox
              aria-label="check-all"
              checked={rowSelectedState.all}
              onChange={onAllClicked}
            />
          </Table.HeadCell>
          <SortableHeadCell sortKey="reference">
            {t("productTable.title.name")}
          </SortableHeadCell>
          <Table.HeadCell>
            {t("productTable.title.productGroup")}
          </Table.HeadCell>
          <Table.HeadCell>{t("productTable.title.price")}</Table.HeadCell>
          <Table.HeadCell className="w-48">
            {t("productTable.title.vat")}
          </Table.HeadCell>
          <Table.HeadCell className="w-24" />
        </Table.Head>
        <Table.Body className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
          {isLoading && <LoadingProductRows />}
          {!isLoading &&
            rows.map((product) => (
              <ProductTableRow
                key={product.id}
                {...product}
                selected={rowSelectedState.ids.includes(product.id)}
                onSelect={handleOneRowClicked}
              />
            ))}
          {!isLoading && rows.length === 0 && (
            <EmptyTableRow colSpan={6}>
              {t("productTable.title.empty")}
            </EmptyTableRow>
          )}
        </Table.Body>
      </Table>
      <Pagination {...rest} />
    </div>
  );
};

export default ProductTable;
