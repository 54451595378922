import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { HiShoppingCart } from "react-icons/hi";

import { useProductGroups, useProducts, useRoutes, useTables } from "domains";

import { EditModal } from "components/modals/EditModal";
import { DeleteModal } from "components/modals/DeleteModal";
import { ActionBar, PageHeader } from "components/PageHeader";
import { AUTO_REFRESH_INTERVAL_KEY } from "components/AutoRefresh";
import { UploadCSV } from "components/UploadCSV";

import { useTablesEffects } from "hooks/tables/useTablesUseCases";

import { HTMLValidationSchemaGenerator } from "helpers/html-validation-schema";
import ProductTable from "./components/ProductTable";
import ProductForm, { ProductFormValues } from "./components/ProductForm";
import { useGetProducts } from "./queries/useGetProducts";
import { useGetProduct } from "./queries/useGetProduct";
import { useGetProductGroups } from "../productGroups/queries/useGetProductGroups";
import {
  PRODUCTS_XSLX_KEY,
  useGetProductsXLSX,
} from "./queries/useGetProductsXLSX";
import ProductGroupForm, {
  ProductGroupFormValues,
} from "../productGroups/components/ProductGroupForm";
import { useGetProductGroup } from "../productGroups/queries/useGetProductGroup";

export const CREATE_PRODUCT_GROUP_PARAM = "create-product-group";

function ProductsPage() {
  const [searchParams] = useSearchParams();
  const [idsToDelete, setIdsToDelete] = useState<string[]>([]);
  const [refetchInterval, setRefetchInterval] = useState<number>(
    parseInt(localStorage.getItem(AUTO_REFRESH_INTERVAL_KEY) ?? "", 10)
  );
  const productTable = useTables();
  const { routes, goToProducts, closeDifferentEntityModal } = useRoutes();
  const { t } = useTranslation();
  const { rowSelectedState, handleAllRowsClicked, handleOneRowClicked } =
    productTable;
  useTablesEffects(productTable);

  const getProducts = useGetProducts({
    limit: Number(searchParams.get("limit")) || 10,
    offset: Number(searchParams.get("offset")) || 0,
    search: searchParams.get("search") || "",
    articleGroupId: searchParams.get("articleGroupId") || "",
    sortKey: searchParams.get("sortKey") || "",
    sortOrder: searchParams.get("sortOrder") || "",
    refetchInterval,
  });

  const getXLSX = useGetProductsXLSX({
    search: searchParams.get("search") || "",
    sortKey: searchParams.get("sortKey") || "",
    sortOrder: searchParams.get("sortOrder") || "",
  });

  const {
    products,
    count,
    isLoading: isLoadingProducts,
  } = useProducts(getProducts);

  const { productGroups } = useProductGroups(useGetProductGroups());
  const productGroupsDropDownOptions = useMemo(
    () =>
      productGroups.map((productGroup) => ({
        value: productGroup.name,
        key: productGroup.id,
      })),
    [productGroups]
  );

  const { action, id, subAction } = useParams();
  const { product, isLoading, error, create, deleteMany, uploadCSV } =
    useProducts(
      useGetProduct({
        id,
      })
    );

  const { error: productGroupError, createFromProduct: createProductGroup } =
    useProductGroups(
      useGetProductGroup({
        id: product?.articleGroup?.id,
      })
    );

  const onDeleteProduct = useCallback(
    () => deleteMany.execute({ ids: idsToDelete }),
    [idsToDelete, deleteMany]
  );

  const closeProductGroupModal = useCallback(
    () => closeDifferentEntityModal(CREATE_PRODUCT_GROUP_PARAM),
    [closeDifferentEntityModal]
  );

  useEffect(() => {
    if (action === "delete") {
      const ids = searchParams.getAll("idsToDelete");
      setIdsToDelete(ids);
    }
  }, [action, setIdsToDelete, searchParams]);

  return (
    <div className="flex flex-col">
      <PageHeader
        className="bg-white dark:bg-gray-800"
        title={t("pageHeader.title.products")}
        breadcrumbs={[
          {
            href: routes.billing,
            label: t("sidebar.title.products") as string,
            icon: HiShoppingCart,
          },
          {
            href: routes.products,
            label: t("sidebar.link.products") as string,
          },
        ]}
      >
        <ActionBar
          showBulkDelete
          pageKey="products"
          onRefetch={getProducts.refetch}
          onRefetchIntervalChange={setRefetchInterval}
          rowSelectedState={rowSelectedState}
          dropdownKey="articleGroupId"
          dropdownOptions={productGroupsDropDownOptions}
          queryKey={PRODUCTS_XSLX_KEY}
          {...getXLSX}
        />
      </PageHeader>
      <ProductTable
        rows={products}
        isLoading={isLoadingProducts}
        limit={Number(searchParams.get("limit")) || 10}
        total={count}
        handleAllRowsClicked={handleAllRowsClicked}
        handleOneRowClicked={handleOneRowClicked}
        rowSelectedState={rowSelectedState}
      />
      {action === "delete" && !!idsToDelete && (
        <DeleteModal
          isLoading={isLoading}
          isDeleting={deleteMany.isLoading}
          isError={!!error}
          isMultiple={idsToDelete.length > 1}
          page="products"
          show={!!idsToDelete}
          onClose={goToProducts}
          onDelete={onDeleteProduct}
        />
      )}
      {action === "create" && (
        <EditModal
          action={action as "create"}
          isError={!!error}
          itemName={product?.name ?? ""}
          page="products"
          onClose={goToProducts}
          show
        >
          <ProductForm
            productGroups={productGroups}
            isLoading={create.isLoading}
            onSubmit={create.execute as (input: ProductFormValues) => void}
            schema={
              create.schema as HTMLValidationSchemaGenerator<ProductFormValues>
            }
          />
        </EditModal>
      )}
      <UploadCSV
        action={action}
        uploadCSV={uploadCSV}
        onClose={goToProducts}
        page="products"
      />
      {action === "edit" && product && (
        <EditModal
          action={action as "edit"}
          isLoading={product.update.isLoading}
          isError={!!product.update.error}
          itemName={product.name ?? ""}
          page="products"
          onClose={goToProducts}
          show={!subAction}
        >
          <ProductForm
            product={product}
            productGroups={productGroups}
            isLoading={product.update.isLoading}
            onSubmit={
              product.update.execute as (input: ProductFormValues) => void
            }
            schema={
              product.update
                .schema as HTMLValidationSchemaGenerator<ProductFormValues>
            }
          />
        </EditModal>
      )}
      {action === "edit" && subAction === CREATE_PRODUCT_GROUP_PARAM && (
        <EditModal
          action="create"
          isError={!!productGroupError}
          page="productGroups"
          onClose={closeProductGroupModal}
          show
        >
          <ProductGroupForm
            productGroup={{ name: searchParams.get("initialValue") ?? "" }}
            isLoading={createProductGroup.isLoading}
            onSubmit={
              createProductGroup.execute as (
                data: ProductGroupFormValues
              ) => void
            }
            schema={createProductGroup.schema}
          />
        </EditModal>
      )}
    </div>
  );
}

export default ProductsPage;
