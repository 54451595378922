/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "../models/validationSchema";
import { validateSchema } from "../validateSchema";

const isDate = (date: unknown): date is Date =>
  Object.prototype.toString.call(date) === "[object Date]";

const isArray = <Type>(arr: Type | Type[]): arr is Type[] => Array.isArray(arr);

const isObject = (obj: unknown): obj is Record<string, unknown> =>
  typeof obj === "object" && !isArray(obj) && !isDate(obj) && obj !== null;

const getErrors = <Input extends { [s: string]: string } | ArrayLike<string>>(
  input: Input
): string[] =>
  Object.values(input)
    .filter((value) => value !== undefined)
    .map((value) => (isObject(value) ? getErrors(value) : value))
    .flat();

export const isValid = <Input extends object>(
  input: Input,
  schema: HTMLValidationSchema<Input> | HTMLValidationSchemaGenerator<Input>
): boolean => {
  const errors = validateSchema(
    input,
    typeof schema === "function" ? (schema(input) as any) : schema
  );

  return getErrors<any>(errors).length === 0;
};
