import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

import { useApiMutation } from "hooks/api/useApiMutation";

import { Template } from "../models/template.interface";

export interface DeleteTemplateParams {
  id: Template["id"];
}

export type DeleteTemplateResponse = void;

export function useDeleteTemplate() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useApiMutation<DeleteTemplateResponse, DeleteTemplateParams>(
    "deleteTemplate",
    (input) => `templates/${input.id}`,
    {
      method: "delete",
    },
    {
      onSuccess: () => queryClient.invalidateQueries("templates"),
      onError: (error) => {
        const { status } = error.response;

        switch (status) {
          case 403:
            throw new Error(
              t(
                "domain.template.error.templateIsLinkedToExistingSalesInvoices"
              ) as string
            );
          default:
            throw new Error(error.message);
        }
      },
    }
  );
}
