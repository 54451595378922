import { UploadCSVInput, UploadCSVResponse } from "models/csv/csv.interface";
import { useApiMutation } from "hooks/api/useApiMutation";

export function useUploadProductCSV() {
  return useApiMutation<UploadCSVResponse, UploadCSVInput>(
    "productsCSV",
    `articles-csv`,
    (input: UploadCSVInput) => ({
      method: "put",
      body: input.formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  );
}
