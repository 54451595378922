import { FC, MouseEvent, useCallback, useEffect, useState } from "react";
import classNames from "classnames";

interface LanguageTab {
  language: string;
  content: React.ReactNode;
  hasError: boolean;
}

interface LanguageTabsProps {
  languages: string[];
  tabs: LanguageTab[];
  onLanguageChange?: (lng: string) => void;
}

export const LanguageTabBar: FC<LanguageTabsProps> = function ({
  languages,
  tabs,
  onLanguageChange,
}) {
  const sortedLanguages = languages.sort();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    sortedLanguages[0]
  );

  useEffect(() => {
    setSelectedLanguage(sortedLanguages[0]);
  }, [sortedLanguages]);

  useEffect(() => {
    if (onLanguageChange) {
      onLanguageChange(selectedLanguage);
    }
  }, [onLanguageChange, selectedLanguage]);

  const onLanguageButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const lng = e.currentTarget.dataset?.lng;

      if (lng) {
        setSelectedLanguage(lng);
      }
    },
    []
  );

  return (
    <div>
      <ul className="flex w-full gap-3 justify-start">
        {sortedLanguages.map((language) => {
          const hasError = tabs.find(
            (tab) => tab.language === language
          )?.hasError;

          return (
            <li key={language}>
              <button
                type="button"
                data-lng={language}
                onClick={onLanguageButtonClick}
                className={classNames(
                  `relative flex h-min rounded-lg border !py-0.5 px-4`,
                  {
                    "bg-primary text-white border-primary":
                      selectedLanguage === language,
                    "bg-white dark:bg-transparent text-gray-900 hover:bg-gray-100 dark:text-white dark:border-gray-400":
                      selectedLanguage !== language,
                  }
                )}
              >
                <span className="!py-2 text-sm uppercase">{language}</span>
                {hasError && (
                  <span className="absolute -top-1.5 -right-1.5 w-3 h-3 bg-red-500 text-white rounded-full" />
                )}
              </button>
            </li>
          );
        })}
      </ul>

      <div className="mt-4">
        {tabs.map((tab) =>
          selectedLanguage === tab.language ? (
            <div key={tab.language} className="block">
              {tab.content}
            </div>
          ) : (
            <div key={tab.language} className="hidden">
              {tab.content}
            </div>
          )
        )}
      </div>
    </div>
  );
};
