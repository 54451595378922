import { create } from "zustand";
import { persist } from "zustand/middleware";

interface AuthState {
  token: null | string;
  // eslint-disable-next-line no-unused-vars
  login: (token: string) => void;
  logout: () => void;
}

export const useAuth = create(
  persist(
    (set) => ({
      token: null,
      login: (token: string) => set({ token }),
      logout: () => {
        set({ token: null });
      },
    }),
    {
      name: "w11-auth-store",
      partialize: ({ token }: AuthState) => ({
        token,
      }),
    }
  )
);
