import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "flowbite-react";
import { useRoutes } from "domains";
import { Quote } from "hooks/quotes/models/quote.interface";
import { RowSelectedState } from "hooks/tables/models/tables.interface";
import { EmptyTableRow } from "components/EmptyTableRow";
import { Pagination, PaginationProps } from "components/Pagination";
import { LoadingInvoiceRows } from "components/invoice/LoadingInvoiceRows";
import { InvoiceTableHead } from "components/invoice/InvoiceTableHead";
import { InvoiceTableRow } from "components/invoice/InvoiceTableRow";
import { InvoicePayStatus } from "models/paymentDocument/invoice/invoice.interface";

interface Props extends Omit<PaginationProps, "rows"> {
  rows: Quote[];
  isLoading?: boolean;
  handleAllRowsClicked: (ids: string[]) => void;
  handleOneRowClicked: (id: string) => void;
  rowSelectedState: RowSelectedState;
}

const QuoteTable: FC<Props> = function ({
  rows,
  isLoading,
  handleAllRowsClicked,
  handleOneRowClicked,
  rowSelectedState,
  ...rest
}) {
  const { t } = useTranslation();
  const { goToQuote } = useRoutes();

  const onAllClicked = useCallback(
    () => handleAllRowsClicked(rows.map((prod) => prod.id)),
    [handleAllRowsClicked, rows]
  );

  const onEdit = useCallback(
    (id: string) => {
      goToQuote(id);
    },
    [goToQuote]
  );

  return (
    <div className="flex flex-col justify-between overflow-hidden">
      <Table
        hoverable
        className="divide-y divide-gray-200 dark:divide-gray-600 table-fixed"
      >
        <InvoiceTableHead
          isQuote
          onSelectAll={onAllClicked}
          allSelected={rowSelectedState.all}
        />
        <Table.Body className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
          {isLoading && (
            <LoadingInvoiceRows
              isQuote
              filteredOnStatus={InvoicePayStatus.Paid}
            />
          )}
          {!isLoading &&
            rows.map((quote) => (
              <InvoiceTableRow
                key={quote.id}
                {...quote}
                totalInclusiveVat={
                  (quote?.totalInclusiveVat || 0) + (quote?.vatPrice || 0)
                }
                companyName={quote?.customerName}
                selected={rowSelectedState.ids.includes(quote.id)}
                onSelect={handleOneRowClicked}
                onEdit={onEdit}
              />
            ))}
          {!isLoading && rows.length === 0 && (
            <EmptyTableRow colSpan={7}>
              {t("quoteTable.title.empty")}
            </EmptyTableRow>
          )}
        </Table.Body>
      </Table>
      <Pagination {...rest} />
    </div>
  );
};

export default QuoteTable;
