import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { SummaryOverview } from "./components/summary/Summary";
import { EbitdaEvolutionOverview } from "./components/ebitdaEvolution/EbitdaEvolution";
import { FinancialEvolutionOverview } from "./components/financialEvolution/FinancialEvolution";
import { UnpaidInvoices } from "./components/unpaidInvoices/UnpaidInvoices";

const DashboardPage: FC = function () {
  const { t } = useTranslation();

  const { instance } = useMsal();

  const account = instance.getActiveAccount();
  const name = account?.idTokenClaims?.name;

  return (
    <div className="p-4 pt-16 flex gap-4 flex-col">
      <div className="dark:text-white">
        <strong>{t("dashboardPage.welcome", { name })}</strong>
        {` ${t("dashboardPage.overview")}`}
      </div>
      <SummaryOverview />
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
        <UnpaidInvoices />
        <EbitdaEvolutionOverview />
      </div>
      <FinancialEvolutionOverview />
    </div>
  );
};

export default DashboardPage;
