import { useTranslation } from "react-i18next";
import { event } from "event";

import { HTMLValidationSchemaGenerator } from "helpers/html-validation-schema";
import { DomainMutation, useDomainMutation } from "hooks/useDomainMutation";
import { API } from "models/core/API.interface";
import { Mutation, UseCases } from "models/core";
import { UploadCSVInput } from "models/csv/csv.interface";

import { ProductFormValues } from "pages/products/components/ProductForm";
import {
  CreateProductInput,
  DeleteProductsInput,
  Product,
  ProductDescription,
  UpdateProductInput,
} from "./models/product.interface";

export type ProductAPI = API<{
  products: Omit<Product, "update">[];
  count: number;
}>;
export type CreateMutation = Mutation<CreateProductInput, Product>;
export type UpdateMutation = Mutation<UpdateProductInput>;
export type DeleteMutation = Mutation<DeleteProductsInput>;
export type UploadCSVMutation = Mutation<UploadCSVInput>;

interface Props {
  api: ProductAPI;
  createProductMutation: CreateMutation;
  updateProductMutation: UpdateMutation;
  deleteProductsMutation: DeleteMutation;
  uploadCSVMutation: UploadCSVMutation;
}

interface Result {
  products: Product[];
  product?: Product;
  count: number;
  isLoading: boolean;
  create: DomainMutation<
    CreateProductInput,
    HTMLValidationSchemaGenerator<CreateProductInput>
  >;
  createFromInvoice: DomainMutation<
    CreateProductInput,
    HTMLValidationSchemaGenerator<CreateProductInput>,
    Product
  >;
  deleteMany: DomainMutation<DeleteProductsInput>;
  uploadCSV: DomainMutation<UploadCSVInput>;
  error?: Error;
}

export const replaceMissingDescriptions = (
  product: CreateProductInput
): CreateProductInput => {
  const descriptions: ProductDescription[] = [];

  const firstDescription = (product?.descriptions || [])[0]?.description;

  product?.descriptions?.forEach((d, index) => {
    if (index > 0 && !d.description) {
      descriptions.push({ ...d, description: firstDescription });
      return;
    }
    descriptions.push(d);
  });

  return {
    ...product,
    descriptions,
  };
};

const productSchema = (values: ProductFormValues) => ({
  descriptions: values.descriptions.map(() => ({
    code: { required: false },
    description: { required: false },
  })),
  reference: { required: true },
  amountExclusiveVat: { required: false },
  vatPercentage: { required: false },
  articleGroupId: { required: false },
});

export const useProductsUseCases: UseCases<Props, Result> = ({
  api,
  createProductMutation,
  updateProductMutation,
  deleteProductsMutation,
  uploadCSVMutation,
}) => {
  const { t } = useTranslation();

  const create: Result["create"] = useDomainMutation<
    CreateProductInput,
    HTMLValidationSchemaGenerator<CreateProductInput>
  >(productSchema, async (input) => {
    await createProductMutation(replaceMissingDescriptions(input));
    event.emit("productSaved");
    event.emit("mutationSucceeded", t("domain.product.productSaved"));
  });

  const createFromInvoice = useDomainMutation<
    CreateProductInput,
    HTMLValidationSchemaGenerator<CreateProductInput>,
    Product
  >(productSchema, async (input) => {
    const product = await createProductMutation(
      replaceMissingDescriptions(input)
    );
    event.emit("mutationSucceeded", t("domain.product.productSaved"));
    return product;
  });

  const update = useDomainMutation<
    UpdateProductInput,
    HTMLValidationSchemaGenerator<UpdateProductInput>
  >(
    (values) => ({
      id: { required: true },
      ...productSchema(values),
    }),
    async (input) => {
      await updateProductMutation(input);
      event.emit("productSaved");
      event.emit("mutationSucceeded", t("domain.product.productSaved"));
    }
  );

  const deleteMany: Result["deleteMany"] =
    useDomainMutation<DeleteProductsInput>(
      {
        ids: [{ required: true }],
      },
      async (input) => {
        await deleteProductsMutation(input);
        event.emit("productsDeleted");
        event.emit("mutationSucceeded", t("domain.product.productsDeleted"));
      }
    );

  const products: Result["products"] = api.data.products.map((product) => ({
    ...product,
    update,
  }));

  const uploadCSV: Result["uploadCSV"] = useDomainMutation<UploadCSVInput>(
    {
      formData: { required: true },
    },
    async (input) => {
      await uploadCSVMutation(input);

      event.emit("productCSVUploaded");
      event.emit("CSVUploaded");
    }
  );

  return {
    ...api,
    products,
    product: products[0],
    count: api.data.count,
    create,
    createFromInvoice,
    deleteMany,
    uploadCSV,
  };
};
