import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { Label } from "flowbite-react";
import classnames from "classnames";

import {
  CreateCustomerInput,
  CreateCustomerWithAddressInput,
  // CustomerType,
  UpdateCustomerInput,
} from "hooks/customers/models/customer.interface";
import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "helpers/html-validation-schema";
import { HTMLValidationSchemaResolver } from "helpers/html-validation-schema/compat/HTMLValidationSchemaResolver";
import { AddressType, Country } from "models/address/address.interface";
import { LoadingButton } from "components/LoadingButton";
import { KBOLookup } from "components/input/KBOLookup";
import { Select } from "components/input/Select";
import { TextInput } from "components/input/TextInput";

export type CustomerFormValues =
  | (CreateCustomerInput | CreateCustomerWithAddressInput)
  | UpdateCustomerInput;

interface Props {
  isInModal?: boolean;
  isEdit?: boolean;
  withAddress?: boolean;
  defaultValues?: CustomerFormValues;
  isLoading?: boolean;
  schema:
    | HTMLValidationSchema<CustomerFormValues>
    | HTMLValidationSchemaGenerator<CustomerFormValues>;
  onSubmit: (input: CustomerFormValues) => void;
}

export const CustomerForm: FC<Props> = function ({
  isInModal,
  isEdit,
  defaultValues,
  isLoading,
  schema,
  onSubmit,
  withAddress = false,
}) {
  const { t } = useTranslation();
  const methods = useForm<CustomerFormValues>({
    mode: "onBlur",
    defaultValues,
    resolver: HTMLValidationSchemaResolver(schema),
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className={`flex flex-col gap-4 ${isInModal ? "px-6 pt-6" : ""}`}>
          <div className={classnames("flex flex-col gap-4")}>
            <div className="flex gap-4">
              <div className="w-1/2 flex flex-col gap-2">
                <Label htmlFor="customerForm.name">
                  {t("customerForm.input.name")}
                </Label>
                <KBOLookup
                  id="customerForm.name"
                  isEdit={isEdit}
                  placeholder={t("customerForm.placeholder.name") as string}
                  formKey="name"
                />
              </div>
              <div className="w-1/2 flex flex-col gap-2">
                <Label htmlFor="customerForm.vatNumber">
                  {t("customerForm.input.vatNumber")}
                </Label>
                <KBOLookup
                  id="customerForm.vatNumber"
                  isEdit={isEdit}
                  placeholder={
                    t("customerForm.placeholder.vatNumber") as string
                  }
                  formKey="vatNumber"
                />
              </div>
            </div>
            <div className="flex gap-4">
              {/* <div className="w-1/2">
                <div className="flex flex-col flex-1 gap-2">
                  <Label htmlFor="customerForm.customerType">
                    {t("customerForm.input.customerType")}
                  </Label>
                  <Select
                    {...methods.register("customerType")}
                    placeholder={
                      t("customerForm.placeholder.customerType") as string
                    }
                    id="customerForm.customerType"
                    errorMessage={
                      methods.formState.errors.customerType?.message
                    }
                  >
                    {Object.values(CustomerType).map((value) => (
                      <option key={value} value={value}>
                        {t(`customerType.${value.toLowerCase()}`)}
                      </option>
                    ))}
                  </Select>
                </div>
              </div> */}
              <div className="w-full">
                <div className="mb-6 flex flex-col flex-1 gap-2">
                  <Label htmlFor="customerForm.email">
                    {t("customerForm.input.email")}
                  </Label>
                  <TextInput
                    {...methods.register("email")}
                    placeholder={t("customerForm.placeholder.email") as string}
                    id="customerForm.email"
                    errorMessage={methods.formState.errors.email?.message}
                    type="email"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {withAddress && (
          <div className="pt-0 mb-6 px-6">
            <hr className="h-px mt-2 mb-6 bg-gray-200 border-0 dark:bg-gray-700" />
            <div className={classnames("flex flex-col", "gap-4  rounded-lg")}>
              <div className="flex gap-4">
                <div className="w-1/3">
                  <div className="mb-2 block">
                    <Label htmlFor="street">
                      {t("addressForm.input.street")}
                    </Label>
                  </div>
                  <TextInput
                    {...methods.register("address.street", { required: true })}
                    placeholder={t("addressForm.placeholder.street") as string}
                    id="street"
                    errorMessage={
                      (
                        methods.formState
                          .errors as FieldErrors<CreateCustomerWithAddressInput>
                      )?.address?.street?.message
                    }
                  />
                </div>
                <div className="w-1/3">
                  <div className="mb-2 block">
                    <Label htmlFor="number">
                      {t("addressForm.input.number")}
                    </Label>
                  </div>
                  <TextInput
                    {...methods.register("address.number")}
                    placeholder={t("addressForm.placeholder.number") as string}
                    id="number"
                    errorMessage={
                      (
                        methods.formState
                          .errors as FieldErrors<CreateCustomerWithAddressInput>
                      )?.address?.number?.message
                    }
                  />
                </div>
                <div className="w-1/3">
                  <div className="mb-2 block">
                    <Label htmlFor="bus">{t("addressForm.input.bus")}</Label>
                  </div>
                  <TextInput
                    {...methods.register("address.bus")}
                    placeholder={t("addressForm.placeholder.bus") as string}
                    id="bus"
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <div className="w-1/3">
                  <div className="mb-2 block">
                    <Label htmlFor="zipCode">
                      {t("addressForm.input.zipCode")}
                    </Label>
                  </div>
                  <TextInput
                    {...methods.register("address.zipCode")}
                    placeholder={t("addressForm.placeholder.zipCode") as string}
                    id="zipCode"
                    errorMessage={
                      (
                        methods.formState
                          .errors as FieldErrors<CreateCustomerWithAddressInput>
                      )?.address?.zipCode?.message
                    }
                  />
                </div>
                <div className="w-1/3">
                  <div className="mb-2 block">
                    <Label htmlFor="city">{t("addressForm.input.city")}</Label>
                  </div>
                  <TextInput
                    {...methods.register("address.city")}
                    placeholder={t("addressForm.placeholder.city") as string}
                    id="city"
                    errorMessage={
                      (
                        methods.formState
                          .errors as FieldErrors<CreateCustomerWithAddressInput>
                      )?.address?.city?.message
                    }
                  />
                </div>
                <div className="w-1/3">
                  <div className="mb-2 block">
                    <Label htmlFor="country">
                      {t("addressForm.input.country")}
                    </Label>
                  </div>
                  <Select
                    {...methods.register("address.country")}
                    placeholder={t("addressForm.placeholder.country") as string}
                    id="country"
                    errorMessage={
                      (
                        methods.formState
                          .errors as FieldErrors<CreateCustomerWithAddressInput>
                      )?.address?.country?.message
                    }
                  >
                    {Object.values(Country).map((value) => (
                      <option key={value} value={value}>
                        {t(`country.${value}`)}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="flex justify-between gap-4">
                <div className="mb-6 flex flex-col flex-1 gap-2">
                  <Label htmlFor="address-type">
                    {t("addressForm.input.type")}
                  </Label>
                  <Select
                    {...methods.register("address.type")}
                    placeholder={t("addressForm.placeholder.type") as string}
                    id="address-type"
                    errorMessage={(
                      methods.formState
                        .errors as FieldErrors<CreateCustomerWithAddressInput>
                    )?.address?.type?.toString()}
                  >
                    {Object.values(AddressType).map((value) => (
                      <option key={value} value={value}>
                        {t(`addressType.${value.toLowerCase()}`)}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className={classnames(
            isInModal ? "border-1 border-t p-6 dark:border-gray-700" : ""
          )}
        >
          <LoadingButton type="submit" loading={isLoading}>
            {t("customerForm.button.save")}
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
};
