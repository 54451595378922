import { useMutation, useQueryClient } from "react-query";

import { api } from "helpers/api";
import {
  // DiscountTypes,
  InvoicePayStatus,
} from "models/paymentDocument/invoice/invoice.interface";
import { UpdateInvoiceInput } from "models/paymentDocument/invoice/invoiceInput.interface";
import { event } from "event";
// import { useTemplates } from "domains";
import { format } from "date-fns";
import { SalesInvoice } from "../models/salesInvoice.interface";

export interface UpdateSalesInvoiceParams {
  id: SalesInvoice["id"];
}

export type UpdateSalesInvoiceBody = UpdateInvoiceInput;

export type UpdateSalesInvoiceResponse = void;

export function useUpdateSalesInvoice() {
  const queryClient = useQueryClient();
  // const { create: createTemplate } = useTemplates();

  return useMutation<
    UpdateSalesInvoiceResponse,
    Error,
    UpdateSalesInvoiceParams & UpdateSalesInvoiceBody
  >(
    "updateSalesInvoice",
    async (input: UpdateSalesInvoiceParams & UpdateSalesInvoiceBody) => {
      // let template;

      // if (input.template) {
      //   if (input.template.id) {
      //     template = input.template;
      //   } else {
      //     template = await createTemplate.execute({
      //       accentColor: input.template.accentColor,
      //       layout: input.template.layout,
      //     });
      //   }
      // }

      const response = await api.put(
        `api/v1/sales-invoices/${input.id}?force=true`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            currency: input.currency,
            extraInformation: input.extraInformation ?? "",
            reference: input.reference,
            ...(input.customerId ? { customerId: input.customerId } : {}),
            issueDate: input.issueDate
              ? format(new Date(input.issueDate), "dd/MM/yyyy")
              : "",
            dueDate: input.dueDate
              ? format(new Date(input.dueDate), "dd/MM/yyyy")
              : "",
            ...(input.documentLines
              ? { documentLines: input.documentLines }
              : {}),
            ...(input.invoicePayStatus
              ? { invoicePayStatus: input.invoicePayStatus }
              : {}),
            ...(input.invoicePayStatus === InvoicePayStatus.Paid &&
            input.freeCommunication
              ? { freeCommunication: input.freeCommunication }
              : { freeCommunication: null }),
            ...(input.invoicePayStatus === InvoicePayStatus.Paid &&
            input.structuredCommunication
              ? { structuredCommunication: input.structuredCommunication }
              : { structuredCommunication: null }),
            ...(input.invoicePayStatus === InvoicePayStatus.Paid &&
            input.payDate
              ? { payDate: format(new Date(input.payDate), "dd/MM/yyyy") }
              : { payDate: null }),
            ...(input.invoicePayStatus === InvoicePayStatus.Paid &&
            input.invoicePayType
              ? { invoicePayType: input.invoicePayType }
              : { invoicePayType: null }),
            // ...(input.discount?.type &&
            // input.discount?.type !== DiscountTypes.NoDiscount &&
            // input.discount?.amount
            //   ? {
            //       discount: {
            //         type: input.discount.type,
            //         amount: input.discount.amount,
            //         description: input.discount.description || "",
            //       },
            //     }
            //   : {}),
            // ...(template ? { template } : {}),
          }),
        }
      );

      if (response.ok) {
        return;
      }

      throw new Error(response.statusText);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("salesInvoices");
      },
      onError: (error) => {
        // For the time being: error messages are shown from the back-end (still in english).
        // They should be able to provide translated error messages since we
        // send the current language over the "Accept-Language" header
        // See: src/helpers/api.ts

        // They'll need to provide they want us
        // to translate messages on the front-end

        if (error?.message) {
          event.emit("mutationFailed", new Error(error?.message));

          return;
        }

        event.emit("mutationFailed", new Error(error.message));
      },
    }
  );
}
