import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import classnames from "classnames";

import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "helpers/html-validation-schema";
import { HTMLValidationSchemaResolver } from "helpers/html-validation-schema/compat/HTMLValidationSchemaResolver";
import { LoadingButton } from "components/LoadingButton";
import { H2 } from "components/headers/H2";
import { UpdateInvoiceSettingsInput } from "hooks/invoiceSettings/models/invoiceSettings.interface";
import { TextArea } from "components/input/TextArea";

export type InvoiceSettingsFormValues = UpdateInvoiceSettingsInput;

interface Props {
  languages?: string[];
  defaultValues?: InvoiceSettingsFormValues;
  isLoading?: boolean;
  schema:
    | HTMLValidationSchema<InvoiceSettingsFormValues>
    | HTMLValidationSchemaGenerator<InvoiceSettingsFormValues>;
  onSubmit: (input: InvoiceSettingsFormValues) => void;
}

export const InvoiceSettingsForm: FC<Props> = function ({
  defaultValues,
  languages = ["en", "nl", "fr"],
  isLoading,
  schema,
  onSubmit,
}) {
  const [invoiceLanguage, setInvoiceLanguage] = useState<string>(languages[0]);
  const [quoteLanguage, setQuoteLanguage] = useState<string>(languages[0]);
  const { t } = useTranslation();
  const methods = useForm<InvoiceSettingsFormValues>({
    mode: "onBlur",
    defaultValues,
    resolver: HTMLValidationSchemaResolver(schema),
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4">
          <div className={classnames("flex flex-col gap-4")}>
            <H2>{t("invoiceSettingsPage.title.invoiceSettings")}</H2>
            <div className="flex gap-4">
              <ul className="flex flex-col gap-3 justify-start">
                {languages.map((l) => (
                  <li className="flex w-12" key={`invoice_${l}`}>
                    <button
                      type="button"
                      onClick={() => setInvoiceLanguage(l)}
                      className={classnames(
                        `relative flex h-min rounded-lg border !py-0.5 px-4`,
                        {
                          "bg-primary text-white border-primary":
                            invoiceLanguage === l,
                          "bg-white dark:bg-transparent text-gray-900 hover:bg-gray-100 dark:text-white dark:border-gray-400":
                            invoiceLanguage !== l,
                        }
                      )}
                    >
                      <span className="!py-2 text-sm">{l.toUpperCase()}</span>
                    </button>
                  </li>
                ))}
              </ul>
              {languages.map((l) => (
                <div
                  key={`invoice-field-${l}`}
                  className={classnames(`"w-full flex flex-col flex-1 gap-2"`, {
                    "hidden opacity-0": invoiceLanguage !== l,
                  })}
                >
                  <TextArea
                    {...methods.register(`invoiceConditions.${l}`)}
                    rows={7}
                    errorMessage={methods.formState.errors.taxProfile?.message}
                  />
                </div>
              ))}
            </div>
            <hr className="h-px mt-4 mb-4 bg-gray-200 border-0 dark:bg-gray-700" />
            <H2>{t("invoiceSettingsPage.title.quoteSettings")}</H2>
            <div className="flex gap-4">
              <ul className="flex flex-col gap-3 justify-start">
                {languages.map((l) => (
                  <li className="flex w-12" key={`quote_${l}`}>
                    <button
                      type="button"
                      onClick={() => setQuoteLanguage(l)}
                      className={classnames(
                        `relative flex h-min rounded-lg border !py-0.5 px-4`,
                        {
                          "bg-primary text-white border-primary":
                            quoteLanguage === l,
                          "bg-white dark:bg-transparent text-gray-900 hover:bg-gray-100 dark:text-white dark:border-gray-400":
                            quoteLanguage !== l,
                        }
                      )}
                    >
                      <span className="!py-2 text-sm">{l.toUpperCase()}</span>
                    </button>
                  </li>
                ))}
              </ul>
              {languages.map((l) => (
                <div
                  key={`quote-field-${l}`}
                  className={classnames(`"w-full flex flex-col flex-1 gap-2"`, {
                    "hidden opacity-0": quoteLanguage !== l,
                  })}
                >
                  <TextArea
                    {...methods.register(`quoteConditions.${l}`)}
                    rows={7}
                    errorMessage={methods.formState.errors.taxProfile?.message}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-8">
          <LoadingButton type="submit" loading={isLoading}>
            {t("invoiceSettingsPage.button.save")}
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
};
