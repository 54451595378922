import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Card } from "flowbite-react";
import { useDashboard } from "domains";
import { Skeleton } from "components/skeletons/Skeleton";
import { Currency, toCurrency } from "helpers/to-currency";
import { getPeriodFilter } from "helpers/get-period-filters";
import { SummaryStat } from "hooks/dashboard/models/dashboard.interface";
import { PeriodFilter, PeriodOption } from "../PeriodFilter";
import { Delta } from "../Delta";
import { useGetSummary } from "../../queries/useGetSummary";

interface SummaryDetailProps {
  summaryStat?: SummaryStat;
  isLoading: boolean;
  label: string;
  isCost?: boolean;
}

export const SUMMARY_PERIOD_FILTER = "period_summary";

const LoadingSummaryDetail: FC = function () {
  return (
    <>
      <Skeleton isText className="h-7">
        {toCurrency(1000000, Currency.Euro)}
      </Skeleton>
      <Skeleton isText>15%</Skeleton>
    </>
  );
};

const EmptySummaryDetail: FC = function () {
  const { t } = useTranslation();

  return <p>{t("dashboardPage.summary.somethingWentWrong")}</p>;
};

const SummaryDetail: FC<SummaryDetailProps> = function ({
  summaryStat,
  label,
  isCost,
  isLoading,
}) {
  return (
    <Card className="grow">
      <p className="text-xs font-medium text-gray-500 dark:text-gray-400">
        {label.toUpperCase()}
      </p>
      <div className="flex justify-between dark:text-white items-center">
        {isLoading && <LoadingSummaryDetail />}
        {!isLoading && summaryStat && (
          <>
            <p className="text-xl font-bold">
              {typeof summaryStat.quantity === "number"
                ? toCurrency(summaryStat.quantity, Currency.Euro)
                : "-"}
            </p>
            {typeof summaryStat.delta === "number" && (
              <small>
                <Delta delta={summaryStat.delta} isCost={isCost} showSign />
              </small>
            )}
          </>
        )}
        {!isLoading && !summaryStat && <EmptySummaryDetail />}
      </div>
    </Card>
  );
};

export const SummaryOverview: FC = function () {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { summary, summaryLoading: isLoading } = useDashboard({
    summary: useGetSummary(
      getPeriodFilter(
        (searchParams.get(SUMMARY_PERIOD_FILTER) as PeriodOption) ?? undefined
      )
    ),
  });

  return (
    <div>
      <div className="flex justify-end mb-4">
        <PeriodFilter name={SUMMARY_PERIOD_FILTER} />
      </div>
      <div className="grid xl:grid-cols-4 xs:grid-cols-1 md:grid-cols-2 gap-6">
        <SummaryDetail
          label={t(`dashboardPage.summary.totalTurnOver`)}
          summaryStat={summary.totalTurnover}
          isLoading={isLoading}
        />
        <SummaryDetail
          isCost
          label={t(`dashboardPage.summary.totalCost`)}
          summaryStat={summary.totalCost}
          isLoading={isLoading}
        />
        <SummaryDetail
          label={t(`dashboardPage.summary.totalEbitda`)}
          summaryStat={summary.totalEbitda}
          isLoading={isLoading}
        />
        <SummaryDetail
          label={t(`dashboardPage.summary.availableCash`)}
          summaryStat={summary.availableCash}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
