/* eslint-disable class-methods-use-this */
import { ToAPI } from "models/core";
import { ReactQueryAPI } from "models/core/reactQuery.interface";
import { FetchError } from "hooks/api/useApiQuery";
import { KBOLookupAPI } from "./useKBOLookupUseCases";
import {
  GetKBOLookupResponse,
  KBOLookupOptionDTO,
} from "./models/KBOLookup.interface";

export type KBOLookupAPIDTO = ReactQueryAPI<GetKBOLookupResponse, FetchError>;

interface Mapper {
  toAPI: ToAPI<KBOLookupAPIDTO, KBOLookupAPI>;
}

export class KBOLookupMapper implements Mapper {
  public toAPI: Mapper["toAPI"] = (api) => {
    const extractDTOs = (
      data: KBOLookupAPIDTO["data"]
    ): KBOLookupOptionDTO[] => {
      if (Array.isArray(data as GetKBOLookupResponse))
        return data as KBOLookupOptionDTO[];
      if (data as GetKBOLookupResponse) return [data as KBOLookupOptionDTO];
      return [];
    };

    return {
      data: {
        options: extractDTOs(api?.data),
      },
      isLoading: (api?.isLoading || api?.isFetching) ?? false,
      error: api?.error ?? undefined,
    };
  };
}
