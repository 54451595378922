import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { HiInbox } from "react-icons/hi";
import { useRoutes, useSuppliers } from "domains";
import { NotFoundBlock } from "components/errors/404/NotFoundBlock";
import { PageHeader } from "components/PageHeader";
import { SupplierDetail } from "components/supplier/SupplierDetail";
import { useGetSupplier } from "../suppliers/queries/useGetSupplier";

const SupplierPage: FC = function () {
  const { t } = useTranslation();
  const { id } = useParams();
  const { routes } = useRoutes();
  const { supplier, isLoading, create } = useSuppliers(useGetSupplier({ id }));

  if (!isLoading && id && !supplier) {
    return (
      <NotFoundBlock
        href={routes.suppliers}
        entity={t("entity.supplier") as string}
      />
    );
  }

  return (
    <>
      <PageHeader
        className="bg-white dark:bg-gray-800"
        title={isLoading ? t("pageHeader.title.loading") : supplier?.name ?? ""}
        breadcrumbs={[
          {
            href: routes.suppliers,
            label: t("sidebar.title.suppliers") as string,
            icon: HiInbox,
          },
          {
            label: supplier?.name ?? "",
          },
        ]}
      />
      <SupplierDetail
        supplier={supplier}
        update={supplier?.update}
        create={create}
        isLoading={isLoading}
      />
    </>
  );
};

export default SupplierPage;
