import {
  ComponentProps,
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  MouseEvent,
  useState,
  useRef,
  useEffect,
} from "react";
import classnames from "classnames";
import { useThrottledCallback } from "use-debounce";

interface Props extends PropsWithChildren, ComponentProps<"div"> {
  isHoverable?: boolean;
  hoverActions?: ReactNode;
  isTemplate?: boolean;
}

export const InvoicePreview: FC<Props> = function ({
  children,
  isHoverable: isHoverableWithoutActions,
  hoverActions,
  isTemplate,
  onMouseEnter,
  onMouseLeave,
  ...rest
}) {
  const svgRef = useRef<SVGSVGElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const isHoverable: boolean = useMemo(
    () =>
      !!onMouseEnter ||
      !!onMouseLeave ||
      !!isHoverableWithoutActions ||
      !!hoverActions,
    [onMouseEnter, onMouseLeave, isHoverableWithoutActions, hoverActions]
  );

  const onMouseEnterWrapper = useCallback(
    (event: MouseEvent<HTMLDivElement>): void => {
      if (isHoverable) setIsHovered(true);
      onMouseEnter?.(event);
    },
    [isHoverable, onMouseEnter]
  );

  const onMouseLeaveWrapper = useCallback(
    (event: MouseEvent<HTMLDivElement>): void => {
      if (isHoverable) setIsHovered(false);
      onMouseLeave?.(event);
    },
    [isHoverable, onMouseLeave]
  );

  const setHeightSVG = useCallback(() => {
    if (svgRef?.current) {
      const foreignObject = svgRef?.current.querySelector("foreignObject");
      const contentHeight = foreignObject?.querySelector("div")?.offsetHeight;
      svgRef.current.setAttribute("height", contentHeight?.toString() || "0");
    }
  }, [svgRef]);

  useEffect(setHeightSVG, [children, setHeightSVG]);

  const scrollHandler = useThrottledCallback(setHeightSVG, 500);

  useEffect(() => {
    window.addEventListener("resize", scrollHandler);

    return () => {
      window.removeEventListener("resize", scrollHandler);
    };
  }, [scrollHandler]);

  return (
    <div
      {...rest}
      onMouseEnter={onMouseEnterWrapper}
      onMouseLeave={onMouseLeaveWrapper}
      className={classnames(
        "bg-white aspect-a4 w-full max-w-full rounded-md shadow-md relative h-fit",
        rest.className,
        {
          "cursor-pointer": isHoverable,
        }
      )}
    >
      {isHovered && (
        <div className="absolute h-full max-w-full aspect-a4 bg-gray-200 bg-opacity-50 flex justify-end p-2">
          {hoverActions}
        </div>
      )}
      <svg
        ref={svgRef}
        width="100%"
        preserveAspectRatio="xMinYMin meet"
        className="rounded-md h-full"
        viewBox={isTemplate ? "0 0 662 935" : undefined}
      >
        <foreignObject
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/1999/xhtml"
        >
          <div className="p-10 bg-white h-full overflow-y-scroll">
            {children}
          </div>
        </foreignObject>
      </svg>
    </div>
  );
};
