interface InvoiceProduct {
  id?: string;
  name?: string;
  description?: string;
  amountExclusiveVat?: number;
  vatPercentage?: number;
  quantity?: number;
}

export const isValidInvoiceProduct = ({
  id,
  name,
  description,
  amountExclusiveVat,
  vatPercentage,
}: InvoiceProduct) => {
  return (
    id ||
    ((description || name) &&
      amountExclusiveVat &&
      typeof vatPercentage === "number")
  );
};
