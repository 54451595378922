import { ChangeEvent, FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DevTool } from "@hookform/devtools";
import {
  FieldArrayWithId,
  FormProvider,
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
  useForm,
  useWatch,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { Label } from "flowbite-react";

import { event } from "event";
import { HTMLValidationSchemaResolver } from "helpers/html-validation-schema/compat/HTMLValidationSchemaResolver";
import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "helpers/html-validation-schema";
import { LanguageTabBar } from "components/LanguageTabBar";
import {
  Product,
  ProductDescription,
} from "hooks/products/models/product.interface";
import { supportedProductLanguages, useProductGroups } from "domains";
import { addMissingDescriptions } from "hooks/products/helpers/add-missing-descriptions";
import { WithAmount } from "models/paymentDocument/paymentDocument.interface";
import { ProductGroupTypeAhead } from "pages/products/components/ProductGroupTypeAhead";
import { useGetProductGroups } from "pages/productGroups/queries/useGetProductGroups";

import { InvoiceFormValues } from "../InvoiceForm";
import { TextInput } from "../../input/TextInput";
import { LoadingButton } from "../../LoadingButton";
import { Checkbox } from "../../Checkbox";

export interface CustomProduct {
  name: string;
  amountExclusiveVat: number;
  vatPercentage: number;
  quantity: number;
  discount?: number;
  reference: string;
  descriptions: ProductDescription[];
  articleGroupId?: string;
}

interface CustomProductFormProps {
  isLoading: boolean;
  index?: string;
  submit: (input: CustomProduct) => Promise<Product>;
}

export interface ProductFieldArrayMethods {
  fields: FieldArrayWithId<InvoiceFormValues, "documentLines">[];
  append: UseFieldArrayAppend<InvoiceFormValues, "documentLines">;
  update: UseFieldArrayUpdate<InvoiceFormValues, "documentLines">;
}

const schema:
  | HTMLValidationSchema<CustomProduct>
  | HTMLValidationSchemaGenerator<CustomProduct> = {
  name: { required: false },
  amountExclusiveVat: { required: true },
  vatPercentage: { required: true },
  quantity: { required: true },
  discount: { required: false },
  reference: { required: true },
  articleGroupId: { required: false },
  descriptions: [
    {
      code: { required: true },
      description: { required: true },
    },
  ],
};

export const CustomProductForm: FC<
  CustomProductFormProps & ProductFieldArrayMethods
> = function ({ index, isLoading, submit, fields, append, update }) {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const [preserveProduct, setPreserveProduct] = useState(false);
  const row = Number(index) ?? 0;
  const product: WithAmount<Product> = useWatch({ name: `products.${row}` });

  const { productGroups } = useProductGroups(useGetProductGroups());

  const supportedLanguages = useMemo(() => {
    return addMissingDescriptions(
      product,
      supportedProductLanguages
    ).descriptions.map((description) => description.code);
  }, [product]);

  const methods = useForm<CustomProduct>({
    defaultValues: {
      descriptions: addMissingDescriptions(
        product,
        supportedProductLanguages
      ).descriptions.map((desc) => ({
        ...desc,
        description:
          desc.description.trim().length > 0
            ? desc.description
            : searchParams.get("initialValue") ?? "",
      })),
      quantity: product?.quantity,
      vatPercentage: product?.vatPercentage || 21,
    },
    mode: "onBlur",
    resolver: HTMLValidationSchemaResolver(schema),
  });

  const { control, register, handleSubmit, formState } = methods;

  const articleGroupId = useWatch<CustomProduct>({
    control,
    name: "articleGroupId",
  });

  const selectedProductGroup = useMemo(() => {
    return productGroups.find(({ id }) => id === articleGroupId)?.name;
  }, [productGroups, articleGroupId]);

  const updateFieldArray = useCallback(
    (row: number, input: CustomProduct & { id?: string }) => {
      if (fields.length > row) {
        update(row, input);
      } else {
        append(input);
      }

      event.emit("customProductCreated");
    },
    [update, append, fields]
  );

  const onSubmit = useCallback(
    (input: CustomProduct) => {
      const productDescriptionByCurrentLanguage = input.descriptions.find(
        (desc) => {
          return desc.code.toLowerCase() === i18n.language.toLowerCase();
        }
      );

      const inputWithTranslatedName = {
        ...input,
        description: productDescriptionByCurrentLanguage?.description ?? "",
      };

      if (preserveProduct) {
        submit({ ...input }).then((data) =>
          updateFieldArray(row, { id: data?.id, ...inputWithTranslatedName })
        );
      } else {
        updateFieldArray(row, inputWithTranslatedName);
      }
    },
    [preserveProduct, i18n.language, submit, updateFieldArray, row]
  );

  const selectPreserveProduct = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) =>
      setPreserveProduct(target.checked),
    [setPreserveProduct]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4 p-6">
          <div className="w-full">
            <LanguageTabBar
              languages={supportedLanguages}
              tabs={[
                ...supportedLanguages.map((lang, index) => ({
                  language: lang,
                  hasError:
                    !!formState.errors.descriptions?.[index]?.description
                      ?.message,
                  content: (
                    <div className="flex gap-4">
                      <div className="w-full">
                        <div className="mb-2 block">
                          <Label
                            htmlFor={`descriptions.${index}.description`}
                            value={t("productForm.label.title") ?? ""}
                          />
                        </div>
                        <TextInput
                          id={`descriptions.${index}.description`}
                          type="text"
                          {...register(`descriptions.${index}.description`)}
                          errorMessage={
                            formState.errors.descriptions?.[index]?.description
                              ?.message
                          }
                        />
                      </div>
                    </div>
                  ),
                })),
              ]}
            />
          </div>
          <div className="flex gap-4">
            <div className="w-1/2">
              <div className="mb-2 block">
                <Label
                  htmlFor="articleGroupId"
                  value={t("productForm.label.productGroup") ?? ""}
                />
              </div>
              <ProductGroupTypeAhead
                id="articleGroupId"
                selectedProductGroup={selectedProductGroup}
              />
            </div>
            <div className="w-1/2">
              <div className="mb-2 block">
                <Label
                  htmlFor="quantity"
                  value={t("customProductForm.label.quantity") ?? ""}
                />
              </div>
              <TextInput
                id="quantity"
                type="number"
                min={1}
                {...register("quantity", {
                  valueAsNumber: true,
                  required: true,
                })}
                errorMessage={formState.errors.quantity?.message}
              />
            </div>
          </div>
          <div className="flex gap-4">
            <div className="w-1/2">
              <div className="mb-2 block">
                <Label
                  htmlFor="amountExclusiveVat"
                  value={t("customProductForm.label.amountExclusiveVat") ?? ""}
                />
              </div>
              <TextInput
                id="amountExclusiveVat"
                addon="€"
                type="number"
                step="0.01"
                {...register("amountExclusiveVat", {
                  valueAsNumber: true,
                  required: true,
                })}
                errorMessage={formState.errors.amountExclusiveVat?.message}
              />
            </div>
            <div className="w-1/2">
              <div className="mb-2 block">
                <Label
                  htmlFor="vatPercentage"
                  value={t("customProductForm.label.vat") ?? ""}
                />
              </div>
              <TextInput
                id="vatPercentage"
                addon="%"
                type="number"
                {...register("vatPercentage", {
                  valueAsNumber: true,
                  required: true,
                })}
                errorMessage={formState.errors.vatPercentage?.message}
              />
            </div>
          </div>
          <div className="flex gap-4">
            <div className="w-1/2">
              <div className="mb-2 block">
                <Label
                  htmlFor="reference"
                  value={t("customProductForm.label.reference") ?? "="}
                />
              </div>
              <TextInput
                id="reference"
                type="text"
                {...register("reference", { required: true })}
                errorMessage={formState.errors.reference?.message}
              />
            </div>
            <div className="w-1/2">
              <div className="mb-2 block">
                <Label
                  htmlFor="discount"
                  value={t("customProductForm.label.discount") ?? ""}
                />
              </div>
              <TextInput
                id="discount"
                addon="%"
                type="number"
                {...register("discount", {
                  valueAsNumber: true,
                  required: false,
                })}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center">
            <Checkbox
              id="createNew"
              checked={preserveProduct}
              onChange={selectPreserveProduct}
            />
            <Label htmlFor="createNew">
              {t(`Save product for future usage`)}
            </Label>
          </div>
        </div>
        <div className="border-1 border-t p-6 dark:border-gray-700">
          <LoadingButton
            loading={isLoading}
            className="px-2 py-0.5"
            type="submit"
          >
            {t("customProductForm.button.save")}
          </LoadingButton>
        </div>
      </form>
      <DevTool control={methods.control} /> {/* set up the dev tool */}
    </FormProvider>
  );
};
