/* eslint-disable no-nested-ternary */
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  HiOutlineBell,
  HiEye,
  HiOutlinePencilAlt,
  HiTrash,
  HiOutlinePaperAirplane,
  HiOutlineClock,
} from "react-icons/hi";
import format from "date-fns/format";
import {
  Table,
  Dropdown,
  Tooltip,
  Badge as FlowbiteBadge,
} from "flowbite-react";
import { useRoutes } from "domains";
import { RoundedIconDropdown } from "components/table/RoundedIconDropdown";
import { Currency, toCurrency } from "helpers/to-currency";
import {
  invoiceBadgeMap,
  invoiceStatusBadgeMap,
  quoteBadgeMap,
} from "helpers/invoice-badge-map";
import {
  InvoicePayStatus,
  InvoiceStatus,
  QuoteStatus,
} from "models/paymentDocument/invoice/invoice.interface";
import Badge from "../Badge";
import { Checkbox } from "../Checkbox";
import { TableRow } from "../table/TableRow";

interface InvoiceTableRowProps {
  id: string;
  invoiceNumber?: number | string;
  quoteNumber?: number;
  invoiceStatus?: string;
  daysSinceLastReminder?: number;
  amountOfReminders?: number;
  companyName?: string;
  issueDate?: string;
  currency?: string;
  uploadDate?: string;
  invoicePayStatus?: InvoicePayStatus;
  quoteStatus?: QuoteStatus;
  selected: boolean;
  totalInclusiveVat: number;
  onSelect: (id: string) => void;
  onEdit: (id: string) => void;
  isPurchase?: boolean;
}

export const InvoiceTableRow: FC<InvoiceTableRowProps> = function ({
  id,
  invoiceNumber,
  quoteNumber,
  invoiceStatus,
  companyName,
  totalInclusiveVat,
  currency,
  uploadDate,
  issueDate,
  invoicePayStatus,
  quoteStatus,
  daysSinceLastReminder = 0,
  amountOfReminders = 0,
  selected,
  onSelect,
  onEdit,
  isPurchase,
  // ...rest
}) {
  const {
    openDeleteModal,
    openRemindModal,
    goToSalesInvoicesSendToAccountant,
    goToSalesInvoicesSendToCustomer,
    goToPurchaseInvoicesSendToAccountant,
  } = useRoutes();
  const { t } = useTranslation();

  const onOpenDeleteModal = useCallback(
    () => openDeleteModal([id]),
    [id, openDeleteModal]
  );

  const edit = useCallback(() => {
    onEdit(id);
  }, [onEdit, id]);

  const select = useCallback(() => onSelect(id), [id, onSelect]);

  const handleOpenRemind = useCallback(() => {
    onSelect(id);
    openRemindModal();
  }, [id, onSelect, openRemindModal]);

  const handleOpenSendToAccountant = useCallback(() => {
    onSelect(id);
    if (isPurchase) {
      goToPurchaseInvoicesSendToAccountant();
    } else {
      goToSalesInvoicesSendToAccountant();
    }
  }, [
    id,
    onSelect,
    isPurchase,
    goToPurchaseInvoicesSendToAccountant,
    goToSalesInvoicesSendToAccountant,
  ]);

  const handleOpenSendToCustomer = useCallback(() => {
    onSelect(id);
    goToSalesInvoicesSendToCustomer();
  }, [id, onSelect, goToSalesInvoicesSendToCustomer]);

  return (
    <TableRow onClick={edit}>
      <Table.Cell>
        <Checkbox onChange={select} checked={selected} />
      </Table.Cell>
      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
        {companyName ?? "-"}
      </Table.Cell>
      <Table.Cell>
        <div className="flex items-center">
          <span> #{(invoiceNumber || quoteNumber) ?? t("draft")}</span>
        </div>
      </Table.Cell>
      <Table.Cell>{issueDate ?? "-"}</Table.Cell>
      <Table.Cell>
        {totalInclusiveVat
          ? toCurrency(
              totalInclusiveVat,
              (currency as Currency) || Currency.Euro
            )
          : "-"}
      </Table.Cell>
      {isPurchase && (
        <Table.Cell>
          {uploadDate ? format(new Date(uploadDate), "dd/MM/yyyy") : "-"}
        </Table.Cell>
      )}
      <Table.Cell>
        {invoiceStatus ? (
          <Badge value={invoiceStatus} map={invoiceStatusBadgeMap} />
        ) : quoteStatus ? (
          <Badge value={quoteStatus} map={quoteBadgeMap} />
        ) : (
          "-"
        )}
      </Table.Cell>
      {invoicePayStatus && (
        <Table.Cell>
          <div className="flex align-center">
            <Badge value={invoicePayStatus} map={invoiceBadgeMap} />
            {!isPurchase && amountOfReminders > 0 && (
              <Tooltip
                content={
                  <div className="flex flex-col">
                    <span>
                      {t("invoiceForm.input.reminder.amount", {
                        amountOfReminders,
                      })}
                    </span>
                    <span>
                      {t("invoiceForm.input.reminder.send", {
                        daysSinceLastReminder,
                      })}
                    </span>
                  </div>
                }
              >
                <div className="flex items-center ml-2">
                  <HiOutlineClock size="20" />
                  <FlowbiteBadge
                    color="red"
                    className="ml-1 mt-[-10px] rounded-full w-[25px] h-[25px] flex justify-center"
                  >
                    {amountOfReminders}
                  </FlowbiteBadge>
                </div>
              </Tooltip>
            )}
          </div>
        </Table.Cell>
      )}
      <Table.Cell>
        <RoundedIconDropdown className="w-max mr-2">
          {invoiceStatus !== InvoiceStatus.SendToAccountant && (
            <Dropdown.Item
              className="flex items-center py-3 rounded-t-lg"
              onClick={edit}
              role="button"
            >
              {invoicePayStatus === InvoicePayStatus.Paid ? (
                <>
                  <HiEye className="mr-2 text-lg" />
                  {t("invoiceTable.button.view")}
                </>
              ) : (
                <>
                  <HiOutlinePencilAlt className="mr-2 text-lg" />
                  {t("invoiceTable.button.edit")}
                </>
              )}
            </Dropdown.Item>
          )}
          {invoiceStatus === InvoiceStatus.Saved && !isPurchase && (
            <Dropdown.Item
              className="flex items-center align-center py-3 rounded-b-lg"
              onClick={handleOpenSendToCustomer}
              role="button"
            >
              <HiOutlinePaperAirplane className="mr-2 text-lg shrink-0 rotate-45" />
              <span className="shrink-0">
                {t("invoiceTable.button.sendToCustomer")}
              </span>
            </Dropdown.Item>
          )}
          {(invoiceStatus === InvoiceStatus.SendToCustomer ||
            (isPurchase &&
              invoiceStatus !== InvoiceStatus.SendToAccountant)) && (
            <Dropdown.Item
              className="flex items-center align-center py-3 rounded-b-lg"
              onClick={handleOpenSendToAccountant}
              role="button"
            >
              <HiOutlinePaperAirplane className="mr-2 text-lg shrink-0 rotate-45" />
              <span className="shrink-0">
                {t("invoiceTable.button.sendToAccountant")}
              </span>
            </Dropdown.Item>
          )}
          {invoicePayStatus === InvoicePayStatus.OverDue &&
            (invoiceStatus === InvoiceStatus.SendToAccountant ||
              invoiceStatus === InvoiceStatus.SendToCustomer) && (
              <Dropdown.Item
                className="flex items-center py-3 rounded-b-lg"
                onClick={handleOpenRemind}
                role="button"
              >
                <HiOutlineBell className="mr-2 text-lg shrink-0" />
                <span className="shrink-0">
                  {t("invoiceTable.button.remind")}
                </span>
              </Dropdown.Item>
            )}
          {invoiceStatus !== InvoiceStatus.SendToAccountant &&
            invoiceStatus !== InvoiceStatus.SendToCustomer && (
              <Dropdown.Item
                className="flex items-center py-3 rounded-b-lg"
                onClick={onOpenDeleteModal}
                role="button"
              >
                <HiTrash className="mr-2 text-lg text-red-500 shrink-0" />
                <span className="text-red-500 shrink-0">
                  {t("invoiceTable.button.delete")}
                </span>
              </Dropdown.Item>
            )}
        </RoundedIconDropdown>
      </Table.Cell>
    </TableRow>
  );
};
