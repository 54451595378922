import { useCallback, useEffect, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { HiDocumentReport } from "react-icons/hi";
import { useRoutes, useQuotes, useTables } from "domains";
import { useTablesEffects } from "hooks/tables/useTablesUseCases";
import { JournalTypes } from "hooks/journals/models/journal.interface";
import { JournalContext } from "context/JournalContext";
import { ActionBar, PageHeader } from "components/PageHeader";
import { DeleteModal } from "components/modals/DeleteModal";
import { QuoteStatus } from "models/paymentDocument/invoice/invoice.interface";
import { AUTO_REFRESH_INTERVAL_KEY } from "components/AutoRefresh";
import { UploadCSV } from "components/UploadCSV";
import { useGetQuotes } from "./queries/useGetQuotes";
import QuoteTable from "./components/quoteTable";
import { QUOTES_XSLX_KEY, useGetQuotesXLSX } from "./queries/useGetQuotesXLSX";

function QuotesPage() {
  const [searchParams] = useSearchParams();
  const [idsToDelete, setIdsToDelete] = useState<string[]>([]);
  const [refetchInterval, setRefetchInterval] = useState<number>(
    parseInt(localStorage.getItem(AUTO_REFRESH_INTERVAL_KEY) ?? "", 10)
  );
  const quotesTable = useTables();
  const { routes, goToQuotes } = useRoutes();
  const { t } = useTranslation();
  const { rowSelectedState, handleAllRowsClicked, handleOneRowClicked } =
    quotesTable;
  useTablesEffects(quotesTable);

  const { journal } = useContext(JournalContext);
  const journalId = journal[JournalTypes.SALES];

  const getQuotes = useGetQuotes({
    limit: Number(searchParams.get("limit")) || 10,
    offset: Number(searchParams.get("offset")) || 0,
    search: searchParams.get("search") || "",
    sortKey: searchParams.get("sortKey") || "",
    sortOrder: searchParams.get("sortOrder") || "",
    from: searchParams.get("from") || "",
    to: searchParams.get("to") || "",
    quoteStatus: searchParams.get("quoteStatus") || "",
    journalId,
    refetchInterval,
  });

  const getXLSX = useGetQuotesXLSX({
    search: searchParams.get("search") || "",
    sortKey: searchParams.get("sortKey") || "",
    sortOrder: searchParams.get("sortOrder") || "",
  });

  const { quotes, count, deleteMany, isLoading, error, uploadCSV } =
    useQuotes(getQuotes);

  const { action } = useParams();

  const onDeleteQuote = useCallback(
    () => deleteMany.execute({ ids: idsToDelete }),
    [idsToDelete, deleteMany]
  );

  useEffect(() => {
    if (action === "delete") {
      const ids = searchParams.getAll("idsToDelete");
      setIdsToDelete(ids);
    }
  }, [action, setIdsToDelete, searchParams]);

  return (
    <div className="flex flex-col">
      <PageHeader
        className="bg-white dark:bg-gray-800"
        title={t("pageHeader.title.quotes")}
        breadcrumbs={[
          {
            href: routes.quotes,
            label: t("sidebar.title.quotes"),
            icon: HiDocumentReport,
          },
        ]}
      >
        <ActionBar
          showBulkDelete
          showTimeFilter
          dropdownKey="quoteStatus"
          dropdownOptions={Object.values(QuoteStatus).map((value) => ({
            key: value,
            value: t(`quotesPage.dropdown.quoteStatus.${value}`),
          }))}
          pageKey="quotes"
          onRefetch={getQuotes.refetch}
          onRefetchIntervalChange={setRefetchInterval}
          rowSelectedState={rowSelectedState}
          queryKey={QUOTES_XSLX_KEY}
          {...getXLSX}
        />
      </PageHeader>
      <QuoteTable
        rows={quotes}
        limit={Number(searchParams.get("limit")) || 10}
        isLoading={isLoading}
        total={count}
        handleAllRowsClicked={handleAllRowsClicked}
        handleOneRowClicked={handleOneRowClicked}
        rowSelectedState={rowSelectedState}
      />
      {action === "delete" && !!idsToDelete && (
        <DeleteModal
          isLoading={isLoading}
          isDeleting={deleteMany.isLoading}
          isError={!!error}
          isMultiple={idsToDelete.length > 1}
          page="quotes"
          show={!!idsToDelete}
          onClose={goToQuotes}
          onDelete={onDeleteQuote}
        />
      )}
      <UploadCSV
        action={action}
        uploadCSV={uploadCSV}
        onClose={goToQuotes}
        page="quotes"
      />
    </div>
  );
}

export default QuotesPage;
