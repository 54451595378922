import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { LoadingButton } from "components/LoadingButton";

interface ConvertModalProps {
  onClose: () => void;
  onConvert: () => void;
  isLoading?: boolean;
}

export const ConvertModal: FC<ConvertModalProps> = function ({
  onConvert,
  onClose,
  isLoading,
}: ConvertModalProps) {
  const { t } = useTranslation();

  return (
    <>
      <p className="text-base text-center leading-relaxed text-gray-400 dark:text-gray-400">
        {t("convertModal.body.info")}
      </p>
      <div className="flex gap-4 justify-center">
        <LoadingButton onClick={onConvert} loading={isLoading}>
          {t("convertModal.footer.convert")}
        </LoadingButton>
        <Button color="gray" onClick={onClose}>
          {t("convertModal.footer.back")}
        </Button>
      </div>
    </>
  );
};
