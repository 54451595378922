import { useEffect, useState, useRef } from "react";

const useComponentWidth = (): [number, React.RefObject<HTMLDivElement>] => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    const observable = ref.current;

    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (entries && entries.length > 0) {
        const { width } = entries[0].contentRect;
        setWidth(width);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (observable) {
      resizeObserver.observe(observable);
    }

    return () => {
      if (observable) {
        resizeObserver.unobserve(observable);
      }
    };
  }, []);

  return [width, ref];
};

export { useComponentWidth };
